import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';
import * as olCoordinate from 'ol/coordinate';
import _cloneDeep from 'lodash/cloneDeep';
import { fromGeoJSON, toGeoJSON } from '../../common/geojson';
import { Map } from 'ol';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';

export function isFirstSegment(polyline: LineString, { start }): boolean {
  const coords = polyline.getCoordinates();
  return olCoordinate.equals(coords[0], start);
}

export function isLastSegment(polyline: LineString, { end }): boolean {
  const coords = polyline.getCoordinates();
  return olCoordinate.equals(coords[coords.length - 1], end);
}

/**
 *
 * @param {Object} line The GeoJSON of a ol.geom.LineString
 */
export function lineToMultiLine(map: Map, gLine) {
  const [line] = fromGeoJSON(map, gLine) as Feature<LineString>[];
  const coordinates: Coordinate[][] = [];
  line.getGeometry()!.forEachSegment((startCoord, endCoord) => {
    coordinates.push([[...startCoord], [...endCoord]]);
  });
  const multiLine = new Feature(new MultiLineString(coordinates));
  multiLine.setProperties(_cloneDeep(gLine.properties));
  return toGeoJSON(map, multiLine);
}

/**
 * Get the length of the line in pixels.
 * @param {ol.Map} map
 * @param {ol.geom.LineString} lineGeometry
 */
export function getDisplayLength(map: Map, polyline: LineString) {
  let result = 0;

  polyline.forEachSegment((start, end) => {
    const startPixel = map.getPixelFromCoordinate(start);
    const endPixel = map.getPixelFromCoordinate(end);
    result += olCoordinate.distance(startPixel, endPixel);
  });

  return result;
}
