import _isEmpty from 'lodash/isEmpty';
import _cloneDeepWith from 'lodash/cloneDeepWith';
import * as layout from './layout';

export function preprocessFigure(figure) {
  const { builtin_callouts_by_sample: builtinCalloutsBySample } = figure;
  if (
    builtinCalloutsBySample &&
    Array.isArray(builtinCalloutsBySample) &&
    _isEmpty(builtinCalloutsBySample)
  ) {
    return {
      ...figure,
      builtin_callouts_by_sample: {},
    };
  }

  return figure;
}

export function checkIsSampleExceedancePlan(figure) {
  const { scenarios, chemicals } = figure;
  return !chemicals && scenarios;
}

export function checkIsSpecificChemicalPlan(figure) {
  const { scenarios, chemicals } = figure;
  return !!(scenarios && chemicals);
}

export function checkIsGatherCalloutEnabled(figure) {
  if (!figure.gather_callout_settings) {
    return [];
  }

  const {
    gather_callout_settings: { selectedFieldsByTab },
  } = figure;
  return !_isEmpty(selectedFieldsByTab);
}

export function convertCalloutSettings(settings) {
  return _cloneDeepWith(
    {
      selectedFieldsByTab: {},
      isSectionHeaderVisibleByTab: {},
      ...settings,
    },
    (value, key) => {
      if (
        ['selectedFieldsByTab', 'isSectionHeaderVisibleByTab'].includes(key) &&
        Array.isArray(value) &&
        _isEmpty(value)
      ) {
        return {};
      }
    }
  );
}

export function updateFontSizeOfBuiltinCalloutsBySample(
  builtinCalloutsBySample,
  fontSize
) {
  return Object.keys(builtinCalloutsBySample).reduce((accu, sampleId) => {
    const builtinCallouts = builtinCalloutsBySample[sampleId];
    return {
      ...accu,
      [sampleId]: builtinCallouts.map((item) => ({
        ...item,
        geojson: {
          ...item.geojson,
          properties: {
            ...item.geojson.properties,
            fontSize,
          },
        },
      })),
    };
  }, {});
}

export function updateBuiltinCalloutsBySample(
  builtinCalloutsBySample,
  sampleId,
  builtinCallout
) {
  const builtinCallouts = builtinCalloutsBySample[sampleId] ?? [];
  const nextBuiltinCallouts = [...builtinCallouts];
  const builtinCalloutIndex = nextBuiltinCallouts.findIndex(
    (item) =>
      item.geojson.properties.contentType ===
      builtinCallout.geojson.properties.contentType
  );
  if (builtinCalloutIndex === -1) {
    nextBuiltinCallouts.push(builtinCallout);
  } else {
    nextBuiltinCallouts.splice(builtinCalloutIndex, 1, builtinCallout);
  }
  return {
    ...builtinCalloutsBySample,
    [sampleId]: nextBuiltinCallouts,
  };
}

export function checkIsBuiltinCalloutPersistent(
  builtinCalloutsBySample,
  sampleId,
  contentType
) {
  return !!builtinCalloutsBySample[sampleId]?.some(
    (item) => item.geojson.properties.contentType === contentType
  );
}

export type FigureSettings = {
  layout: layout.FigureLayout;
};

export type UpdateFigureSettingsPayload = (draft: FigureSettings) => void;

export function roundFigureViewScale(value: number): number {
  return Math.round(value);
}

export function formatFigureViewScale(value: number): string {
  if (!value) {
    return 'Auto';
  }

  return `1:${roundFigureViewScale(value)}`;
}
