import { STROKE_PATTERN_SOLID } from '@component-library/business-model/common';
import * as bl_color from '../color';
import * as bl_layer from '../layer';
import { layer_types } from '../layer';

export const ALIGNMENT_LEFT = 0;
export const ALIGNMENT_CENTER = 1;

export function createImagePlaceholder() {
  return { url: null, isFromGather: false };
}

export const DEFAULT_OPTIONS = Object.freeze({
  text: '',
  image_data: Object.freeze(createImagePlaceholder()),
  mapData: null,
  isTextUsedAsCaption: false,
  // text options
  textColor: bl_color.BLACK,
  textOutlineColor: bl_color.WHITE,
  fontSize: 9,
  alignment: ALIGNMENT_LEFT,
  isItalic: false,
  isBold: false,
  isUnderlined: false,
  // box options
  width: 200,
  height: 200,
  color: bl_color.BLACK, // outline colour
  outlineStyle: STROKE_PATTERN_SOLID - 1,
  weight: 2, // outline thickness
  opacity: 1, // outline opacity
  backgroundColor: bl_color.WHITE,
  backgroundOpacity: 1,
  isAutoPositioningEnabled: true,
  // connector options
  connectorColor: bl_color.BLACK,
  connectorPattern: STROKE_PATTERN_SOLID - 1,
  connectorWeight: 2,
  connectorOpacity: 1,
});

export enum Usage {
  ShowText = 1,
  ShowImage = 2,
  ShowMap = 3,
}

export const UsageOptions = [
  { id: Usage.ShowText, label: 'Show text' },
  { id: Usage.ShowImage, label: 'Show image' },
  { id: Usage.ShowMap, label: 'Show map' },
];

export const IMAGE_CALL_OUT_DEFAULT_TEXT = 'Uncaptioned image call-out';
export const MAP_CALL_OUT_DEFAULT_TEXT = 'Uncaptioned map call-out';

export function getUsage(options) {
  const { usage } = options;
  if (usage) {
    return usage;
  }

  const { image_data } = options;
  return image_data.url ? Usage.ShowImage : Usage.ShowText;
}

export type MapData = {
  basemapApiIndex: number;
  // Deprecated but must be retained here for backward-compatibility.
  layerId: number;
  layerIds: number[] | undefined;
};

export function getLayerOptions(layerTree): { label: string; value: string }[] {
  return bl_layer
    .flattenLayerTree(layerTree)
    .filter((layer) => {
      return [
        layer_types.ARROW,
        layer_types.CHAINAGE,
        layer_types.CIRCLE,
        layer_types.FEATURE_COLLECTION,
        layer_types.HEDGE,
        layer_types.IMAGE,
        layer_types.POLYGON,
        layer_types.POLYLINE,
        layer_types.RECTANGLE,
        layer_types.SAMPLE_GROUP,
        layer_types.SITE_BOUNDARY,
        layer_types.TEXT,
        layer_types.BUFFER,
      ].includes(layer.data.properties.type);
    })
    .map((layer) => {
      return {
        label: bl_layer.getLayerTitle(layer),
        value: String(layer.data.id),
      };
    })
    .sort((lo1, lo2) => lo1.label.localeCompare(lo2.label));
}

export function getLayerIds(
  mapData: { layerIds: number[] | undefined; layerId: number } | undefined
): number[] {
  if (!mapData) {
    return [];
  }

  return mapData.layerIds ?? [mapData.layerId];
}
