import { getUnit } from '../adapter/project';
import type { StoreApi } from '../common/store-api';
import type { Id } from '../common/types';
import type { Figure, Project } from '../figure/types';
import type { Sample } from '../layer/sample/types';
import { CriteriaType, SampleExceedanceColor } from './types';
import { checkIsSpecificChemicalPlan } from './utils';

export function checkHasResults(sample: Sample, figure: Figure): boolean {
  return !!figure.results?.some((result) =>
    result.sample_items.some((si) => si.sample_id === sample.id)
  );
}

export const checkHasResultsFully = (
  sample: Sample,
  figure: Figure
): boolean => {
  if (checkHasResults(sample, figure)) {
    return true;
  }

  const { duplicate } = sample;
  if (duplicate && checkHasResults(duplicate, figure)) {
    return true;
  }

  const { child_samples = [] } = sample;
  if (
    child_samples.some(
      (cs) =>
        checkHasResults(cs, figure) ||
        (cs.duplicate && checkHasResults(cs.duplicate, figure))
    )
  ) {
    return true;
  }

  return false;
};

export function checkIsExceedanceStylingUsed(
  figure: Figure,
  sample: Sample
): boolean {
  const { hide_sample_exceedance_styling } = figure;

  return (
    !hide_sample_exceedance_styling &&
    checkIsSpecificChemicalPlan(figure) &&
    checkHasResultsFully(sample, figure)
  );
}

function formatSampleDepth(sample: Sample, project: Project): string | null {
  const { start_depth, end_depth } = sample;

  // cant use ! as some depths may be 0
  if (start_depth === null) {
    return null;
  }

  const unit = getUnit(project);
  return (
    `${start_depth}${unit.shortName}` +
    `${end_depth ? ' - ' + end_depth + unit.shortName : ''}`
  );
}

function checkShouldSkipSample(
  sample: Sample,
  hiddenFormattedDepths: (string | null)[],
  project: Project
): boolean {
  const formattedDepth = formatSampleDepth(sample, project);
  return hiddenFormattedDepths.includes(formattedDepth);
}

export function getAllSampleIdsFromSample(
  sample: Sample,
  hiddenFormattedDepths: (string | null)[],
  project: Project
): Id[] {
  const result: Id[] = [];

  if (!checkShouldSkipSample(sample, hiddenFormattedDepths, project)) {
    result.push(sample.id);
  }

  if (
    sample.duplicate &&
    !checkShouldSkipSample(sample.duplicate, hiddenFormattedDepths, project)
  ) {
    result.push(sample.duplicate.id);
  }

  sample.child_samples?.forEach((cs) => {
    if (!checkShouldSkipSample(cs, hiddenFormattedDepths, project)) {
      result.push(cs.id);
    }

    if (
      cs.duplicate &&
      !checkShouldSkipSample(cs.duplicate, hiddenFormattedDepths, project)
    ) {
      result.push(cs.duplicate.id);
    }
  });

  return result;
}

function getResultExceedanceBySample(
  figure: Figure,
  sample: Sample,
  type: 'sample' | 'result',
  storeApi: StoreApi
) {
  const project = storeApi.getProject();
  const { scenarios, chemicals } = figure;
  const isChemicalFigure = !!chemicals;
  const hiddenFormattedDepths =
    figure.enviro_callout_filter?.hiddenFormattedDepths ?? [];

  const allSampleIds = getAllSampleIdsFromSample(
    sample,
    hiddenFormattedDepths,
    project
  );

  // Filter results by samples that exceed
  let itemIdsExceeding: Id[] = [];
  if (isChemicalFigure && type === 'sample') {
    const results = figure.results || [];
    results.forEach((chemical) => {
      itemIdsExceeding = [
        ...itemIdsExceeding,
        ...chemical.sample_items
          .filter((r) => allSampleIds.includes(r.sample_id))
          .map((r) => r.id),
      ];
    });
  }

  let exceedanceTypes: any[] = [];
  scenarios.forEach((scenario) => {
    const exceedance = storeApi.getExceedance(scenario.criteria_type);

    const criteriaTypeExceedances =
      exceedance.exceedances.filter(
        (cte) =>
          ((scenario.criteria_type === CriteriaType.Landuse &&
            cte.scenario_id == scenario.scenario_id) ||
            (scenario.criteria_type === CriteriaType.Criteria &&
              cte.criteria_set_id == scenario.criteria_set_id)) &&
          cte.exceedances.findIndex((re) =>
            allSampleIds.includes(re.sample_id)
          ) != -1 &&
          (cte.document_id ? scenario.document_id == cte.document_id : true)
      ) ?? [];

    if (criteriaTypeExceedances.length == 0) {
      return false;
    }

    // chemical based figures should only show an exceedance if any of the results in popup exceed
    criteriaTypeExceedances.forEach((cte) => {
      if (type == 'sample') {
        if (
          isChemicalFigure &&
          cte.exceedances.filter((re) => itemIdsExceeding.includes(re.item_id))
            .length > 0
        ) {
          exceedanceTypes.push(scenario.criteria_type);
        }

        if (!isChemicalFigure) {
          exceedanceTypes.push(scenario.criteria_type);
        }
      } else {
        exceedanceTypes.push({
          criteriaType: scenario.criteria_type,
          ...cte,
        });
      }
    });
  });

  return exceedanceTypes;
}

export function getSampleExceedanceColor(
  figure: Figure,
  sample: Sample,
  storeApi: StoreApi
): SampleExceedanceColor {
  const exceedances = getResultExceedanceBySample(
    figure,
    sample,
    'sample',
    storeApi
  );

  if (exceedances.includes('landuse')) {
    return SampleExceedanceColor.Landuse;
  }

  if (exceedances.includes('criteria')) {
    return SampleExceedanceColor.Criteria;
  }

  return SampleExceedanceColor.NoExceedances;
}
