import store from '@/js/store';
import * as constants from './constants';
import createMoveResizeRotateEdit from './createMoveResizeRotateEdit';
import createGeoreferenceEdit from './createGeoreferenceEdit';
import { NAMESPACE } from '../../../../store';

export { constants };

export function createEdit(map) {
  let currentEdit;

  return {
    useEditMethod(editMethodCode) {
      if (currentEdit?.editMethodCode === editMethodCode) {
        return;
      }

      let feature;
      if (currentEdit) {
        feature = currentEdit.getFeature();
        currentEdit.destroy(map);
      }

      const { currentOtherLayer: mapImage } = map.getViewer();
      if (editMethodCode === constants.EDIT_METHOD_CODES.MOVE_RESIZE_ROTATE) {
        mapImage.setVisible(true);
        currentEdit = createMoveResizeRotateEdit(map);
      } else if (editMethodCode === constants.EDIT_METHOD_CODES.GEOREFERENCE) {
        mapImage.setVisible(false);
        currentEdit = createGeoreferenceEdit(map);
      } else {
        throw `Unknown image edit method code: ${editMethodCode}`;
      }

      if (feature) {
        currentEdit.activate(map);
        currentEdit.selectFeature(feature);
      }
    },
    selectFeature(feature) {
      currentEdit.selectFeature(feature);
    },
    getFeature() {
      return currentEdit.getFeature();
    },
    activate(map) {
      currentEdit.activate(map);
    },
    destroy(map) {
      currentEdit.destroy(map);
      NAMESPACE.dispatch(store, 'resetImageEditMethodCode');
    },
    addControlPoints(topLeftCoord, bottomRightCoord) {
      if (
        currentEdit?.editMethodCode !== constants.EDIT_METHOD_CODES.GEOREFERENCE
      ) {
        return;
      }

      currentEdit.addControlPoints(topLeftCoord, bottomRightCoord);
    },
  };
}
