export function getStyledMapIconUrl(mapIconUrl, color) {
  const pattern = /^\/images\/map_icons\/([^\/]+)\/(.+)\.svg/;
  const match = mapIconUrl.match(pattern);

  if (!match) {
    throw `Unsupported map icon URL: ${mapIconUrl}`;
  }

  const folder = match[1];
  const name = match[2];
  const colorParam = encodeURIComponent(color);

  return `/styled-map-icon/${folder}/${name}?fill=${colorParam}&stroke=${colorParam}`;
}
