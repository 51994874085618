import Api from '../../../api';

export async function saveBufferLayerAsNewLayer(
  context,
  { figureId, bufferLayer }
) {
  const geojson = JSON.parse(bufferLayer.geojson);
  const newLayerProps = { ...geojson.properties, type: 'polygon' };
  // The properties exclusive to Buffer layers are deleted.
  ['distance', 'folderId', 'bufferType', 'boundLayerIds'].forEach((item) => {
    delete newLayerProps[item];
  });
  const newLayerGeojson = { ...geojson, properties: newLayerProps };

  const {
    data: { layer: newLayer },
  } = await Api.modifyLayer({
    figure_id: figureId,
    layer_id: null,
    layer: newLayerGeojson,
  });

  return newLayer;
}
