const addFieldValueFilter = (context, filter) => {
  context.commit('ADD_FIELD_VALUE_FILTER', filter);
};

const removeFieldValueFilter = (context, field_id) => {
  context.commit('REMOVE_FIELD_VALUE_FILTER', field_id);
};

const setFilters = (context, data) => {
  context.commit('SET_FILTERS', data);
};

const setFilter = (context, data) => {
  context.commit('SET_FILTER', data);
};

const clearFilters = (context) => {
  context.commit('CLEAR_FILTERS');
};

export default {
  addFieldValueFilter,
  removeFieldValueFilter,
  setFilter,
  setFilters,
  clearFilters,
};
