import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import type { Id, LegacyBounds } from '../common/types';
import type { LayerUsage } from './constants';
import type { ImageLayer } from './image/types';
import type { SampleLayer } from './sample/types';
import type { EsriLayer } from './service/esri/types';
import type { GoogleMapsLayer } from './service/google/types';
import type { OgcOpenGisLayer } from './service/ogc-opengis/types';
import type { XyzLayer } from './service/types';
import type { ShapeLayer } from './shape/types';
import type { TextLayer } from './text/types';

export enum LayerType {
  RECTANGLE = 'rectangle',
  CIRCLE = 'circle',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  POLYLINE_INTERSECTION_INDICATOR = 'polyline_intersection_indicator',
  ARROW = 'arrow',
  SITE_BOUNDARY = 'siteboundary',
  SITE_BOUNDARY_INDICATOR = 'site_boundary_indicator',
  TEXT = 'textarea',
  CALL_OUT = 'call-out',
  // The text mate layer is used to edit the text.
  TEXT_MATE = 'text_mate',
  IMAGE = 'image',
  // The image mate layer is used to edit the image.
  IMAGE_MATE = 'image_mate',
  SAMPLE = 'marker',
  SAMPLE_GROUP = 'sample_group',
  SAMPLE_POPUP_CONNECTORS = 'sample_popup_connectors',
  HIGHLIGHTED = 'highlighted',
  FEATURE_COLLECTION = 'feature_collection',
  FEATURE_COLLECTION_PREVIEW = 'feature_collection_preview',
  POINT_FEATURE_COLLECTION_PREVIEW = 'point_feature_collection_preview',
  NON_POINT_FEATURE_COLLECTION_PREVIEW = 'non_point_feature_collection_preview',
  SERVICE = 'service_layer',
  BASEMAP_SERVICE = 'basemap_service',
  BASEMAP_IMAGE = 'basemap_image',
  PARCELS = 'parcels',
  HOVERED_PARCEL = 'hovered_parcel',
  COMPOUND_PARCEL = 'compound_parcel',
  EXTENT_EDITOR = 'extent_editor',
  BUFFER = 'buffer',
  MODIFICATION_OVERLAY = 'modification_overlay',
  FOLDER = 'folder',
  LEGEND_NOTE = 'legend_note',
  CHAINAGE = 'chainage',
  POINT = 'point',
  HEDGE = 'hedge',
}

export type Properties = {
  type: LayerType;
  usage?: LayerUsage;
  title: string;
  agreedBy?: number;
};

export type LayerProperties = Readonly<{
  modelId: Id;
  type: LayerType;
  usage?: LayerUsage;
}>;

export type Geojson<T extends Properties> = {
  properties: T;
};

export type LayerModel<
  T extends Properties = Properties,
  G extends Geojson<T> = Geojson<T>
> = {
  // String ids are temporary ones, created by makeId helper
  id: Id;
  geojson: G;
  visible: boolean;
  is_visible_in_basemap_figure: boolean;
  marker_identifier?: string | null;
  children: LayerModel[];
  parent_id?: Id;
};

// Temporary layer models are used to create and edit layers.
export type TempLayerModel<T extends LayerModel = LayerModel> = T & {
  isTemp: true;
};

export type Layer =
  | ShapeLayer
  | SampleLayer
  | ImageLayer
  | EsriLayer
  | XyzLayer
  | OgcOpenGisLayer
  | GoogleMapsLayer
  | TextLayer;

export interface LayerMixin<T extends Geometry = Geometry> {
  getFirstFeature(): Feature<T> | undefined;
  checkHasFeature(feature: Feature<T>): boolean;
  toGeoJSON(): GeoJSON.Feature | GeoJSON.FeatureCollection;
  getBounds(padding: number): LegacyBounds;
  refresh(): void;
}
