import store from '@/js/store';
import { inject, provide, ref } from 'vue';
import { NAMESPACE } from './store';
import { isProd } from '@component-library/utils/is-prod';

type GetViewer = () => any;
const getViewerSymbol = Symbol('getViewer');
// A viewer container is a component that renders the map viewer inside.
// The MapEditor, IFrameMapViewer, ActionMapViewer are viewer containers.
// $refs is not reative (https://stackoverflow.com/questions/54927707/why-does-the-vue-documentation-say-refs-are-not-reactive-when-indeed-they-are)
// Accessing the _counter variable makes getViewer method reactive.
export function provideGetViewer(viewerContainer) {
  const _counter = ref(0);
  provide(getViewerSymbol, () => {
    const { viewer } = viewerContainer.$refs;
    if (!viewer) {
      _counter.value = _counter.value + 1;
    }
    return viewer;
  });
}

export function injectGetViewer(): GetViewer | undefined {
  return inject(getViewerSymbol, undefined);
}

export enum AttributesTabMode {
  VIEWING = 1,
  EDITING,
}

export function createTextShadow(color) {
  return `
    2px 0 0 ${color},
    -2px 0 0 ${color},
    0 2px 0 ${color},
    0 -2px 0 ${color},
    1px 1px ${color},
    -1px -1px 0 ${color},
    1px -1px 0 ${color},
    -1px 1px 0 ${color}
  `;
}

export function getState(name: string, namespace = NAMESPACE) {
  return namespace.getState(store, name);
}

export function getGetter(name: string, namespace = NAMESPACE) {
  return namespace.getGetter(store, name);
}

export async function dispatch<P>(
  actionName: string,
  payload: P,
  namespace = NAMESPACE
) {
  return await namespace.dispatch(store, actionName, payload);
}

export function checkIsProduction() {
  return isProd;
}
