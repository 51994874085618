import heatmap from './heatmap';
import buffer from './buffer';
import * as bl from '../../business-logic';
import * as helpers from './helpers';

export default {
  state: () => ({
    loaders: {
      [helpers.MODULE_NAMES.HEATMAP]: {
        [bl.analysis.heatmap.ACTION_TYPES.GENERIC]: false,
        [bl.analysis.heatmap.ACTION_TYPES.ANALYZE_SAMPLES]: false,
        [bl.analysis.heatmap.ACTION_TYPES.CREATE]: false,
      },
      [helpers.MODULE_NAMES.BUFFER]: {
        [bl.analysis.buffer.ACTION_TYPES.GENERIC]: false,
      },
    },
  }),
  mutations: {
    updateLoaders(state, { moduleName, actionType, isLoading }) {
      state.loaders = {
        ...state.loaders,
        [moduleName]: {
          ...state.loaders[moduleName],
          [actionType]: isLoading,
        },
      };
    },
  },
  actions: {
    updateLoaders(context, { moduleName, actionType, isLoading }) {
      context.commit('updateLoaders', { moduleName, actionType, isLoading });
    },
  },
  modules: {
    [helpers.MODULE_NAMES.HEATMAP]: {
      namespaced: true,
      ...heatmap,
    },
    [helpers.MODULE_NAMES.BUFFER]: {
      namespaced: true,
      ...buffer,
    },
  },
};

export { helpers };
