import * as bl_measure from './measure';

export enum MediumType {
  Screen = 'Screen',
  A4 = 'A4',
  A3 = 'A3',
  ANSI_A = 'ANSI_A',
  ANSI_B = 'ANSI_B',
}

export type PrinterPaper =
  | MediumType.A4
  | MediumType.A3
  | MediumType.ANSI_A
  | MediumType.ANSI_B;

export type PrinterPaperSpec = {
  label: string;
  shortLabel: string;
  width: number;
  height: number;
  unit: bl_measure.Unit;
};

export const PRINTER_PAPER_SPECS: Record<PrinterPaper, PrinterPaperSpec> = {
  [MediumType.A4]: {
    label: 'Printer Paper A4',
    shortLabel: 'A4',
    width: 210,
    height: 297,
    unit: bl_measure.Unit.Millimeter,
  },
  [MediumType.A3]: {
    label: 'Printer Paper A3',
    shortLabel: 'A3',
    width: 297,
    height: 420,
    unit: bl_measure.Unit.Millimeter,
  },
  [MediumType.ANSI_A]: {
    label: 'US Letter',
    shortLabel: 'US Letter',
    width: 8.5,
    height: 11.0,
    unit: bl_measure.Unit.Inch,
  },
  [MediumType.ANSI_B]: {
    label: 'US Tabloid/Ledger',
    shortLabel: 'US Tabloid/Ledger',
    width: 11.0,
    height: 17.0,
    unit: bl_measure.Unit.Inch,
  },
};

export function getPrinterPaperSize(
  printerPaper: PrinterPaper
): bl_measure.Size {
  const { width, height, unit: sourceUnit } = PRINTER_PAPER_SPECS[printerPaper];
  return [
    bl_measure.transformMeasure(
      width,
      bl_measure.Dpi.PrinterPaper,
      bl_measure.Dpi.Screen,
      sourceUnit,
      bl_measure.Unit.Pixel
    ),
    bl_measure.transformMeasure(
      height,
      bl_measure.Dpi.PrinterPaper,
      bl_measure.Dpi.Screen,
      sourceUnit,
      bl_measure.Unit.Pixel
    ),
  ];
}

export function getMediumDpi(mediumType: MediumType): bl_measure.Dpi {
  return mediumType === MediumType.Screen
    ? bl_measure.Dpi.Screen
    : bl_measure.Dpi.PrinterPaper;
}

export const MEDIUM_TYPES_BY_COUNTRY = {
  US: [MediumType.Screen, MediumType.ANSI_A, MediumType.ANSI_B],
  NZ: [MediumType.Screen, MediumType.A4, MediumType.A3],
  AU: [MediumType.Screen, MediumType.A4, MediumType.A3],
  CA: [
    MediumType.Screen,
    MediumType.A4,
    MediumType.A3,
    MediumType.ANSI_A,
    MediumType.ANSI_B,
  ],
  GB: [MediumType.Screen, MediumType.A4, MediumType.A3],
};

export function checkIsScreen(mediumType: MediumType): boolean {
  return mediumType === MediumType.Screen;
}
export function checkIsPrinterPaper(mediumType: MediumType): boolean {
  return Object.keys(PRINTER_PAPER_SPECS).includes(mediumType);
}

export function getMediumTypeLabel(
  mediumType: MediumType,
  isShort = false
): string {
  if (checkIsScreen(mediumType)) {
    return MediumType.Screen;
  }

  return checkIsPrinterPaper(mediumType)
    ? !isShort
      ? PRINTER_PAPER_SPECS[mediumType].label
      : PRINTER_PAPER_SPECS[mediumType].shortLabel
    : 'Unknown medium type';
}

export function getAvailableMediumTypes(country) {
  return MEDIUM_TYPES_BY_COUNTRY[country];
}
