<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import api from '../api';
import { useGatherSchemaStore } from '@component-library/store/gather-schema';
import _debounce from 'lodash/debounce';
import useInfiniteScroll from '@/js/composables/useInfiniteScroll';

const { onScroll, loadedAllPaginatedData, currentPage } = useInfiniteScroll();
const gatherAppStore = useGatherSchemaStore();

const props = defineProps({
  tabId: [Number, String],
  isLoading: Boolean,
  filters: Object,
});

const emit = defineEmits(['applyFilters']);

const samples = ref([]);
const isLoadingSamples = ref(false);
const filterOptions = ref(null);
const sampleQuery = ref(null);

watch(
  sampleQuery,
  _debounce(() => {
    getAllSamples(true);
  }, 300)
);

watch(
  filterOptions,
  () => {
    emit('applyFilters');
  },
  {
    deep: true,
  }
);

const templateTab = computed(() => {
  return gatherAppStore.apps.find((item) => item.id === props.tabId);
});

const subFolders = computed(() => {
  return templateTab.value?.sub_folders ?? [];
});

const isShowSamplesInSubFoldersAvailable = computed(() => {
  if (!templateTab.value) {
    return;
  }
  const { drawing_type: drawingType } = templateTab.value;
  return ['any', 'point'].includes(drawingType) && !!subFolders.value.length;
});

const getFilters = () => {
  return {
    sampleFilterType: filterOptions.value.sampleFilterType,
    specificSampleIds: filterOptions.value.specificSampleIds,
    selectedSubFolders: filterOptions.value.selectedSubFolders,
    orderSamplesBy: filterOptions.value.orderSamplesBy,
  };
};

const getAllSamples = async (reset = false) => {
  if (reset) {
    currentPage.value = 1;
    samples.value = [];
    loadedAllPaginatedData.value = false;
  }

  if (isLoadingSamples.value || loadedAllPaginatedData.value) {
    return;
  }

  isLoadingSamples.value = true;

  try {
    const { data } = await api.getAllSamples({
      tabId: props.tabId,
      query: sampleQuery.value,
      page: currentPage.value,
    });

    if (data.samples.data.length == 0) {
      loadedAllPaginatedData.value = true;
    } else {
      currentPage.value = data.samples.current_page + 1;
      samples.value = [...(samples.value || []), ...data.samples.data];
    }
  } catch (e) {
    throw e;
  } finally {
    isLoadingSamples.value = false;
  }
};

const onChangeShowSpecificSamples = () => {
  if (filterOptions.value.sampleFilterType == 'specific-samples') {
    getAllSamples();
  }
};

onMounted(() => {
  filterOptions.value = JSON.parse(JSON.stringify(props.filters));

  if (filterOptions.value.sampleFilterType == 'specific-samples') {
    getAllSamples();
  }
});

defineExpose({
  getFilters,
});
</script>

<template>
  <div v-if="filterOptions">
    <h6 class="mb-3">Sample Filters</h6>

    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="form-check">
            <input
              type="radio"
              id="showAllSamples"
              class="form-check-input"
              value="all-samples"
              v-model="filterOptions.sampleFilterType"
            />
            <label class="form-label" for="showAllSamples">
              Show all samples
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-check">
            <input
              type="radio"
              id="showOnlySamplesWithData"
              class="form-check-input"
              value="samples-with-data"
              v-model="filterOptions.sampleFilterType"
            />
            <label class="form-label" for="showOnlySamplesWithData">
              Show only samples with data
            </label>
          </div>
        </div>
      </div>
      <hr class="mt-2 mb-3" />
    </div>

    <div class="col-12">
      <div class="form-check">
        <input
          type="radio"
          id="showSpecificSamples"
          class="form-check-input"
          value="specific-samples"
          v-model="filterOptions.sampleFilterType"
          @change="onChangeShowSpecificSamples"
        />
        <label class="form-label" for="showSpecificSamples">
          Show only specific samples
        </label>
      </div>
    </div>

    <template v-if="filterOptions.sampleFilterType == 'specific-samples'">
      <div class="col-12">
        <hr />

        <div class="input-group input-group-sm mb-2">
          <span class="input-group-text">
            <div
              v-if="isLoadingSamples"
              class="spinner-border spinner-border-sm"
            />
            <i v-else class="fas fa-search" />
          </span>
          <input
            type="text"
            class="form-control w-50"
            :placeholder="`search for a sample...`"
            v-model="sampleQuery"
          />
        </div>
        <hr class="my-3" />

        <div
          v-if="isLoadingSamples && samples.length === 0"
          class="spinner-border spinner-border-sm"
        />
        <template v-else>
          <small v-if="samples.length === 0" class="d-block mb-3 text-danger">
            No samples found for this query.
          </small>
          <div
            v-else
            class="custom-scrollbar"
            style="max-height: 250px"
            @scroll="onScroll($event, getAllSamples)"
          >
            <div
              class="form-check"
              v-for="(sample, sampleKey) in samples"
              :key="sampleKey"
            >
              <input
                type="checkbox"
                :id="'sample-' + sampleKey"
                :value="sample.id"
                v-model="filterOptions.specificSampleIds"
                class="form-check-input"
              />
              <label class="form-label fw-medium" :for="'sample-' + sampleKey">
                {{ formatSampleTitle(sample) }}
              </label>
            </div>
          </div>
        </template>
      </div>
    </template>

    <hr class="mt-2 mb-3" />

    <div class="col-12">
      <div class="form-group">
        <label class="form-label">Order by</label>
        <select
          class="form-control form-control-sm"
          v-model="filterOptions.orderSamplesBy"
        >
          <option value="new-to-old">Newest to oldest</option>
          <option value="old-to-new">Oldest to newest</option>
          <option value="a-z">A-Z</option>
        </select>
      </div>
    </div>

    <!-- Filter samples by sub folder -->
    <template v-if="isShowSamplesInSubFoldersAvailable">
      <hr class="mt-2 mb-3" />

      <div class="col-12">
        <div class="form-check">
          <input
            type="radio"
            id="showSamplesInSubFolders"
            class="form-check-input"
            value="samples-in-sub-folders"
            v-model="filterOptions.sampleFilterType"
          />
          <label class="form-label" for="showSamplesInSubFolders">
            Show samples in sub folders
          </label>
        </div>
      </div>

      <template
        v-if="filterOptions.sampleFilterType == 'samples-in-sub-folders'"
      >
        <div class="col-12 ms-3">
          <hr />

          <div class="three-column">
            <div
              v-for="(item, index) in subFolders"
              :key="`subFolder_${index}`"
              class="form-check"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :id="`subFolderCheckbox_${index}`"
                :value="item"
                v-model="filterOptions.selectedSubFolders"
              />
              <label class="form-label" :for="`subFolderCheckbox_${index}`">
                {{ item }}
              </label>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
