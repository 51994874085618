import { AccessType, PERMISSION_ID } from '../company-role-profile';
import { Project } from '../project';
import { useProjectStore } from '../store/project';

export default function useViewRestriction(
  type?: PERMISSION_ID,
  project: Project | null = null
) {
  // this is set to null if user has full access, as no permissions were set.
  const getAllPermissions = () => {
    const currentProject = project || useProjectStore().project;

    const permissions = currentProject
      ? currentProject.session_role_permissions
      : null;

    return permissions || null;
  };

  const getPermissionByType = (byType: PERMISSION_ID) => {
    const permissions = getAllPermissions();

    if (!permissions) {
      return null;
    }

    const permission =
      permissions.find((p) => p.feature_key === byType) || null;
    return permission;
  };

  // this is generally set on a route, if they don't have permission it blocks them.
  const hasPermissionToAccess = (overriddenType: PERMISSION_ID | null) => {
    if (getAllPermissions() === null) {
      return true;
    }

    return getPermissionByType(overriddenType || type!) !== null;
  };

  // this allows us to control using v-if/v-show whether to show a component based on a permission
  // used for building a custom read only interface.
  const isViewOnly = () => {
    if (getAllPermissions() === null) {
      return false;
    }

    const permission = getPermissionByType(type!);
    return permission !== null
      ? permission.access_type === AccessType.READ
      : true;
  };

  // used for building a custom read only interface, sometimes we just want to disable not hide it.
  const hasEditRestriction = () => {
    return isViewOnly() ? 'disabled' : '';
  };

  return {
    hasPermissionToAccess,
    isViewOnly,
    hasEditRestriction,
  };
}
