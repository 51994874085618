export const COLOR_0 = '#1FBC9C';
export const COLOR_1 = '#1CA085';
export const COLOR_2 = '#2ECC70';
export const COLOR_3 = '#27AF60';
export const COLOR_4 = '#3398DB';
export const COLOR_5 = '#2980B9';
export const COLOR_6 = '#A463BF';
export const COLOR_7 = '#8E43AD';
export const COLOR_8 = '#3D556E';
export const COLOR_9 = '#222F3D';
export const COLOR_10 = '#F2C511';
export const COLOR_11 = '#F39C19';
export const COLOR_12 = '#E84B3C';
export const COLOR_13 = '#C0382B';
export const COLOR_14 = '#DDE6E8';

//The following colors are copied from https://en.wikipedia.org/wiki/List_of_colors:_A%E2%80%93F
export const COLOR_15 = '#0048BA';
export const COLOR_16 = '#B0BF1A';
export const COLOR_17 = '#7CB9E8';
export const COLOR_18 = '#C0E8D5';
export const COLOR_19 = '#B284BE';
export const COLOR_20 = '#72A0C1';
export const COLOR_21 = '#EDEAE0';
export const COLOR_22 = '#F0F8FF';
export const COLOR_23 = '#C46210';
export const COLOR_24 = '#EFDECD';
export const COLOR_25 = '#E52B50';
export const COLOR_26 = '#9F2B68';
export const COLOR_27 = '#F19CBB';
export const COLOR_28 = '#AB274F';
export const COLOR_29 = '#D3212D';
export const COLOR_30 = '#3B7A57';
export const COLOR_31 = '#FFBF00';
export const COLOR_32 = '#FF7E00';
export const COLOR_33 = '#9966CC';
export const COLOR_34 = '#3DDC84';
export const COLOR_35 = '#CD9575';
export const COLOR_36 = '#665D1E';
export const COLOR_37 = '#915C83';
export const COLOR_38 = '#841B2D';
export const COLOR_39 = '#FAEBD7';
export const COLOR_40 = '#008000';
export const COLOR_41 = '#8DB600';
export const COLOR_42 = '#FBCEB1';
export const COLOR_43 = '#00FFFF';
export const COLOR_44 = '#7FFFD4';
export const COLOR_45 = '#D0FF14';
export const COLOR_46 = '#4B5320';
export const COLOR_47 = '#8F9779';
export const COLOR_48 = '#E9D66B';
export const COLOR_49 = '#B2BEB5';
export const COLOR_50 = '#87A96B';
export const COLOR_51 = '#FF9966';
export const COLOR_52 = '#A52A2A';
export const COLOR_53 = '#FDEE00';
export const COLOR_54 = '#568203';
export const COLOR_55 = '#007FFF';
export const COLOR_56 = '#F0FFFF';
export const COLOR_57 = '#89CFF0';
export const COLOR_58 = '#A1CAF1';
export const COLOR_59 = '#F4C2C2';
export const COLOR_60 = '#FEFEFA';
export const COLOR_61 = '#FF91AF';
export const COLOR_62 = '#FAE7B5';
export const COLOR_63 = '#DA1884';
export const COLOR_64 = '#7C0A02';
export const COLOR_65 = '#848482';
export const COLOR_66 = '#BCD4E6';
export const COLOR_67 = '#9F8170';
export const COLOR_68 = '#F5F5DC';
export const COLOR_69 = '#2E5894';
export const COLOR_70 = '#9C2542';
export const COLOR_71 = '#FFE4C4';
export const COLOR_72 = '#3D2B1F';
export const COLOR_73 = '#967117';
export const COLOR_74 = '#CAE00D';
export const COLOR_75 = '#BFFF00';
export const COLOR_76 = '#FE6F5E';
export const COLOR_77 = '#BF4F51';
export const COLOR_78 = '#000000';
export const COLOR_79 = '#3D0C02';
export const COLOR_80 = '#1B1811';
export const COLOR_81 = '#3B2F2F';
export const COLOR_82 = '#54626F';
export const COLOR_83 = '#3B3C36';
export const COLOR_84 = '#BFAFB2';
export const COLOR_85 = '#FFEBCD';
export const COLOR_86 = '#A57164';
export const COLOR_87 = '#318CE7';
export const COLOR_88 = '#ACE5EE';
export const COLOR_89 = '#FAF0BE';
export const COLOR_90 = '#660000';
export const COLOR_91 = '#0000FF';
export const COLOR_92 = '#1F75FE';
export const COLOR_93 = '#0093AF';
export const COLOR_94 = '#0087BD';
export const COLOR_95 = '#0018A8';
export const COLOR_96 = '#333399';
export const COLOR_97 = '#0247FE';
export const COLOR_98 = '#A2A2D0';
export const COLOR_99 = '#6699CC';
export const COLOR_100 = '#0D98BA';
export const COLOR_101 = '#064E40';
export const COLOR_102 = '#5DADEC';
export const COLOR_103 = '#126180';
export const COLOR_104 = '#8A2BE2';
export const COLOR_105 = '#7366BD';
export const COLOR_106 = '#4D1A7F';
export const COLOR_107 = '#5072A7';
export const COLOR_108 = '#3C69E7';
export const COLOR_109 = '#DE5D83';
export const COLOR_110 = '#79443B';
export const COLOR_111 = '#E3DAC9';
export const COLOR_112 = '#006A4E';
export const COLOR_113 = '#87413F';
export const COLOR_114 = '#CB4154';
export const COLOR_115 = '#66FF00';
export const COLOR_116 = '#D891EF';
export const COLOR_117 = '#C32148';
export const COLOR_118 = '#1974D2';
export const COLOR_119 = '#FFAA1D';
export const COLOR_120 = '#FF55A3';
export const COLOR_121 = '#FB607F';
export const COLOR_122 = '#004225';
export const COLOR_123 = '#CD7F32';
export const COLOR_124 = '#88540B';
export const COLOR_125 = '#AF6E4D';
export const COLOR_126 = '#1B4D3E';
export const COLOR_127 = '#7BB661';
export const COLOR_128 = '#FFC680';
export const COLOR_129 = '#800020';
export const COLOR_130 = '#DEB887';
export const COLOR_131 = '#A17A74';
export const COLOR_132 = '#CC5500';
export const COLOR_133 = '#E97451';
export const COLOR_134 = '#8A3324';
export const COLOR_135 = '#BD33A4';
export const COLOR_136 = '#702963';
export const COLOR_137 = '#536872';
export const COLOR_138 = '#5F9EA0';
export const COLOR_139 = '#A9B2C3';
export const COLOR_140 = '#91A3B0';
export const COLOR_141 = '#006B3C';
export const COLOR_142 = '#ED872D';
export const COLOR_143 = '#E30022';
export const COLOR_144 = '#FFF600';
export const COLOR_145 = '#A67B5B';
export const COLOR_146 = '#4B3621';
export const COLOR_147 = '#A3C1AD';
export const COLOR_148 = '#C19A6B';
export const COLOR_149 = '#EFBBCC';
export const COLOR_150 = '#FFFF99';
export const COLOR_151 = '#FFEF00';
export const COLOR_152 = '#FF0800';
export const COLOR_153 = '#E4717A';
export const COLOR_154 = '#00BFFF';
export const COLOR_155 = '#592720';
export const COLOR_156 = '#C41E3A';
export const COLOR_157 = '#00CC99';
export const COLOR_158 = '#960018';
export const COLOR_159 = '#D70040';
export const COLOR_160 = '#FFA6C9';
export const COLOR_161 = '#B31B1B';
export const COLOR_162 = '#56A0D3';
export const COLOR_163 = '#ED9121';
export const COLOR_164 = '#00563F';
export const COLOR_165 = '#703642';
export const COLOR_166 = '#C95A49';
export const COLOR_167 = '#ACE1AF';
export const COLOR_168 = '#007BA7';
export const COLOR_169 = '#2F847C';
export const COLOR_170 = '#B2FFFF';
export const COLOR_171 = '#246BCE';
export const COLOR_172 = '#DE3163';
export const COLOR_173 = '#007BA7';
export const COLOR_174 = '#2A52BE';
export const COLOR_175 = '#6D9BC3';
export const COLOR_176 = '#1DACD6';
export const COLOR_177 = '#007AA5';
export const COLOR_178 = '#E03C31';
export const COLOR_179 = '#F7E7CE';
export const COLOR_180 = '#F1DDCF';
export const COLOR_181 = '#36454F';
export const COLOR_182 = '#232B2B';
export const COLOR_183 = '#E68FAC';
export const COLOR_184 = '#DFFF00';
export const COLOR_185 = '#7FFF00';
export const COLOR_186 = '#FFB7C5';
export const COLOR_187 = '#954535';
export const COLOR_188 = '#E23D28';
export const COLOR_189 = '#DE6FA1';
export const COLOR_190 = '#A8516E';
export const COLOR_191 = '#AA381E';
export const COLOR_192 = '#856088';
export const COLOR_193 = '#FFB200';
export const COLOR_194 = '#7B3F00';
export const COLOR_195 = '#D2691E';
export const COLOR_196 = '#58111A';
export const COLOR_197 = '#FFA700';
export const COLOR_198 = '#98817B';
export const COLOR_199 = '#E34234';
export const COLOR_200 = '#CD607E';
export const COLOR_201 = '#E4D00A';
export const COLOR_202 = '#9FA91F';
export const COLOR_203 = '#7F1734';
export const COLOR_204 = '#0047AB';
export const COLOR_205 = '#D2691E';
export const COLOR_206 = '#6F4E37';
export const COLOR_207 = '#B9D9EB';
export const COLOR_208 = '#F88379';
export const COLOR_209 = '#8C92AC';
export const COLOR_210 = '#B87333';
export const COLOR_211 = '#DA8A67';
export const COLOR_212 = '#AD6F69';
export const COLOR_213 = '#CB6D51';
export const COLOR_214 = '#996666';
export const COLOR_215 = '#FF3800';
export const COLOR_216 = '#FF7F50';
export const COLOR_217 = '#F88379';
export const COLOR_218 = '#893F45';
export const COLOR_219 = '#FBEC5D';
export const COLOR_220 = '#B31B1B';
export const COLOR_221 = '#6495ED';
export const COLOR_222 = '#FFF8DC';
export const COLOR_223 = '#2E2D88';
export const COLOR_224 = '#FFF8E7';
export const COLOR_225 = '#81613C';
export const COLOR_226 = '#FFBCD9';
export const COLOR_227 = '#FFFDD0';
export const COLOR_228 = '#DC143C';
export const COLOR_229 = '#9E1B32';
export const COLOR_230 = '#A7D8DE';
export const COLOR_231 = '#F5F5F5';
export const COLOR_232 = '#00FFFF';
export const COLOR_233 = '#00B7EB';
export const COLOR_234 = '#58427C';
export const COLOR_235 = '#FFD300';
export const COLOR_236 = '#F56FA1';
export const COLOR_237 = '#666699';
export const COLOR_238 = '#654321';
export const COLOR_239 = '#5D3954';
export const COLOR_240 = '#26428B';
export const COLOR_241 = '#008B8B';
export const COLOR_242 = '#536878';
export const COLOR_243 = '#B8860B';
export const COLOR_244 = '#013220';
export const COLOR_245 = '#006400';
export const COLOR_246 = '#1A2421';
export const COLOR_247 = '#BDB76B';
export const COLOR_248 = '#483C32';
export const COLOR_249 = '#534B4F';
export const COLOR_250 = '#543D37';
export const COLOR_251 = '#8B008B';
export const COLOR_252 = '#4A5D23';
export const COLOR_253 = '#556B2F';
export const COLOR_254 = '#FF8C00';
export const COLOR_255 = '#9932CC';
export const COLOR_256 = '#03C03C';
export const COLOR_257 = '#301934';
export const COLOR_258 = '#8B0000';
export const COLOR_259 = '#E9967A';
export const COLOR_260 = '#8FBC8F';
export const COLOR_261 = '#3C1414';
export const COLOR_262 = '#8CBED6';
export const COLOR_263 = '#483D8B';
export const COLOR_264 = '#2F4F4F';
export const COLOR_265 = '#177245';
export const COLOR_266 = '#00CED1';
export const COLOR_267 = '#9400D3';
export const COLOR_268 = '#00703C';
export const COLOR_269 = '#555555';
export const COLOR_270 = '#DA3287';
export const COLOR_271 = '#FAD6A5';
export const COLOR_272 = '#B94E48';
export const COLOR_273 = '#004B49';
export const COLOR_274 = '#FF1493'; // Deep Pink
export const COLOR_275 = '#FF9933';
export const COLOR_276 = '#00BFFF';
export const COLOR_277 = '#4A646C';
export const COLOR_278 = '#7E5E60';
export const COLOR_279 = '#1560BD';
export const COLOR_280 = '#2243B6';
export const COLOR_281 = '#C19A6B';
export const COLOR_282 = '#EDC9AF';
export const COLOR_283 = '#696969';
export const COLOR_284 = '#1E90FF';
export const COLOR_285 = '#D71868';
export const COLOR_286 = '#967117';
export const COLOR_287 = '#00009C';
export const COLOR_288 = '#EFDFBB';
export const COLOR_289 = '#E1A95F';
export const COLOR_290 = '#555D50';
export const COLOR_291 = '#C2B280';
export const COLOR_292 = '#1B1B1B';
export const COLOR_293 = '#614051';
export const COLOR_294 = '#F0EAD6';
export const COLOR_295 = '#1034A6';
export const COLOR_296 = '#16161D';
export const COLOR_297 = '#7DF9FF';
export const COLOR_298 = '#00FF00';
export const COLOR_299 = '#6F00FF';
export const COLOR_300 = '#CCFF00';
export const COLOR_301 = '#BF00FF';
export const COLOR_302 = '#8F00FF';
export const COLOR_303 = '#50C878';
export const COLOR_304 = '#6C3082';
export const COLOR_305 = '#1B4D3E';
export const COLOR_306 = '#B48395';
export const COLOR_307 = '#AB4B52';
export const COLOR_308 = '#CC474B';
export const COLOR_309 = '#563C5C';
export const COLOR_310 = '#00FF40';
export const COLOR_311 = '#96C8A2';
export const COLOR_312 = '#C19A6B';
export const COLOR_313 = '#801818';
export const COLOR_314 = '#B53389';
export const COLOR_315 = '#DE5285';
export const COLOR_316 = '#F400A1';
export const COLOR_317 = '#E5AA70';
export const COLOR_318 = '#4D5D53';
export const COLOR_319 = '#4F7942';
export const COLOR_320 = '#6C541E';
export const COLOR_321 = '#FF5470';
export const COLOR_322 = '#B22222';
export const COLOR_323 = '#CE2029';
export const COLOR_324 = '#E95C4B';
export const COLOR_325 = '#E25822';
export const COLOR_326 = '#EEDC82';
export const COLOR_327 = '#A2006D';
export const COLOR_328 = '#FFFAF0';
export const COLOR_329 = '#15F4EE';
export const COLOR_330 = '#5FA777';
export const COLOR_331 = '#014421';
export const COLOR_332 = '#228B22';
export const COLOR_333 = '#A67B5B';
export const COLOR_334 = '#856D4D';
export const COLOR_335 = '#0072BB';
export const COLOR_336 = '#FD3F92';
export const COLOR_337 = '#86608E';
export const COLOR_338 = '#9EFD38';
export const COLOR_339 = '#D473D4';
export const COLOR_340 = '#FD6C9E';
export const COLOR_341 = '#C72C48';
export const COLOR_342 = '#F64A8A';
export const COLOR_343 = '#77B5FE';
export const COLOR_344 = '#8806CE';
export const COLOR_345 = '#E936A7';
export const COLOR_346 = '#FF00FF';
export const COLOR_347 = '#C154C1';
export const COLOR_348 = '#CC397B';
export const COLOR_349 = '#C74375';
export const COLOR_350 = '#E48400';
export const COLOR_351 = '#87421F';

// Other colors.
export const COLOR_352 = '#FFFFFF';
export const COLOR_353 = '#F4F4F4';
export const COLOR_354 = '#FF0000';
export const COLOR_355 = '#4169E1'; // Royal Blue
export const COLOR_356 = '#4B0082'; // Indigo
