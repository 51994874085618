export const getSupportedCountries = () => {
  return {
    NZ: 'New Zealand',
    AU: 'Australia',
    GB: 'United Kingdom',
    US: 'United States',
    CA: 'Canada',
    int: 'International',
    IN: 'International',
  };
};

export const countryCodeToFull = (code: string) => {
  const countries = getSupportedCountries();
  return countries[code] || code || 'Not Set';
};

export const countryCodeToIcon = (code: string) => {
  const iconMap = {
    NZ: 'fa-kiwi-bird',
    AU: 'fa-globe-asia',
    GB: 'fa-globe-europe',
    US: 'fa-flag-usa',
    CA: 'fa-leaf-maple',
    int: 'fa-globe',
    IN: 'fa-globe',
  };

  return iconMap[code] || 'fa-globe';
};
