import { getPointResolution, get as getProjection } from 'ol/proj';
import * as print from './print';

const INCHES_PER_METER = 1 / 0.0254;

/**
 * Convert scale to point resolution.
 * @param {Number} scale
 * @param {ProjectionLike} projection
 * @param {Number} dpi
 * @returns
 */
export function scaleToResolution(
  projection,
  scale,
  center,
  dpi = print.DPI_96
) {
  projection = getProjection(projection);

  const mpu = projection.getMetersPerUnit();
  const pointResolution =
    getPointResolution(projection, 1 / mpu, center, 'm') * mpu;

  return scale / (pointResolution * INCHES_PER_METER * dpi);
}

/**
 * Convert point resolution to scale.
 * @param {Number} resolution
 * @param {ProjectionLike} projection
 * @param {Number} dpi
 * @returns
 */
export function resolutionToScale(
  projection,
  resolution,
  center,
  dpi = print.DPI_96
) {
  const pointResolution = getPointResolution(
    projection,
    resolution,
    center,
    'm'
  );
  return pointResolution * INCHES_PER_METER * dpi;
}
