export default function useContextMenu(editMenus, isDisabled = false) {
  const hasColumnMenu = (byRow = false, key = null) => {
    if (isDisabled) {
      return;
    }
    return _getColumnMenu(editMenus, byRow, key) != null;
  };

  const openColumnContextMenu = (e, data, byRow = false, key = null) => {
    if (isDisabled) {
      return;
    }

    let editMenu = _getColumnMenu(editMenus, byRow, key);
    if (!editMenu) {
      return;
    }

    Object.keys(editMenus).forEach((menu) => {
      let editMenu = editMenus[menu];

      editMenu.menu.close();
      if (editMenu.bulkMenu) {
        editMenu.bulkMenu.close();
      }
    });

    editMenu.open(e, data);
  };

  const getResultMenu = (menu) => {
    if (!editMenus || isDisabled) return;
    return editMenus[menu] || null;
  };

  return { hasColumnMenu, openColumnContextMenu, getResultMenu };
}

const _getColumnMenu = (editMenus, byRow = false, key = null) => {
  if (!editMenus) {
    return;
  }

  const editMenu = editMenus[key];
  if (!editMenu) {
    return;
  }

  if (byRow && !editMenu.byRow) {
    return;
  }

  return byRow && editMenu.byRow && editMenu.bulkMenu
    ? editMenu.bulkMenu
    : editMenu.menu;
};

export const isEditingCell = (id, editingData) => {
  if (!editingData || !id) {
    return false;
  }

  const { type, data } = editingData;

  if (type == 'result') {
    return data.item && (data.item.id == id || data.item.chemical_id == id);
  }

  if (type == 'guideline') {
    return data.item ? data.item.id == id : data.chemical.id == id;
  }

  return data.id == id;
};
