import axios from 'axios';
import { InputValue } from '../gather';
import { useToastStore } from '../store/toasts';
import { captureException } from '@sentry/browser';

type UploadedAsset = {
  src: string;
  inputValue: InputValue;
  index: number | null;
};

export default function useUpdateGatherInputValue() {
  const toast = useToastStore();

  const uploadAsset = async (
    blob,
    inputValue: InputValue,
    index: number | null = null
  ) => {
    let formData = new FormData();
    formData.append('file', blob);
    if (!inputValue.project_id) {
      throw new Error('Project ID is required to upload asset');
    }
    formData.append('project_id', inputValue.project_id.toString());

    try {
      const { data } = await axios.post('/gather/project/asset', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return { src: data.src, inputValue, index };
    } catch (e) {
      toast.error('Failed to update value for this item, try again.');
      throw e;
    }
  };

  const uploadAssets = async (inputValueAssets: InputValue[]) => {
    const convertedAssets: UploadedAsset[] = [];

    for (let inputValue of inputValueAssets) {
      if (inputValue.value instanceof Blob) {
        const uploadedAsset = await uploadAsset(inputValue.value, inputValue);
        convertedAssets.push(uploadedAsset);
        continue;
      }

      if (Array.isArray(inputValue.value) && inputValue.value.length > 0) {
        for (let [index, aFile] of inputValue.value.entries()) {
          if (aFile?.blob && aFile.blob instanceof Blob) {
            const uploadedAsset = await uploadAsset(
              aFile.blob,
              inputValue,
              index
            );
            convertedAssets.push(uploadedAsset);
          }
        }
      }
    }

    return convertedAssets;
  };

  const updateInputValue = async (inputValue: InputValue) => {
    try {
      const { data } = await axios.post('/gather/value/update', inputValue);

      return data.input_value;
    } catch (e: any) {
      if (e?.response?.status === 422) {
        toast.warning('Invalid values, please try again');
      } else {
        toast.unexpected();
      }
      captureException(e);
      throw e;
    }
  };

  return {
    uploadAssets,
    updateInputValue,
  };
}
