import { Code, throwError } from '../common/error';

export enum Color {
  PrimaryColor = '#3366a2',
  ExtentEditorCornerColor = '#ff0000',

  // 16 main colours.
  Aqua = '#00FFFF',
  Black = '#000000',
  Blue = '#0000FF',
  Fuchsia = '#CA2C92',
  Gray = '#808080',
  Green = '#00FF00',
  Lime = '#BFFF00',
  Maroon = '#800000',
  Navy = '#000080',
  Olive = '#808000',
  Purple = '#A020F0',
  Red = '#FF0000',
  Silver = '#C0C0C0',
  Teal = '#008080',
  White = '#FFFFFF',
  Yellow = '#FFFF00',

  // Datanest colors
  Primary = '#3366a2',
  Border = '#dbdbdb',

  // Feature colors
  Feature = '#2980b9',
  SiteBoundary = '#e84b3c',

  Parcel = '#3388ff',
}

export const SWATCHES = Object.freeze([
  Object.freeze(['#1FBC9C', '#1CA085', '#2ECC70', '#27AF60', '#3398DB']),
  Object.freeze(['#2980B9', '#A463BF', '#8E43AD', '#3D556E', Color.Black]),
  Object.freeze(['#F2C511', '#F39C19', '#E84B3C', '#C0382B', '#DDE6E8']),
]);

export function getSwatchTriggerStyle(color) {
  return color.toLowerCase() === Color.White.toLowerCase()
    ? { border: `1px solid ${Color.Border}` }
    : null;
}

export function normalizeHex(hex: string): string {
  if (hex.length === 9) {
    return hex;
  } else if (hex.length === 7) {
    return `${hex}ff`;
  }

  throwError(Code.InvalidArgument, hex);
}
