<template>
  <div class="d-flex flex-column flex-md-row justify-content-between">
    <button
      class="btn btn-dark dropdown-toggle w-100 mobile-filter-toggle"
      :class="{
        toggled: isFiltersVisible,
      }"
      @click="isFiltersVisible = !isFiltersVisible"
    >
      <i class="fal fa-bars me-1"></i> Filters
    </button>
    <div
      class="mobile-filter-container d-flex flex-column flex-md-row justify-content-between w-100"
      :class="{
        'toggled mt-2': isFiltersVisible,
      }"
    >
      <div
        v-if="editable"
        class="d-flex flex-column flex-md-row align-items-md-center"
      >
        <template v-if="filterChanged">
          <button
            class="btn btn-danger btn-sm me-2 clear-filters"
            v-tooltip="'Clear filters'"
            @click="clearAllFilters"
          >
            <i class="far fa-times-circle"></i>
          </button>

          <div class="horizontal-separator ms-3 me-3 d-none d-md-block"></div>
        </template>

        <DropdownMenu customWidth="550px" padding="p-3" class="mb-2 mb-md-0">
          <template slot="button" slot-scope="btn">
            <button
              class="btn btn-flat dropdown-toggle w-100"
              :class="{ toggled: btn.toggled }"
              @click="btn.toggle"
            >
              <i class="fas fa-filter me-1"></i> Samples
            </button>
          </template>
          <template slot="items">
            <SampleFilter
              ref="sampleFilter"
              class="me-0 me-md-2 mb-2 mb-md-0"
              :tabId="tabId"
              :filters="filters"
            />

            <ButtonSpinner
              type="submit"
              class="btn btn-primary mt-3"
              :is-loading="isApplying"
              @click.native.prevent="applyFilters('sampleFilter')"
            >
              <i class="fas fa-filter"></i> Apply Filters
            </ButtonSpinner>
          </template>
        </DropdownMenu>

        <DropdownMenu
          v-if="!isCompoundApp"
          customWidth="550px"
          padding="p-3"
          class="mb-2 mb-md-0"
        >
          <template slot="button" slot-scope="btn">
            <button
              class="btn btn-flat dropdown-toggle w-100"
              :class="{ toggled: btn.toggled }"
              @click="btn.toggle"
            >
              <i class="fas fa-filter me-1"></i> Fields
            </button>
          </template>
          <template slot="items">
            <FieldFilter ref="fieldFilter" :tabId="tabId" :filters="filters" />

            <ButtonSpinner
              type="submit"
              class="btn btn-primary mt-3"
              :is-loading="isApplying"
              @click.native.prevent="applyFilters('fieldFilter')"
            >
              <i class="fas fa-filter"></i> Apply Filters
            </ButtonSpinner>
          </template>
        </DropdownMenu>

        <div class="horizontal-separator ms-3 me-3 d-none d-md-block"></div>

        <div class="input-group input-group-sm mb-2 mb-md-0 me-0 me-md-2">
          <div class="input-group-text">
            <div
              v-if="isLoadingSamples && searchQuery"
              class="spinner-border spinner-border-sm"
            />
            <i v-else class="fas fa-search" />
          </div>

          <input
            type="text"
            class="form-control"
            placeholder="search..."
            v-model="searchQuery"
            @keyup="searchKeyMonitor"
          />

          <button
            v-if="searchQuery"
            class="btn btn-outline-danger"
            @click="clearSearch"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div
          v-if="!isCompoundApp"
          class="horizontal-separator ms-3 me-3 d-none d-md-block"
        ></div>

        <button
          v-if="!isCompoundApp"
          class="btn btn-primary btn-sm text-nowrap toggled"
          @click="toggleGatherHorizontalTable"
        >
          Use {{ isHorizontal ? 'Vertical' : 'Horizontal' }} Table
        </button>
      </div>

      <slot></slot>
    </div>
  </div>
</template>
<script>
import DropdownMenu from '@/js/components/DropdownMenu.vue';
import Paginate from '@/js/components/Pagination.vue';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import _debounce from 'lodash/debounce';
import { mapActions, mapState } from 'vuex';
import api from './api';
import FieldFilter from './components/FieldFilter.vue';
import SampleFilter from './components/SampleFilter.vue';
import { parseIdAsArray } from '@component-library/business-logic/app';

export default {
  props: {
    editable: Boolean,
    tabId: [Number, String],
    filters: Object,
    isLoadingSamples: Boolean,
    isHorizontal: Boolean,
  },
  emits: ['loadEverything', 'setSearchQuery', 'toggleOrientation'],
  data: () => ({
    searchQuery: null,
    isApplying: false,
    isFiltersVisible: false,
  }),
  components: {
    ButtonSpinner,
    DropdownMenu,
    Paginate,
    SampleFilter,
    FieldFilter,
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
      filterChanged: (state) => state.gather.filters.updated,
    }),
    isCompoundApp() {
      return parseIdAsArray(this.tabId).length > 1;
    },
  },
  methods: {
    ...mapActions({
      clearFilters: 'gather/clearFilters',
      setFilter: 'gather/setFilter',
      setFilters: 'gather/setFilters',
      updateProject: 'updateProject',
    }),
    async applyFilters(type) {
      const filters = this.$refs[type].getFilters();

      Object.keys(filters).forEach((filterKey) => {
        this.setFilter({
          key: filterKey,
          value: filters[filterKey],
        });
      });

      try {
        this.isApplying = true;

        if (!this.isCompoundApp) {
          await api.updateTemplateFilters(this.tabId, this.filters);
        }
        this.$emit('loadEverything');
      } catch (e) {
        throw e;
      } finally {
        this.isApplying = false;
      }
    },
    async clearAllFilters() {
      try {
        if (!this.isCompoundApp) {
          await api.updateTemplateFilters(this.tabId, null);
        }

        this.setFilters(null);

        this.$emit('loadEverything');
      } catch (e) {
        throw e;
      }
    },
    searchKeyMonitor: _debounce(function () {
      this.$emit('setSearchQuery', this.searchQuery);
    }, 800),
    clearSearch() {
      this.searchQuery = null;
      this.$emit('setSearchQuery', null);
    },
    toggleGatherHorizontalTable() {
      const isLegacy = !this.project.is_legacy_gather_table;

      axios.post('/project/update-by-field', {
        field: 'is_legacy_gather_table',
        value: isLegacy,
      });

      this.updateProject({
        is_legacy_gather_table: isLegacy,
      });
    },
  },
};
</script>
<style scoped>
.clear-filters {
  white-space: nowrap;
}
</style>
