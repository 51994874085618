import GeoJSON from 'ol/format/GeoJSON';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import { transformExtent, get as getProjection } from 'ol/proj';
import * as network from '../utils/network';
import enableLoadingEvents from './enableLoadingEvents';
import { assignIdToLayer } from '../layers';
import { mergeExtents, intersectsWithoutEdgeOverlap } from '../utils';
import * as utils from '../utils';
import { createDrawingLayerStyle } from '../styles';
import * as bl from '../../../business-logic';

const SUPPORTED_OUTPUT_FORMATS = [
  'application/json',
  'application/vnd.geo+json',
];

export async function getServiceData(serviceUrl, shouldUseCorsProxy) {
  const url = !shouldUseCorsProxy
    ? serviceUrl
    : utils.network.proxify(serviceUrl);
  const { data } = await axios.get(url, network.createRequestOptions());
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, 'application/xml');
  const nsResolver = doc.createNSResolver(doc.documentElement);

  const version = getText(
    doc,
    '//ows:ServiceIdentification/ows:ServiceTypeVersion/text()',
    doc,
    nsResolver
  );
  const title = getText(
    doc,
    '//ows:ServiceIdentification/ows:Title/text()',
    doc,
    nsResolver
  );

  const featureTypeListNode = doc.evaluate(
    '//wfs:FeatureTypeList/wfs:FeatureType',
    doc,
    nsResolver,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null
  );
  const featureTypes = [];
  let featureTypeNode;
  while ((featureTypeNode = featureTypeListNode.iterateNext())) {
    const featureType = getFeatureType(doc, featureTypeNode, nsResolver);
    featureTypes.push(featureType);
  }

  const layers = featureTypes.map((featureType) => ({
    id: featureType.name,
    name: featureType.title,
    crs: featureType.crs,
    extent: bl.extent.normalizeExtent(featureType.extent),
    outputFormat: featureType.outputFormat,
    parentLayerId: -1,
    subLayerIds: null,
  }));

  const extents = layers.map((l) => l.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    url: serviceUrl,
    attributions: '',
    version,
    title,
    description: title,
    layers,
    fullExtent,
  };
}

export function createLayer(map, options) {
  const { attributions, version, id, crs, outputFormat, shouldUseCorsProxy } =
    options;

  const projection = map.getView().getProjection();
  const visibleExtent = transformExtent(
    options.visibleExtent,
    'EPSG:4326',
    projection
  );

  const format = new GeoJSON({
    dataProjection: crs,
    featureProjection: projection,
  });
  const readProjectionFromObjectNative = format.readProjectionFromObject;
  // Hacked because crs of type string can't be recognized.
  // See the response from https://opendata.ccc.govt.nz/Park/service.svc/get?request=GetCapabilities&SERVICE=WFS.
  format.readProjectionFromObject = function (object) {
    if (typeof object.crs !== 'object') {
      delete object.crs;
    }
    return readProjectionFromObjectNative.call(this, object);
  };

  const layerSource = new VectorSource({
    attributions,
    loader: function (extent, resolution, projection, onLoad, onError) {
      if (!intersectsWithoutEdgeOverlap(visibleExtent, extent)) {
        onLoad([]);
        return;
      }

      const targetExtent = transformExtent(extent, projection, crs);
      const url = new URL(options.url);
      const query = new URLSearchParams({
        request: 'GetFeature',
        service: 'WFS',
        version,
        typenames: id,
        outputFormat,
        srsname: crs,
        bbox: `${targetExtent.join(',')},${crs}`,
      });
      for (let entry of query.entries()) {
        url.searchParams.set(entry[0], entry[1]);
      }
      let getFeatureUrl = url.toString();
      if (shouldUseCorsProxy) {
        getFeatureUrl = utils.network.proxify(getFeatureUrl);
      }

      axios
        .get(getFeatureUrl, network.createRequestOptions())
        .then(({ data }) => {
          const features = format.readFeatures(data, {
            featureProjection: projection,
          });
          features.forEach((item) => {
            if (!layerSource.getFeatureById(item.id_)) {
              layerSource.addFeature(item);
              if (typeof layer.onFeatureLoaded === 'function') {
                layer.onFeatureLoaded(item);
              }
            }
          });
          onLoad(features);
        })
        .catch(() => {
          onError();
        });
    },
    strategy: tileStrategy(
      createXYZ({
        extent: visibleExtent,
        tileSize: 256,
        maxResolution: map.getView().getMaxResolution(),
        maxZoom: map.getView().getMaxZoom(),
      })
    ),
  });

  const layer = new VectorLayer({
    source: layerSource,
    extent: visibleExtent,
  });
  assignIdToLayer(layer);
  layer.hasFeature = function (feature) {
    return this.getSource().hasFeature(feature);
  };
  layer.applyOptions = function (options) {
    this.options = options;

    const { renderer } = this.options;
    if (renderer) {
      const style = createDrawingLayerStyle(
        map,
        map.getViewer().getScaledLayerProperties(renderer.properties)
      );
      this.setStyle(style);
    }
  };

  enableLoadingEvents(layer);

  return layer;
}

function getText(doc, textXPath, contextNode, nsResolver) {
  const { stringValue } = doc.evaluate(
    textXPath,
    contextNode,
    nsResolver,
    XPathResult.STRING_TYPE,
    null
  );
  return stringValue;
}

function getOutputFormats(doc, nsResolver, contextNode, outputFormatTextXPath) {
  const outputFormats = [];
  const outputFormatTextNodes = doc.evaluate(
    outputFormatTextXPath,
    contextNode,
    nsResolver,
    XPathResult.ORDERED_NODE_ITERATOR_TYPE,
    null
  );
  let outputFormatTextNode;
  while ((outputFormatTextNode = outputFormatTextNodes.iterateNext())) {
    const { wholeText: outputFormat } = outputFormatTextNode;
    outputFormats.push(outputFormat);
  }
  return outputFormats;
}

function getOutputFormatsOfGetFeature(doc, nsResolver) {
  const { singleNodeValue: getFeatureNode } = doc.evaluate(
    `//ows:OperationsMetadata/ows:Operation[@name='GetFeature']`,
    doc,
    nsResolver,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  );
  return getOutputFormats(
    doc,
    nsResolver,
    getFeatureNode,
    `ows:Parameter[@name='outputFormat']/ows:AllowedValues/ows:Value/text()`
  );
}

function getOutputFormatsOfFeatureType(doc, nsResolver, featureTypeNode) {
  return getOutputFormats(
    doc,
    nsResolver,
    featureTypeNode,
    'wfs:OutputFormats/wfs:Format/text()'
  );
}

function getFeatureType(doc, featureTypeNode, nsResolver) {
  const name = getText(doc, 'wfs:Name', featureTypeNode, nsResolver);
  const title = getText(doc, 'wfs:Title', featureTypeNode, nsResolver);

  let crs = getText(doc, 'wfs:DefaultCRS', featureTypeNode, nsResolver);
  crs = getProjection(crs).getCode();

  let outputFormats = getOutputFormatsOfFeatureType(
    doc,
    nsResolver,
    featureTypeNode
  );
  if (!outputFormats.length) {
    outputFormats = getOutputFormatsOfGetFeature(doc, nsResolver);
  }
  const outputFormat = SUPPORTED_OUTPUT_FORMATS.find(
    (item) => outputFormats.indexOf(item) !== -1
  );
  if (!outputFormat) {
    throw new Error(
      `The service doesn't support the ${outputFormat} output format.`
    );
  }

  const lowerCornerText = getText(
    doc,
    'ows:WGS84BoundingBox/ows:LowerCorner/text()',
    featureTypeNode,
    nsResolver
  );
  const upperCornerText = getText(
    doc,
    'ows:WGS84BoundingBox/ows:UpperCorner/text()',
    featureTypeNode,
    nsResolver
  );
  const extent = [
    ...lowerCornerText.split(/\s+/).map(parseFloat),
    ...upperCornerText.split(/\s+/).map(parseFloat),
  ];

  return {
    name,
    title,
    crs,
    extent,
    outputFormat,
  };
}
