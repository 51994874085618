<template>
  <div
    class="text-center w-100 figure8-editor"
    :class="{
      'expanded-map': isExpanded,
      'position-relative': !isExpanded,
    }"
    :id="'map-iframe-container-' + block.id"
    v-click-outside="disableClickEvents"
  >
    <div class="expand__btn box-shadow" @click="toggleExpand">
      <i
        class="fas fa-fw"
        :class="{
          'fa-compress-wide': isExpanded,
          'fa-expand-wide': !isExpanded,
        }"
      />
    </div>

    <iframe
      v-if="isVisible"
      :src="getViewerUrl"
      :ref="identifier"
      class="h-100 w-100"
      @load="iframeLoaded"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },
  data: () => ({
    isVisible: false,
    hasLoaded: false,
    identifier: Date.now().toString(), //used so that component remounts as new
    isExpanded: false,
  }),
  watch: {
    block: {
      handler: function () {
        const iframe = this.getIframe();
        if (iframe) {
          iframe.location.reload(true);
        }
      },
      deep: true,
    },
    blockActionProperties() {
      this.handleAction();
    },
  },
  computed: {
    ...mapState({
      blockActionProperties: (state) => state.reporter.blockActionProperties,
      reportToken: (state) => state.reporter.reportToken,
    }),
    getBlockActionProperties() {
      // map is not inside slideshow, so dispatch actions
      if (!this.isSlide) {
        return this.block;
      }

      return this.blockActionProperties;
    },
    getViewerUrl() {
      const token = this.reportToken;
      return `/map-iframe/${this.block.id}` + (token ? `/${token}` : '');
    },
  },
  methods: {
    ...mapActions('reporter', ['setIsMapExpanded']),
    getIframe() {
      const viewer = this.$refs[this.identifier];

      if (!viewer || !viewer.contentWindow) {
        return;
      }

      return viewer.contentWindow;
    },
    disableClickEvents() {
      const iframe = this.getIframe();

      if (!iframe) {
        return;
      }

      iframe.postMessage({
        type: 'disable',
        action: null,
      });
    },
    iframeLoaded() {
      this.hasLoaded = true;
    },
    handleAction() {
      const iframe = this.getIframe();

      if (!this.isVisible || !this.hasLoaded || !iframe) {
        return;
      }

      if (
        !this.getBlockActionProperties ||
        (this.isSlide &&
          this.getBlockActionProperties.slide_id != this.block.slide_id)
      ) {
        iframe.postMessage({
          type: 'action',
          action: null,
        });
        return;
      }

      iframe.postMessage({
        type: 'action',
        action: this.getBlockActionProperties,
      });
    },
    handleIntersect(entries) {
      entries.forEach((entry) => {
        this.isVisible = entry.isIntersecting;
        if (!this.isVisible && this.hasLoaded) {
          this.hasLoaded = false;
          this.identifier = Date.now().toString();
        }
      });
    },
    handleMessageFromIFrameViewer({ data }) {
      if (data == 'mapHasLoaded') {
        this.handleAction();
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      this.setIsMapExpanded(this.isExpanded ? this.block.id : null);
    },
  },
  mounted() {
    const el = document.getElementById('map-iframe-container-' + this.block.id);

    this.observer = new IntersectionObserver(this.handleIntersect);
    this.observer.observe(el);
  },
  created() {
    window.addEventListener('message', this.handleMessageFromIFrameViewer);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessageFromIFrameViewer);

    const el = document.getElementById('map-iframe-container-' + this.block.id);
    if (el) {
      this.observer.unobserve(el);
    }
    this.observer.disconnect();
  },
};
</script>
<style lang="scss">
.figure8-map {
  left: 0;
  top: 0;
}

.block--map .figure8-editor {
  height: 550px;
}

.block--slideshow .figure8-editor {
  height: 100%;
}

.expand__btn {
  position: absolute;
  right: 0.625em;
  bottom: 0.625em;
  background: #ffffff;
  text-transform: none;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 9;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.05) 0px 3px 3px -2px, rgba(0, 0, 0, 0.03) 0px 1px 8px 0px;
}

.expanded-map {
  position: fixed;
  left: 0;
  top: 56px;
  z-index: 9999;
  width: 100vw;
  height: calc(100vh - 56px) !important;
}
</style>
