export function getImageSrc(projectId, value) {
  if (value.startsWith('file:')) {
    return (
      '/api/file/' +
      value.replace('file:', '') +
      '/data?project_id=' +
      projectId
    );
  }
  return value.startsWith('data:image')
    ? value
    : `/api/images/figure/${value}?project_id=${projectId}`;
}

export function getGatherImageSrc(projectId, fileName) {
  if (fileName.startsWith('file:')) {
    return (
      '/api/file/' +
      fileName.replace('file:', '') +
      '/data?project_id=' +
      projectId
    );
  }
  // Using redirect causes CORS security issue.
  return `/api/images/value/${projectId}/${fileName}?redirect=false`;
}
