<script lang="ts" setup>
import makeId from '@component-library/local-id.mjs';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import {
  nextGraphColor,
  resetGraphColors,
} from '@component-library/business-logic/mapping/color';
import { FEATURES, hasAccess } from '@component-library/feature-manager';
import AlertBox from '@component-library/components/AlertBox.vue';

const id = makeId();
const containerElement = ref<HTMLElement>();
const canvasElement = ref<HTMLCanvasElement>();
const context = ref<CanvasRenderingContext2D>();
const resizeObserver = ref<ResizeObserver>();
const aspectRatio = ref(1);
const wgs84ToScreenFactor = 40075000;

const props = defineProps<{
  options?: any;
  data?: any;
}>();

type Point = [number, number];
type WeightedPoint = {
  offset: number | null;
  point: Point;
};
type PointOfInterest = {
  id: number;
  latitude: number;
  longitude: number;
  title: string;
  values: (number | null)[];
};
type Visit = {
  id: number;
  readings: (WeightedPoint | null)[];
};
const graphData = computed(() => {
  return Array.isArray(props.data) ? props.data[0]?.pages[0] : props.data;
});

function drawGraph(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) {
  const margin = 50;
  const polygon = (graphData.value?.polygon || []) as Point[];
  const isometricPolygon = polygon.map(transformToIsometric);

  const { scale, offsetX, offsetY } = getScaleAndOffset(
    isometricPolygon,
    canvas.width,
    canvas.height,
    margin
  );

  const scaledAndCenteredPolygon = isometricPolygon.map(
    (point) => [point[0] * scale + offsetX, point[1] * scale + offsetY] as Point
  );

  const {
    style: { graph },
  } = props.options;
  const graphKey = 'dashboard-' + id;

  resetGraphColors(
    graphKey,
    graph.defaultBackgroundColors
      ? [...graph.defaultBackgroundColors]
      : undefined
  );

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawBasePolygon(
    ctx,
    scaledAndCenteredPolygon,
    nextGraphColor(graphKey) || 'blue'
  );

  // Locations with multiple values
  const pointsOfInterests = (graphData.value?.points_of_interest ??
    []) as PointOfInterest[];

  // values with multiple points of interest
  const visits: Visit[] = [];
  const maxValuesLength = Math.max(
    ...pointsOfInterests.map((poi) => poi.values.length)
  );
  for (let i = 0; i < maxValuesLength; i++) {
    const readings = pointsOfInterests.map((poi) => {
      const offset = i > poi.values.length - 1 ? null : poi.values[i];
      const point = transformToIsometric([poi.longitude, poi.latitude]);
      return {
        offset: offset === null ? null : -offset,
        point: [
          point[0] * scale + offsetX,
          point[1] * scale + offsetY,
        ] as Point,
      } as WeightedPoint;
    });
    visits.push({ id: i, readings });
  }

  // find max visit offset
  const maxOffset = Math.max(
    ...visits.map((visit) =>
      Math.max(
        ...visit.readings
          .filter((r) => r !== null)
          .map((r) => Math.abs(r!.offset ?? 0))
      )
    )
  );

  // Scale the offsets (displacement polygons) to fit within the margin
  const verticalScaleFactor = margin / maxOffset;
  if (maxOffset > margin * 0.8) {
    for (let i = 0; i < visits.length; i++) {
      const visit = visits[i];
      for (let j = 0; j < visit.readings.length; j++) {
        const reading = visit.readings[j];
        if (reading !== null) {
          reading.offset = reading.offset! * verticalScaleFactor;
        }
      }
    }
  }

  for (let i = 0; i < visits.length; i++) {
    const visit = visits[i];
    const weightedPoints = visit.readings.filter(
      (r) => r !== null
    ) as WeightedPoint[];
    drawAdjustedPolygon(
      ctx,
      scaledAndCenteredPolygon,
      weightedPoints,
      nextGraphColor('dashboard-' + id) || 'red'
    );

    // Draw the labels last for the weighted points
    // So they are rended on top
    if (i === visits.length - 1) {
      drawWeightedPointLabels(ctx, weightedPoints);
    }
  }

  const pixelsPerMm = 2480 / 210;
  const factor = 1 / pixelsPerMm;
  const scaleRatio = Math.round(1 / (verticalScaleFactor * factor));
  if (scaleRatio === Infinity || scaleRatio === -Infinity) {
    return;
  }
  drawText(
    ctx,
    10,
    canvas.height - 20,
    'Vertical Distorted Scale 1:' + scaleRatio
  );
}

function drawText(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  text: string
) {
  ctx.save();
  ctx.strokeStyle = 'white';
  ctx.font = '12px Arial';
  ctx.fillStyle = 'black';
  ctx.lineWidth = 2;
  ctx.strokeText(text, x, y);
  ctx.fillText(text, x, y);
  ctx.restore();
}

onMounted(() => {
  const canvas = document.getElementById(id) as HTMLCanvasElement | null;
  if (!canvas) {
    throw new Error('Displacement Graph: Canvas not found');
  }

  resizeToContainer(canvas);

  const ctx = canvas.getContext('2d') || undefined;
  if (!ctx) {
    throw new Error('Displacement Graph: Canvas context not found');
  }

  canvasElement.value = canvas;
  context.value = ctx;

  drawGraph(canvas, ctx);

  if (!containerElement.value) {
    throw new Error('containerElement is not set');
  }
  resizeObserver.value = new ResizeObserver(() => {
    resizeToContainer(canvas);
    drawGraph(canvas, ctx);
  });
  resizeObserver.value.observe(containerElement.value);
});

onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect();
  }
});

watch([() => props.data, () => props.options, () => aspectRatio.value], () => {
  if (context.value && canvasElement.value) {
    drawGraph(canvasElement.value, context.value);
  }
});

function resizeToContainer(canvas: HTMLCanvasElement) {
  if (!containerElement.value) {
    console.error('containerElement is not set');
    return;
  }
  canvas.width = containerElement.value.clientWidth;
  canvas.height = containerElement.value.clientHeight;

  aspectRatio.value = canvas.width / canvas.height;
}

function getScaleAndOffset(
  points: Point[],
  canvasWidth: number,
  canvasHeight: number,
  margin: number
): { scale: number; offsetX: number; offsetY: number } {
  // Find bounding box of the points
  let minX = Infinity,
    maxX = -Infinity,
    minY = Infinity,
    maxY = -Infinity;
  for (let point of points) {
    if (point[0] < minX) minX = point[0];
    if (point[0] > maxX) maxX = point[0];
    if (point[1] < minY) minY = point[1];
    if (point[1] > maxY) maxY = point[1];
  }

  // Determine scaling factor to fit within canvas with margin
  const width = maxX - minX;
  const height = maxY - minY;
  const scaleX = (canvasWidth - margin * 2) / width;
  const scaleY = (canvasHeight - margin * 2) / height;
  const scale = Math.min(scaleX, scaleY);

  // Calculate offset to center the points
  const offsetX = (canvasWidth - width * scale) / 2 - minX * scale;
  const offsetY = (canvasHeight - height * scale) / 2 - minY * scale;

  return {
    scale,
    offsetX,
    offsetY,
  };
}

function drawBasePolygon(
  ctx: CanvasRenderingContext2D,
  polygon: Point[],
  color: string
) {
  ctx.save();
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.lineWidth = 2;
  ctx.beginPath();
  for (let i = 0; i < polygon.length; i++) {
    const point = polygon[i];
    if (i === 0) {
      ctx.moveTo(point[0], point[1]);
    } else {
      ctx.lineTo(point[0], point[1]);
    }
  }
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
}

function drawAdjustedPolygon(
  ctx: CanvasRenderingContext2D,
  polygon: Point[],
  weightedPoints: WeightedPoint[],
  strokeColor: string
) {
  const validWeightedPoints = weightedPoints.filter((wp) => wp.offset !== null);
  const adjustedPolygon = polygon.map((point) => {
    // Find the closest weighted point to determine the offset
    const closestWeightedPoint = validWeightedPoints.reduce((prev, curr) => {
      const prevDistance = Math.sqrt(
        Math.pow(prev.point[0] - point[0], 2) +
          Math.pow(prev.point[1] - point[1], 2)
      );
      const currDistance = Math.sqrt(
        Math.pow(curr.point[0] - point[0], 2) +
          Math.pow(curr.point[1] - point[1], 2)
      );
      return prevDistance < currDistance ? prev : curr;
    });

    // Calculate vertical offset based on weight difference from the base weight
    const verticalOffset = closestWeightedPoint.offset ?? 0;

    return [point[0], point[1] + verticalOffset] as Point;
  });

  // Draw the adjusted polygon
  ctx.save();
  ctx.strokeStyle = strokeColor;
  ctx.lineWidth = 1;
  ctx.beginPath();
  ctx.setLineDash([5, 5]);
  for (let i = 0; i < adjustedPolygon.length; i++) {
    const point = adjustedPolygon[i];
    if (i === 0) {
      ctx.moveTo(point[0], point[1]);
    } else {
      ctx.lineTo(point[0], point[1]);
    }
  }
  ctx.closePath();
  ctx.stroke();
  ctx.restore();
}

function drawWeightedPointLabels(
  ctx: CanvasRenderingContext2D,
  weightedPoints: WeightedPoint[],
  size: number = 12,
  offsetX: number = 20,
  padding: number = 2
) {
  ctx.font = size + 'px Arial';
  for (let i = 0; i < weightedPoints.length; i++) {
    const wp = weightedPoints[i];
    // fill label background with padding
    const label = String(i + 1);
    const labelSize = ctx.measureText(label);
    ctx.fillStyle = 'white';
    ctx.fillRect(
      wp.point[0] + offsetX - padding,
      wp.point[1] - size,
      labelSize.width + padding * 2,
      size + padding * 2
    );
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.setLineDash([]);

    // Background border
    ctx.strokeRect(
      wp.point[0] + offsetX - padding,
      wp.point[1] - size,
      labelSize.width + padding * 2,
      size + padding * 2
    );

    ctx.fillText(label, wp.point[0] + offsetX, wp.point[1]);
  }
}

function transformToIsometric(point: Point): Point {
  return latLngToScreenSpace([point[0] - point[1], (point[0] + point[1]) / 2]);
}

function latLngToScreenSpace(latLng: Point): Point {
  // Note: This may need to be changed to use proj4
  // to convert from WGS84 to UTM, to get screen space.
  return [
    (latLng[0] + 180) * ((wgs84ToScreenFactor * aspectRatio.value) / 360),
    (90 - latLng[1]) * (wgs84ToScreenFactor / 180),
  ];
}
</script>

<template>
  <div ref="containerElement" class="h-100">
    <AlertBox
      v-if="!hasAccess(FEATURES.DISPLACEMENT_GRAPH)"
      type="warning"
      class="mb-3"
    >
      This feature is not available in your current plan.
    </AlertBox>
    <canvas v-else :id="id"></canvas>
  </div>
</template>
