const loadPaginatedSamples = (data) => {
  return axios.get('/table/samples', {
    params: data,
  });
};

const loadChemicals = (data) => {
  return axios.get('/table/chemicals', {
    params: data,
  });
};

const loadResults = (data) => {
  return axios.get('/table/results', {
    params: data,
  });
};

const loadGatherValues = (data) => {
  return axios.get('/table/gather-values', {
    params: data,
  });
};

const loadAdditionalData = (data) => {
  return axios.get('/table/additional-data', {
    params: data,
  });
};

const modifyTableFields = (data) => {
  return axios.post('/table/modify-fields', data);
};

const updateChemicalOrdering = (data) => {
  return axios.post('/table/update-chemical-ordering', data);
};

const getExportSettings = (data) => {
  return axios.get('/table/export-settings', {
    params: data,
  });
};

const getFilterOptionByType = (type) => {
  return axios.get(`/table/filters/options/${type}`);
};

const getFilterOptionById = (id) => {
  return axios.get(`/table/filters/options/${id}/gather`);
};

const getSampleById = (id) => {
  return axios.get('/data/sample/' + id);
};

const getFilterPaginatedSamples = (data) => {
  return axios.get('/table/filters/paginated-samples', {
    params: data,
  });
};

const getFilterPaginatedFigureLayers = (data) => {
  return axios.get('/table/filters/paginated-figure-layers', {
    params: data,
  });
};

const exportToFile = (data) => {
  return axios.post('/download/general', data);
};

const getAllCustomTemplates = (data) => {
  return axios.get('/download/custom-templates', {
    params: data,
  });
};

const getAllSamples = (data) => {
  return axios.get('/data/samples', {
    params: data,
  });
};

const getAllChemicalUnits = ({ chemical_id }) => {
  return axios.get('/data/chemical/units', {
    params: {
      chemical_id,
    },
  });
};

const getChemicalGuidelineAnalysis = (id, scenario_id) => {
  return axios.get(`/data/chemical/${id}/guideline-analysis`, {
    params: {
      scenario_id,
    },
  });
};

const updateScenarioGroup = (group_id, data) => {
  return axios.put(`/dashboard/scenario-group/${group_id}`, data);
};

const updateStatistics = (data) => {
  return axios.post(`/table/update-statistics`, data);
};

const updateLayerFilters = (data) => {
  return axios.post('/table/update-layer-filters', data);
};

/**
 * Download Management
 */
const getDocumentExportInformation = (data) => {
  return axios.get('/dashboard/get-export-information', {
    params: data,
  });
};

const exportToDocument = (data) => {
  return axios.post('/download/text', data);
};

const exportToExcelTable = (data) => {
  return axios.post('/download/enviro-table', data);
};

const exportToProUCLTable = (data) => {
  return axios.post('/proucl/download-table', data);
};

/**
 * Sample Management
 */

const updateSample = (data) => {
  return axios.put('/data/sample/' + data.id, data);
};

const deleteSample = (id) => {
  return axios.delete('/data/sample/' + id);
};

const absorbSample = (data) => {
  return axios.post('/data/sample/absorb/' + data.id, data);
};

/**
 * Result Management
 */
const getResult = (id) => {
  return axios.get(`/data/result/${id}`);
};

const createResult = (data) => {
  return axios.post('/data/result', data);
};

const updateResult = (data) => {
  return axios.put('/data/result/' + data.id, data);
};

const deleteResult = (id) => {
  return axios.delete('/data/result/' + id);
};

/**
 * Note Management
 */

const createResultNote = (data) => {
  return axios.post('/data/note', data);
};

const updateResultNote = (data) => {
  return axios.put('/data/note/' + data.id, data);
};

const deleteResultNote = (id) => {
  return axios.delete('/data/note/' + id);
};

/**
 * Chemical Management
 */
const getChemical = (id) => {
  return axios.get(`/data/chemical/${id}`);
};

const modifyChemical = (data) => {
  return axios.put('/data/chemical/' + data.id, data);
};

const deleteChemical = (id) => {
  return axios.delete('/data/chemical/' + id);
};

const revertChemicalAlignment = (id) => {
  return axios.post(`/data/chemical/${id}/revert-alignment`);
};

/**
 * Guideline Management
 */
const getSampleGuidelines = (data) => {
  return axios.get('/dashboard/sample-guideline/get', {
    params: data,
  });
};

const modifyExistingGuideline = (data) => {
  return axios.post('/dashboard/sample-guideline/modify-existing', data);
};

const modifyCustomGuideline = (data) => {
  return axios.post('/dashboard/sample-guideline/modify-custom', data);
};

/**
 * ProUCL Module
 */
const getProUCLExportedChemicals = () => {
  return axios.get('/proucl/exported-chemicals');
};

const createUCLSample = (data) => {
  return axios.post('/proucl/create-ucl-sample', data);
};

/**
 * RPD
 */

const updateRPDAcceptance = (data) => {
  return axios.put('/verification/rpd/acceptance-value', data);
};

export default {
  loadPaginatedSamples,
  loadChemicals,
  loadResults,
  loadGatherValues,
  loadAdditionalData,
  modifyTableFields,
  updateChemicalOrdering,
  getExportSettings,
  getFilterOptionByType,
  getFilterOptionById,
  getSampleById,
  getFilterPaginatedSamples,
  getFilterPaginatedFigureLayers,
  exportToFile,
  getAllCustomTemplates,

  getAllSamples,
  getAllChemicalUnits,
  getChemicalGuidelineAnalysis,
  updateScenarioGroup,
  updateStatistics,
  updateLayerFilters,
  getDocumentExportInformation,
  exportToDocument,
  exportToExcelTable,
  exportToProUCLTable,

  updateSample,
  deleteSample,
  absorbSample,

  getResult,
  createResult,
  updateResult,
  deleteResult,

  createResultNote,
  updateResultNote,
  deleteResultNote,

  getChemical,
  modifyChemical,
  deleteChemical,
  revertChemicalAlignment,

  getSampleGuidelines,
  modifyExistingGuideline,
  modifyCustomGuideline,

  getProUCLExportedChemicals,
  createUCLSample,

  updateRPDAcceptance,
};
