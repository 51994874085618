function getContent(a) {
  const startIndex = a.indexOf('>');

  let endIndex = -1;
  while (true) {
    const ltIndex = a.indexOf('<', endIndex + 1);
    if (ltIndex !== -1) {
      endIndex = ltIndex;
    } else {
      break;
    }
  }

  if (startIndex === -1 || endIndex === -1) {
    throw new Error(`Invalid a: ${a}`);
  }

  return a.substring(startIndex + 1, endIndex);
}

/**
 * Links handled by HTML Purifier lose attributes such as rel and target.
 * This function brings the lost attributes back.
 * @param {String} content
 * @returns
 */
export default function fixLinks(content) {
  if (!content) {
    return content;
  }

  const aRegEx = /<\s*a[^>]*>(.*?)<\s*\/\s*a>/gi;
  const attrsRegEx =
    /(\w+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']?/gi;
  const fixedContent = content.replaceAll(aRegEx, (a) => {
    const iterator = a.matchAll(attrsRegEx);
    const aAttrs = [];
    for (const match of iterator) {
      aAttrs.push({ name: match[1], value: match[2] });
    }

    //Attributes removed by HTML purification need to come back.
    aAttrs.push({ name: 'rel', value: 'noopener noreferrer' });
    aAttrs.push({ name: 'target', value: '_blank' });

    const aContent = getContent(a);
    const aAttrsString = aAttrs
      .map(({ name, value }) => `${name}="${value}"`)
      .join(' ');
    return `<a ${aAttrsString}>${aContent}</a>`;
  });
  return fixedContent;
}
