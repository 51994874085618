const MEASUREMENTS = {
  metre: {
    short: 'm',
    long: 'Metre',
  },
  mm: {
    short: 'mm',
    long: 'Millimetre',
  },
  cm: {
    short: 'cm',
    long: 'Centimetre',
  },
  feet: {
    short: 'ft',
    long: 'Feet',
  },
  inch: {
    short: '″',
    long: 'Inch',
  },
};

export const getMeasurements = () => {
  return MEASUREMENTS;
};

export const getMeasurementShortTitle = (unit) => {
  return getMeasurementByUnit(unit).short;
};

export const getMeasurementLongTitle = (unit) => {
  return getMeasurementByUnit(unit).long;
};

/**
 * Uses object to map unit type, and Defaults to metre unit
 */
const getMeasurementByUnit = (unit) => {
  return MEASUREMENTS[unit] || MEASUREMENTS.metre;
};
