import { useStore } from '@/js/store';

import { convertDate } from '@component-library/utils/date';

export default function useDate() {
  const store = useStore();

  return {
    convertDate: (date, withTime) => {
      return convertDate(
        date,
        store.getters.get_date_format_by_country,
        withTime,
        store.getters.get_project_timezone
      );
    },
  };
}
