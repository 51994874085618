import { useStore } from '@/js/store';
import { LineString } from 'ol/geom';
import { Style, Text } from 'ol/style';
import TextPlacement from 'ol/style/TextPlacement';
import { formatLength } from '../utils';
import { getMeasurementStyleConfig } from './text';

export default function createOutlineStyle(map, feature, isTemporary) {
  const store = useStore();
  const outlineStyle = [];
  const geometry = feature.getGeometry();
  const geometryType = geometry.getType();
  let coordinates = [];
  if (['Polygon', 'Circle'].indexOf(geometryType) !== -1) {
    coordinates = geometry.getLinearRing(0).getCoordinates();
  } else if (geometryType === 'LineString') {
    coordinates = geometry.getCoordinates();
  }

  for (let i = 0; i < coordinates.length - 1; i++) {
    const segmentCoordinates = [coordinates[i], coordinates[i + 1]];
    const segment = new LineString(segmentCoordinates);
    const segmentStyle = new Style({
      geometry: segment,
      text: new Text({
        ...getMeasurementStyleConfig(map, isTemporary),
        text: formatLength(map, segment, store.state.project.measurement_type),
        placement: TextPlacement.LINE,
        textBaseline: 'bottom',
        overflow: false,
      }),
      zIndex: 1,
    });
    outlineStyle.push(segmentStyle);
  }

  return outlineStyle;
}
