<template>
  <div class="w-100 h-100" :class="getSetting('cropping') || 'dont-crop'">
    <div
      v-if="isLoading"
      class="py-5 d-flex align-items-center justify-content-center w-100 h-100 position-absolute"
    >
      <i class="spinner-border"></i>
    </div>

    <div
      v-if="is3DModel"
      @click="enableClickEvents"
      v-click-outside="() => (disableClickEvents = true)"
      class="w-100 h-100"
    >
      <div v-if="disableClickEvents && isLoaded" class="move__alert">
        <i class="fal fa-mouse me-2" /> Click on the image to enable 3D
        interactions
      </div>

      <iframe
        frameborder="0"
        allowfullscreen
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking"
        xr-spatial-tracking
        execution-while-out-of-viewport
        execution-while-not-rendered
        web-share
        v-lazy
        :data-url="block.file_url + '?autostart=1'"
        width="100%"
        height="100%"
        :class="{
          'block-events': disableClickEvents,
        }"
        @load="imageLoaded"
      >
      </iframe>
    </div>
    <template v-else>
      <img
        v-lazy
        :data-url="block.file_url"
        :style="{
          'object-position':
            getSetting('cropping') == 'crop'
              ? getSetting('focal-point') || '0% 0%'
              : '',
        }"
        class="clickable"
        @load="imageLoaded"
        @click="setImagePreviewUrl(block.file_url)"
      />
    </template>

    <div v-if="!isSlide" class="caption mt-3">
      <CaptionText
        :block="block"
        placeholder="Add a caption for this image (optional)"
        @update="(data) => $emit('update', data)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

import CaptionText from './Text.vue';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },

  data: () => ({
    disableClickEvents: true,
    isLoading: true,
    isLoaded: false,
  }),
  components: { CaptionText },
  computed: {
    is3DModel() {
      return this.block.file_url.includes('sketchfab');
    },
  },
  methods: {
    ...mapActions('reporter', ['setImagePreviewUrl']),
    getSetting(setting) {
      const settings = this.block.settings;
      return settings ? settings[setting] : null;
    },
    enableClickEvents() {
      this.disableClickEvents = false;
    },
    imageLoaded() {
      this.isLoading = false;
      this.isLoaded = true;
    },
  },
};
</script>
<style lang="scss" scoped>
iframe {
  min-height: 480px;
}

.block-events {
  pointer-events: none;
}

.loading-bg {
  background: var(--accent-color);
}
</style>
