import { Style } from 'ol/style';
import { ArrowHead } from '../geometries';
import * as utils from '../utils';

export default function createArrowStyle(
  map,
  feature,
  headStroke,
  weight,
  multiDirectional = false
) {
  const arrowStyle = [];
  const geometry = feature.getGeometry();
  geometry.forEachSegment(function (start, end) {
    if (utils.line.isLastSegment(geometry, { end })) {
      const arrowHead = new ArrowHead(map, start, end, geometry, weight);
      const [arrowHeadLine1, arrowHeadLine2] = arrowHead.getLines(map);
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine1,
          stroke: headStroke,
        })
      );
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine2,
          stroke: headStroke,
        })
      );
    }

    if (multiDirectional && utils.line.isFirstSegment(geometry, { start })) {
      const arrowHead = new ArrowHead(map, end, start, geometry, weight);
      const [arrowHeadLine1, arrowHeadLine2] = arrowHead.getLines(map);

      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine1,
          stroke: headStroke,
        })
      );
      arrowStyle.push(
        new Style({
          geometry: arrowHeadLine2,
          stroke: headStroke,
        })
      );
    }
  });

  return arrowStyle;
}
