import { getUid } from 'ol/util';
import { EditableSample } from '../overlays';

export default function createSampleEdit(map, sample, forNewSample = true) {
  const editableSample = new EditableSample(map, sample, forNewSample);
  const onResolutionChange = () => {
    editableSample.refresh();
  };
  let feature_;
  let layer_;

  return {
    get layer() {
      return layer_;
    },
    set layer(value) {
      const previousLayer = layer_;
      if (previousLayer && value !== previousLayer) {
        const { interactionManager } = map;
        interactionManager.changeEditsKey(getUid(previousLayer), getUid(value));
      }
      layer_ = value;
    },
    selectFeature(feature) {
      const position = feature.getGeometry().getCoordinates();
      editableSample.setPosition(position);
      feature_ = feature;
    },
    getFeature() {
      return feature_;
    },
    activate() {
      map.getView().on('change:resolution', onResolutionChange);
      editableSample.activate(this);
    },
    destroy() {
      map.getView().un('change:resolution', onResolutionChange);
      editableSample.deactivate();
    },
    invalidate() {
      editableSample.invalidate(this);
    },
    setPosition(position) {
      editableSample.setPosition(position);
    },
  };
}
