import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

import analysisModule from './analysis';

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {
    analysis: {
      namespaced: true,
      ...analysisModule,
    },
  },
};

export { NAMESPACE } from './helpers';
