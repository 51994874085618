import { Vector as VectorSource } from 'ol/source';
import * as constants from './constants';
import createSquareEdit from '../createSquareEdit';
import { LAYER_TYPES, createDrawingLayer, getMaxZIndex } from '../../layers';

export default function createMoveResizeRotateEdit(map) {
  const edit = createSquareEdit(map, {
    translate: false,
    translateFeature: true,
    draggingOnSidesEnabled: false,
    rotate: true,
  });
  const {
    selectFeature,
    destroy,
    interactions: [transform],
  } = edit;
  const onChangeGeometry = () => {
    // Becuase feature has the layer's uid, so imageLayer instead of
    // imageMateLayer is returned.
    const imageLayer = map.layerManager.findLayerByFeature(feature_);
    imageLayer.syncCorners();
  };

  let feature_ = null;
  let imageMateLayer = null;

  edit.editMethodCode = constants.EDIT_METHOD_CODES.MOVE_RESIZE_ROTATE;

  edit.selectFeature = function (feature) {
    feature_ = feature;
    const source = new VectorSource({ features: [feature_] });
    imageMateLayer = createDrawingLayer(map, source);
    imageMateLayer.applyOptions({
      type: LAYER_TYPES.IMAGE_MATE,
    });
    imageMateLayer.setZIndex(getMaxZIndex());
    map.layerManager.addLayer(imageMateLayer);

    selectFeature.call(this, feature_);

    // Handle scaling and rotation.
    feature_.on('change:geometry', onChangeGeometry);
  };

  edit.destroy = function (map) {
    if (feature_) {
      feature_.un('change:geometry', onChangeGeometry);
    }

    if (imageMateLayer) {
      map.layerManager.removeLayer(imageMateLayer);
    }

    feature_ = null;
    imageMateLayer = null;
    destroy.call(this, map);
  };

  // Handle movement.
  transform.on('translating', ({ feature }) => {
    const imageLayer = map.layerManager.findLayerByFeature(feature);
    imageLayer.syncCorners();
  });

  return edit;
}
