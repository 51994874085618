import { Attribution, ScaleLine } from 'ol/control';
import GoogleLogo from '../../../lib/olbm/control/GoogleLogoControl';
import { MapType } from '../../../lib/olbm/common/types';
import LoadingSpinner from './LoadingSpinner';
import NorthArrow from './NorthArrow';

const ATTRIBUTION = 'Attribution';
const SCALE_BAR = 'ScaleLine';
const LOADING_SPINNER = 'LoadingSpinner';
const NORTH_ARROW = 'NorthArrow';
const GOOGLE_LOGO = 'GoogleLogo';

const MANAGED_CONTROL_CLASSES = {
  [ATTRIBUTION]: Attribution,
  [SCALE_BAR]: ScaleLine,
  [LOADING_SPINNER]: LoadingSpinner,
  [NORTH_ARROW]: NorthArrow,
  [GOOGLE_LOGO]: GoogleLogo,
};

export default class ControlManager {
  map: any;
  constructor(map) {
    this.map = map;
  }

  getControl_(key) {
    return this.map
      .getControls()
      .getArray()
      .find((control) => control instanceof MANAGED_CONTROL_CLASSES[key]);
  }

  createControl_(key) {
    const viewer = this.map.getViewer();
    const {
      country,
      figureLayout,
      scale,
      selectedFigure,
      isFigurePrintLayoutUsed,
    } = viewer;
    let target: HTMLInputElement | null = null;
    switch (key) {
      case ATTRIBUTION:
        return new Attribution({
          className: 'ol-attribution ol-attribution-adaptive',
          collapsible: false,
        });
      case SCALE_BAR:
        target = !isFigurePrintLayoutUsed
          ? document.querySelector('#scale-bar') // The scale bar shows either on the map or in the legend.
          : document.querySelector('.mv-scale-bar'); // The scale bar shows in a figure print layout.
        return new ScaleLine({
          target: target!,
          bar: true,
          units: country === 'US' ? 'us' : 'metric',
          dpi: figureLayout.dpi * figureLayout.zoom,
        });
      case LOADING_SPINNER:
        return new LoadingSpinner({
          isScaled:
            this.map.getType() === MapType.PARCEL_EDITOR
              ? false
              : figureLayout.isFigureReadyForExporting,
        });
      case NORTH_ARROW:
        target = !isFigurePrintLayoutUsed
          ? null
          : document.querySelector('.mv-north-arrow');
        const northArrowType = selectedFigure?.north_arrow_type || 0;
        const northArrowSize = selectedFigure?.north_arrow_size || 32;
        const width =
          (figureLayout.isFigureReadyForExporting
            ? northArrowSize * (48 / 32)
            : northArrowSize) * scale;
        const rotation = this.map.getRotation();

        return new NorthArrow({
          target,
          northArrowType,
          width,
          rotation,
          clickable: true,
          nonClickableTitle: '',
          addClass: !isFigurePrintLayoutUsed,
        });
      case GOOGLE_LOGO:
        return new GoogleLogo({ layoutZoom: figureLayout.zoom });
      default:
        throw new Error(`The control ${key} is not supported.`);
    }
  }

  showControl_(key) {
    let control = this.getControl_(key);
    if (!control) {
      control = this.createControl_(key);
      this.map.addControl(control);
    }
    return control;
  }

  hideControl_(key) {
    const control = this.getControl_(key);
    if (control) {
      this.map.removeControl(control);
    }
    return control;
  }

  showAttribution() {
    this.showControl_(ATTRIBUTION);
  }

  hideAttribution() {
    this.hideControl_(ATTRIBUTION);
  }

  showScaleBar() {
    this.showControl_(SCALE_BAR);
  }

  hideScaleBar() {
    this.hideControl_(SCALE_BAR);
  }

  showLoadingSpinner() {
    this.showControl_(LOADING_SPINNER);
  }

  hideLoadingSpinner() {
    this.hideControl_(LOADING_SPINNER);
  }

  getLoadingSpinner() {
    return this.getControl_(LOADING_SPINNER);
  }

  showNorthArrow() {
    this.showControl_(NORTH_ARROW);
  }

  hideNorthArrow() {
    this.hideControl_(NORTH_ARROW);
  }

  getNorthArrow() {
    return this.getControl_(NORTH_ARROW);
  }

  showGoogleLogo() {
    this.showControl_(GOOGLE_LOGO);
  }

  hideGoogleLogo() {
    this.hideControl_(GOOGLE_LOGO);
  }

  getGoogleLogo() {
    return this.getControl_(GOOGLE_LOGO);
  }
}
