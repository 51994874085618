<script lang="ts" setup>
import Dashboard from '@/js/modules/project/data-insights/components/dashboard/Dashboard.vue';
import { useInsightsStore } from '@/js/modules/project/data-insights/store/insights';
import { DASHBOARD_CONTEXTS } from '@component-library/data-insights';
import { onMounted, ref } from 'vue';

const insightsStore = useInsightsStore();
defineProps<{
  block: { insight_dashboard_id: string };
  isSlide: boolean;
}>();
const dashboardContext = ref(DASHBOARD_CONTEXTS.VISUAL_REPORT);

onMounted(() => {
  if (insightsStore.dashboards.length === 0) {
    insightsStore.loadDashboards();
  }
});
</script>

<template>
  <Dashboard
    v-if="
      insightsStore.dashboards.length > 0 && !insightsStore.loadingDashboards
    "
    :id="parseInt(block.insight_dashboard_id, 10)"
    @editDashboardItem="() => {}"
    @deleteDashboardItem="() => {}"
    :readOnly="true"
    :controlled="false"
    :heightLimited="isSlide"
    :context="dashboardContext"
  />
</template>
