export type Field = {
  key?: string;
  template_field_id?: number;
  is_repeatable?: boolean;
  title: string;
  is_default?: boolean;
  has_calculation?: boolean;
  is_header?: boolean;
};

export const allFields: Field[] = [
  {
    key: 'sampled_depth',
    title: 'Depth',
    is_default: true,
  },
  {
    key: 'sampled_date',
    title: 'Sampled Date',
    is_default: true,
  },
  {
    key: 'lab_title',
    title: 'Lab Title',
  },
  {
    key: 'matrix',
    title: 'Matrix',
  },
  {
    key: 'original_title',
    title: 'Original Title',
  },
  {
    key: 'location_code',
    title: 'Location Code',
  },
  {
    key: 'lab_report_number',
    title: 'Lab Report No.',
  },
  {
    key: 'soil_description',
    title: 'Soil Description',
  },
  {
    key: 'lab_sample_type',
    title: 'QC Sample Type',
  },
  {
    key: 'latitude',
    title: 'Latitude',
  },
  {
    key: 'longitude',
    title: 'Longitude',
  },
  {
    key: 'guideline_soil_options',
    title: 'Soil Type / Depth',
  },
  {
    key: 'gw_guideline_soil_options',
    title: 'GW Soil Type / Depth',
  },
  {
    key: 'composite_of',
    title: 'No. of sub samples in composite',
  },
  {
    key: 'field_ph_test',
    title: 'ASS - field pH Test',
    has_calculation: true,
  },
  {
    key: 'sampled_elevation',
    title: 'Elevation',
  },
  {
    key: 'apec',
    title: 'APEC',
  },
  {
    key: 'precinct',
    title: 'Precinct',
  },
  {
    key: 'catchment',
    title: 'Catchment',
  },
  {
    key: 'data_source',
    title: 'Data Source',
  },
  {
    key: 'document_index',
    title: 'Document Index',
  },
  {
    key: 'location_code_alt',
    title: 'Location Code Alt',
  },
  {
    key: 'analysis',
    title: 'Analysis',
  },
  {
    key: 'raw_matrix',
    title: 'Raw Matrix',
  },
  {
    key: 'parent_sample',
    title: 'Parent Sample',
  },
  {
    key: 'batch',
    title: 'Batch',
  },
];

export const getDefaultSampleFields = () => {
  return allFields.filter((field) => field.is_default);
};
