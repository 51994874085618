import * as bl from './business-logic';
import { normalizeHex } from './lib/olbm/style/color';
import MarkerHandler from './modules/MarkerHandler';
import ShapeHandler from './modules/ShapeHandler';
import * as openlayers from './modules/openlayers';

export default {
  data: () => ({
    figureTools: [
      {
        title: 'Rectangle',
        icon: 'fal fa-square',
        type: 'rectangle',
        supportedOptions: [
          'zoom',
          'rename',
          'duplicate',
          'toggle_legend',
          'delete',
        ],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Circle',
        icon: 'fal fa-circle',
        type: 'circle',
        supportedOptions: [
          'zoom',
          'rename',
          'duplicate',
          'toggle_legend',
          'delete',
        ],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Polygon',
        icon: 'fal fa-hexagon',
        type: 'polygon',
        supportedOptions: [
          'zoom',
          'rename',
          'duplicate',
          'toggle_legend',
          'delete',
        ],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Sample',
        icon: 'fal fa-map-marker-question',
        type: 'marker',
        supportedOptions: ['zoom', 'rename', 'toggle_legend', 'delete'],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Line',
        icon: 'fal fa-horizontal-rule',
        type: 'polyline',
        supportedOptions: [
          'zoom',
          'rename',
          'duplicate',
          'toggle_legend',
          'delete',
        ],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Arrow',
        icon: 'fal fa-arrow-right',
        type: 'arrow',
        supportedOptions: [
          'zoom',
          'rename',
          'duplicate',
          'toggle_legend',
          'delete',
        ],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Call-out',
        icon: 'far fa-comment-alt',
        type: 'call-out',
        supportedOptions: ['zoom', 'duplicate', 'delete'],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Text',
        icon: 'fal fa-text',
        type: 'textarea',
        supportedOptions: ['rename', 'duplicate', 'toggle_legend', 'delete'],
        addMultipleFeaturesEnabled: true,
      },
      {
        title: 'Boundary',
        icon: 'far fa-square',
        type: 'siteboundary',
        supportedOptions: [
          'zoom',
          'rename',
          'toggle_legend',
          'delete',
          'duplicate',
        ],
        addMultipleFeaturesEnabled: true,
      },
    ],
    figureImports: [
      {
        title: 'Import',
        icon: 'fal fa-file-alt',
        method: 'import',
      },
      {
        title: 'Import Boundary',
        icon: 'fal fa-draw-polygon',
        method: 'site-boundary',
      },
      {
        title: 'Request Service Layer',
        icon: 'fal fa-map-marked',
        method: 'service-layer',
      },
      {
        title: 'Write Legend Note',
        icon: 'fal fa-sticky-note',
        method: 'legend-note',
      },
    ],
    additionalLayers: [
      {
        icon: 'fal fa-map',
        type: 'service_layer',
        title: 'Service Layer',
        supportedOptions: ['zoom', 'rename', 'delete'],
      },
      {
        icon: 'fal fa-folder',
        title: 'Folder',
        type: 'folder',
        supportedOptions: ['rename', 'toggle_legend', 'delete'],
        contextMenuKey: 'Folder',
      },
      {
        icon: 'fal fa-vials',
        title: 'Sample Group',
        type: 'sample_group',
        supportedOptions: ['zoom', 'rename', 'toggle_legend', 'delete'],
        contextMenuKey: 'Group',
      },
      {
        icon: 'fal fa-image',
        title: 'Image',
        type: 'image',
        contextMenuKey: 'Image',
        supportedOptions: ['zoom', 'rename', 'toggle_legend', 'delete'],
      },
      {
        title: 'Legend Note',
        icon: null,
        type: 'legend_note',
        supportedOptions: ['rename', 'delete'],
      },
      {
        title: 'Feature Collection',
        icon: 'fal fa-shapes',
        type: 'feature_collection',
        supportedOptions: ['zoom', 'rename', 'toggle_legend', 'delete'],
      },
    ],

    //styling properties
    fillStyleOptions: [
      { title: 'Overlay', identifier: 0, class: 'overlay' },
      { title: 'No Overlay', identifier: 1, class: 'noverlay' },
      { title: 'Horizontal Stripe', identifier: 2, class: 'hstripe' },
      { title: 'Vertical Stripe', identifier: 3, class: 'vstripe' },
      { title: 'Dots', identifier: 4, class: 'dots' },
      { tilte: 'Circle', identifier: 5, class: 'circle' },
      { title: '45° Cross', identifier: 6, class: '45degcross' },
      { title: '45° Stripe', identifier: 7, class: '45degstripe' },
    ],
    outlineStyleOptions: [
      { title: 'Solid', identifier: 0, class: 'solid' },
      { title: 'Dashed', identifier: 1, class: 'dashed' },
      { title: 'Dotted', identifier: 2, class: 'dotted' },
    ],
    figures: [
      { title: 'Site Location Plan', identifier: 0 },
      { title: 'Sample Location Plan', identifier: 1 },
      { title: 'Sample Exceedance Plan', identifier: 2 },
      { title: 'Specific Chemical Plan', identifier: 3 },
    ],
    noteIcons: [{ identifier: 0 }, { identifier: 1, class: 'fas fa-star' }],
  }),
  methods: {
    getParentLayerIcon(layer, scale = 1) {
      if (layer.data.properties.type === 'sub_folder') {
        layer = layer.parent;
      }

      if (!layer.children?.length || layer.data.marker_identifier) {
        return this.getLayerIcon(layer, scale);
      }

      if (this.hasMismatchingChildren(layer)) {
        return this.getLayerIcon(layer, scale);
      }

      return this.getLayerIcon(layer.children[0], scale);
    },
    getLayerIcon(layer, scale = 1) {
      let { properties } = layer.data;
      const { type: layerType, color: layerColor, usage } = properties;

      if (layerType == 'sample') {
        return {
          icon: null,
        };
      }

      if (layerType == 'sample_group') {
        const { icon, color } = MarkerHandler.getMarkerPropertiesByIdentifier(
          layer.data
        );

        return {
          icon: MarkerHandler.getMarkerImageIcon(icon, color, '1em'),
        };
      }

      if (layerType == 'service_layer') {
        let styling = this.getServiceLayerStyling_(layer.data.renderer, scale);

        if (styling) {
          return styling;
        }
      }

      if (layerType == 'legend_note' && properties.noteIcon) {
        let icon = this.noteIcons.find(
          (i) => i.identifier == properties.noteIcon
        );

        if (icon) {
          return {
            icon: `<i class="${icon.class}" style="color: ${properties.noteIconColor};"></i>`,
          };
        }
      }

      if (layerType == 'legend_note') {
        return {
          icon: null,
        };
      }

      if (layerType === 'image' && usage === bl.layer.layer_usages.HEATMAP) {
        return {
          icon: `<div class="layer-image">
                   <img src="/styled-map-icon/tool-icons/heatmap"/>
                </div>`,
        };
      }

      if (
        layerType === bl.layer.layer_types.FEATURE_COLLECTION &&
        [
          bl.layer.layer_usages.CONTOURS,
          bl.layer.layer_usages.BASEMAP_POLYLINES,
        ].includes(usage)
      ) {
        return {
          icon: ShapeHandler.getShapeImageIcon({
            type: 'polyline',
            color: layerColor,
          }),
        };
      }

      if (
        layerType === bl.layer.layer_types.FEATURE_COLLECTION &&
        [bl.layer.layer_usages.BASEMAP_POLYGONS].includes(usage)
      ) {
        return {
          icon: ShapeHandler.getShapeImageIcon({
            type: 'polygon',
            color: layerColor,
          }),
        };
      }

      if (this.getViewer) {
        const viewer = this.getViewer();
        const { map } = viewer;
        const layerOptions = openlayers.layers.getDrawingFeatureOptions(
          map,
          layer
        );
        const propertiesUpdate = viewer.getScaledLayerProperties(
          layerOptions,
          true
        );
        properties = {
          ...properties,
          ...propertiesUpdate,
        };
      }

      let shapeIcon = ShapeHandler.getShapeImageIcon(properties);

      if (shapeIcon) {
        return {
          icon: shapeIcon,
        };
      }

      return {
        icon: `<i
          class="${this.getLayerIconByType(layerType)} fa-fw"
          style="color: ${layerColor};"
        ></i>`,
      };
    },
    getLayerIconByType(type) {
      if (!type) {
        return 'fas fa-layer-group';
      }

      if (type === bl.layer.layer_types.CHAINAGE) {
        return 'fal fa-ruler';
      }

      let allLayerTypes = [...this.figureTools, ...this.additionalLayers];

      let shapeType = allLayerTypes.find((lt) => lt.type == type);

      if (!shapeType) {
        return 'fas fa-layer-group';
      }

      return shapeType['icon'];
    },
    hasMismatchingChildren(layer) {
      const { children } = layer;

      if (!children || !children.length) {
        return false;
      }

      const baseChildGroup = children[0].data.properties;

      if (!baseChildGroup) {
        return true;
      }

      const baseChildType = baseChildGroup.type;
      const baseChildUsage = baseChildGroup.usage;
      const baseChildColor = baseChildGroup.color;
      const baseChildOutlineStyle = baseChildGroup.outlineStyle;
      const baseChildFillStyle = baseChildGroup.fillStyle;

      if (
        ['service_layer', 'sample_group', 'legend_note'].includes(baseChildType)
      ) {
        return true;
      }

      if (
        baseChildType === bl.layer.layer_types.IMAGE &&
        baseChildUsage === bl.layer.layer_usages.HEATMAP
      ) {
        return true;
      }

      const matchingChildren = children.filter((l) => {
        let properties = l.data.properties;

        return (
          (properties.type
            ? properties.type.toLowerCase() == baseChildType.toLowerCase()
            : true) &&
          (properties.color
            ? normalizeHex(properties.color).toLowerCase() ==
              normalizeHex(baseChildColor).toLowerCase()
            : true) &&
          properties.outlineStyle == baseChildOutlineStyle &&
          properties.fillStyle == baseChildFillStyle
        );
      });

      return children.length != matchingChildren.length;
    },
    getShortenedLayerTitle(layer) {
      let title = bl.layer.getLayerTitle(layer);

      if (!title) {
        return;
      }

      const length = 140;

      title =
        title.length > length ? title.substr(0, length - 1) + '...' : title;

      return title;
    },
    getServiceLayerStyling_(renderer, scale) {
      if (!renderer) {
        return {
          icon: null,
        };
      }

      if (renderer.type === 'simple') {
        return {
          icon: this._getEsriStylingFromObject(
            {
              symbol: renderer.symbol,
              label: renderer.label,
              description: renderer.description,
              value: renderer.value,
            },
            scale
          ),
        };
      }

      if (renderer.type === 'uniqueValue') {
        const result = {
          icon: null,
          subLayers: [],
        };

        if (renderer.uniqueValueInfos.length == 1) {
          const data = renderer.uniqueValueInfos[0];
          return {
            icon: this._getEsriStylingFromObject(data, scale),
          };
        }

        renderer.uniqueValueInfos.forEach((data, index) => {
          result.subLayers.push({
            icon: this._getEsriStylingFromObject(data, scale),
            data,
            index,
          });
        });

        if (renderer.defaultSymbol) {
          const data = {
            symbol: renderer.defaultSymbol,
            label: renderer.defaultLabel,
            description: '',
            value: null,
          };
          result.subLayers.push({
            icon: this._getEsriStylingFromObject(data, scale),
            data,
            index: null,
          });
        }

        return result;
      }

      if (renderer.type === 'ogc_opengis_wfs') {
        const { type } = renderer.properties;
        if (type !== 'point') {
          return {
            icon: ShapeHandler.getShapeImageIcon(renderer.properties),
          };
        }

        const { icon, color } = renderer.properties;
        return {
          icon: MarkerHandler.getMarkerImageIcon(
            `/markers/${icon + 1}`,
            color,
            '1em'
          ),
        };
      }

      return {
        icon: null,
      };
    },
    _getEsriStylingFromObject(stylingInfo, scale) {
      if (!stylingInfo) {
        return;
      }

      if (stylingInfo.symbol) {
        stylingInfo = {
          ...stylingInfo,
          ...stylingInfo.symbol,
        };
      }

      if (stylingInfo.imageData) {
        return (
          '<img src="data:' +
          stylingInfo.contentType +
          ';base64,' +
          stylingInfo.imageData +
          '" width="' +
          stylingInfo.width * scale +
          '" height="' +
          stylingInfo.height * scale +
          '" class="esri-styling-icon"/>'
        );
      }

      let outline = '';

      if (stylingInfo.outline) {
        outline = `border: 1px solid ${this._getRgbaFromColor(
          stylingInfo.outline.color
        )};`;
      }

      const color = stylingInfo.color;
      const size = (stylingInfo.size || 6) * scale;
      if (color) {
        return `<div style="background: ${this._getRgbaFromColor(
          color
        )}; width: ${size}px; height: ${size}px; ${outline}" class="esri-styling-icon"></div>`;
      }
    },
    _getRgbaFromColor(color) {
      let r = color[0],
        g = color[1],
        b = color[2],
        a = color[3];

      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    getSubLayers(layer, scale) {
      return this.getLayerIcon(layer, scale).subLayers ?? [];
    },
    convertFigureTypeToText(identifier) {
      return this.figures.find((f) => f.identifier == identifier)['title'];
    },
    getTypeFromFile(file) {
      if (!file) {
        return;
      }

      let type = null;

      let types = file.name.split('.');

      if (types.length > 1) {
        type = types[types.length - 1].toLowerCase();
      }

      if (!type) {
        return;
      }

      if (new RegExp(['png', 'jpg', 'jpeg', 'bmp'].join('|')).test(type)) {
        return 'image';
      }

      if (new RegExp(['json', 'geojson'].join('|')).test(type)) {
        return 'geojson';
      }

      if (new RegExp(['zip'].join('|')).test(type)) {
        return 'zip';
      }

      if (new RegExp(['kml'].join('|')).test(type)) {
        return 'kml';
      }

      if (new RegExp(['svg'].join('|')).test(type)) {
        return 'svg';
      }

      return;
    },
  },
};
