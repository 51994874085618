export const AXIS_ORIENTATION_ENU = {
  value: 'enu',
  displayValue: 'Easting, Northing([x,y])',
};
export const AXIS_ORIENTATION_NEU = {
  value: 'neu',
  displayValue: 'Northing, Easting([y,x])',
};

export function getAxisOrientations() {
  return [AXIS_ORIENTATION_ENU, AXIS_ORIENTATION_NEU];
}
