import { getMeasurementShortTitle } from './measurement.js';

export const formatSampleTitle = ({ custom_title, lab_title }) => {
  return custom_title || lab_title;
};

export const formatSampleDepth = (
  { start_depth, end_depth },
  measurementType,
  showMeasurementType = true
) => {
  measurementType = getMeasurementShortTitle(measurementType);

  // cant use ! as some depths may be 0
  if (start_depth == null || start_depth === '') {
    return null;
  }

  return (
    start_depth +
    (showMeasurementType ? measurementType : '') +
    (end_depth != null && end_depth != 0
      ? ' - ' + end_depth + (showMeasurementType ? measurementType : '')
      : '')
  );
};

export const getSampleTooltip = ({ custom_title, lab_title }) => {
  return custom_title && lab_title ? `Lab title <b>${lab_title}</b>` : '';
};

export const formatSampleTitleWithDepth = (sample, measurementType) => {
  let title = formatSampleTitle(sample);

  if (sample.start_depth !== null) {
    title += ' @ ' + formatSampleDepth(sample, measurementType);
  }

  return title;
};

export const formatElevation = (startElevation, endElevation) => {
  if (!startElevation) {
    return null;
  }

  return startElevation + (endElevation !== null ? ' - ' + endElevation : '');
};

export const MAPPED_SAMPLE_TYPES = {
  normal: 'Normal',
  lab_d: 'Lab_D',
  field_d: 'Field_D',
  interlab_d: 'Interlab_D',
  field_b: 'Field_B',
  rinsate: 'Rinsate',
  trip_b: 'Trip_B',
  lcs_d: 'LCS_D',
  trg: 'TRG',
  lcs: 'LCS',
  mb: 'MB',
  ms: 'MS',
  ncp: 'NCP',
};

export const formatSampleType = (sampleTypeKey) => {
  return MAPPED_SAMPLE_TYPES[sampleTypeKey] || sampleTypeKey;
};
