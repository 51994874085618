export const EDIT_METHOD_CODES = {
  MOVE_RESIZE_ROTATE: 1,
  GEOREFERENCE: 2,
};
export const EDIT_METHODS = [
  {
    code: EDIT_METHOD_CODES.MOVE_RESIZE_ROTATE,
    label: 'Move, Resize and Rotate',
  },
  {
    code: EDIT_METHOD_CODES.GEOREFERENCE,
    label: 'Georeference via Control Points',
  },
];
