export default {
  data: () => ({
    media_sizes: {
      small: 0,
      medium: 1,
      large: 2,
    },
    floats: {
      none: 0,
      left: 1,
      right: 2,
    },
    TABLE_TYPES: {
      GATHER_TABLE: 1,
      CUSTOM_TABLE: 2,
      ENVIRO_TABLE: 3,
    },
  }),
};
