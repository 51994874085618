
/**
 * dashboards
 */
export type DataInsightDashboard = {
    id: number;
    title: string;
    description: string | null;
    config: DataInsightDashboardConfig;
    auto_refresh_config: DataInsightDashboardAutoRefreshConfig | null;
    project_id: number;
    items?: DataInsightGraph[];
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
};

export type DataInsightDashboardConfig = {
    colNum: number,
    rowNum: number,
    colWidth: number,
    rowHeight: number,
    isDraggable?: boolean,
    isResizable?: boolean,
    isMirrored: boolean,
    isVerticalCompact: boolean,
    margin: number,
    isCssTransformsUsed: boolean,
}

export const DATA_INSIGHTS = 'Data Insights';
export const HUB_DATA = 'Hub Data';
export const VISUAL_REPORT = 'Visual Report';
export const DASHBOARD_CONTEXTS = { DATA_INSIGHTS, HUB_DATA, VISUAL_REPORT } as const;

export type DataInsightDashboardAutoRefreshConfig = {
    /**
     * Title of module
     * @warning These should be 
     */
    context: typeof DATA_INSIGHTS | typeof HUB_DATA | typeof VISUAL_REPORT;

    enabled: boolean;

    /**
     * Interval in seconds
     */
    interval: number;
}[];

/**
 * dashboard_items
 */
export type DataInsightGraph = {
    id: number;
    tool_type: 'word-cloud' | 'bar-chart' | 'line-chart' | 'scatter-chart' | 'pie-chart' | 'percentage';
    layout: {
        x: number;
        y: number;
        w: number;
        h: number;
        /**
         * @deprecated Every record in db has false, 2023-02
         */
        moved: false;
    };
    options: {
        info: {
            templateId: null,
            viewModeId: number,
            numberOfPlannedSamples: number,
            title: null | string,
            statisticTypeId?: number,
            includeSampleIds?: number[],
            excludedSampleIds?: number[],
            sectionId?: number,
            dimensionFieldId?: number | null,
            dimensionFieldLabel?: string | null,
            measureFieldId?: number | null,
            measureFieldLabel?: string | null,
            aggregateFunctionId?: number | null,
            showStatisticOfNoData?: boolean,
            showStatisticEqualToZero?: boolean,
            eventSourceIds?: unknown[],
        },
        style?: {
            canvas?: {
                backgroundColor?: string,
                borderColor?: string,
                titleColor?: string;
                /**
                 * pt?
                 */
                titleSize?: number,
                percentageColor?: string,
                percentageSize?: number;
            },
            /**
             * @deprecated Why is this an array?
             */
            graph?: {
                /**
                 * @deprecated Why is this an array?
                 */
                backgroundColor?: string[],
            }[]
        }
    },
    data: DataInsightGraphData | null,
    dashboard_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export type DataInsightGraphData = any | { groups: any[] };
