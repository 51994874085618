<template>
  <div>
    <div
      class="d-flex align-items-center justify-content-center flex-column"
      v-if="block.attachments.length == 0"
    >
      <h6>No attachments exist yet.</h6>
      <p class="text-muted mb-0">
        It's really easy to get add these, hover and click the upload button.
      </p>
    </div>
    <template v-else>
      <div
        class="attachments"
        :class="{
          'attachments--small': block.slide_id,
        }"
      >
        <div
          class="attachment"
          v-for="attachment in block.attachments"
          :key="'attachment-' + attachment.id"
          @click="download(attachment)"
        >
          <div class="attachment-icon text-muted">
            <i class="fad" :class="getUploadIconFromType(attachment.type)"></i>
          </div>

          <div class="attachment-blanket">
            <i class="fal fa-download"></i>
          </div>

          <div class="content p-1">
            <b>{{ attachment.display_name }}.{{ attachment.type }}</b>
            <small class="d-block mt-1">{{
              convertDate(attachment.created_at, true)
            }}</small>
          </div>
        </div>
      </div>

      <div class="caption mt-3">
        <caption-text
          :block="block"
          placeholder="Add a caption for these attachments (optional)"
          @update="(data) => $emit('update', data)"
        />
      </div>
    </template>
  </div>
</template>
<script>
import api from '../../api';
import CaptionText from './Text.vue';
import downloadByHref from '@component-library/utils/download';
import { getUploadIconFromType } from '@component-library/store/files';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },
  components: { CaptionText },
  methods: {
    download(attachment) {
      api.downloadAttachment(attachment.id).then(({ data }) => {
        downloadByHref(
          data.url,
          `${attachment.display_name}.${attachment.type}`
        );
      });
    },
    getUploadIconFromType(type) {
      return getUploadIconFromType(type);
    },
  },
};
</script>
<style lang="scss" scoped>
.attachments {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 125px;
  grid-gap: 1em;
}

.attachments--small {
  grid-template-columns: repeat(2, 1fr);
}

.block__layout--float-left .attachments {
  grid-template-columns: 1fr;
}

.attachment {
  position: relative;
  max-height: 100%;
  background-color: rgb(244, 245, 247);
  cursor: pointer;
  box-shadow: rgba(44, 50, 62, 0.31) 0px 1px 1px,
    rgba(44, 50, 62, 0.31) 0px 0px 1px 0px;
  font-size: 11px;
  line-height: 14px;
  transition: all 0.3s ease 0s;
  border-radius: 3px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.attachment-icon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4em;
}

.attachment-blanket {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 1.5em;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  left: 0px;
  top: 0px;
  border-radius: 3px;
  transition: 0.3s ease-in-out all;
  opacity: 0;
  background-color: rgba(44, 50, 62, 0.48);
}

.attachment:hover > .attachment-blanket {
  opacity: 1;
}

.attachment img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.attachment .content {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  color: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  word-break: break-all;
}
</style>
