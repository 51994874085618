import * as bl from '../business-logic';
import helpers from '../helpers';
import * as openlayers from './openlayers';

class ShapeHandler {
  static getShapeImageIcon({
    type,
    color = bl.color.BLACK,
    fillStyle = 0,
    outlineStyle = 0,
    multiDirectional = false,
  }) {
    const supportedTypes = [
      openlayers.layers.LAYER_TYPES.RECTANGLE,
      openlayers.layers.LAYER_TYPES.CIRCLE,
      openlayers.layers.LAYER_TYPES.POLYGON,
      openlayers.layers.LAYER_TYPES.POLYLINE,
      openlayers.layers.LAYER_TYPES.ARROW,
      openlayers.layers.LAYER_TYPES.SITE_BOUNDARY,
      openlayers.layers.LAYER_TYPES.HEDGE,
    ];

    if (type === openlayers.layers.LAYER_TYPES.BUFFER) {
      type = openlayers.layers.LAYER_TYPES.POLYGON;
    }

    if (!supportedTypes.includes(type)) {
      return;
    }

    const subType = type === 'arrow' && multiDirectional ? 'double' : '';
    const icon =
      '/images/map_icons/shapes/' +
      ShapeHandler._getShapeImageSVG(type, fillStyle, outlineStyle, subType);

    return `<div class="layer-image">
      <img src="${openlayers.utils.getStyledMapIconUrl(icon, color)}"/>
    </div>`;
  }

  static _getShapeImageSVG(type, fillStyle, outlineStyle, subType = '') {
    let fillStyleClass =
      helpers.data().fillStyleOptions.find((o) => o.identifier == fillStyle)
        ?.class || 'overlay';

    let outlineStyleClass =
      helpers
        .data()
        .outlineStyleOptions.find((o) => o.identifier == outlineStyle)?.class ||
      'solid';

    return (
      type +
      (subType ? '_' + subType : '') +
      '_' +
      ([
        openlayers.layers.LAYER_TYPES.POLYLINE,
        openlayers.layers.LAYER_TYPES.ARROW,
      ].includes(type)
        ? 'overlay'
        : fillStyleClass) +
      '_' +
      outlineStyleClass +
      '.svg'
    );
  }
}

export default ShapeHandler;
