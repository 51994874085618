import blockModule from '../components/blocks';

export function createBlock(
  type,
  group_block_id = null,
  slideshow_block_id = null,
  slide_id = null
) {
  const newBlock = __createChildBlockObject(type, group_block_id, slideshow_block_id, slide_id);
  const fieldsToAdd = _createBlockFieldsObjectByType(type);

  return {
    ...newBlock,
    ...fieldsToAdd,
  };
}

const __createChildBlockObject = (type, group_block_id, slideshow_block_id, slide_id) => {
  const groupBlockId = blockModule.typeIsSupportedAsChildBlock(type)
    ? group_block_id
    : null;

  return {
    type,
    group_block_id: groupBlockId,
    slideshow_block_id,
    slide_id,
  };
}

const _createBlockFieldsObjectByType = (type)  => {
  switch (type) {
    case 'slideshow':
      return {
        size: 2,
        sidebar_width: 30,
      };
    case 'image':
      return {
        settings: {
          cropping: 'dont-crop',
        },
      };
    case 'video':
      return {
        settings: {
          playblack: 'click-to-play',
        },
      };
    case 'map':
      return {
        size: 2,
        settings: {
          allow_map_navigation: true,
          show_map_legend: true,
        },
      };
    case 'table':
    case 'insight':
      return {
        size: 2,
        settings: {},
      };
  }
}

export function getNewBlockOrder(blocks, id, position) {
  if (!id) {
    return 0;
  }

  const draggingOverBlockIndex = blocks.find((b) => b.id == id).order;

  return Math.max(
    0,
    position == 'bottom' ? draggingOverBlockIndex + 1 : draggingOverBlockIndex
  );
}

export function getNewBlockIndex(blocks, id, position) {
  if (!id) {
    return 0;
  }

  const draggingOverBlockIndex = blocks.findIndex((b) => b.id == id);

  return Math.max(
    0,
    position == 'bottom' ? draggingOverBlockIndex + 1 : draggingOverBlockIndex
  );
}

export function resetChildGroupBlocks(blocks, block, dispatch) {
  return Promise.all(
    blocks
      .filter((b) => b.group_block_id == block.id)
      .map((childBlock) => {
        return dispatch('updateBlock', {
          id: childBlock.id,
          updated_fields: {
            group_block_id: null,
          },
        });
      })
  );
}
