import { always } from 'ol/events/condition';
import Transform from 'ol-ext/interaction/Transform';
import { changeTransformStyle } from '../styles';

export default function createBufferEdit(map) {
  const edit = [];

  // The transform interaction is from ol-ext. It is used to do square resizing, rotation and moving.
  // For usage of transform, see https://viglino.github.io/ol-ext/examples/interaction/map.interaction.transform.html
  const transform = new Transform({
    hitTolerance: 2,
    translateFeature: false,
    scale: false,
    rotate: false,
    keepAspectRatio: always,
    translate: false,
    stretch: false,
    translateBBox: false,
    enableRotatedTransform: true,
    // Don't use the Select interaction so that the shape can keep being selected
    selection: false,
  });
  edit.push(transform);

  edit.selectFeature = function (feature) {
    transform.select(feature, true);
  };

  edit.getFeature = function () {
    return transform.getFeatures().item(0);
  };

  edit.activate = function (map) {
    this.forEach((interaction) => {
      map.addInteraction(interaction);
    });
    changeTransformStyle(transform);
  };

  edit.destroy = function (map) {
    this.forEach((interaction) => {
      map.removeInteraction(interaction);
    });
  };

  edit.setActive = function (active) {
    this[0].setActive(active);
  };

  return edit;
}
