const HEADING_PATTERN = /<(h2|h3)(.|\s)+?<\/\1>/gi;

class Node {
  constructor() {
    this.children = [];
    this.parent = null;
  }
  addChild(child) {
    this.children = [...this.children, child];
    child.parent = this;
  }
}

class HeadingNode extends Node {
  constructor(heading, blockId) {
    super();

    this.heading = heading;
    this.blockId = blockId;
  }
  isTitle() {
    return /^<h2(.|\s)+?<\/h2>$/i.test(this.heading);
  }
  isSubheading() {
    return /^<h3(.|\s)+?<\/h3>$/i.test(this.heading);
  }
  getIndentCount() {
    let result = 0;

    const matchResult = this.heading.match(
      /^<h(2|3)(\sclass="ql-indent-(\d+)")?/i
    );
    if (!!matchResult && !!matchResult[3]) {
      result = parseInt(matchResult[3], 10);
    }

    return result;
  }
  getNumber() {
    let numbers = [];
    let node = this;
    while (node.parent != null) {
      const number = node.parent.children.indexOf(node) + 1;
      numbers = [number, ...numbers];
      node = node.parent;
    }
    return numbers.join('.');
  }
  toString() {
    // Group 1: The start tag
    // Group 2: The tag name
    // Group 3: The indent class
    // Group 4: The content
    // Group 5: The last character of content
    // Group 6: The end tag
    const pattern = /(<(h2|h3)(\sclass="ql-indent-\d+")?>)((.|\s)+?)(<\/\2>)/i;
    const [
      all,
      startTag,
      tagName,
      indentClass,
      content,
      lastCharOfContent,
      endTag,
    ] = this.heading.match(pattern);
    const empty = content.replaceAll('<br>', '').trim() === '';

    if (empty) {
      return this.heading;
    }

    let number = `${this.getNumber()}`;
    // Add the ending full stop to the number of titles.
    number += number.indexOf('.') === -1 ? '. ' : ' ';

    return `${startTag}${number}${content}${endTag}`;
  }
}

class RootNode extends Node {
  constructor() {
    super();
  }
}

class HeadingTree {
  constructor() {
    this.root = new RootNode();
    this.current = this.root;
  }
  addHeading(heading, blockId = null) {
    const headingNode = new HeadingNode(heading, blockId);

    let parent = null;
    if (this.current === this.root || headingNode.isTitle()) {
      parent = this.root;
    } else if (headingNode.isSubheading()) {
      parent = this.current;
      while (
        parent !== this.root &&
        !parent.isTitle() &&
        parent.isSubheading() &&
        parent.getIndentCount() >= headingNode.getIndentCount()
      ) {
        parent = parent.parent;
      }
    }

    parent.addChild(headingNode);
    this.current = headingNode;
  }
  iterate_(startNode, blockId) {
    let result = startNode.blockId === blockId ? [startNode] : [];
    for (const child of startNode.children) {
      const nodes = this.iterate_(child, blockId);
      result = [...result, ...nodes];
    }
    return result;
  }
  iterate(blockId = null) {
    let result = [];
    for (const child of this.root.children) {
      const nodes = this.iterate_(child, blockId);
      result = [...result, ...nodes];
    }
    return result;
  }
}

export function createHeadingTree(feed) {
  const headingTree = new HeadingTree();
  for (const { blockId, textContent } of feed) {
    if (textContent) {
      const headings = textContent.match(HEADING_PATTERN) || [];
      for (const heading of headings) {
        headingTree.addHeading(heading, blockId);
      }
    }
  }
  return headingTree;
}

export function goNumbering2(headingTree, textContent, blockId) {
  if (!textContent) {
    return textContent;
  }

  const headingNodes = headingTree.iterate(blockId);

  let matchIndex = 0;
  return textContent.replaceAll(HEADING_PATTERN, function (match) {
    return headingNodes[matchIndex++]?.toString() ?? match;
  });
}

/**
 * Deprecated.
 * @param {String} textContent
 * @returns
 */
export default function goNumbering(textContent) {
  const headingTree = createHeadingTree([{ blockId: null, textContent }]);
  return goNumbering2(headingTree, textContent, null);
}
