import { Color } from '../../style/color';
import type { SampleStyle } from './types';

export const ICON_SIZE_ADJUST_FACTOR = 18 / 22;
export const LABEL_SIZE_ADJUST_FACTOR = 12 / 16;

export default function getDefaultSampleStyle(
  override: object = {}
): SampleStyle {
  return {
    icon: 0,
    color: Color.Black,
    iconSize: 22,
    iconRotation: 0,
    iconOpacity: 1,
    isIconVisible: true,
    labelSize: 16,
    isLabelHidden: false,
    isDuplicateLabelHidden: false,
    labelColor: Color.Black,
    labelShadowColor: Color.White,
    isLabelUnderlined: false,
    isLabelAsteriskAppended: false,
    ...override,
  };
}
