import store from '@/js/store';
import { NAMESPACE } from '../store';
import { getMeasurementShortTitle } from '@/js/helpers/measurement.js';
import { getResultValue } from '@/js/helpers/result.js';
import { formatSampleDepth as helperFormatSampleDepth } from '@/js/helpers/sample.js';
import { getStylingPropertiesByMethodAsString } from '@/js/helpers/scenario.js';
import { BLACK } from '../business-logic/color';

/**
 * The Sample Chemicals Call-out Handler.
 */
class PopupHandler {
  constructor(project, selectedFigure, sample) {
    this.project = project;
    this.selectedFigure = selectedFigure;
    this.sample = sample;
    this.resultExceedances = NAMESPACE.getGetter(
      store,
      'getResultExceedanceBySample'
    )(sample, 'result');
    this.scenarioSet = NAMESPACE.getState(store, 'scenarioSet');
  }

  getHiddenFormattedDepths() {
    return (
      this.selectedFigure.enviro_callout_filter?.hiddenFormattedDepths ?? []
    );
  }

  getPopupTable(
    style = { textColor: BLACK, textShadow: 'none', fontWeight: 'normal' },
    extraInfo = { scenarioSet: null, isScientificFormat: false }
  ) {
    const { textColor, textShadow, fontWeight } = style;
    const styleString = `color: ${textColor}; text-shadow: ${textShadow}; font-weight: ${fontWeight}`;
    const bodyContent = this._getBodyContent(extraInfo);

    return bodyContent !== ''
      ? `<table class="table table-bordered builtin-callout-table" style="${styleString}">
          <thead>
              ${this._getHeadContent()}
          </thead>
          <tbody>
              ${bodyContent}
          </tbody>
          </table>`
      : undefined;
  }

  _getHeadContent() {
    let sampleTitle = this.sample.custom_title || this.sample.lab_title;
    let sampleDepths = this._getSampleDepthColumns();

    return `<tr>
      <th>${sampleTitle}</th>
      <th>Units</th>
      ${sampleDepths}
      </tr>`;
  }

  _getSampleDepthColumns() {
    const {
      enviro_callout_filter: { isDupVisible },
    } = this.selectedFigure;
    const depthColumns = [];
    const hiddenFormattedDepths = this.getHiddenFormattedDepths();
    const formattedDepth = this.formatSampleDepth(this.sample);

    if (
      this.checkHasResults(this.sample) &&
      !hiddenFormattedDepths.includes(formattedDepth)
    ) {
      depthColumns.push({
        startDepth: this.sample.start_depth ?? 0,
        formattedDepth,
        class: '',
      });
    }

    if (
      this.sample.duplicate &&
      this.checkHasResults(this.sample.duplicate) &&
      !hiddenFormattedDepths.includes(
        this.formatSampleDepth(this.sample.duplicate)
      ) &&
      isDupVisible
    ) {
      depthColumns.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        formattedDepth: 'Dup',
        class: 'dupe',
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cSample) => {
      const formattedDepth = this.formatSampleDepth(cSample);

      if (
        this.checkHasResults(cSample) &&
        !hiddenFormattedDepths.includes(formattedDepth)
      ) {
        depthColumns.push({
          startDepth: cSample.start_depth ?? 0,
          formattedDepth,
          class: '',
        });
      }

      if (
        cSample.duplicate &&
        this.checkHasResults(cSample.duplicate) &&
        !hiddenFormattedDepths.includes(
          this.formatSampleDepth(cSample.duplicate)
        ) &&
        isDupVisible
      ) {
        depthColumns.push({
          startDepth: cSample.duplicate.start_depth ?? 0,
          formattedDepth: 'Dup',
          class: 'dupe',
        });
      }
    });

    return depthColumns
      .sort((a, b) => a.startDepth - b.startDepth)
      .map((item) => `<th class="${item.class}">${item.formattedDepth}</th>`)
      .join('');
  }

  _getBodyContent(extraInfo) {
    let bodyRows = '';

    for (const cr of this.selectedFigure.results) {
      const resultColumns = this._getResultColumns(cr, extraInfo);

      if (resultColumns !== '') {
        bodyRows += `<tr>
                <td>${cr.display_title}</td>
                <td>${cr.units}</td>
                ${resultColumns}
            </tr>`;
      }
    }

    return bodyRows;
  }

  _getResultColumns(chemicalResult, extraInfo) {
    const {
      enviro_callout_filter: { visibleUnits, isDupVisible },
      hide_chemicals_without_exceedances: hideChemicalsWithoutExceedances,
      hide_non_detect_chemicals: hideNonDetectChemicals,
    } = this.selectedFigure;
    const { scenarioSet, isScientificFormat } = extraInfo;
    const { units } = chemicalResult;
    const resultColumns = [];

    const results = chemicalResult.sample_items;
    const hiddenFormattedDepths = this.getHiddenFormattedDepths();

    const formattedDepth = this.formatSampleDepth(this.sample);
    if (
      this.checkHasResults(this.sample) &&
      !hiddenFormattedDepths.includes(formattedDepth) &&
      (!visibleUnits || visibleUnits.includes(units))
    ) {
      const parentResult = results.find((r) => r.sample_id == this.sample.id);
      resultColumns.push({
        startDepth: this.sample.start_depth ?? 0,
        style: this._getResultExceedanceStyling(parentResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(parentResult, scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!parentResult?.prefix,
      });
    }

    if (
      this.sample.duplicate &&
      this.checkHasResults(this.sample.duplicate) &&
      !hiddenFormattedDepths.includes(
        this.formatSampleDepth(this.sample.duplicate)
      ) &&
      (!visibleUnits || visibleUnits.includes(units)) &&
      isDupVisible
    ) {
      const duplicateResult = results.find(
        (r) => r.sample_id == this.sample.duplicate.id
      );
      resultColumns.push({
        startDepth: this.sample.duplicate.start_depth ?? 0,
        style: this._getResultExceedanceStyling(duplicateResult),
        class: 'text-center',
        displayResult: this.getDisplayResult(
          units,
          getResultValue(duplicateResult, scenarioSet, isScientificFormat)
        ),
        isNonDetect: !!duplicateResult?.prefix,
      });
    }

    // The child_samples is null when the sample is a brand new one(created by the tool).
    this.sample.child_samples?.forEach((cSample) => {
      const formattedDepth = this.formatSampleDepth(cSample);
      if (
        this.checkHasResults(cSample) &&
        !hiddenFormattedDepths.includes(formattedDepth) &&
        (!visibleUnits || visibleUnits.includes(units))
      ) {
        const childResult = results.find((r) => r.sample_id == cSample.id);
        resultColumns.push({
          startDepth: cSample.start_depth ?? 0,
          style: this._getResultExceedanceStyling(childResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(childResult, scenarioSet, isScientificFormat)
          ),
          isNonDetect: !!childResult?.prefix,
        });
      }

      if (
        cSample.duplicate &&
        this.checkHasResults(cSample.duplicate) &&
        !hiddenFormattedDepths.includes(
          this.formatSampleDepth(cSample.duplicate)
        ) &&
        (!visibleUnits || visibleUnits.includes(units)) &&
        isDupVisible
      ) {
        const duplicateResult = results.find(
          (r) => r.sample_id == cSample.duplicate.id
        );
        resultColumns.push({
          startDepth: cSample.duplicate.start_depth ?? 0,
          style: this._getResultExceedanceStyling(duplicateResult),
          class: 'text-center',
          displayResult: this.getDisplayResult(
            units,
            getResultValue(duplicateResult, scenarioSet, isScientificFormat)
          ),
          isNonDetect: !!duplicateResult?.prefix,
        });
      }
    });

    if (
      hideChemicalsWithoutExceedances &&
      !resultColumns.find((rc) => rc.style !== '')
    ) {
      return '';
    }

    if (
      hideNonDetectChemicals &&
      resultColumns.filter((c) => !c.isNonDetect).length === 0
    ) {
      return '';
    }

    return resultColumns
      .sort((a, b) => a.startDepth - b.startDepth)
      .map(
        (item) =>
          `<td class="${item.class}" style="${item.style}">${item.displayResult}</td>`
      )
      .join('');
  }

  _getExceedanceProperties(result, exceedances) {
    if (exceedances.length == 0) {
      return [];
    }

    let stylingProperties = [];

    exceedances.forEach((exceedance) => {
      const styleByScenario = NAMESPACE.getGetter(
        store,
        'getStyleByScenario'
      )(exceedance);

      let highestValue = exceedance.exceedances.find(
        (i) => i.item_id == result.id
      ).value;

      let cellStyling = styleByScenario?.exceedance_cell_styling;
      if (exceedance.criteria_type === 'landuse' && result.prefix) {
        cellStyling = this.scenarioSet?.nd_exceedance_cell_styling || {
          background_color: '#f8f1d7',
        };

        highestValue = Infinity;
      }

      const properties = getStylingPropertiesByMethodAsString(
        cellStyling,
        exceedance.criteria_type
      );

      stylingProperties.push({
        highestValue,
        properties,
      });
    });

    return stylingProperties;
  }

  _getResultExceedanceStyling(result) {
    if (!result) {
      return '';
    }

    const exceedances = this.resultExceedances;

    let stylingProperties = [];

    // get landuse exceedances
    const landuseExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'landuse' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, landuseExceedances),
    ];

    // get criteria exceedances
    const criteriaExceedances = exceedances.filter(
      (e) =>
        e.criteria_type == 'criteria' &&
        e.exceedances.findIndex((i) => i.item_id == result.id) != -1
    );

    stylingProperties = [
      ...stylingProperties,
      ...this._getExceedanceProperties(result, criteriaExceedances),
    ];

    let styling = '';

    stylingProperties
      .sort((a, b) => a.highestValue - b.highestValue)
      .forEach((style) => {
        styling += style.properties;
      });

    return styling;
  }

  checkHasResults(sample) {
    const { sampled_date: sampledDate, sample_type: sampleType } = sample;
    const {
      enviro_callout_filter: { visibleTypes, visibleDates },
    } = this.selectedFigure;

    if (
      (visibleDates && !visibleDates.includes(sampledDate)) ||
      (visibleTypes && !visibleTypes.includes(sampleType))
    ) {
      return false;
    }

    return NAMESPACE.getGetter(store, 'checkHasResults')(
      this.selectedFigure,
      sample
    );
  }

  formatSampleDepth(sample) {
    return (
      helperFormatSampleDepth(
        sample,
        getMeasurementShortTitle(this.project.measurement_type)
      ) || ''
    );
  }

  // specifically for asbestos, user needs to manually update result based on the comment
  getDisplayResult(units, display_result) {
    if (units == 'comment' && (display_result == 0 || display_result == 1)) {
      display_result = 'COMMENT';
    }

    if (units == 'detect') {
      if (display_result == 0) {
        display_result = 'ABSENT';
      }
      if (display_result == 1) {
        display_result = 'PRESENT';
      }
    }

    return display_result;
  }
}

export default PopupHandler;
