import * as TOOL_TYPES from '../constants/toolTypes';
import * as STATISTIC_TYPES from '../constants/statisticTypes';
import * as VIEW_MODES from '../constants/viewModes';
import * as SWATCHES from '../constants/swatches';

function patchStatisticRelatedStuff(options) {
  let newOptions = { ...options };

  if (!newOptions.info.statisticTypeId) {
    newOptions = {
      ...newOptions,
      info: {
        ...newOptions.info,
        statisticTypeId: !!newOptions.measureFieldId
          ? STATISTIC_TYPES.CUSTOM_MADE_STATISTIC.id
          : STATISTIC_TYPES.NUMBER_OF_SAMPLES.id,
      },
    };
  }

  if (newOptions.info.showStatisticOfNoData === undefined) {
    newOptions = {
      ...newOptions,
      info: {
        ...newOptions.info,
        showStatisticOfNoData: false,
      },
    };
  }

  if (newOptions.info.showStatisticEqualToZero === undefined) {
    newOptions = {
      ...newOptions,
      info: {
        ...newOptions.info,
        showStatisticEqualToZero: false,
      },
    };
  }

  return newOptions;
}

function patchYAxisRelatedStuff(options) {
  let newOptions = { ...options };
  if (!newOptions.style.axes) {
    newOptions = {
      ...newOptions,
      style: {
        ...newOptions.style,
        axes: {
          yAxis: {
            beginAtZero: false,
            precision: 0,
            max: NaN, // NaN means auto
            min: NaN, // NaN means auto
            stepSize: NaN, // NaN means auto
          },
        },
      },
    };
  }
  return newOptions;
}

function modifyGraphBackgroundColor(options, data) {
  const {
    style: {
      graph: [{ backgroundColor }],
    },
  } = options;
  if (Array.isArray(backgroundColor)) {
    const nextBackgroundColor = backgroundColor.reduce((accu, item, index) => {
      return {
        ...accu,
        [data.labels[index]]: item,
      };
    }, {});
    return {
      ...options,
      style: {
        ...options.style,
        graph: [
          {
            ...options.style.graph[0],
            backgroundColor: nextBackgroundColor,
          },
        ],
      },
    };
  }
  return options;
}

/**
 * This function is used in loading dashboards.
 * 1. An empty title string in info becomes null when it is saved, so
 *    converting null title to an empty string is required.
 * 2. For legacy bar charts, set an empty selection for them if there selection doesn't exist.
 *    See info of selection at line #23 in createOptions function.
 * 3. For all legacy charts but Percentage chart, set an empty eventSourceIds for
 *    them if their eventSourceIds doesn't exist. Percentage chart doens't need eventSourceIds
 *    because it can't be an observer.
 */
export default (toolType, options, data) => {
  const { viewModeId, includedSampleIds, excludedSampleIds, title } =
    options.info;
  let newOptions = {
    ...options,
    info: {
      ...options.info,
      viewModeId: viewModeId === undefined ? VIEW_MODES.CHART.id : viewModeId,
      includedSampleIds:
        includedSampleIds === undefined ? null : includedSampleIds,
      excludedSampleIds:
        excludedSampleIds === undefined ? [] : excludedSampleIds,
      title: title ?? '',
    },
    style: {
      ...options.style,
      canvas: {
        ...options.style.canvas,
        titleSize: 18,
      },
    },
  };

  switch (toolType) {
    case TOOL_TYPES.BAR_CHART:
      if (!newOptions.info.selection) {
        newOptions = {
          ...newOptions,
          info: {
            ...newOptions.info,
            selection: [[]],
          },
        };
      }

      newOptions = patchStatisticRelatedStuff(newOptions);
      newOptions = patchYAxisRelatedStuff(newOptions);
      newOptions = modifyGraphBackgroundColor(newOptions, data);

      break;
    case TOOL_TYPES.LINE_CHART:
      newOptions = patchYAxisRelatedStuff(newOptions);
      break;
    case TOOL_TYPES.PIE_CHART:
      if (!newOptions.info.selection) {
        newOptions = {
          ...newOptions,
          info: {
            ...newOptions.info,
            selection: [],
          },
        };
      }

      newOptions = patchStatisticRelatedStuff(newOptions);
      newOptions = modifyGraphBackgroundColor(newOptions, data);

      break;
    case TOOL_TYPES.WORD_CLOUD_CHART: {
      if (!newOptions.style.graph) {
        newOptions = {
          ...newOptions,
          style: {
            ...newOptions.style,
            graph: {
              heavyWeightColor: SWATCHES.COLOR_274,
              mediumWeightColor: SWATCHES.COLOR_355,
              lightWeightColor: SWATCHES.COLOR_356,
            },
          },
        };
      }
      break;
    }
  }

  if (toolType !== TOOL_TYPES.PERCENTAGE_CHART) {
    newOptions = {
      ...newOptions,
      info: {
        ...newOptions.info,
        eventSourceIds: newOptions.info.eventSourceIds ?? [],
      },
    };
  }

  return newOptions;
};
