export const COUNT = { id: 1, label: 'Count' };
export const SUM = { id: 2, label: 'Sum' };
export const AVERAGE = { id: 3, label: 'Average' };
export const MINIMUM = { id: 4, label: 'Minimum' };
export const MAXIMUM = { id: 5, label: 'Maximum' };

export const AGGREGATE_FUNCTIONS = { COUNT, SUM, AVERAGE, MINIMUM, MAXIMUM };

export function findAggregateFunctionById(id) {
  return Object.values(AGGREGATE_FUNCTIONS).find(
    (aggregateFunction) => aggregateFunction.id === id
  );
}
