import { ProjectScenarioId } from '@component-library/enviro';
import * as cl_bl from '@component-library/business-logic';
import type {
  BaseStylingRule,
  BaseTarget,
  GatherAppTarget,
  Id,
  SampleStyle,
  StylingRuleOnGatherApp,
  StylingRuleOnGatherAppStyle,
} from '@component-library/business-logic/mapping/styling-rule';
import { TargetType } from '@component-library/business-logic/mapping/styling-rule';
import * as cl_bm from '@component-library/business-model';
import * as bl_gather from '@component-library/gather';
import { captureException } from '@component-library/sentry';
import { produce } from 'immer';
import type { SampleChemicalId } from '../evalu8';

export type FigureId = number | undefined;

export type EnviroSamplesTarget = BaseTarget & {
  type: TargetType.EnviroSamples;
};

export type Target = GatherAppTarget | EnviroSamplesTarget;

export type StylingRuleOnEnviroSamplesStyle = SampleStyle | {};

export type ConditionOnEnviroSamples = {
  scenarioIds: ProjectScenarioId[];
  chemicalIds: SampleChemicalId[];
};

export interface FigureStylingRuleOnEnvrioSamples extends BaseStylingRule {
  target: EnviroSamplesTarget;
  condition: ConditionOnEnviroSamples;
  style: StylingRuleOnEnviroSamplesStyle;
  figureId: number;
}

// If figureId is undefined then the rule is defined in Gather.
export interface FigureStylingRuleOnGatherApp extends StylingRuleOnGatherApp {
  figureId: FigureId;
}

export type FigureStylingRule =
  | FigureStylingRuleOnEnvrioSamples
  | FigureStylingRuleOnGatherApp;

export type FigureStylingRuleStyle =
  | StylingRuleOnEnviroSamplesStyle
  | StylingRuleOnGatherAppStyle;

export type FigureStylingRuleLocator = {
  id: Id;
  figureId: FigureId;
};

// Styling rules defined in Gather are included.
export function findFigureStylingRulesByFigureId(
  figureStylingRules: FigureStylingRule[],
  figureId: number
): FigureStylingRule[] {
  return figureStylingRules.filter(
    (fsr) => !fsr.figureId || fsr.figureId === figureId
  );
}

export type UpdateOneOfFigureStylingRulesPayload = {
  locator: FigureStylingRuleLocator;
  producer: (draft: FigureStylingRule) => void;
};

export function updateOneOfFigureStylingRules(
  figureStylingRules: FigureStylingRule[],
  payload: UpdateOneOfFigureStylingRulesPayload
) {
  const { locator, producer } = payload;
  const fsr = figureStylingRules.find(
    (fsr) => fsr.id === locator.id && fsr.figureId === locator.figureId
  );

  if (!fsr) {
    return;
  }

  const newFsr = produce(fsr, producer);
  const index = figureStylingRules.indexOf(fsr);
  figureStylingRules.splice(index, 1, newFsr);
}

export function deleteOneOfFigureStylingRules(
  figureStylingRules: FigureStylingRule[],
  locator: FigureStylingRuleLocator
) {
  const fsr = figureStylingRules.find(
    (fsr) => fsr.id === locator.id && fsr.figureId === locator.figureId
  );

  if (!fsr) {
    return;
  }

  const index = figureStylingRules.indexOf(fsr);
  figureStylingRules.splice(index, 1);
}

export function checkIsFigureStylingRuleOnGatherAppFollowed(
  stylingRuleOnGatherApp: FigureStylingRuleOnGatherApp,
  gatherField,
  inputValuesForStyling
): boolean {
  const { target, condition } = stylingRuleOnGatherApp;
  const { id: appId, sectionId } = target;
  const { fieldId, operator, operand } = condition;
  const inputValue = inputValuesForStyling.find(
    (iv) =>
      iv.template_tab_id === appId &&
      iv.template_section_id === sectionId &&
      iv.template_field_id === fieldId &&
      iv.template_section_index === 0
  );

  if (!inputValue) {
    return false;
  }

  const { value, value2 } = inputValue;
  const values: (string | cl_bm.expression.Result)[] = [];
  const leftOperand = cl_bl.expression.resolveFieldFromValuePairs(
    gatherField,
    cl_bm.expression.ACTION_CURRENT,
    [{ value, value2 }],
    0
  );
  const rightOperand = cl_bl.expression.resolveFieldFromValuePairs(
    gatherField,
    cl_bm.expression.ACTION_CURRENT,
    [operand],
    0
  );
  if (operator === bl_gather.Operator.EqualTo) {
    values.push('isEqual(');
    values.push(leftOperand);
    values.push(', ');
    values.push(rightOperand);
    values.push(')');
  } else if (operator === bl_gather.Operator.NotEqualTo) {
    values.push('!isEqual(');
    values.push(leftOperand);
    values.push(', ');
    values.push(rightOperand);
    values.push(')');
  } else {
    values.push(leftOperand);
    values.push(operator);
    values.push(rightOperand);
  }

  try {
    return cl_bl.expression.evaluateValues(values);
  } catch (e) {
    if (cl_bl.expression.checkIsExpressionReady(values)) {
      console.error(
        `Checking whether the Gather styling rule is followed or not failed, styling rule: ${stylingRuleOnGatherApp.id}, values: ${values}.`
      );
      captureException(e);
    }
    return false;
  }
}

export function checkIsFigureStylingRuleOnEnviroSamples(
  fsr: FigureStylingRule
): fsr is FigureStylingRuleOnEnvrioSamples {
  return fsr.target.type === TargetType.EnviroSamples;
}

export function checkIsFigureStylingRuleOnGatherApp(
  fsr: FigureStylingRule
): fsr is FigureStylingRuleOnGatherApp {
  return fsr.target.type === TargetType.GatherApp;
}
