import { safeMultiply } from '@component-library/utils/unit-conversion';
import useUnitConversion from '@/js/composables/useUnitConversion';

export const getFormattedValue = (
  value,
  isScientificFormat = false,
  toDecimalPlace = null
) => {
  value = safeMultiply(value, 1);

  if (isScientificFormat) {
    return parseFloat(value).toExponential();
  }

  return formatNumberWithCommas(value, toDecimalPlace);
};

export const formatNumberWithCommas = (x, toDecimalPlace = null) => {
  if (toDecimalPlace && !isNaN(x)) {
    x = +(Math.round(x + 'e+' + toDecimalPlace) + 'e-' + toDecimalPlace);
  }

  if (!x || x % 1 != 0) {
    return x;
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const isNonDetect = (result) => {
  return result?.prefix === '<';
};

export const convertValueByUnits = (
  result,
  fromUnit,
  toUnit,
  multiplier = null,
  unitConversions = []
) => {
  if (!result || !fromUnit || !toUnit || isNaN(result)) {
    return result;
  }

  fromUnit = cleanUnit(fromUnit);
  toUnit = cleanUnit(toUnit);

  if (multiplier != null) {
    if (fromUnit != toUnit) {
      return safeMultiply(result, multiplier);
    }

    return result;
  }

  const { convertResultUnits } = useUnitConversion(unitConversions);
  return convertResultUnits(result, fromUnit, toUnit);
};

const cleanUnit = (unit) => {
  return unit.replace('µ', 'u');
};

export const getResultValue = (
  result,
  scenarioSet = null,
  isScientificFormat = false
) => {
  if (isNonDetect(result) && scenarioSet?.nd_result_text) {
    return scenarioSet.nd_result_text;
  }

  const prefix =
    isNonDetect(result) && (!scenarioSet || scenarioSet.show_prefix)
      ? result.prefix + ' '
      : '';

  if (!result || result.display_result === null) {
    if (prefix) {
      return 'ND';
    }
    return scenarioSet?.no_result_text || '-';
  }

  return prefix + getFormattedValue(result.display_result, isScientificFormat);
};

/**
 * Chemical factor should be prioritized over scenario factor
 */
export const getGuidelineFactor = (itemGuideline, chemical) => {
  return (
    chemical.factors.find((f) => f.scenario_id == itemGuideline.scenario_id)
      ?.factor || (itemGuideline.factor != 1 ? itemGuideline.factor : null)
  );
};

export const getGuidelineValue = (
  guideline,
  chemical,
  formatAsScientific = false,
  compositeOf = null,
  compositeGuidelineRounding = null,
  unitConversions = []
) => {
  let guidelineValue = guideline.data_1 || guideline.data_alpha;
  if (isNaN(guidelineValue)) {
    return guidelineValue;
  }

  const convertGuidelineValue = (key) => {
    let guidelineValue = convertValueByUnits(
      guideline[key],
      guideline.units,
      chemical.units,
      chemical.unit_multiplier,
      unitConversions
    );

    const factor = getGuidelineFactor(guideline, chemical);
    if (factor) {
      guidelineValue = guidelineValue * factor;
    }

    if (compositeOf) {
      guidelineValue = guidelineValue / compositeOf;
    }

    return getFormattedValue(
      guidelineValue,
      formatAsScientific,
      compositeOf ? compositeGuidelineRounding : null
    );
  };

  const minimumValue = convertGuidelineValue('data_1');
  if (guideline.data_2) {
    return minimumValue + ' - ' + convertGuidelineValue('data_2');
  }

  return minimumValue;
};
