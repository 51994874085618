export const getScenarioRequestParameters = (scenarios, by_documents) => {
  return {
    criteria_type:
      scenarios.length == 0
        ? null
        : scenarios.length == 1
        ? scenarios[0].criteria_type
        : 'landuse',
    landuses: JSON.stringify(
      scenarios
        .filter((s) => s.criteria_type == 'landuse')
        .map((s) => {
          return {
            scenario_id: s.scenario_id,
            document_id: s.document_id,
            factor: s.factor,
          };
        })
    ),
    criteria_set_ids: scenarios
      .filter((s) => s.criteria_type == 'criteria')
      .map((s) => s.criteria_set_id),
    by_documents: by_documents || false,
  };
};

export const canHydrocarbonOnlyExport = (settings, scenarios) => {
  return settings
    ? !settings.by_documents &&
        (settings.test_hydrocarbons == 1 ||
          scenarios.findIndex(
            (s) => s.options && s.options.test_hydrocarbons == 1
          ) != -1)
    : false;
};
