<template>
  <div v-tooltip="value.value">
    {{ value.value }}
  </div>
</template>
<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
};
</script>
