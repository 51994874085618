export const getHydrocarbonHierarchy = (hierarchy) => {
  if (!hierarchy) return [];

  const result = [];

  for (const scenario_id in hierarchy) {
    const scenario = hierarchy[scenario_id];
    const pathways = [];

    for (const pathway in scenario) {
      const soilTypes = scenario[pathway];
      const formattedSoilTypes = [];

      for (const soilType in soilTypes) {
        const { soil_depths } = soilTypes[soilType];
        const formattedSoilDepths = soil_depths.map(
          ({ soil_depth }) => soil_depth
        );

        formattedSoilTypes.push({
          soilType,
          soilDepths: formattedSoilDepths,
          colSpan: formattedSoilDepths.length,
        });
      }

      const soilTypeColSpan = formattedSoilTypes.reduce(
        (total, { soilDepths }) => total + soilDepths.length,
        0
      );

      pathways.push({
        pathway,
        soilTypes: formattedSoilTypes,
        colSpan: soilTypeColSpan,
      });
    }

    const colSpan = pathways.reduce((total, { colSpan }) => total + colSpan, 0);

    result.push({ id: scenario_id, pathways, colSpan });
  }

  return result;
};

export const checkIfResultIsAboveGuideline = (samples, result, guideline) => {
  const sample = samples.find((x) => x.id == result.sample_id);
  const compositeOf = (sample != null ? sample.composite_of : null) || 1;

  const resultValue = Number(result.display_result);
  const minimumValue = Number(guideline.data_1) / compositeOf;

  if (guideline.data_2) {
    return !isResultInRange(
      resultValue,
      minimumValue,
      Number(guideline.data_2) / compositeOf
    );
  }

  return minimumValue ? resultValue > minimumValue : false;
};

const isResultInRange = (resultValue, minimumValue, maximumValue) => {
  return minimumValue <= resultValue && resultValue <= maximumValue;
};

export const getDocumentIdentifier = (
  chemical,
  scenario,
  exportSettings,
  useReferenceColumn
) => {
  if (scenario.criteria_set_id) {
    const analyteComment = getCriteriaIdentifer(
      chemical,
      scenario,
      exportSettings
    );

    const criteriaComment = (
      exportSettings?.custom_criteria_comments || []
    ).find((c) => c.set_id === scenario.criteria_set_id)?.identifier;

    return [analyteComment, criteriaComment].filter((i) => i).join(', ');
  }

  const documentId = (chemical?.item_guidelines || []).find(
    (g) => g.scenario_id == scenario.id
  )?.document_id;
  if (!documentId) {
    return;
  }

  return getDocumentIdentifierByDocumentId(
    documentId,
    scenario,
    exportSettings,
    useReferenceColumn
  );
};

const getCriteriaIdentifer = (chemical, scenario, exportSettings) => {
  if (!chemical) {
    return;
  }

  const criteriaAnalyte = (chemical?.criteria_analytes || []).find(
    (v) => v.set_id == scenario.criteria_set_id
  );
  if (!criteriaAnalyte) {
    return;
  }

  const analyteComment = (
    exportSettings?.custom_criteria_analyte_comments || []
  ).find((c) => c.id === criteriaAnalyte.comment_id);
  return analyteComment?.identifier;
};

export const getDocumentIdentifierByDocumentId = (
  documentId,
  scenario,
  exportSettings,
  useReferenceColumn
) => {
  const documents = exportSettings?.documents_used || [];
  if (documents.length == 0) {
    return;
  }

  const document = documents.find((d) => d.document_id == documentId);
  if (!document) {
    return;
  }

  if (useReferenceColumn && document.acronym) {
    return document.acronym;
  }

  let scenarioId = scenario.scenario_id;
  if (document.short_url == 'mfe-hydrocarbons-gw') {
    scenarioId = 4;
  }

  const reference = document.scenario_table_references.find(
    (reference) =>
      reference.scenario_id == scenarioId &&
      (scenario.document_id
        ? reference.document_id == scenario.document_id
        : true)
  );

  return reference?.identifier;
};

export const iterateScenarios = (
  scenarios,
  hydrocarbonHierarchy,
  compositeSampleCalculations,
  callback
) => {
  scenarios.forEach((scenario, scenarioIndex) => {
    const isFirst = scenarioIndex === 0;
    const isLast = scenarioIndex === scenarios.length - 1;

    if (hydrocarbonHierarchy && hydrocarbonHierarchy[scenario.id]) {
      const hydrocarbonScenario = hydrocarbonHierarchy[scenario.id];

      Object.keys(hydrocarbonScenario).forEach((pathway) => {
        const pathwaySoilTypes = Object.keys(hydrocarbonScenario[pathway]);

        pathwaySoilTypes.forEach((soilType) => {
          const { soil_depths } = hydrocarbonScenario[pathway][soilType];

          soil_depths.forEach(({ media_2, soil_depth }) => {
            const hydrocarbonLookup = {
              pathway: pathway === '' ? null : pathway,
              soilType: media_2,
              soilDepth: soil_depth,
            };

            callback(
              {
                ...scenario,
                document: {
                  ...scenario.document,
                  media_2,
                  pathways: pathway,
                  soil_depth,
                },
              },
              isFirst,
              isLast,
              null,
              hydrocarbonLookup
            );
          });
        });
      });
    } else {
      callback(scenario, isFirst, isLast);

      if (compositeSampleCalculations && scenario.criteria_type == 'landuse') {
        compositeSampleCalculations.forEach((composite) => {
          callback(scenario, isFirst, isLast, composite);
        });
      }
    }
  });
};
