import * as bl_measure from './measure';
import * as bl_medium from './medium';

export enum Orientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}

export type FigureLayout = {
  id: number;
  name: string;
  orientation: Orientation;
  mediumType: bl_medium.MediumType;
  paper_size: bl_medium.PrinterPaper;
  is_draft: boolean;
  author_id: number;
};

export const DEFAULT_FIGURE_LAYOUT_ID = 0;

// Margin in millimetres
export const DEFAULT_MARGIN = 8;

export function adaptSize(
  size: bl_measure.Size,
  orientation: Orientation
): bl_measure.Size {
  const [width, height] = size;
  if (checkIsPortrait(width, height)) {
    return orientation === Orientation.Portrait ? size : [height, width];
  } else {
    return orientation === Orientation.Portrait ? [height, width] : size;
  }
}

export function checkIsPortrait(width: number, height: number) {
  return width < height;
}
