export const getMatrixTypes = () => {
  return [
    {
      index: 0,
      key: 'soil',
      icon: 'fas fa-seedling',
      title: 'Soil',
      countries: ['NZ', 'US', 'AU', 'GB', 'CA'],
    },
    {
      index: 1,
      key: 'water',
      icon: 'fas fa-water',
      title: 'Water',
      countries: ['NZ', 'US', 'AU', 'GB', 'CA'],
    },
    {
      index: 2,
      key: 'leachate',
      icon: 'fas fa-recycle',
      title: 'Leachate',
      countries: ['NZ', 'US', 'AU', 'GB', 'CA'],
    },
    {
      index: 3,
      key: 'soilgas',
      icon: 'fas fa-wind',
      title: 'Soil Gas',
      countries: ['NZ', 'US', 'AU', 'GB', 'CA'],
    },
    {
      index: 4,
      key: 'sediment',
      icon: 'fas fa-chart-network',
      title: 'Sediment',
      countries: ['NZ', 'US', 'AU', 'GB', 'CA'],
    },
  ];
};

export default {
  DOCUMENT_TYPE_EXCEL: 1,
  DOCUMENT_TYPE_WORD: 0,

  matrixTypes: getMatrixTypes(),
  matrices: ['soil', 'water', 'leachate', 'soilgas', 'sediment'],

  pageSizes: {
    a4_landscape: [3508, 2480],
    a4_portrait: [2480, 3508],
    a3_landscape: [4960, 3508],
    a3_portrait: [3508, 4960],
  },
};
