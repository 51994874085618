import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import _isEmpty from 'lodash/isEmpty';
import { getSampleTitle } from '../lib/olbm/layer/sample/utils';
import * as bl_coord from './coord';
import * as bl_layer from './layer';

export function checkHasGatherCallout(sample) {
  const { gather_callout_data } = sample;
  return !_isEmpty(gather_callout_data);
}

function checkHasResults(sample, figure) {
  return !!figure.results?.some((item) =>
    item.sample_items.some((item2) => item2.sample_id === sample.id)
  );
}

export function checkHasResultsFully(sample, figure) {
  if (checkHasResults(sample, figure)) {
    return true;
  }

  const { duplicate } = sample;
  if (duplicate && checkHasResults(duplicate, figure)) {
    return true;
  }

  const { child_samples } = sample;
  if (
    child_samples?.some(
      (item) =>
        checkHasResults(item, figure) ||
        (item.duplicate && checkHasResults(item.duplicate, figure))
    )
  ) {
    return true;
  }

  return false;
}

// A sample scope is a container of samples. There are two categories of
// samples scopes:
// 1. Sample Group
// 2. Sub Folder
export function checkIsSampleInScope(sample, sampleScope) {
  const { type } = sampleScope.data.properties;
  if (type === 'sample_group') {
    return sample.sample_group.id === sampleScope.id;
  } else if (type === 'sub_folder') {
    const { sub_folder: subFolder } = sample;
    return (
      sample.sample_group.id === sampleScope.data.properties.sampleGroupId &&
      subFolder === sampleScope.text
    );
  }
  return false;
}

export function getScopedSamples(sampleScope, allSamples) {
  return allSamples.filter((s) => checkIsSampleInScope(s, sampleScope));
}

export function extractSampleIds(samples) {
  return samples.map((s) => s.id);
}

/**
 *
 * @param {Array} allSamples
 * @param {Feature} boundary A GeoJSON rectangle feature
 * @returns
 */
export function getSamplesInBoundary(allSamples, boundary) {
  return allSamples
    .filter((item) => {
      const { latitude: lat, longitude: lng } = item;
      return bl_coord.checkIsValidLatLng({ lat, lng });
    })
    .filter((item) => {
      const { latitude: lat, longitude: lng } = item;
      const point = [parseFloat(lng), parseFloat(lat)];
      return booleanPointInPolygon(point, boundary);
    });
}

/**
 * A Gather sample is one created through Gather.
 * @param {Array} samples
 */
export function getGatherSamples(samples) {
  return samples.filter((s) => !!s.template_tab_id);
}

function checkHasChemicalResult(sampleId, chemicalResult) {
  return chemicalResult.sample_items.some((si) => si.sample_id === sampleId);
}

function formatDepth(sample, unit) {
  const { start_depth: startDepth, end_depth: endDepth } = sample;
  if (typeof startDepth !== 'number') {
    return 'Unset Depth';
  }

  return (
    `${startDepth}${unit}` +
    (typeof endDepth === 'number' ? ` - ${endDepth}${unit}` : '')
  );
}

export function getEnviroSamples(samples, chemicalResult, depthUnit) {
  const result = [];
  samples.forEach((item) => {
    const depths = [];
    if (checkHasChemicalResult(item.id, chemicalResult)) {
      depths.push({ title: formatDepth(item, depthUnit), sampleId: item.id });
    }

    if (
      item.duplicate &&
      checkHasChemicalResult(item.duplicate.id, chemicalResult)
    ) {
      depths.push({ title: 'Dup', sampleId: item.duplicate.id });
    }

    const { child_samples: children } = item;
    children
      .filter((child) => !child.duplicate_of_id)
      .forEach((item2) => {
        if (checkHasChemicalResult(item2.id, chemicalResult)) {
          depths.push({
            title: formatDepth(item2, depthUnit),
            sampleId: item2.id,
          });
        }

        if (
          item2.duplicate &&
          checkHasChemicalResult(item2.duplicate.id, chemicalResult)
        ) {
          depths.push({
            title: 'Dup',
            sampleId: item2.duplicate.id,
          });
        }
      });

    if (depths.length) {
      result.push({
        id: item.id,
        title: getSampleTitle(item),
        depths,
      });
    }
  });

  return result;
}

export function getSampleScope(sample) {
  const { sample_group: sampleGroup } = sample;

  if (!sampleGroup.id) {
    return sampleGroup;
  }

  const { sub_folder: subFolder } = sample;
  if (!subFolder) {
    return sampleGroup;
  }

  const subFolderLayers = sampleGroup.children.filter(
    (sgc) =>
      sgc.data.properties.type === bl_layer.layer_types.SAMPLE_GROUP_SUB_FOLDER
  );
  return subFolderLayers.find((sfl) => sfl.text === subFolder) ?? sampleGroup;
}

export function getMappedSample(figure, sample_id) {
  return figure.gather_sample_mapping?.[sample_id] ?? null;
}
