import _camelCase from 'lodash/camelCase';
import * as openlayers from '@maps/modules/openlayers';
import {
  TRAIT_CODES,
  COMPONENT_TYPE_CODES,
  COMPONENT_TYPES,
  DIRECTIONS,
  VALUE_TYPES,
} from '../constants/FigurePrintLayoutConstants';

export function createTraits(traitCodes) {
  return traitCodes.map((item) => ({
    code: item,
    visible: true,
    disabled: false,
  }));
}

export function getComponentType(code) {
  return COMPONENT_TYPES.find((item) => item.code === code);
}

let componentIdCounter = 1;
export function assignIdToComponent(component) {
  return {
    ...component,
    id: componentIdCounter++,
  };
}

export function patchComponent(component) {
  if (component.typeCode === COMPONENT_TYPE_CODES.FIGURE_TEXT) {
    if (typeof component.value.isContentUpppercase === 'undefined') {
      return {
        ...component,
        value: {
          ...component.value,
          isContentUpppercase: false,
        },
      };
    }
  }

  return component;
}

export function createComponent(typeCode, props = {}) {
  const type = getComponentType(typeCode);
  if (!type) {
    throw `Unsupported component type: code=${typeCode}.`;
  }

  let component = type.traits.reduce(
    (accu, item) => {
      if (item.code === TRAIT_CODES.PADDINGS) {
        accu = {
          ...accu,
          paddings: 0,
        };
      } else if (item.code === TRAIT_CODES.BORDERS) {
        accu = {
          ...accu,
          borders: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        };
      } else if (item.code === TRAIT_CODES.FILL) {
        accu = {
          ...accu,
          fill: {
            color: '#FFFFFF',
            opacity: 1,
          },
        };
      } else if (item.code === TRAIT_CODES.ALIGNMENTS) {
        accu = {
          ...accu,
          alignments: {
            horizontal: 'center',
            vertical: 'center',
          },
        };
      } else if (item.code === TRAIT_CODES.IS_MULTIPLE) {
        accu = {
          ...accu,
          isMultiple: true,
        };
      } else if (item.code === TRAIT_CODES.DIRECTION) {
        accu = {
          ...accu,
          direction: DIRECTIONS.HORIZONTAL,
        };
      } else if (item.code === TRAIT_CODES.FONT) {
        accu = {
          ...accu,
          font: {
            family: 'Poppins',
            color: '#000000',
            size: 13,
          },
        };
      } else if (item.code === TRAIT_CODES.VALUE) {
        accu = {
          ...accu,
          value: {
            label: '',
            type: VALUE_TYPES.STATIC,
            content: '',
            isLabelBold: false,
            isContentBold: true,
            isContentUpppercase: false,
          },
        };
      } else if (item.code === TRAIT_CODES.RICH_TEXT) {
        accu = {
          ...accu,
          richText: '',
          isEditing: false,
        };
      } else if (item.code === TRAIT_CODES.IMAGE) {
        accu = {
          ...accu,
          url: null,
          aspectRatio: null,
        };
      }

      return accu;
    },
    {
      typeCode,
    }
  );

  component = assignIdToComponent({
    ...component,
    ...props,
  });

  return component;
}

/**
 * Return a color hexidecimal string that includes transparency.
 * @param {String} color
 * @param {Number} opacity
 * @returns
 */
export function getComponentFillColor(component) {
  const {
    fill: { color, opacity },
  } = component;
  return `${color}${Math.round(255 * opacity)
    .toString(16)
    .toUpperCase()}`;
}

export function getComponentRef(component) {
  const { typeCode, id } = component;
  const componentType = getComponentType(typeCode);
  return _camelCase(
    componentType.traits.find((item) => item.code === TRAIT_CODES.IS_MULTIPLE)
      ? `${typeCode}-${id}`
      : typeCode
  );
}

function getElementSize(element) {
  const { width, height } = element?.getBoundingClientRect() ?? {
    width: 0,
    height: 0,
  };
  return { width, height };
}

function getEditorHeight() {
  const header = document.querySelector('.layout > .header');
  const { height: headerHeight } = getElementSize(header);

  const actionsFooter = document.querySelector('.actions-footer');
  const { height: actionsFooterHeight } = getElementSize(actionsFooter);

  return window.innerHeight - headerHeight - actionsFooterHeight;
}

export function getInfoPaneBodyHeight() {
  const header = document.querySelector(
    '.figure-print-layout-editor .info-pane .header'
  );
  const { height: headerHeight } = getElementSize(header);
  const bordersWidth = 2;
  return getEditorHeight() - headerHeight - bordersWidth;
}

export function getComponentPropsPaneFormHeight() {
  const header = document.querySelector(
    '.figure-print-layout-editor .component-props-pane .header'
  );
  const { height: headerHeight } = getElementSize(header);
  const actionBar = document.querySelector(
    '.figure-print-layout-editor .component-props-pane .action-bar'
  );
  const { height: actionBarHeight } = getElementSize(actionBar);
  const bordersWidth = 2;

  return getEditorHeight() - headerHeight - actionBarHeight - bordersWidth;
}

export function getCompanyLogoUrl(companyId) {
  return `/api/images/company-logo/${companyId}`;
}

export function tryComponentSnapping(
  figurePrintLayout,
  scaledPaperSize,
  componentId
) {
  const { paper_size: paperSizeCode } = figurePrintLayout;
  const { DPI_300, MEASURE_UNIT_PIXEL } = openlayers.utils.print;
  const paperSize = openlayers.utils.print.findPaperSize(paperSizeCode);
  const toMeasureInPx = (measure) => {
    return openlayers.utils.print.transformMeasure(
      measure,
      DPI_300,
      DPI_300,
      paperSize.measureUnitCode,
      MEASURE_UNIT_PIXEL
    );
  };
  const fromMeasureInPx = (measure) => {
    return openlayers.utils.print.transformMeasure(
      measure,
      DPI_300,
      DPI_300,
      MEASURE_UNIT_PIXEL,
      paperSize.measureUnitCode
    );
  };
  const findComponent = (id) =>
    figurePrintLayout.components.find((item) => item.id === id);
  const contentWidth = scaledPaperSize.contentWidth / scaledPaperSize.scale;
  const contentHeight = scaledPaperSize.contentHeight / scaledPaperSize.scale;
  const component = findComponent(componentId);
  const { width, height, top, left } = component;
  const componentMeasures = {
    top: toMeasureInPx(top),
    right: toMeasureInPx(left + width),
    bottom: toMeasureInPx(top + height),
    left: toMeasureInPx(left),
  };
  const gapCompareFunc = (g1, g2) => {
    return Math.abs(g1[1]) - Math.abs(g2[1]);
  };
  // The gap's unit is pixel.
  const shouldSnap = (gap) => {
    const { scale } = scaledPaperSize;
    const threshold = 10;
    const scaledGap = gap * scale;
    return scaledGap >= -threshold && scaledGap <= threshold;
  };

  // A gap is an array with two items. the 1st one is the
  // relative component id, the 2nd one is the gap value.
  const paperContentId = 0;
  const topGaps = [[paperContentId, componentMeasures.top]];
  const rightGaps = [[paperContentId, contentWidth - componentMeasures.right]];
  const bottomGaps = [
    [paperContentId, contentHeight - componentMeasures.bottom],
  ];
  const leftGaps = [[paperContentId, componentMeasures.left]];

  figurePrintLayout.components
    .filter((item) => item !== component)
    .forEach((item) => {
      const { id, width, height, top, left } = item;
      topGaps.push([id, componentMeasures.top - toMeasureInPx(top + height)]);
      rightGaps.push([id, toMeasureInPx(left) - componentMeasures.right]);
      bottomGaps.push([id, toMeasureInPx(top) - componentMeasures.bottom]);
      leftGaps.push([id, componentMeasures.left - toMeasureInPx(left + width)]);
    });

  topGaps.sort(gapCompareFunc);
  rightGaps.sort(gapCompareFunc);
  bottomGaps.sort(gapCompareFunc);
  leftGaps.sort(gapCompareFunc);

  let targetWidth = width;
  if (shouldSnap(leftGaps[0][1]) && shouldSnap(rightGaps[0][1])) {
    targetWidth +=
      fromMeasureInPx(leftGaps[0][1]) + fromMeasureInPx(rightGaps[0][1]);
  }
  let targetHeight = height;
  if (shouldSnap(topGaps[0][1]) && shouldSnap(bottomGaps[0][1])) {
    targetHeight +=
      fromMeasureInPx(topGaps[0][1]) + fromMeasureInPx(bottomGaps[0][1]);
  }
  let targetTop = top;
  if (shouldSnap(topGaps[0][1])) {
    const relativeComponent = findComponent(topGaps[0][0]);
    targetTop = relativeComponent
      ? relativeComponent.top + relativeComponent.height
      : 0;
  } else if (shouldSnap(bottomGaps[0][1])) {
    const relativeComponent = findComponent(bottomGaps[0][0]);
    targetTop = relativeComponent
      ? relativeComponent.top - targetHeight
      : fromMeasureInPx(contentHeight) - targetHeight;
  }
  let targetLeft = left;
  if (shouldSnap(leftGaps[0][1])) {
    const relativeComponent = findComponent(leftGaps[0][0]);
    targetLeft = relativeComponent
      ? relativeComponent.left + relativeComponent.width
      : 0;
  } else if (shouldSnap(rightGaps[0][1])) {
    const relativeComponent = findComponent(rightGaps[0][0]);
    targetLeft = relativeComponent
      ? relativeComponent.left - targetWidth
      : fromMeasureInPx(contentWidth) - targetWidth;
  }
  return {
    width: targetWidth,
    height: targetHeight,
    top: targetTop,
    left: targetLeft,
  };
}
