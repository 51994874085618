<template>
  <modal :show="src" @close="close" :half="true">
    <div class="modal-body">
      <div v-if="loading && type == 'image'" class="text-center py-4">
        <i class="spinner-border spinner-border-lg"></i>
      </div>
      <div
        class="align-items-center justify-content-center"
        :class="{
          'd-none': loading && type == 'image',
          'd-flex flex-column': !loading || type !== 'image',
        }"
      >
        <img
          v-if="type == 'image'"
          :src="src"
          @load="() => (loading = false)"
          loading="eager"
        />
        <audio
          controls
          :src="src"
          ref="audioPlayer"
          v-show="type == 'audio'"
          class="contain"
        >
          Your device does not support the
          <code>audio</code> element.
        </audio>
        <div
          v-if="type == 'audio' && !canPlayAudio"
          class="alert alert-warning mb-0 mt-3"
        >
          Device does not support playing this audio file<br />
          <a :href="src" download class="text-warning">
            <i class="fas fa-file-download"></i> Download here
          </a>
        </div>
        <video
          v-show="type == 'video'"
          controls
          :src="src"
          ref="videoPlayer"
          class="contain"
        />
        <div
          v-if="type == 'video' && !canPlayVideo"
          class="alert alert-warning mb-0 mt-3"
        >
          Device does not support playing this video file<br />
          <a :href="src" download class="text-warning">
            <i class="fas fa-file-download"></i> Download here
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal.vue';

export default {
  name: 'MediaModal',
  props: {
    type: {
      required: false,
      default: 'image',
      type: String,
    },
    src: {
      required: true,
      type: String,
    },
  },
  watch: {
    type() {
      this.setup();
    },
  },
  data: () => ({
    loading: true,
    canPlayAudio: false,
    canPlayVideo: false,
    mimeType: null,
  }),
  components: {
    Modal,
  },
  mounted() {
    this.setup();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    setup() {
      this.canPlayAudio = false;
      this.canPlayVideo = false;
      if (this.type == 'image') this.loading = true;
      if (this.type != 'image') {
        this.mimeType = `${this.type}/${this.src
          .split('.')
          .at(-1)
          .toLowerCase()}`;
      } else {
        this.mimeType = null;
      }
      if (this.type == 'audio') {
        if (this.$refs.audioPlayer.canPlayType(this.mimeType)) {
          this.canPlayAudio = true;
        } else {
          this.canPlayAudio = false;
        }
      }
      if (this.type == 'video') {
        if (this.$refs.videoPlayer.canPlayType(this.mimeType)) {
          this.canPlayVideo = true;
        } else {
          this.canPlayVideo = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.modal-body img {
  max-width: 100%;
}

.contain {
  max-width: 100%;
  max-height: 50%;
}
</style>
