import { Map } from 'ol';
import LineString from 'ol/geom/LineString';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import type { StyleLike } from 'ol/style/Style';
import { getStoreApi } from '../../common/store-api';
import {
  appendAsterisk,
  convertTextToLines,
  wrapText,
} from '../../common/utils';
import { getLayoutZoom } from '../../measurement/layout';
import { getFont } from '../../style/text';
import { LABEL_SIZE_ADJUST_FACTOR } from './getDefaultSampleStyle';
import getSampleStyle from './getSampleStyle';
import type { SampleGroup, SampleLayer } from './types';
import { getSampleIcon, getSampleTitle, preloadedSampleIcons } from './utils';

export default function createSampleStyleFunction(
  map: Map,
  layer: SampleLayer
): StyleLike {
  return (feature, resolution) => {
    if (feature.get('isHidden')) {
      return undefined;
    }

    const id = feature.getId()!;
    const storeApi = getStoreApi(map);
    const sample = storeApi.findSampleById(id)!;
    const zoom = getLayoutZoom(map);

    // Sample could be null when switching to a different figure
    if (!sample) {
      return undefined;
    }

    const figure = storeApi.getSelectedFigure()!;
    const sampleStyle = getSampleStyle(map, figure, sample);
    const {
      icon,
      color,
      iconSize,
      iconRotation,
      iconOpacity,
      labelColor,
      labelShadowColor,
      labelSize,
      isLabelHidden,
      isDuplicateLabelHidden,
      isLabelUnderlined,
      isLabelAsteriskAppended,
      width,
    } = sampleStyle;
    const { src, size } = getSampleIcon(
      icon,
      color,
      iconSize * zoom,
      iconSize * zoom
    );

    let iconOptions: object = {
      size,
      rotation: (iconRotation / 180) * Math.PI,
      opacity: iconOpacity,
    };
    if (preloadedSampleIcons[src]) {
      iconOptions = {
        ...iconOptions,
        img: preloadedSampleIcons[src],
        imgSize: size,
      };
    } else {
      iconOptions = { ...iconOptions, src };
    }
    const iconStyle = new Style({
      image: new Icon(iconOptions),
      fill: new Fill({
        color,
      }),
      stroke: new Stroke({
        color,
      }),
    });

    if (isLabelHidden) {
      return [iconStyle];
    }

    const maxWidth = width! * zoom;
    const [offsetX, offsetY] = layer.getLabelOffsets(sample);
    const fontSize = Math.round(labelSize * LABEL_SIZE_ADJUST_FACTOR * zoom);
    const font = getFont(fontSize);
    let sampleTitle = getSampleTitle(sample);
    if (isLabelAsteriskAppended) {
      sampleTitle = appendAsterisk(sampleTitle);
    }
    const isDupVisible = !!sample.duplicate && !isDuplicateLabelHidden;

    let labelStyle;
    if (!isLabelUnderlined && !isDupVisible) {
      labelStyle = new Style({
        text: new Text({
          font,
          text: wrapText(sampleTitle, font, maxWidth),
          textBaseline: 'top',
          offsetX,
          offsetY,
          fill: new Fill({
            color: labelColor,
          }),
          stroke: new Stroke({
            color: labelShadowColor,
            width: 3 * zoom,
          }),
        }),
      });
    } else {
      labelStyle = new Style({
        renderer(pixels, state) {
          const pixel = pixels as number[];
          const { context: ctx } = state;
          const lineHeight = fontSize * 1.4;

          ctx.save();

          ctx.font = font;
          ctx.lineCap = 'round';
          ctx.lineJoin = 'round';

          // Split text into lines
          const lines = convertTextToLines(ctx, sampleTitle, maxWidth);
          const dupLines = isDupVisible
            ? convertTextToLines(
                ctx,
                getSampleTitle(sample.duplicate!),
                maxWidth
              )
            : [];

          // Calculate starting y of text
          let y = pixel[1] + offsetY + fontSize;

          // Draw each line of text
          [...lines, ...dupLines].forEach(function (line, idx) {
            ctx.fillStyle = idx < lines.length ? labelColor : '#ad4dfd';
            ctx.strokeStyle = labelShadowColor;
            ctx.lineWidth = 3 * zoom;
            // Draw line
            const { width: lineWidth } = ctx.measureText(line);
            const x = pixel[0] + offsetX - lineWidth / 2;
            ctx.strokeText(line, x, y);
            ctx.fillText(line, x, y);

            // Draw underline stroke
            const underlineY = y + 5 * zoom; // Adjust this value as needed
            ctx.beginPath();
            ctx.moveTo(x, underlineY);
            ctx.lineTo(x + lineWidth, underlineY);
            ctx.stroke();

            // Draw underline
            ctx.strokeStyle = labelColor;
            ctx.lineWidth = 1 * zoom;
            ctx.stroke();

            // Move to next line
            y += lineHeight;
          });

          ctx.restore();
        },
      });
    }

    let connectorStyle;
    const sampleGroup = storeApi.getSampleLayerModel(sample) as SampleGroup;
    if (sampleGroup?.geojson.properties.showDetatchedConnectors) {
      const position = layer.getPosition(sample);
      const labelPosition = layer.getLabelPosition(sample);
      if (position && labelPosition) {
        connectorStyle = new Style({
          geometry: new LineString([position, labelPosition]),
          stroke: new Stroke({
            color: labelColor,
            width: 2 * zoom,
          }),
        });
      }
    }

    return [iconStyle, labelStyle, connectorStyle].filter((s) => !!s);
  };
}
