import { transformExtent } from 'ol/proj';
import { Tile as TileLayer } from 'ol/layer';
import { XYZ } from 'ol/source';
import { assignIdToLayer } from '../layers/LayerManager';
import enableLoadingEvents from './enableLoadingEvents';
import * as utils from '../utils';

export default function createXyzTilesServiceLayer(map, options) {
  const { url, attributions, shouldUseCorsProxy } = options;
  const visibleExtent = transformExtent(
    options.visibleExtent,
    'EPSG:4326',
    map.getView().getProjection()
  );

  const layer = new TileLayer({
    source: new XYZ({
      crossOrigin: 'anonymous',
      url: shouldUseCorsProxy ? utils.network.proxify(url) : url,
      attributions,
    }),
    extent: visibleExtent,
  });
  assignIdToLayer(layer);
  layer.options = options;
  layer.hasFeature = function (feature) {
    return false;
  };

  enableLoadingEvents(layer);

  return layer;
}
