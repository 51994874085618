const UPDATE_LOADERS = 'maps/analysis/updateLoaders';

export const MODULE_NAMES = {
  HEATMAP: 'heatmap',
  BUFFER: 'buffer',
};

export const withLoadingForAction =
  (action, moduleName, actionType) => async (context, payload) => {
    const { loaders } = context.rootState.maps.analysis;
    if (loaders[moduleName][actionType]) {
      return await action(context, payload);
    }

    try {
      context.dispatch(
        UPDATE_LOADERS,
        { moduleName, actionType, isLoading: true },
        { root: true }
      );
      return await action(context, payload);
    } finally {
      context.dispatch(
        UPDATE_LOADERS,
        { moduleName, actionType, isLoading: false },
        { root: true }
      );
    }
  };

export const withLoadingForFunc =
  (store, fn, moduleName, actionType) =>
  async (...args) => {
    const { loaders } = store.state.maps.analysis;
    if (loaders[moduleName][actionType]) {
      return await fn(...args);
    }

    try {
      store.dispatch(UPDATE_LOADERS, {
        moduleName,
        actionType,
        isLoading: true,
      });
      return await fn(...args);
    } finally {
      store.dispatch(UPDATE_LOADERS, {
        moduleName,
        actionType,
        isLoading: false,
      });
    }
  };
