import bearer from '@websanova/vue-auth/dist/drivers/auth/bearer';
import axios from '@websanova/vue-auth/dist/drivers/http/axios.1.x';
import router from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x';
import { setAccessList } from '@component-library/feature-manager';
import { postAnalyticsUserIdentificationEvent } from '@component-library/analytics';
import makeId from '@component-library/local-id.mjs';

const config = {
  drivers: {
    auth: bearer,
    http: axios,
    router: router,
  },
  options: {
    tokenDefaultKey: 'envirotool',
    tokenImpersonateKey: 'envirotool_impersonator',
    stores: ['storage', 'cookie'],
    rolesVar: 'role',
    loginData: {
      url: '/auth/login',
      method: 'POST',
      redirect: false,
      fetchUser: false,
    },
    logoutData: {
      url: '/auth/logout',
      method: 'POST',
      redirect: false,
      makeRequest: true,
    },
    fetchData: {
      url: '/auth/user',
      method: 'GET',
      enabled: true,
    },
    refreshData: {
      url: '/auth/refresh',
      method: 'POST',
      enabled: false,
      interval: 30,
    },
    unimpersonateData: {
      url: '/auth/impersonate/leave',
      method: 'POST',
      makeRequest: true,
    },
    authRedirect: {
      path: '/login',
    },
    parseUserData: (data) => {
      const user = data.user_data || data.user;

      setAccessList(data.access_list || [], user.user_id);

      if (!user) {
        // Think this happens during the login request, before the follow up request to /auth/user
        console.warn('No user data returned in parse user data', data);
        return null;
      }

      if (data.subscription_data) {
        localStorage.setItem(
          'subscription',
          JSON.stringify(data.subscription_data)
        );
      }
      localStorage.setItem('tenant', JSON.stringify(data.tenant));
      localStorage.setItem('user', JSON.stringify(user));
      if (!localStorage.getItem('hub-user-' + user.enad_ref)) {
        localStorage.setItem('hub-user-' + user.enad_ref, makeId());
      }
      user &&
        postAnalyticsUserIdentificationEvent({
          email: user.email,
          enad_ref: user.enad_ref,
          user_name: user.name,
          customer_ref: user.customer_ref,
          company_name: user.company?.company_name,
        });

      return user;
    },
  },
};

export default config;
