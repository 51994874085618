import { Control } from 'ol/control';

const LoadingSpinner = (function (Control) {
  /**
   * This control is used to indicate that something is being done on the map.
   * @param {Object} opt_options
   */
  function LoadingSpinner(opt_options) {
    const options = opt_options || { isScaled: false };

    const element = document.createElement('div');
    element.setAttribute('class', 'ol-control');

    const { isScaled } = options;
    const className = this.getClassName_(isScaled);
    element.innerHTML = `<div class="${className}">\
                  <span class="sr-only">Loading...</span>\
              </div>`;
    options.element = element;
    Control.call(this, options);
  }

  if (Control) {
    LoadingSpinner.__proto__ = Control;
  }

  LoadingSpinner.prototype = Object.create(Control && Control.prototype);
  LoadingSpinner.prototype.constructor = LoadingSpinner;
  LoadingSpinner.prototype.getClassName_ = function (isScaled) {
    return isScaled
      ? 'spinner-border spinner-border-lg'
      : 'spinner-border spinner-border-sm';
  };
  LoadingSpinner.prototype.refresh = function () {
    const map = this.getMap();
    if (!map) {
      return;
    }

    const isScaled = map.getViewer().figureLayout.isExportingOnPrintPaper;
    const className = this.getClassName_(isScaled);
    this.element.childNodes[0].setAttribute('class', className);
  };

  return LoadingSpinner;
})(Control);

export default LoadingSpinner;
