export enum Dpi {
  Screen = 96,
  PrinterPaper = 300,
  // Refer to https://gis.stackexchange.com/questions/29671/mathematics-behind-converting-scale-to-resolution
  // to understand the fomula used to calculate the resolution.
  WMTS = 25.4 / 0.28,
}

export enum Unit {
  Pixel = 1,
  Millimeter = 2,
  Inch = 3,
  Point = 4,
}

export type Size = [number, number];

export const INCHES_PER_MILLIMETER = 1 / 25.4;
export const INCHES_PER_POINT = 1 / 72;

export function getPixelsPerUnit(unit: Unit, dpi: Dpi): number {
  if (unit === Unit.Pixel) {
    return 1;
  } else if (unit === Unit.Millimeter) {
    return INCHES_PER_MILLIMETER * dpi;
  } else if (unit === Unit.Point) {
    return INCHES_PER_POINT * dpi;
  } else if (unit === Unit.Inch) {
    return dpi;
  }

  throw `Unit ${unit} is not supported.`;
}

export function transformMeasure(
  measure: number,
  sourceDpi: Dpi,
  targetDpi: Dpi,
  sourceUnit: Unit,
  targetUnit: Unit
): number {
  if (sourceUnit === targetUnit) {
    return measure;
  }

  const measureInPx = measure * getPixelsPerUnit(sourceUnit, sourceDpi);
  if (targetUnit === Unit.Pixel) {
    return measureInPx;
  } else if (targetUnit === Unit.Millimeter) {
    return measureInPx / (INCHES_PER_MILLIMETER * targetDpi);
  } else if (targetUnit === Unit.Point) {
    return measureInPx / (INCHES_PER_POINT * targetDpi);
  } else if (targetUnit === Unit.Inch) {
    return measureInPx / targetDpi;
  }

  throw `Target unit ${targetUnit} is not supported.`;
}

export function getRatioOfHeightToWidth(size: Size): number {
  const [width, height] = size;
  return height / width;
}
