import {
  VectorImage as VectorImageLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { toGeoJSON } from '../utils';
import { assignIdToLayer } from './LayerManager';

export default function createVectorLayer(
  map,
  features = [],
  style = null,
  isRenderedAsImage = false
) {
  const source = new VectorSource({ features });
  const layer = !isRenderedAsImage
    ? new VectorLayer({ source })
    : new VectorImageLayer({ source });
  assignIdToLayer(layer);

  if (style) {
    layer.setStyle(style);
  }

  layer.getFeatures = function () {
    return source.getFeatures();
  };

  layer.addFeature = function (feature) {
    this.getSource().addFeature(feature);
  };

  layer.removeFeature = function (feature) {
    this.getSource().removeFeature(feature);
  };

  layer.clear = function () {
    this.getSource().clear();
  };

  layer.toGeoJSON = function () {
    const features = this.getSource().getFeatures();
    return toGeoJSON(map, features);
  };

  return layer;
}
