import { appendAsterisk } from '../../../lib/olbm/common/utils';
import { getSampleIcon } from '../../../lib/olbm/layer/sample/utils';
import { createTextShadow } from '../../../view-utils';
import { applyStyle } from '../styles';
import * as coordinate from './coordinate';

export function createSampleElement(options) {
  const {
    id,
    isDefault,
    isIconVisible,
    icon,
    color,
    isLabelHidden,
    isDuplicateLabelHidden,
    title,
    duplicate,
    width,
    isLabelAsteriskAppended,
  } = options;
  const sampleEl = document.createElement('div');
  sampleEl.setAttribute(
    'class',
    'd-flex flex-column align-items-center sample'
  );
  sampleEl.setAttribute(
    'style',
    `position: absolute; width: ${width}px; word-break: break-word; transform-origin: left top;`
  );
  sampleEl.setAttribute('data-sample-id', id);
  sampleEl.setAttribute('data-is-default', isDefault);

  // Handle the sample icon
  if (isIconVisible) {
    const iconEl = document.createElement('div');
    iconEl.setAttribute('class', 'marker-image');
    const svgEl = document.createElement('img');
    const { src } = getSampleIcon(icon, color);
    svgEl.src = src;
    iconEl.appendChild(svgEl);
    sampleEl.appendChild(iconEl);
  }

  // Handle the sample label
  if (!isLabelHidden) {
    const labelEl = document.createElement('span');
    labelEl.setAttribute('id', 'sample-title');
    labelEl.setAttribute('style', 'transform-origin: left top;');

    const titleEl = document.createTextNode(
      isLabelAsteriskAppended ? appendAsterisk(title) : title
    );
    labelEl.appendChild(titleEl);

    if (duplicate && !isDuplicateLabelHidden) {
      const {
        custom_title: duplicateCustomTitle,
        lab_title: duplicateLabTitle,
      } = duplicate;

      const duplicateTitleEl = document.createElement('span');
      duplicateTitleEl.setAttribute('class', 'duplicate');
      duplicateTitleEl.innerHTML = duplicateCustomTitle || duplicateLabTitle;
      labelEl.appendChild(duplicateTitleEl);
    }

    sampleEl.appendChild(labelEl);
  }

  return sampleEl;
}

export function updateSampleElement(sampleEl, options) {
  const {
    isIconVisible,
    iconSize,
    iconRotation,
    iconOpacity,
    isLabelHidden,
    exceedanceColor,
    labelColor,
    labelShadowColor,
    isLabelUnderlined,
    labelSize,
    width,
  } = options;
  // Update the style of the sample icon
  if (isIconVisible) {
    const iconEl = sampleEl.childNodes[0];

    const iconStyle = {
      width: `${iconSize}px`,
      height: `${iconSize}px`,
      opacity: iconOpacity,
      rotate: `${iconRotation}deg`,
    };

    applyStyle(iconEl, iconStyle);
  }

  // Update the style of the sample label
  if (!isLabelHidden) {
    const labelEl = sampleEl.childNodes[isIconVisible ? 1 : 0];
    labelEl.setAttribute('class', `custom-marker__title mt-1`);

    let labelStyle = {
      color: !isIconVisible && exceedanceColor ? exceedanceColor : labelColor,
      textShadow: createTextShadow(labelShadowColor),
      fontSize: `${labelSize}px`,
      width: `${width}px`,
      textDecoration: isLabelUnderlined ? 'underline' : 'none',
      textUnderlinePosition: 'under',
    };
    applyStyle(labelEl, labelStyle);
  }
}

export function getSampleCoordinate(map, sample) {
  const { latitude: lat, longitude: lng } = sample;
  return coordinate.latLngToCoordinate(
    {
      lat,
      lng,
    },
    map.getView().getProjection()
  );
}
