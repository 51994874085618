<template>
  <div class="block__grid" :class="'grid--' + type">
    <div
      class="grid__item"
      @click="$emit('createBlock', 'table')"
      v-if="isTypeSupported('table')"
    >
      <i class="fal fa-fw fa-table mb-0" />
      <small class="text-muted mt-1"
        ><b>Datanest / Custom</b> <br />Table</small
      >
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'insight')"
      v-if="isTypeSupported('insight')"
    >
      <i class="fal fa-fw fa-analytics mb-0" />
      <small class="text-muted mt-1">
        <b>Datanest</b> <br />
        Insights
      </small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'map')"
      v-if="isTypeSupported('map')"
    >
      <i class="fal fa-fw fa-map mb-0" />
      <small class="text-muted mt-1"><b>Datanest</b> <br />Maps</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'text')"
      v-if="isTypeSupported('text')"
    >
      <i class="fal fa-fw fa-text mb-0" />
      <small class="text-muted mt-1">Text</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'image')"
      v-if="isTypeSupported('image')"
    >
      <i class="fal fa-fw fa-image mb-0" />
      <small class="text-muted mt-1">Image / GIF / 3D</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'audio')"
      v-if="isTypeSupported('audio')"
    >
      <i class="fal fa-fw fa-volume-up mb-0" />
      <small class="text-muted mt-1">Audio</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'video')"
      v-if="isTypeSupported('video')"
    >
      <i class="fal fa-fw fa-video mb-0" />
      <small class="text-muted mt-1">Video</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'attachment')"
      v-if="isTypeSupported('attachment')"
    >
      <i class="fal fa-fw fa-file mb-0" />
      <small class="text-muted mt-1">Attachments</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'iframe')"
      v-if="isTypeSupported('iframe')"
    >
      <i class="fal fa-fw fa-window mb-0" />
      <small class="text-muted mt-1">iFrame</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'seperator')"
      v-if="isTypeSupported('seperator')"
    >
      <i class="fal fa-fw fa-horizontal-rule mb-0" />
      <small class="text-muted mt-1">Separator</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'button')"
      v-if="isTypeSupported('button')"
    >
      <i class="fal fa-fw fa-mouse-alt mb-0" />
      <small class="text-muted mt-1">Button</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'slideshow')"
      v-if="isTypeSupported('slideshow')"
    >
      <i class="fal fa-fw fa-presentation mb-0" />
      <small class="text-muted mt-1">Chapter</small>
    </div>

    <div
      class="grid__item"
      @click="$emit('createBlock', 'coverpage')"
      v-if="isTypeSupported('coverpage')"
    >
      <i class="fal fa-fw fa-presentation mb-0" />
      <small class="text-muted mt-1">Coverpage</small>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: String,
  },
  computed: {
    supportedTypes() {
      if (!this.type) {
        return [
          'text',
          'image',
          'audio',
          'video',
          'attachment',
          'iframe',
          'table',
          'seperator',
          'button',
          'slideshow',
          'insight',
          'map',
        ];
      }

      if (this.type == 'figure') {
        return [
          'image',
          'audio',
          'video',
          'table',
          'insight',
          'map',
          'coverpage',
          'iframe',
        ];
      }

      if (this.type == 'slideshow') {
        return [
          'text',
          'image',
          'seperator',
          'button',
          'audio',
          'video',
          'table',
          'attachment',
          'iframe',
        ];
      }

      if (this.type == 'image' || this.type == 'map') {
        return ['text', 'audio', 'seperator', 'button'];
      }
    },
  },
  methods: {
    isTypeSupported(type) {
      if (!this.supportedTypes) {
        return true;
      }

      return this.supportedTypes.includes(type);
    },
  },
};
</script>
<style lang="scss" scoped>
.block__grid {
  display: grid;
  grid-template-columns: 10em 10em 10em 10em;
  grid-template-rows: 8em 8em 8em;
  grid-gap: 1em;
}

.block__grid.grid--figure {
  grid-template-columns: 33% 33% 33%;
  grid-template-rows: 8em 8em 8em;
  .grid__item:last-child {
    grid-column: span 2;
  }
}

.block__grid.grid--slideshow {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 7em 7em 7em 7em 7em;

  .grid__item:last-child {
    grid-column: span 2;
  }
}

.block__grid.grid--image,
.block__grid.grid--map {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 7em 7em;
}

.grid__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f8f9fa;
  border-bottom: 3px solid #f8f9fa;
  cursor: pointer;
  transition: 0.25s ease-in all;
  text-align: center;
  border-radius: 0.5rem;
}

.grid__item i {
  opacity: 0.8;
  font-size: 1.75rem;
}

.grid__item:hover {
  border-bottom-color: #212121;
}
</style>
