<template>
  <div class="flex-column" :id="id">
    <div v-if="editingByValue && !isVisible" class="alert alert-warning mb-0">
      Condition was not met, not editable.
    </div>
    <div
      style="display: block; visibility: hidden; height: 0; width: 0"
      :style="{ 'scroll-margin-top': normalizedCssFieldScrollMarginTop }"
      :id="fieldAnchorId"
    ></div>
    <component
      v-show="isVisible"
      :is="tagName"
      :field="field"
      :item="sample"
      :inputValue="inputValue"
      :inputValues="inputValues"
      :lastValue="lastValue"
      :sectionIndex="sectionIndex"
      :defaultValue="defaultValue || dValue"
      :groupIndex="hasGroup"
      :fields="fields"
      :allFields="allFields"
      :allSections="allSections"
      :repeatable="!!repeatable"
      :isAutoAssignEnabled="isAutoAssignEnabled"
      :isAutoAssignActive="isAutoAssignActive"
      :isDisabled="isDisabled"
      :sampleIdentifier="sampleIdentifier"
      :isVisible="isVisible"
      @isLoading="isLoading"
      @input="$emit('input')"
      @clickCamera="clickCamera"
      @clickVideo="clickVideo"
      @clickSetPreview="clickSetPreview"
      @clickStartDrawing="clickStartDrawing"
    />

    <div v-if="isVisible && isAutoAssignEnabled" class="d-flex mt-2">
      <label :for="checkboxId" class="form-label me-2 mb-0"> Edit </label>
      <div class="form-switch">
        <input
          type="checkbox"
          class="form-check-input me-2"
          :id="checkboxId"
          v-model="isAutoAssignActive"
        />
        <label :for="checkboxId">Read-only</label>
        <InfoButton
          class="ms-2"
          :backgroundColor="formContext?.theme.primaryColor"
          info="Switch this option off to edit the value. Leave it on to use the automatic value."
          :container="`#${id}`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoButton from '../components/InfoButton.vue';
import {
  FieldTypeIds,
  checkIsConditionMet,
  getDefaultValue,
  getFieldAnchorId,
  hasGroup,
} from '../fields';
import makeId from '../local-id.mjs';
import {
  components as fieldComponents,
  getComponentNameFromId,
} from './fields';

export default {
  components: { ...fieldComponents, InfoButton },
  props: [
    'id',
    'field',
    'inputValue',
    'inputValues',
    'editingByValue',
    'lastValue',
    'sectionIndex',
    'sample',
    'fields',
    'allFields',
    'allSections',
    'templateTabId',
    'repeatable',
    'search',
    'sectionCount',
    'sampleIdentifier',
    'cssFieldScrollMarginTop',
  ],
  inject: ['formContext'],
  data: () => ({
    dValue: null,
    checkboxId: makeId(),
  }),
  computed: {
    tagName() {
      return getComponentNameFromId(this.field.field_type_id);
    },
    conditionIsMet() {
      return checkIsConditionMet(
        this.field,
        this.inputValues,
        this.sectionIndex
      );
    },
    matchesSearch() {
      if (!this.search) {
        return true;
      }

      const search = this.search.toLowerCase();
      const value = this.inputValue.value
        ? this.inputValue.value.toLowerCase()
        : '';
      const value2 = this.inputValue.value2
        ? this.inputValue.value2.toLowerCase()
        : '';

      return (
        value.includes(search) ||
        value2.includes(search) ||
        this.field.label?.toLowerCase().includes(search)
      );
    },
    isVisible() {
      return this.conditionIsMet && this.matchesSearch;
    },
    defaultValue() {
      return getDefaultValue(
        this.field,
        this.inputValues,
        this.sectionIndex,
        (newValue) => {
          this.dValue = newValue;
        }
      );
    },
    hasGroup() {
      return hasGroup(this.field, this.inputValues, this.sectionIndex);
    },
    isAutoAssignEnabled() {
      return this.field.options?.defaults?.length > 0;
    },
    isAutoAssignActive: {
      get() {
        return this.inputValue?.options?.isAutoAssignActive ?? true;
      },
      set(value) {
        this.$root.$emit('updateInputValue', {
          inputValue: {
            ...this.inputValue,
            options: { ...this.inputValue.options, isAutoAssignActive: value },
          },
          field: this.inputValue.template_field_id,
          sectionIndex: this.inputValue.template_section_index,
          templateTabId: this.inputValue.template_tab_id,
        });
      },
    },
    isDisabled() {
      return (
        this.field.options.is_readonly ||
        this.inputValue?.options?.disabled ||
        (this.isAutoAssignEnabled && this.isAutoAssignActive)
      );
    },
    fieldAnchorId() {
      return getFieldAnchorId(this.field.label);
    },
    normalizedCssFieldScrollMarginTop() {
      return this.cssFieldScrollMarginTop ?? '0px';
    },
  },
  methods: {
    clickCamera(data) {
      this.$emit('clickCamera', data);
    },
    clickVideo(data) {
      this.$emit('clickVideo', data);
    },
    clickSetPreview(data) {
      this.$emit('clickSetPreview', data);
    },
    clickStartDrawing(data) {
      this.$emit('clickStartDrawing', data);
    },
    isLoading(value) {
      this.$emit('isLoading', value);
    },
    checkShouldBeVisible() {
      if (
        this.isVisible ||
        ![
          FieldTypeIds.DROPDOWN,
          FieldTypeIds.DUPLICATE,
          FieldTypeIds.TRIPLICATE,
        ].includes(this.field.field_type_id)
      ) {
        return;
      }

      const { should_clear_selection_on_invisible } = this.field.options;
      if (
        should_clear_selection_on_invisible ||
        [FieldTypeIds.DUPLICATE, FieldTypeIds.TRIPLICATE].includes(
          this.field.field_type_id
        )
      ) {
        this.$root.$emit('updateInputValue', {
          inputValue: { ...this.inputValue, value: null, value2: null },
          field: this.field.id,
          sectionIndex: this.sectionIndex,
          templateTabId: this.templateTabId,
        });
      }
    },
  },
  watch: {
    'inputValue.value'() {
      this.$emit('input');
    },
    'inputValue.options'() {
      this.$emit('input');
    },
    'inputValue.value2'() {
      this.$emit('input');
    },
    isVisible(newValue) {
      this.checkShouldBeVisible();
    },
  },
  mounted() {
    this.checkShouldBeVisible();
  },
};
</script>
