import * as bl_medium from './medium';

export enum FigureExportType {
  Classic = 1,
  ImageLayer,
}

export enum FigureExportFormat {
  Pdf = 'pdf',
  Jpg = 'jpg',
  Png = 'png',
}

export enum FigureExportingStage {
  Start,
  CheckJobs,
  CreateJob,
  AdjustFigure,
  GenerateImage,
  UploadImage,
  AddLayer,
}

export type FigureExportingAction = {
  type: FigureExportType;
  format: FigureExportFormat;
  extent: [number, number, number, number];
  stage: FigureExportingStage;
  isFigureReady: boolean;
};

export const FIGURE_EXPORT_FORMATS_BY_MEDIUM_TYPE = {
  [bl_medium.MediumType.Screen]: [
    FigureExportFormat.Jpg,
    FigureExportFormat.Png,
  ],
  [bl_medium.MediumType.A4]: [
    FigureExportFormat.Pdf,
    FigureExportFormat.Jpg,
    FigureExportFormat.Png,
  ],
  [bl_medium.MediumType.A3]: [
    FigureExportFormat.Pdf,
    FigureExportFormat.Jpg,
    FigureExportFormat.Png,
  ],
  [bl_medium.MediumType.ANSI_A]: [
    FigureExportFormat.Pdf,
    FigureExportFormat.Jpg,
    FigureExportFormat.Png,
  ],
  [bl_medium.MediumType.ANSI_B]: [
    FigureExportFormat.Pdf,
    FigureExportFormat.Jpg,
    FigureExportFormat.Png,
  ],
};
