import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

const state = {
  processed_information: null,
  processed_settings: null,
  scenarios: [],
  display_exceedance_table: false,
  has_insights: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
