import globals from '../globals.js';
import store from '../store/index.js';
import {
  getMatrixStylingByKey,
  getMatrixTypesByCountry,
} from '../helpers/matrix.ts';
import {
  getMeasurementLongTitle,
  getMeasurementShortTitle,
} from '../helpers/measurement.js';
import { formatNumberWithCommas } from '../helpers/result.js';
import { formatSampleDepth, formatSampleTitle } from '../helpers/sample.js';
import { getDocumentTitle } from '../helpers/scenario.js';
import { getProjectLastUpdated } from '@component-library/utils';
import useDate from '../composables/useDate.js';
import { countryCodeToFull } from '../helpers/country';

const { convertDate } = useDate();

export default {
  data: () => ({
    globals,
  }),
  methods: {
    getListOfMatrixTypes() {
      return getMatrixTypesByCountry(store.getters.get_project_country);
    },
    formatNumberWithCommas(x, toDecimalPlace = null) {
      return formatNumberWithCommas(x, toDecimalPlace);
    },
    isAdmin() {
      const user = this.$auth.user();
      return user != null && user.role == 'admin';
    },
    convertDate(date, withTime = false) {
      return convertDate(date, withTime);
    },
    getProjectMeasurementUnitShort() {
      return getMeasurementShortTitle(
        store.getters.get_project_field_by_key('measurement_type')
      );
    },
    getProjectMeasurementUnitLong() {
      return getMeasurementLongTitle(
        store.getters.get_project_field_by_key('measurement_type')
      );
    },
    formatSampleTitle(sample) {
      return formatSampleTitle(sample);
    },
    formatSampleDepth(sample, showMeasurementType = true) {
      return formatSampleDepth(
        sample,
        store.getters.get_project_field_by_key('measurement_type'),
        showMeasurementType
      );
    },
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    countryCodeToFull(code) {
      return countryCodeToFull(code);
    },
    getMatrixStylingByKey,
    getProjectLastUpdated(updatedAt) {
      return getProjectLastUpdated(updatedAt);
    },
    getDocumentTitle(data, use_shorthand = false) {
      return getDocumentTitle(data, use_shorthand);
    },
  },
};
