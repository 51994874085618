<template>
  <div
    class="dashboard w-100"
    :class="{
      empty: dashboardEmpty,
      'h-100': controlled,
      'overflow-hidden': controlled,
    }"
    ref="dashboardRef"
  >
    <div v-if="dashboardEmpty" class="get-started-message">
      <h1 class="fal fa-analytics"></h1>
      <h2 class="fw-bolder">Use tools on the sidebar to get started!</h2>
      <h6 class="text-muted mb-0">
        It appears that you have not added any items yet.
      </h6>
    </div>
    <GridLayout
      v-else-if="dashboard"
      class="dashboard-layout w-100"
      :class="{ 'h-100': controlled }"
      :layout.sync="dashboardLayout"
      :col-num="dashboard.config.colNum"
      :row-height="adaptedRowHeight"
      :is-draggable="dashboard.config.isDraggable"
      :is-resizable="dashboard.config.isResizable"
      :is-mirrored="dashboard.config.isMirrored"
      :vertical-compact="dashboard.config.isVerticalCompact"
      :margin="[dashboard.config.margin, dashboard.config.margin]"
      :use-css-transforms="dashboard.config.isCssTransformsUsed"
    >
      <GridItem
        v-for="item in dashboard.items"
        :x="item.layout.x"
        :y="item.layout.y"
        :w="item.layout.w"
        :h="item.layout.h"
        :i="item.layout.i"
        :is-draggable="insightsStore.isDashboardItemBeingEdited(item.id)"
        :is-resizable="insightsStore.isDashboardItemBeingEdited(item.id)"
        :key="item.layout.i"
        class="dashboard-item-layout"
        :class="{
          highlighted: insightsStore.isDashboardItemBeingEdited(item.id),
        }"
      >
        <DashboardItem
          :dashboardId="dashboard.id"
          :dashboardItemId="item.id"
          :readOnly="
            readOnly ||
            !!(
              insightsStore.selectedDashboardItem &&
              insightsStore.selectedDashboardItem?.id !== item.id
            )
          "
          :style="dashboardItemStyle"
          class="w-100 h-100 position-relative"
          @edit="(itemId) => emit('editDashboardItem', itemId)"
          @delete="(itemId) => emit('deleteDashboardItem', itemId)"
          @makeObserversBreathe="onMakeObserversBreathe"
          @makeEventSourcesBreathe="onMakeEventSourcesBreathe"
          @changeSelection="onChangeSelection"
          @changeBackgroundColor="onChangeBackgroundColor"
        />
      </GridItem>
    </GridLayout>
  </div>
</template>

<script lang="ts" setup>
import { GridLayout, GridItem } from 'vue-grid-layout';
import DashboardItem from './DashboardItem.vue';
import { DATA_INSIGHTS } from '@component-library/data-insights';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useInsightsStore } from '../../store/insights';

const props = withDefaults(
  defineProps<{
    id: number;
    readOnly?: boolean;
    controlled?: boolean;
    heightLimited?: boolean;
    context?: string;
  }>(),
  {
    controlled: true,
    context: DATA_INSIGHTS,
  }
);

const emit = defineEmits<{
  (event: 'editDashboardItem', id: number): void;
  (event: 'deleteDashboardItem', id: number): void;
}>();

const dashboardRef = ref<HTMLElement | null>(null);
const insightsStore = useInsightsStore();
const adaptedRowHeight = ref(0);
const dashboardObserver = ref<ResizeObserver | null>(null);

const dashboard = computed(() => {
  return insightsStore.findDashboardById(props.id);
});

const dashboardLayout = computed(() => {
  if (!dashboard.value) {
    return undefined;
  }
  return insightsStore.getDashboardLayout(dashboard.value);
});

const dashboardEmpty = computed(() => {
  return !dashboardLayout.value || dashboardLayout.value.length === 0;
});

const dashboardItemStyle = computed(() => {
  return props.readOnly ? {} : { padding: '8px' };
});

const autoRefreshIntervalId = computed(() => {
  return insightsStore.getAutoRefreshIntervalId(props.id);
});

const activeAutoRefreshConfigItem = computed(() => {
  return (
    dashboard.value?.autoRefreshConfig.find(
      (item) => item.context === props.context
    ) || {}
  );
});

function onMakeObserversBreathe(eventSourceId: number) {
  const eventSource = insightsStore.findDashboardItemById(
    props.id,
    eventSourceId
  );
  if (!eventSource) {
    throw new Error('onMakeObserversBreathe: Event source not found');
  }
  if (!dashboard.value) {
    throw new Error('onMakeObserversBreathe: Dashboard not found');
  }
  dashboard.value.items
    .filter((item) => {
      return item.options?.info?.eventSourceIds?.includes(eventSource.id);
    })
    .forEach((observer) => {
      insightsStore.updateDashboardRuntimeData({
        dashboardId: props.id,
        dashboardItemId: observer.id,
        update: { breathing: true },
      });
    });
}

function onMakeEventSourcesBreathe(observerId: number) {
  const observer = insightsStore.findDashboardItemById(props.id, observerId);
  if (!observer) {
    throw new Error('onMakeEventSourcesBreathe: Observer not found');
  }
  observer.options?.info?.eventSourceIds?.forEach((eventSourceId) => {
    insightsStore.updateDashboardRuntimeData({
      dashboardId: props.id,
      dashboardItemId: eventSourceId,
      update: { breathing: true },
    });
  });
}

function onChangeSelection(eventSourceId: number) {
  const observers = insightsStore.getObservers(props.id, eventSourceId);
  observers.forEach((observer) => {
    insightsStore.updateDashboardRuntimeData({
      dashboardId: props.id,
      dashboardItemId: observer.id,
      update: { hasOutdatedData: true },
    });
  });
}

function onChangeBackgroundColor(eventSourceId: number) {
  const observers = insightsStore.getObservers(props.id, eventSourceId);
  observers.forEach((observer) => {
    insightsStore.updateDashboardRuntimeData({
      dashboardId: props.id,
      dashboardItemId: observer.id,
      update: { checkBackgroundColor: true },
    });
  });
}

function startAutoRefresh(interval: number) {
  const { id: dashboardId } = props;
  const autoRefreshIntervalId = setInterval(() => {
    dashboard.value?.items.forEach((item) => {
      insightsStore.updateDashboardRuntimeData({
        dashboardId,
        dashboardItemId: item.id,
        update: { hasOutdatedData: true },
      });
    });
  }, interval * 1000);
  insightsStore.setAutoRefreshIntervalId({
    dashboardId,
    autoRefreshIntervalId,
  });
}

function stopAutoRefresh(
  dashboardId: number,
  autoRefreshIntervalId: NodeJS.Timer
) {
  clearInterval(autoRefreshIntervalId);
  insightsStore.setAutoRefreshIntervalId({
    dashboardId,
    autoRefreshIntervalId: null,
  });
}

watch(
  () => dashboard.value?.config,
  (config) => {
    adaptedRowHeight.value = config.rowHeight;
  }
);

watch(activeAutoRefreshConfigItem, (item) => {
  if (!item) {
    console.warn('activeAutoRefreshConfigItem is not found');
    return;
  }
  if (!autoRefreshIntervalId.value) {
    return;
  }
  const { interval, enabled } = activeAutoRefreshConfigItem.value;
  if (enabled) {
    stopAutoRefresh(props.id, autoRefreshIntervalId.value);
    startAutoRefresh(interval);
  } else {
    stopAutoRefresh(props.id, autoRefreshIntervalId.value);
  }
});

watch(
  () => props.id,
  (newId, oldId) => {
    if (!!oldId) {
      const oldAutoRefreshIntervalId =
        insightsStore.getAutoRefreshIntervalId(oldId);
      if (!!oldAutoRefreshIntervalId) {
        stopAutoRefresh(oldId, oldAutoRefreshIntervalId);
      }
    }

    const { enabled, interval } = activeAutoRefreshConfigItem.value;
    if (enabled && !autoRefreshIntervalId.value) {
      startAutoRefresh(interval);
    }
  }
);

onMounted(() => {
  if (!props.controlled) {
    dashboardObserver.value = new ResizeObserver((entries) => {
      if (!dashboardRef.value) {
        return;
      }
      if (!dashboard.value) {
        throw new Error('Dashboard not found');
      }
      for (let entry of entries) {
        if (entry.target === dashboardRef.value.parentElement) {
          const { width: contentWidth, height: contentHeight } = getContentSize(
            dashboardRef.value.parentElement
          );
          const dashboardPaddings = getPaddings(dashboardRef.value);
          const hPadding = dashboardPaddings.left + dashboardPaddings.right;
          const vPadding = dashboardPaddings.top + dashboardPaddings.bottom;
          const dashboardWidth = contentWidth - hPadding;
          const dashboardHeight = contentHeight - vPadding;
          const { colNum, rowNum, margin } = dashboard.value.config;
          const colWidth = (dashboardWidth - (colNum + 1) * margin) / colNum;
          const rowHeight =
            colWidth / (colWidth / dashboard.value.config.rowHeight);
          const hMargin = (colNum + 1) * margin;
          const vMargin = (rowNum + 1) * margin;
          const adaptedColWidth = (dashboardWidth - hMargin) / colNum;
          const newAdaptedRowHeight = rowHeight * (adaptedColWidth / colWidth);
          const adaptedDashboardHeight = newAdaptedRowHeight * rowNum + vMargin;
          if (props.heightLimited && dashboardHeight < adaptedDashboardHeight) {
            adaptedRowHeight.value = (dashboardHeight - vMargin) / rowNum;
          } else {
            adaptedRowHeight.value = newAdaptedRowHeight;
          }
        }
      }
    });
    if (dashboardRef.value?.parentElement) {
      dashboardObserver.value.observe(dashboardRef.value.parentElement);
    }
  } else {
    adaptedRowHeight.value = dashboard.value?.config.rowHeight;
  }

  const { enabled, interval } = activeAutoRefreshConfigItem.value || {};
  if (enabled && !autoRefreshIntervalId.value) {
    startAutoRefresh(interval);
  }
});
onBeforeUnmount(() => {
  if (!props.controlled) {
    dashboardObserver.value?.disconnect();
    dashboardObserver.value = null;
  }

  if (!!autoRefreshIntervalId.value) {
    stopAutoRefresh(props.id, autoRefreshIntervalId.value);
  }
});

function getPaddings(element) {
  const style = getComputedStyle(element);
  const top = parseInt(style.getPropertyValue('padding-top'), 10);
  const left = parseInt(style.getPropertyValue('padding-left'), 10);
  const bottom = parseInt(style.getPropertyValue('padding-bottom'), 10);
  const right = parseInt(style.getPropertyValue('padding-right'), 10);
  return {
    top,
    left,
    bottom,
    right,
  };
}

function getContentSize(element) {
  const paddings = getPaddings(element);
  const { width, height } = element.getBoundingClientRect();
  return {
    width: width - paddings.left - paddings.right,
    height: height - paddings.top - paddings.bottom,
  };
}
</script>

<style lang="scss" scoped>
.dashboard {
  &.empty {
    display: flex;
    justify-content: center;
    align-items: center;

    .get-started-message {
      display: inline-block;
      text-align: center;
    }
  }
  .dashboard-layout {
    .dashboard-item-layout {
      background: white !important;
      opacity: 0.8;
      border-radius: 6px;
      border-bottom: 1px solid rgba(57, 76, 96, 0.15);

      &.highlighted {
        box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
