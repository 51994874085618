export const FILL_PATTERN_SOLID = 1;
export const FILL_PATTERN_TRANSPARENT = 2;
export const FILL_PATTERN_HORIZONTAL_STRIPE = 3;
export const FILL_PATTERN_VERTICAL_STRIPE = 4;
export const FILL_PATTERN_DOT = 5;
export const FILL_PATTERN_CIRCLE = 6;
export const FILL_PATTERN_CROSS_45_DEG = 7;
export const FILL_PATTERN_STRIPE_45_DEG = 8;

export type FillPatternSize = [number, number];

export const FILL_PATTERN_DATA: Record<
  number,
  { title: string; size: FillPatternSize; color: string }
> = {
  [FILL_PATTERN_SOLID]: {
    title: 'Solid',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_TRANSPARENT]: {
    title: 'Transparent',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_HORIZONTAL_STRIPE]: {
    title: 'Horizontal stripe',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_VERTICAL_STRIPE]: {
    title: 'Vertical stripe',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_DOT]: {
    title: 'Dot',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_CIRCLE]: {
    title: 'Circle',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_CROSS_45_DEG]: {
    title: '45° Cross',
    size: [24, 24],
    color: '#000000',
  },
  [FILL_PATTERN_STRIPE_45_DEG]: {
    title: '45° Stripe',
    size: [24, 24],
    color: '#000000',
  },
};

export const STROKE_PATTERN_SOLID = 1;
export const STROKE_PATTERN_DASH = 2;
export const STROKE_PATTERN_DOT = 3;

export const STROKE_PATTERN_DATA = {
  [STROKE_PATTERN_SOLID]: {
    title: 'Solid',
    color: '#000000',
  },
  [STROKE_PATTERN_DASH]: {
    title: 'Dash',
    color: '#000000',
  },
  [STROKE_PATTERN_DOT]: {
    title: 'Dot',
    color: '#000000',
  },
};

export const FORM_CONTEXT_TYPE_GATHER = 1;
export const FORM_CONTEXT_TYPE_DATA_MANAGER = 2;
export const FORM_CONTEXT_TYPE_PUBLIC_FORM = 3;
