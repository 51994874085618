export function getThemeTemplate(created_by_id = null, company_id = null) {
  return {
    title: 'Untitled Theme',
    created_by_id,
    company_id,
    is_global: false,
    is_shared: false,
    is_default: false,

    background_color: '#ffffff',
    accent_color: '#3366a2',
    header_color: '#f4f4f4',
    heading_font: 'Poppins',
    heading_color: '#212529',
    paragraph_font: 'Poppins',
    paragraph_color: '#212529',

    footer_color: '#f4f4f4',
    footer_font_color: '#212529',
    paragraph_font_size: 0, //normal
    number_headings: true,
  };
}

export function createDefaultTheme(created_by_id = null, company_id = null) {
  return {
    ...getThemeTemplate(created_by_id, company_id),
    title: 'Default Theme',
    is_shared: true,
    is_default: true,
  };
}

export function getThemeVariables(theme) {
  const {
    background_color,
    accent_color,
    heading_color,
    heading_font,
    paragraph_color,
    paragraph_font,
    paragraph_font_size,
    footer_color,
    footer_font_color,
  } = theme || createDefaultTheme();

  return {
    '--background-color': background_color || '#ffffff',
    '--accent-color': accent_color || '#3366a2',
    '--accent-primary-field-color': accent_color ? accent_color + '60' : null,
    '--btn-text-color': accent_color
      ? _getColorByBgColor(accent_color)
      : 'initial',
    '--heading-font': heading_font,
    '--heading-color': heading_color || '#f4f4f4',
    '--paragraph-color': paragraph_color,
    '--paragraph-font': paragraph_font,
    '--paragraph-font-size': paragraph_font_size == 0 ? '13px' : '16px',
    '--footer-color': footer_color,
    '--footer-text-color': footer_font_color,
  };
}

function _getColorByBgColor(bgColor) {
  let color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;

  let r = parseInt(color.substring(0, 2), 16); // hexToR
  let g = parseInt(color.substring(2, 4), 16); // hexToG
  let b = parseInt(color.substring(4, 6), 16); // hexToB

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#212529' : '#ffffff';
}
