import { AxiosRequestHeaders } from 'axios';

export function createRequestOptions(timeout = 120) {
  return {
    transformRequest: (data, headers) => {
      delete headers['Authorization'];
      delete headers.common['X-CSRF-TOKEN'];
      delete headers.common['X-Requested-With'];
    },
    timeout: 1000 * timeout,
  };
}

export function proxify(url: string) {
  return `${import.meta.env.VITE_CORS_PROXY_URL}/?${url}`;
}

export function encodeRequestParams(
  params: Record<string, string | number | boolean>
) {
  const result: string[] = [];

  for (let key in params) {
    result.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    );
  }

  return result.join('&');
}

export function forceHttps(url: string) {
  return url.replace(/^http:\/\/(.+)$/i, `https://$1`);
}
