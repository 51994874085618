import _pick from 'lodash/pick';
import _mapKeys from 'lodash/mapKeys';
import _snakeCase from 'lodash/snakeCase';
import * as TOOL_TYPES from '../constants/toolTypes';

function getBarChartLoadDataParams(options) {
  let paramKeys = [
    'templateId',
    'includedSampleIds',
    'excludedSampleIds',
    'sectionId',
    'dimensionFieldId',
    'dateFormat',
    'statisticTypeId',
    'measureFieldId',
    'aggregateFunctionId',
    'showStatisticOfNoData',
    'showStatisticEqualToZero',
  ];

  if (options?.info?.eventSourceIds?.length > 0) {
    // Bar chart is an observer.
    paramKeys = [...paramKeys, 'eventSourceIds'];
  }

  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getLineChartLoadDataParams(options) {
  let paramKeys = [
    'templateId',
    'includedSampleIds',
    'excludedSampleIds',
    'sectionId',
    'dimensionFieldId',
    'dateFormat',
    'measureFieldIds',
    'aggregateFunctionId',
    'limitPerPage',
    'groupByParentApp',
    'groupByChildAppTitle',
  ];

  if (options?.info?.eventSourceIds?.length > 0) {
    // Line chart is an observer.
    paramKeys = [...paramKeys, 'eventSourceIds'];
  }

  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getPieChartLoadDataParams(options) {
  let paramKeys = [
    'templateId',
    'includedSampleIds',
    'excludedSampleIds',
    'sectionId',
    'dimensionFieldId',
    'dateFormat',
    'statisticTypeId',
    'measureFieldId',
    'aggregateFunctionId',
    'showStatisticOfNoData',
    'showStatisticEqualToZero',
  ];

  if (options?.info?.eventSourceIds?.length > 0) {
    // Pie chart is an observer.
    paramKeys = [...paramKeys, 'eventSourceIds'];
  }

  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getScatterChartLoadDataParams(options) {
  let paramKeys = [
    'templateId',
    'includedSampleIds',
    'excludedSampleIds',
    'sectionId',
    'xAxisMeasureFieldId',
    'yAxisMeasureFieldId',
  ];

  if (options?.info?.eventSourceIds?.length > 0) {
    // Scatter chart is an observer.
    paramKeys = [...paramKeys, 'eventSourceIds'];
  }

  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getPercentageChartLoadDataParams(options) {
  const paramKeys = ['templateId'];
  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getDisplacementGraphLoadDataParams(options) {
  const paramKeys = [
    'childAppId',
    'parentAppId',
    'dimensionFieldId',
    'measureFieldId',
    'limitPerPage',
  ];
  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

function getWordCloudChartLoadDataParams(options) {
  let paramKeys = [
    'templateId',
    'includedSampleIds',
    'excludedSampleIds',
    'sectionId',
    'textFieldId',
  ];

  if (options?.info?.eventSourceIds?.length > 0) {
    // Word cloud chart is an observer.
    paramKeys = [...paramKeys, 'eventSourceIds'];
  }

  return _mapKeys(_pick(options.info, paramKeys), (value, key) =>
    _snakeCase(key)
  );
}

export default (toolType, options) => {
  switch (toolType) {
    case TOOL_TYPES.BAR_CHART:
      return getBarChartLoadDataParams(options);
    case TOOL_TYPES.LINE_CHART:
      return getLineChartLoadDataParams(options);
    case TOOL_TYPES.PIE_CHART:
      return getPieChartLoadDataParams(options);
    case TOOL_TYPES.SCATTER_CHART:
      return getScatterChartLoadDataParams(options);
    case TOOL_TYPES.PERCENTAGE_CHART:
      return getPercentageChartLoadDataParams(options);
    case TOOL_TYPES.WORD_CLOUD_CHART:
      return getWordCloudChartLoadDataParams(options);
    case TOOL_TYPES.DISPLACEMENT_GRAPH:
      return getDisplacementGraphLoadDataParams(options);
    default:
      return {};
  }
};
