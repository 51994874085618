export function checkIsValidLatLng(latLng) {
  let { lat, lng } = latLng;
  lat = parseFloat(lat);
  lng = parseFloat(lng);
  return lng > -180 && lng < 180 && lat > -90 && lat < 90;
}

export function normalizeLongitude(longitude) {
  let result = longitude;
  while (result < -180) {
    result += 360;
  }
  while (result > 180) {
    result -= 360;
  }
  return result;
}

export function normalizeLatitude(latitude) {
  let result = latitude;
  while (result < -90) {
    result += 180;
  }
  while (result > 90) {
    result -= 180;
  }
  return result;
}
