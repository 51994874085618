<template>
  <GatherTableVertical
    v-if="isGatherTable"
    :tabId="block.template_tab_id"
    :filter="block.settings.filters || null"
    :editable="false"
    class="table__container"
    :class="[
      {
        'table__container--full': isSlide,
      },
      block.settings.columnWidth || 'small',
    ]"
    ref="table"
  />
  <div
    v-else-if="isEnviroTable"
    :class="[
      {
        'table__container--full': isSlide,
      },
      block.settings.columnWidth || 'small',
    ]"
    class="enviro-table"
  >
    <iframe :ref="identifier" :src="getViewerUrl" class="h-100 w-100" />
  </div>
  <div
    v-else
    class="custom-table-container table__container"
    :class="[
      {
        'table__container--full': isSlide,
      },
    ]"
  >
    <Editor
      ref="vueEditor"
      :id="`editor-${block.id}`"
      :init="editorInitOptions"
      :initial-value="block.content"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _debounce from 'lodash/debounce';
import { createInitOptions } from '@/js/tinymce-utils';
import constants from '../../helpers/constants';
import GatherTableVertical from '@/js/modules/project/gather-table-vertical/index.vue';
const Editor = () => import('@/js/tinymce');

export default {
  mixins: [constants],
  props: {
    block: Object,
    isSlide: Boolean,
  },
  data() {
    const self = this;
    return {
      editorInitOptions: {
        ...createInitOptions(),
        toolbar: false,
        menubar: false,
        inline: true,
        content_style: '[contenteditable] {outline: 0px solid transparent;}',
        placeholder: 'Click here to start to insert a custom table.',
        quickbars_insert_toolbar: 'quicktable',
        quickbars_selection_toolbar: `bold italic strikethrough underline blockquote |
        subscript superscript |
        alignleft aligncenter alignright alignjustify alignnone lineheight |
        indent outdent |
        forecolor backcolor |
        copy cut paste |
        fontselect fontsizeselect formatselect |
        redo undo |
        remove |
        selectall `,
        setup: function (editor) {
          const handleChange = function (e) {
            const content = editor.getContent();

            if (!!content && !/^<table.+<\/table>$/is.test(content)) {
              editor.setContent('');
            } else {
              self.saveCustomTable(content);
            }
          };
          editor.on('input', handleChange);
          editor.on('change', handleChange);
          editor.on('paste', handleChange);
          editor.on('BeforeExecCommand', self.handleBeforeExecCommand);

          self.setIsEditable();
        },
      },
      // Whether to show confirmation modal when deleting a custom table.
      needConfirmation: true,
      identifier: Date.now().toString(),
    };
  },
  computed: {
    ...mapState({
      editable: (state) => state.reporter.editable,
      reportToken: (state) => state.reporter.reportToken,
    }),
    tableType() {
      const { tableType = this.TABLE_TYPES.GATHER_TABLE } = this.block.settings;
      return tableType;
    },
    isGatherTable() {
      return this.tableType === this.TABLE_TYPES.GATHER_TABLE;
    },
    isEnviroTable() {
      return this.tableType === this.TABLE_TYPES.ENVIRO_TABLE;
    },
    getViewerUrl() {
      const token = this.reportToken;
      return `/table-iframe/${this.block.id}` + (token ? `/${token}` : '');
    },
  },
  watch: {
    'block.settings': {
      handler() {
        this.reloadTable();
      },
      deep: true,
    },
    'block.template_tab_id': {
      handler() {
        this.reloadTable();
      },
      deep: true,
    },
    'block.scenario_group_id': {
      handler() {
        this.reloadTable();
      },
      deep: true,
    },
  },
  components: {
    GatherTableVertical,
    Editor,
  },
  methods: {
    ...mapActions('reporter', ['setConfirmation']),
    reloadTable() {
      if (this.isEnviroTable) {
        const viewer = this.$refs[this.identifier];
        viewer.contentWindow.location.reload();
      }

      if (this.isGatherTable) {
        this.$nextTick(() => {
          // The table is null when just changing from custom table to datanest table.
          this.$refs.table?.reloadTable();
        });
      }
    },
    handleBeforeExecCommand(event) {
      const { command, target } = event;
      if (command === 'mceTableDelete' && this.needConfirmation) {
        this.setConfirmation({
          header: 'Are you sure you want to delete this table?',
          submit: () => {
            // Skip the confirmation for the confirmed deletion.
            this.needConfirmation = false;
            target.execCommand(command);
            this.needConfirmation = true;
          },
          isDelete: true,
        });
        return false;
      }

      return true;
    },
    setIsEditable() {
      if (this.tableType == this.TABLE_TYPES.CUSTOM_TABLE) {
        this.$refs.vueEditor.editor.mode.set(
          this.editable ? 'design' : 'readonly'
        );
      }
    },
  },
  created() {
    this.saveCustomTable = _debounce((content) => {
      this.$emit('update', {
        content,
      });
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.slideshow__figure .table__container--full {
  height: calc(100vh - 175px - 70px);
  width: 100%;
  overflow: auto;
}

.slideshow__figure .custom-table-container {
  padding: 20px;
}

.slideshow__container--full-screen {
  .slideshow__figure {
    height: calc(100vh - 70px - 56px);

    .table__container--full {
      height: calc(100vh - 70px - 56px);
    }
  }
}

.viewer__container--headings {
  .slideshow__figure {
    .table__container--full {
      height: calc(100vh - 175px - 70px - 50px);
    }
  }

  .slideshow__container--full-screen {
    .slideshow__figure {
      .table__container--full {
        height: calc(100vh - 70px - 56px - 50px);
      }
    }
  }
}

.block--table .enviro-table {
  height: 650px;
}

.slideshow__figure .enviro-table {
  height: 100%;
}
</style>

<style lang="scss">
// Can't put this piece of style in the above style block because <p> is created
// by TinyMCE instead of Vue so scoped attribute can't be applied.
.custom-table-container {
  max-width: 100%;
  overflow: auto;

  p {
    margin-bottom: 0;
  }
}
</style>
