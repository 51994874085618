import {
  calculateCellTop,
  calculateCellLeft,
} from '../helpers/table-fixed-calc';

const Sticky = (el, binding) => {
  // These are defined as top (row index), left (column index)
  const { top, left } = binding.value;
  if (top === undefined && left === undefined) {
    return;
  }

  el.style.position = 'sticky';

  if (top !== undefined) {
    el.style.top = `${calculateCellTop(top)}px`;
  }

  if (left !== undefined) {
    el.style.left = `${calculateCellLeft(left)}px`;
  }

  let startZIndex = 100;

  if (top !== undefined && left === undefined) {
    // need the top rows to sit above body rows
    startZIndex = 50;
  }

  if (left !== undefined && top === undefined) {
    // need the left columns to sit above body columns
    startZIndex = 50;
  }

  if (top !== undefined) {
    startZIndex -= top;
  }

  if (left !== undefined) {
    startZIndex -= left;
  }

  el.style.setProperty('z-index', startZIndex, 'important');
};

export default Sticky;
