import { getDefaultFilters } from './index.js';

const ADD_FIELD_VALUE_FILTER = (state, filter) => {
  state.filters.updated = true;

  const fieldValueFilters = state.filters.fieldValueFilters;
  const filterIndex = fieldValueFilters.findIndex(
    (f) => f.field_id == filter.field_id
  );

  const emptyValues = filter.values.length == 0;
  if (filterIndex != -1 || emptyValues) {
    fieldValueFilters.splice(filterIndex, 1);
    if (emptyValues) {
      return;
    }
  }

  fieldValueFilters.push(filter);
};

const REMOVE_FIELD_VALUE_FILTER = (state, field_id) => {
  state.filters.updated = true;

  const fieldValueFilters = state.filters.fieldValueFilters;

  let filterIndex = fieldValueFilters.findIndex((f) => f.field_id == field_id);
  if (filterIndex != -1) {
    fieldValueFilters.splice(filterIndex);
  }
};

const SET_FILTERS = (state, filters) => {
  state.filters = {
    ...getDefaultFilters(),
    ...filters,
  };
};

const SET_FILTER = (state, { key, value }) => {
  state.filters.updated = true;
  state.filters[key] = value;
};

const CLEAR_FILTERS = (state) => {
  state.filters = getDefaultFilters();
};

export default {
  ADD_FIELD_VALUE_FILTER,
  REMOVE_FIELD_VALUE_FILTER,
  SET_FILTERS,
  SET_FILTER,
  CLEAR_FILTERS,
};
