import validateUrl from './validate-url';

export function getOptions(placeholder) {
  return {
    theme: 'bubble',
    modules: {
      toolbar: [
        ['bold', 'italic', 'blockquote', 'link'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [2, 3, false] }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ color: [] }],
        ['clean'],
      ],
    },
    placeholder,
  };
}

export function addQuillEvents(context, quill) {
  quill.focus();
  quill.setSelection(quill.getLength());

  quill.getModule('toolbar').container.addEventListener('mousedown', (e) => {
    e.preventDefault();
  });

  quill.getModule('toolbar').addHandler('link', (value) => {
    if (value) {
      let href = prompt('Enter the URL');
      if (!validateUrl(href)) {
        context.$toastStore.error('Please input a valid URL.');
        return;
      }
      quill.format('link', href);
    } else {
      quill.format('link', false);
    }
  });
}
