import globals from '../globals';

export const enum MatrixType {
  SOIL = 0,
  WATER = 1,
  LEACHATE = 2,
  SOIL_GAS = 3,
  SEDIMENT = 4,
  AIR = 5,
}

export const convertMatrixStringToType = (type: string): MatrixType => {
  switch (type) {
    case 'soil':
      return MatrixType.SOIL;
    case 'water':
      return MatrixType.WATER;
    case 'leachate':
      return MatrixType.LEACHATE;
    case 'soilgas':
    case 'gas':
      return MatrixType.SOIL_GAS;
    case 'sediment':
      return MatrixType.SEDIMENT;
  }

  return MatrixType.SOIL;
};

export const convertMatrixTypeToString = (type: MatrixType): string => {
  switch (type) {
    case MatrixType.WATER:
      return 'water';
    case MatrixType.LEACHATE:
      return 'leachate';
    case MatrixType.SOIL_GAS:
      return 'soilgas';
    case MatrixType.SEDIMENT:
      return 'sediment';
  }

  return 'soil';
};

export function getMatrixStylingByKey(key: string) {
  return (
    globals.matrixTypes.find((m) => m['key'] == key) || globals.matrixTypes[0]
  );
}

export function getMatrixTypesByCountry(country: string) {
  return globals.matrixTypes.filter((m) => m.countries.includes(country));
}

export const getMatrixIcon = (matrix: number) => {
  return globals.matrixTypes.find((m) => m.index == matrix)!.icon;
};
