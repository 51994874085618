let loadedFonts = [];

export const fonts = [
  {
    key: 'Poppins',
    google: true,
  },
  {
    key: 'Playfair Display',
    google: true,
  },
  {
    key: 'Source Sans Pro',
    google: true,
  },
  {
    key: 'Quattrocento',
    google: true,
  },
  {
    key: 'Quattrocento Sans',
    google: true,
  },
  {
    key: 'Oswald',
    google: true,
  },
  {
    key: 'Quattrocento',
    google: true,
  },
  {
    key: 'Sacramento',
    google: true,
  },
  {
    key: 'Source Sans Pro',
    google: true,
  },
  {
    key: 'Montserrat',
    google: true,
  },
  {
    key: 'Arial',
    google: false,
  },
];

export function loadFontByName(name) {
  const font = fonts.find((f) => f.key == name);

  if (!font || !font.google || loadedFonts.includes(font.key)) {
    return;
  }

  let link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute(
    'href',
    'https://fonts.googleapis.com/css?family=' + font.key
  );

  document.head.appendChild(link);

  loadedFonts.push(name);
}
