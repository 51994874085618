import store from '@/js/store';
import EventBus from '@component-library/EventBus';
import {
  Code,
  OlbmError,
  getMessage,
  throwError,
} from '../lib/olbm/common/error';
import { getStoreApi } from '../lib/olbm/common/store-api';
import { MapType } from '../lib/olbm/common/types';
import { getMapType } from '../lib/olbm/common/utils';
import { BasemapId } from '../lib/olbm/layer/basemap/types';
import { inspectBasemap } from '../lib/olbm/layer/basemap/utils';
import { NAMESPACE } from '../store';
import { getGetter } from '../view-utils';
import { createBasemapServiceLayer } from './openlayers';

class TileSet {
  constructor(map, basemapId = BasemapId.OPEN_STREET_MAP) {
    this.map = map;
    this.basemapApis = getGetter('basemapApis');
    this.basemapId = basemapId;
  }

  get basemapId() {
    return this._basemapId;
  }

  set basemapId(value) {
    if (!this.findBasemapApiById(value)) {
      throwError(Code.InvalidArgument, value);
    }

    this._basemapId = value;

    const viewer = this.map.getViewer();
    viewer.useBgWhite = ![
      BasemapId.GOOGLE_MAPS_ROADMAP,
      BasemapId.GOOGLE_MAPS_SATELLITE,
    ].includes(this._basemapId);
  }

  get leftLayer() {
    const [leftLayer] = this.map.layerManager.getBasemapLayers();
    return leftLayer;
  }

  get rightLayer() {
    const [, rightLayer] = this.map.layerManager.getBasemapLayers();
    return rightLayer;
  }

  updateBasemapId(basemapId, shouldUpdateFigure = false) {
    this.basemapId = basemapId;

    if (shouldUpdateFigure) {
      EventBus.$emit('updateFigureBasemapIndex', basemapId);
    }
  }

  findBasemapApiById(basemapId) {
    return this.basemapApis.find((ba) => ba.index === basemapId);
  }

  getBasemapApi() {
    return this.findBasemapApiById(this.basemapId);
  }

  getBasemapApiWithDate(date) {
    const basemapApi = this.getBasemapApi();
    return {
      ...basemapApi,
      ...{
        source: `${basemapApi.source}&until=${date}`,
      },
    };
  }

  /**
   * Render the basemap.
   * @param {*} basemapApi
   * @param {*} position Used to put left and right layers which are used by Nearmap swipe in the correct position.
   *                     The valid values are only 0(Left Layer) and 1(Right Layer).
   * @returns
   */
  _renderBasemap(basemapApi, position = 0) {
    const { index } = basemapApi;
    const viewer = this.map.getViewer();
    const { layerManager } = this.map;
    const mapType = getMapType(this.map);
    const storeApi = getStoreApi(this.map);

    try {
      const integrations = storeApi.getIntegrations();
      inspectBasemap(this.map, index, integrations);
    } catch (error) {
      const isOlbmError = error instanceof OlbmError;
      const shouldSkipAlert =
        isOlbmError && error.code === Code.IntegrationNotReady;
      if (!shouldSkipAlert) {
        storeApi.showError(
          isOlbmError ? error.message : getMessage(Code.Generic)
        );
      }
      layerManager.removeBasemap();
      return;
    }

    let maxZoom;
    let opacity;
    if (mapType === MapType.MINI) {
      maxZoom = 18;
      opacity = 1;
    }
    const bsLayer = createBasemapServiceLayer(
      this.map,
      basemapApi,
      maxZoom,
      opacity
    );
    bsLayer.set('position', position);
    layerManager.addLayer(bsLayer);

    if (mapType === MapType.MAIN) {
      EventBus.$emit('updateBasemapAttributions');
    }

    return bsLayer;
  }

  renderBasemap(position = 0) {
    return this._renderBasemap(this.getBasemapApi(), position);
  }

  renderBasemapWithDate(date, position = 0) {
    return this._renderBasemap(this.getBasemapApiWithDate(date), position);
  }

  changeBasemap({ index, shouldUpdateFigure = false }) {
    const mapType = getMapType(this.map);
    if (mapType !== MapType.MAIN) {
      shouldUpdateFigure = false;
    }
    this.updateBasemapId(index, shouldUpdateFigure);

    const basemapRevisionDate = NAMESPACE.getState(
      store,
      'basemapRevisionDate'
    );
    if (
      mapType === MapType.MAIN &&
      this.basemapId === BasemapId.NEARMAP &&
      basemapRevisionDate
    ) {
      return this.renderBasemapWithDate(basemapRevisionDate);
    } else {
      return this.renderBasemap();
    }
  }
}

export default TileSet;
