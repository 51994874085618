import OverlayPositioning from 'ol/OverlayPositioning';

const ANCHOR_SIZE = 11;

/**
 * Offset is required when the popup doesn't have an anchor.
 * @param {ol-ext/overlayer/Popup} popup
 */
export function offsetNoAnchor(popup) {
  const positioning = popup.getPositioning();
  let offset = [0, 0];
  if (positioning === OverlayPositioning.TOP_CENTER) {
    offset[1] = -ANCHOR_SIZE;
  } else if (positioning === OverlayPositioning.CENTER_RIGHT) {
    offset[0] = ANCHOR_SIZE;
  } else if (positioning === OverlayPositioning.BOTTOM_CENTER) {
    offset[1] = ANCHOR_SIZE;
  } else if (positioning === OverlayPositioning.CENTER_LEFT) {
    offset[0] = -ANCHOR_SIZE;
  }
  popup.setOffset(offset);
}
