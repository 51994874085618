// Static imports
import Vue from 'vue';
import { captureException } from '@sentry/browser';
import './bootstrap';
import generalMixins from './mixins/general';
import scenarioMixins from './mixins/scenario';
import applicationRouter, { setRouterVueInstance } from '@/js/routes';
import store from '@/js/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import App from '@/js/layouts/App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import auth from './auth.js';
import { VTooltip, VClosePopover } from 'v-tooltip';
import BlinkingDots from '@/js/components/BlinkingDots.vue';
import { Fragment } from 'vue-frag';
import ClickOutside from '@component-library/directives/click-outside';
import ClickAndContextMenu from '@component-library/directives/click-and-context-menu';
import Sticky from '@/js/modules/project/data-table/directives/sticky';
import '@component-library/EventBus';
import { Buffer } from 'buffer';
import ToastsStorePlugin from '@component-library/store/plugins/toasts-vue-adapter-plugin';
import { setToken, setApiAuth } from '@component-library/composables/useApi';
import { isEnvTruthy } from '@component-library/utils';
import createSentry from '@component-library/sentry';
import { initializeAnalytics } from '@component-library/analytics';
import { isProd } from '@component-library/utils/is-prod';

// Dynamic imports
import('vue-search-select/dist/VueSearchSelect.css');
if (
  import.meta.env.VITE_SENTRY_LARAVEL_DSN &&
  import.meta.env.VITE_SENTRY_LARAVEL_DSN != ''
) {
  createSentry();
}

// If explicitly enabled or not local environment
// Use the service worker.
if (
  isEnvTruthy('VITE_SERVICE_WORKER') ||
  import.meta.env.VITE_APP_ENV !== 'local'
) {
  if (window.location.search.includes('no-service-worker')) {
    console.log(
      'Service worker skipped due to query parameter: no-service-worker'
    );
  } else {
    console.log('Service worker enabled');
    import('./register-service-worker');
  }
}

window.global = window;
window.Buffer = Buffer;

// Set Vue router
Vue.router = applicationRouter;

// Set Axios configuration
Vue.use(VueAxios, axios);
axios.defaults.baseURL = `/api`;

Vue.component('Fragment', Fragment);
Vue.component('blinking-dots', BlinkingDots);
Vue.directive('click-outside', ClickOutside);
Vue.directive('context-and-click-menu', ClickAndContextMenu);
Vue.directive('sticky', Sticky);

Vue.directive('lazy', {
  inserted: lazyLoad,
  update: lazyLoad,
});

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  },
});

function lazyLoad(el) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const img = entry.target;
        img.src = img.dataset.url;
        observer.disconnect();
      }
    });
  }, options);

  observer.observe(el);
}

//Set custom global methods
Vue.mixin(generalMixins);
Vue.mixin(scenarioMixins);

// Add project ID to requests
axios.interceptors.request.use(
  function (config) {
    if (!config.params) {
      config.params = {};
    }

    const { url } = config;
    const isExternalUrl =
      !!url.match(/^http(s)?/) && !url.includes(import.meta.env.VITE_APP_URL);

    if (!config.params.project_id && !isExternalUrl) {
      config.params.project_id =
        store.getters.get_project_field_by_key('project_id');
    }

    if (!config.params.matrix && !isExternalUrl) {
      config.params.matrix = store.state.matrix;
    }

    const reportToken = store.state.reporter.reportToken;
    if (reportToken) {
      config.params.report_token = reportToken;

      const reportId = store.state.reporter.report?.id;
      if (reportId) {
        config.params.report_id = reportId;
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//Intercept errors if project, or user session expired.
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!error.response) {
      captureException(error);
      return Promise.reject(error);
    }

    const status = error.response.status;
    if (status !== 422) {
      captureException(error);
    }

    if (
      status == 401 &&
      !error.request.responseURL.includes(import.meta.env.VITE_APP_URL)
    ) {
      return true;
    }

    if (status == 401) {
      console.error('Clearing login as we received an 401 error status');
      const auth = Vue.auth;
      if (auth.check()) {
        if (auth.impersonating()) {
          auth.unimpersonate();
        }
        auth.logout();
      }
      setToken(null);

      localStorage.removeItem('envirotool');
      localStorage.removeItem('envirotool_impersonator');
    }

    const errorMessage = error.response.data?.message;

    if (
      status == 403 &&
      (errorMessage == 'Project number has not been set.' ||
        errorMessage == 'You do not have access to this project.')
    ) {
      Vue.toastStore.error(errorMessage);

      localStorage.removeItem('project_id');
    }

    if (status == 413) {
      Vue.toastStore.error('The uploaded file size exceeded our upload limit.');
    }

    if (status === 429 || status === 420) {
      Vue.toastStore.error(
        'Too many requests. Please try again in a few minutes.'
      );
    }

    return Promise.reject(error);
  }
);

//Set Vue authentication
Vue.use(VueAuth, {
  ...auth,
  plugins: {
    http: Vue.axios,
    router: Vue.router,
  },
});

setApiAuth(Vue.auth);

// Tooltips
VTooltip.options.defaultBoundariesElement = 'window';
VTooltip.options.autoHide = false;
Vue.directive('tooltip', VTooltip);

Vue.directive('close-popover', VClosePopover);

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

setRouterVueInstance(Vue);

const app = new Vue({
  el: '#app',
  router: applicationRouter,
  store,
  render: (h) => h(App),
  pinia,
});

Vue.use(ToastsStorePlugin);

if (!isProd) {
  Vue.config.performance = true;
  console.warn('Non production build!');
  window.app = app;
}

initializeAnalytics();

export default app;
export const router = applicationRouter;

export const hasRegistration = isEnvTruthy('VITE_APP_REGISTRATION');
