export const calculateCellTop = (rowIndex: number) => {
  const firstRowHeight = 44.5;

  if (rowIndex === 0) {
    return 0;
  }

  return firstRowHeight + (rowIndex - 1) * 38;
};

export const calculateCellLeft = (
  columnIndex: number,
  firstColumnWidth: number = 220
) => {
  if (columnIndex === 0) {
    return 0;
  }

  return firstColumnWidth + (columnIndex - 1) * 100;
};
