import axios from 'axios';
import { AxiosProgressEvent } from '@component-library/utils';

const path = '/reporter';

/**
 * Reports
 */

const getReports = (search) => {
  return axios.get(`${path}/reports`, {
    params: {
      search,
    },
  });
};

const loginToReport = (slug, password) => {
  return axios.post(`${path}/reports/${slug}/login`, {
    password,
  });
};

const checkReportRequiresPassword = (slug) => {
  return axios.get(`${path}/reports/${slug}/requires-password`);
};

const getReport = (slug) => {
  return axios.get(`${path}/reports/${slug}`);
};

const createReport = (data) => {
  return axios.post(`${path}/reports`, data);
};

const updateReport = (report, data) => {
  return axios.put(`${path}/reports/${report.id}`, data);
};

const duplicateReport = (id) => {
  return axios.post(`${path}/reports/${id}/duplicate`);
};

const deleteReport = (id) => {
  return axios.delete(`${path}/reports/${id}`);
};

const uploadReportMedia = (id, data, progressUpdater) => {
  return axios.post(`${path}/reports/${id}/upload-media`, data, {
    onUploadProgress: (e) =>
      progressUpdater(Math.round((e.loaded * 100) / (e.total || 1))),
  });
};

const publishReport = (id) => {
  return axios.post(`${path}/reports/${id}/publish`);
};

const unpublishReport = (id) => {
  return axios.post(`${path}/reports/${id}/unpublish`);
};

/**
 * Themes
 */

const getThemes = () => {
  return axios.get(`${path}/themes`);
};

const updateTheme = (data) => {
  return axios.post(`${path}/themes`, data);
};

/**
 * Blocks
 */

const createBlock = (report_id, block, order) => {
  return axios.post(`${path}/blocks`, { report_id, ...block, order });
};

const updateBlock = (block_id, updated_fields) => {
  return axios.put(`${path}/blocks/${block_id}`, updated_fields);
};

const updateBlockOrdering = (report_id, order) => {
  return axios.post(`${path}/blocks/ordering`, { report_id, order });
};

const duplicateBlock = (block_id) => {
  return axios.post(`${path}/blocks/${block_id}/duplicate`);
};

const deleteBlock = (block_id) => {
  return axios.delete(`${path}/blocks/${block_id}`);
};

const updateBlockActions = (data) => {
  return axios.post(`${path}/blocks/${data.block_id}/actions`, data);
};

const uploadBlockAttachment = (id, { data, progressUpdater }) => {
  return axios.post(`${path}/blocks/${id}/attachment`, data, {
    onUploadProgress: (e: AxiosProgressEvent) =>
      progressUpdater(Math.round((e.loaded * 100) / (e.total || 1))),
  });
};

/**
 * Slideshow
 */

const createSlide = (data) => {
  return axios.post(`${path}/slides/${data.slideshow_block_id}`, data);
};

const duplicateSlide = ({ slide_id }) => {
  return axios.post(`${path}/slides/${slide_id}/duplicate`);
};

const deleteSlide = ({ slide_id }) => {
  return axios.delete(`${path}/slides/${slide_id}`);
};

const updateSlideOrdering = ({ block_id, slideOrder }) => {
  return axios.post(`${path}/blocks/${block_id}/slide-ordering`, {
    slideOrder,
  });
};

const modifySlideFigure = (data) => {
  return axios.post(`${path}/slides/${data.slide_id}/figure`, data);
};

const deleteSlideFigure = ({ slide_id }) => {
  return axios.delete(`${path}/slides/${slide_id}/figure`);
};

/**
 * Attachments
 */
const downloadAttachment = (id) => {
  return axios.get(`${path}/attachments/${id}`);
};

const updateAttachment = (id, updated_fields) => {
  return axios.put(`${path}/attachments/${id}`, updated_fields);
};

const deleteAttachment = (id) => {
  return axios.delete(`${path}/attachments/${id}`);
};

/**
 * Misc
 */

/**
 * @deprecated Use `useFigureStore.fetchProjectFigures` instead.
 */
const getListOfFigures = (data) => {
  return axios.get(`/figure8/figure/list`, {
    params: data,
  });
};

const getListOfChemicals = () => {
  return axios.get(`/figure8/data/chemicals`);
};

const getGatherTemplates = () => {
  return axios.get(`/gather/tabs`);
};

const getAllSamples = (tabId) => {
  return axios.get('/gather/all-samples', {
    params: {
      tabId,
    },
  });
};

const getAllAppFields = (app_id) => {
  return axios.get(`/app/${app_id}/fields`);
};

const getAllInsightDashboards = () => {
  return axios.get(`data-insights/dashboards`);
};

const getScenarioGroups = () => {
  return axios.get(`/dashboard/scenario-group`);
};

export default {
  getReports,
  getReport,
  loginToReport,
  checkReportRequiresPassword,
  createReport,
  updateReport,
  duplicateReport,
  deleteReport,
  uploadReportMedia,
  publishReport,
  unpublishReport,

  getThemes,
  updateTheme,

  createBlock,
  updateBlock,
  updateBlockOrdering,
  duplicateBlock,
  deleteBlock,
  updateBlockActions,
  uploadBlockAttachment,

  createSlide,
  duplicateSlide,
  deleteSlide,
  updateSlideOrdering,
  modifySlideFigure,
  deleteSlideFigure,

  downloadAttachment,
  updateAttachment,
  deleteAttachment,

  getListOfFigures,
  getListOfChemicals,
  getGatherTemplates,
  getAllSamples,
  getAllAppFields,
  getAllInsightDashboards,
  getScenarioGroups,
};
