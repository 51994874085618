<template>
  <div class="w-100 h-100" :class="block.settings.style || 'half-and-half'">
    <div class="content__block p-4">
      <span>
        <h1
          ref="titleInput"
          class="fw-bold mb-0"
          :class="{
            header__link: block.settings.include_in_heading,
          }"
          :contenteditable="editable"
          data-placeholder="Title your section"
          v-once
          @input="
            (e) =>
              updateProperty({
                key: 'title',
                value: e.target.innerText,
              })
          "
          @focus="(e) => (focused = true)"
          @blur="(e) => (focused = false)"
        >
          <template v-if="displayTitle">{{ displayTitle }}</template>
        </h1>
      </span>
    </div>
    <div
      class="styled__block"
      :style="{
        'background-image': `url(${
          block.file_url ? block.file_url : '/images/media-bg.svg'
        })`,
      }"
    ></div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { goNumbering2 } from '../../helpers/heading-numbering';

export default {
  props: {
    block: Object,
  },
  data: () => ({
    timeout: null,
    focused: false,
    titleInputValue: null,
  }),
  computed: {
    ...mapState({
      editable: (state) => state['reporter'].editable,
    }),
    ...mapGetters('reporter', ['getThemePropertyByKey', 'headingTree']),
    numberHeadings() {
      return this.getThemePropertyByKey('number_headings');
    },
    displayTitle() {
      const { include_in_heading, title } = this.block.settings;
      let displayTitle = this.titleInputValue ?? title ?? '';

      if (
        !this.focused &&
        include_in_heading &&
        displayTitle &&
        this.numberHeadings
      ) {
        displayTitle = goNumbering2(
          this.headingTree,
          `<h2>${displayTitle}</h2>`,
          this.block.id
        );
        displayTitle = displayTitle.substring(
          '<h2>'.length,
          displayTitle.length - '</h2>'.length
        );
      }

      return displayTitle;
    },
  },
  methods: {
    ...mapActions('reporter', ['modifySlideFigure', 'setPageHeadings']),
    updateProperty({ key, value }) {
      if (key === 'title') {
        this.titleInputValue = value;
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        let updated_fields = {};
        updated_fields[key] = value;

        this.modifySlideFigure({
          slide_id: this.block.slide_id,
          settings: {
            ...this.block.settings,
            ...updated_fields,
          },
        });

        this.setPageHeadings();
      }, 400);
    },
  },
  watch: {
    focused() {
      this.$refs.titleInput.innerHTML = this.displayTitle;
    },
    numberHeadings() {
      this.$refs.titleInput.innerHTML = this.displayTitle;
      this.setPageHeadings();
    },
    'block.settings.title'() {
      this.setPageHeadings();
    },
    'block.settings.include_in_heading'() {
      this.$refs.titleInput.innerHTML = this.displayTitle;
      this.setPageHeadings();
    },
  },
};
</script>
<style lang="scss" scoped>
.half-and-half,
.half-and-half-flipped {
  position: relative;
  overflow: hidden;
  border: 6px solid var(--accent-color);
  display: grid;
  grid-template-columns: 50% 50%;

  .content__block {
    height: 100%;
    display: flex;
    align-items: center;

    h1 {
      color: var(--accent-color);
    }

    span {
      position: relative;
      max-width: 100%;
    }

    span::after {
      content: ' ';
      position: absolute;
      width: 30%;
      height: 6px;
      background: var(--accent-color);
      bottom: -15px;
      left: 0px;
    }
  }

  .styled__block {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: center center;

    &::after {
      content: '';
      background: var(--accent-color);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0.9;
    }
  }
}

.half-and-half-flipped {
  .content__block {
    grid-column: 2;

    h1 {
      padding-right: 0px;
    }
  }

  .styled__block {
    grid-column: 1;
    grid-row: 1;
  }
}

h1 {
  outline: none;
}
</style>
