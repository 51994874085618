import generalMixin from '@/js/mixins/general';
import { getFormattedTitle, getDocumentTitle } from '@/js/helpers/scenario.js';
import { ProjectScenario } from '@component-library/enviro';

export enum CriteriaType {
  Landuse = 'landuse',
  Criteria = 'criteria',
}

enum CriteriaTypeNumber {
  Landuse = 0,
  Criteria = 1,
}

type GuidelineScenarioId = number;
type CriteriaSetId = number;
export type SampleChemicalId = number;

type ScenarioDocumentStyle = {
  short_url: string;
};

type ScenarioStyle = {
  scenario_id: GuidelineScenarioId;
  factor: number;
  documents: ScenarioDocumentStyle[];
  criteria_set_id: CriteriaSetId;
  criteria_type: CriteriaTypeNumber;
  title?: string;
};

export function getOrderedScenarios(
  scenarios: ProjectScenario[]
): ProjectScenario[] {
  const criteriaTypes: CriteriaType[] = [
    CriteriaType.Landuse,
    CriteriaType.Criteria,
  ];
  const result = [...scenarios];
  result.sort(
    (s1, s2) =>
      criteriaTypes.indexOf(s1.criteria_type) -
      criteriaTypes.indexOf(s2.criteria_type)
  );
  return result;
}

export function getScenarioStyle(
  scenarios: ProjectScenario[],
  scenarioStyles: ScenarioStyle[],
  scenario: ProjectScenario | SampleChemicalExceed
): ScenarioStyle | ScenarioDocumentStyle | null | undefined {
  if (scenario.criteria_type === CriteriaType.Landuse) {
    const landuseStyle = scenarioStyles.find(
      (ss) =>
        ss.scenario_id === scenario.scenario_id &&
        ss.factor === (scenario.factor ?? 1)
    );

    if (scenario.document_id && landuseStyle) {
      const documentScenario = scenarios.find(
        (s) =>
          s.scenario_id === scenario.scenario_id &&
          s.document_id === scenario.document_id
      );

      return documentScenario
        ? landuseStyle.documents.find(
            (d) => d.short_url == documentScenario.document?.short_url
          )
        : null;
    }

    return landuseStyle;
  }

  if (scenario.criteria_type === CriteriaType.Criteria) {
    return scenarioStyles.find(
      (ss) => ss.criteria_set_id === scenario.criteria_set_id
    );
  }
}

function checkIsScenarioStyle(
  scenarioStyle: ScenarioStyle | ScenarioDocumentStyle | null | undefined
): scenarioStyle is ScenarioStyle {
  return !!scenarioStyle && !('short_url' in scenarioStyle);
}

export function getScenarioTitle(
  scenario: ProjectScenario,
  showBasis: boolean,
  scenarioStyle: ScenarioStyle | ScenarioDocumentStyle | null | undefined,
  country: string
): string {
  let result = '';

  if (scenario.document) {
    result += getDocumentTitle(scenario.document);
  }

  if (checkIsScenarioStyle(scenarioStyle) && scenarioStyle.title) {
    result += scenarioStyle.title ?? '';
  } else {
    result += getFormattedTitle(
      scenario.scenario || scenario.criteria,
      scenario.document,
      showBasis,
      scenario.criteria_type,
      scenario.factor,
      country as any,
      true
    );
  }

  return result;
}

export type SampleChemicalExceed = {
  chemicals: SampleChemicalId[];
  criteria_type: CriteriaType;
  document_id: number;
  factor: number;
  highest_value: number;
  scenario_id: GuidelineScenarioId;
  criteria_set_id: CriteriaSetId;
};

export type ChemicalId = number;

export type Chemical = {
  id: ChemicalId;
  title: string;
};

export type SampleChemical = {
  casno: string;
  chemical_id: ChemicalId;
  display_title: string;
  exceeds: SampleChemicalExceed[];
  id: SampleChemicalId;
  primary_title: string;
  title: string;
};

const checkShouldSkipSample = (sample, hiddenFormattedDepths) => {
  const formattedDepth = generalMixin.methods.formatSampleDepth(sample);
  return hiddenFormattedDepths.includes(formattedDepth);
};

export function getAllSampleIdsFromSample(
  sample,
  hiddenFormattedDepths
): number[] {
  const result: number[] = [];

  if (!checkShouldSkipSample(sample, hiddenFormattedDepths)) {
    result.push(sample.id as number);
  }

  if (
    sample.duplicate &&
    !checkShouldSkipSample(sample.duplicate, hiddenFormattedDepths)
  ) {
    result.push(sample.duplicate.id as number);
  }

  sample.child_samples?.forEach((item) => {
    if (!checkShouldSkipSample(item, hiddenFormattedDepths)) {
      result.push(item.id as number);
    }

    if (
      item.duplicate &&
      !checkShouldSkipSample(item.duplicate, hiddenFormattedDepths)
    ) {
      result.push(item.duplicate.id as number);
    }
  });

  return result;
}
