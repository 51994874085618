import { ScenarioSet } from '@component-library/enviro';
import { CustomNote } from './custom-note';
import { VariableOption } from './variable';

export enum EditableBlockType {
  SCENARIOS,
  ADOPTED_CRITERIA,
  CRITERIA_COMMENTS,
  CRITERIA_ANALYTES,
  CUSTOM_NOTES,
  RESULT_NOTES,
  CHEMICAL_FACTOR,
  GUIDELINE_CHANGE,
  GUIDELINE_NOTE,
}

export type EditableBlock = {
  title: string | null;
  is_visible: boolean;
  scenario_text?: Array<DataItem>;
  criteria_text?: Array<DataItem>;
  notes?: Array<CustomNote>;
  has_shaded_title?: boolean;
  hide_hydrocarbon_text?: boolean;
};

export type DataItem = {
  type: 'text' | 'variable';
  value: string;
};

export const getDefaultBlocks = (
  scenarioSet: ScenarioSet | null
): Record<EditableBlockType, EditableBlock> => {
  const defaultConfig: Record<EditableBlockType, EditableBlock> = {
    [EditableBlockType.SCENARIOS]: {
      is_visible: true,
      title: 'Scenarios:',
      has_shaded_title: true,
      scenario_text: [
        { type: 'text', value: 'Indicates result exceeds' },
        {
          type: 'variable',
          value: VariableOption.DOCUMENT_SHORT,
        },
        {
          type: 'text',
          value: 'for',
        },
        {
          type: 'variable',
          value: VariableOption.SCENARIO_TITLE,
        },
        {
          type: 'text',
          value: '&nbsp',
        },
      ],
      criteria_text: [
        { type: 'text', value: 'Indicates result exceeds' },
        {
          type: 'variable',
          value: VariableOption.SCENARIO_TITLE,
        },
        {
          type: 'text',
          value: '&nbsp',
        },
      ],
    },
    [EditableBlockType.ADOPTED_CRITERIA]: {
      is_visible: true,
      title: 'Criteria adopted from the following guidelines:',
      hide_hydrocarbon_text: false,
      scenario_text: [
        { type: 'variable', value: VariableOption.IDENTIFER },
        { type: 'text', value: '&nbsp' },
        { type: 'variable', value: VariableOption.DOCUMENT_LONG },
        { type: 'text', value: 'Criteria for' },
        { type: 'variable', value: VariableOption.SCENARIO_TITLE },
        { type: 'text', value: '&nbsp' },
        { type: 'variable', value: VariableOption.TABLE_REFERENCES },
        { type: 'text', value: '&nbsp' },
      ],
    },
    [EditableBlockType.CRITERIA_COMMENTS]: {
      is_visible: true,
      title: 'Criteria Comments:',
      criteria_text: [
        { type: 'variable', value: VariableOption.CUSTOM_CRITERIA_IDENTIFER },
        { type: 'text', value: '&nbsp' },
        { type: 'variable', value: VariableOption.SCENARIO_TITLE },
        { type: 'text', value: '-' },
        { type: 'variable', value: VariableOption.CUSTOM_CRITERIA_COMMENT },
        { type: 'text', value: '&nbsp' },
      ],
    },
    [EditableBlockType.CRITERIA_ANALYTES]: {
      is_visible: true,
      title: 'Criteria Analytes:',
    },
    [EditableBlockType.CUSTOM_NOTES]: {
      is_visible: true,
      title: 'Notes:',
      notes: [],
    },
    [EditableBlockType.RESULT_NOTES]: {
      is_visible: true,
      title: 'Result Notes:',
    },
    [EditableBlockType.CHEMICAL_FACTOR]: {
      is_visible: true,
      title: 'Chemical Factors:',
    },
    [EditableBlockType.GUIDELINE_CHANGE]: {
      is_visible: true,
      title: 'Guideline Changes:',
    },
    [EditableBlockType.GUIDELINE_NOTE]: {
      is_visible: true,
      title: 'Guideline Notes:',
    },
  };

  // In future if we add new blocks, need to populate these defaults
  const savedConfig = scenarioSet?.footer_settings;
  if (savedConfig) {
    Object.keys(defaultConfig).forEach((key) => {
      if (!savedConfig[key]) {
        savedConfig[key] = defaultConfig[key];
      }
    });

    return savedConfig;
  }

  return defaultConfig;
};

export const hasFooterSettingToggled = (
  blockType: EditableBlockType,
  scenarioSet: ScenarioSet | null,
  setting: 'is_visible'
) => {
  if (
    !scenarioSet?.footer_settings ||
    scenarioSet?.footer_settings?.[blockType]?.[setting]
  ) {
    return true;
  }

  return false;
};
