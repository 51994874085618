<template>
  <div v-tooltip="getDepth">
    {{ getDepth }}
  </div>
</template>

<script>
export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    unit() {
      return this.field.options.unit || 'm';
    },
    getDepth() {
      if (this.field.options.is_range && this.value.value2) {
        return `${this.value.value} - ${this.value.value2} ${this.unit}`;
      }

      return `${this.value.value} ${this.unit}`;
    },
  },
};
</script>
