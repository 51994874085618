import { Feature } from 'ol';
import { Vector as VectorSource } from 'ol/source';
import { Layer } from 'ol/layer';
import { Point } from 'ol/geom';
import { assignIdToLayer } from './LayerManager';
import * as utils from '../utils';
import { createTextStyle } from '../styles';

export default function createTextLayer(map, position, initialVisible = true) {
  const textEl = document.createElement('div');
  textEl.style.position = 'absolute';
  textEl.style.whiteSpace = 'pre-wrap';
  let visible = initialVisible;

  const layer = new Layer({
    source: createSource(position),
    render: function () {
      const viewer = map.getViewer();
      const style = createTextStyle(this.options, viewer.figureLayout.zoom);
      textEl.innerHTML = this.options.text;
      for (const property in style) {
        textEl.style[property] = style[property];
      }

      const position = this.getPosition();
      const pixel = map.getPixelFromCoordinate(position);
      textEl.style.left = `${pixel[0]}px`;
      textEl.style.top = `${pixel[1]}px`;

      return visible ? textEl : null;
    },
  });
  assignIdToLayer(layer);

  layer.getFirstFeature = function () {
    const [firstFeature] = this.getSource().getFeatures();
    return firstFeature;
  };
  layer.hasFeature = function (feature) {
    return this.getSource().hasFeature(feature);
  };

  layer.show = function () {
    visible = true;
    this.changed();
  };

  layer.hide = function () {
    visible = false;
    this.changed();
  };

  layer.intersectsCoordinate = function (coordinate) {
    return utils.position.CheckIsPositionInHtmlElement(map, coordinate, textEl);
  };

  layer.getPosition = function () {
    return this.getFirstFeature().getGeometry().getCoordinates();
  };

  layer.setPosition = function (position) {
    this.getFirstFeature().getGeometry().setCoordinates(position);
  };

  layer.duplicate = function () {
    const duplicate = createTextLayer(map, this.getPosition().slice());
    duplicate.applyOptions({ ...this.options });
    return duplicate;
  };

  layer.toGeoJSON = function () {
    const feature = this.getFirstFeature().clone();
    feature.setProperties({
      ...this.options,
    });
    return utils.toGeoJSON(map, feature);
  };

  layer.applyOptions = function (options) {
    this.options = options;
    this.changed();
  };

  layer.refresh = function () {
    this.applyOptions(this.options);
  };

  return layer;
}

function createSource(position) {
  const point = new Point(position);
  const feature = new Feature(point);
  return new VectorSource({
    features: [feature],
  });
}
