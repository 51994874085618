<template>
  <div class="form-section">
    <div
      v-if="!currentValue"
      class="bg-dark text-white d-flex justify-content-between align-items-center p-2 p-md-3 clickable rounded"
      :id="'section-' + section.id"
      :class="{
        'mb-2': section.collapsed,
      }"
      @click.prevent="toggleSectionCollapsed()"
    >
      <span class="mb-0 fs-md-6 fw-medium">{{ section.label }}</span>

      <div class="d-flex align-items-center">
        <button
          v-if="sectionCount === 1 && checkIsFetchButtonVisible(0)"
          type="button"
          class="btn btn-outline-secondary text-white me-2"
          :disabled="isFetchingByIndex[0]"
          @click.stop="handleFetchClick(0)"
          title="Click to get the current position."
        >
          <div
            v-if="isFetchingByIndex[0]"
            class="spinner-border spinner-border-sm"
          ></div>
          <i v-else class="fas" :class="fetchIcon" />
        </button>

        <span
          class="fal mb-0"
          :class="{
            'fa-chevron-down': section.collapsed,
            'fa-chevron-up': !section.collapsed,
          }"
        />
      </div>
    </div>

    <div v-if="!section.collapsed">
      <template v-if="!isBespokePointOfInterestSection">
        <div class="py-2">
          <div
            v-if="shownSectionFields.length == 0"
            class="alert alert-danger mb-0"
          >
            No fields exist under this section, please add them in the template
            builder.
          </div>

          <template v-else>
            <div
              v-for="n in sectionCount"
              :key="n"
              class="position-relative"
              :class="{
                'mt-2': !currentValue && checkIsGapVisible(n),
              }"
              :id="'section-' + section.id + '-' + n"
            >
              <div
                v-if="sectionCount > 1 && !currentValue"
                :ref="getTitleBarRef(n)"
                class="d-flex justify-content-between align-items-center bg-light clickable title-bar mb-1 w-100"
                @click="toggleSectionRepeatCollapsed(n)"
              >
                <div
                  class="p-2 px-3 overflow-hidden text-truncate"
                  :title="getRepeatedSectionTitle(n)"
                >
                  <b :key="reactivityNumber">{{
                    getRepeatedSectionTitle(n)
                  }}</b>
                </div>

                <SecondaryFieldHeader
                  v-if="secondaryField"
                  class="flex-grow-1 flex-shrink-0 align-self-stretch"
                  :secondaryField="secondaryField"
                  :inputValue="getInputValue(secondaryField, n - 1)"
                />

                <div
                  class="flex-shrink-0 d-flex justify-content-end align-items-center p-2 px-3 action-bar"
                >
                  <button
                    v-if="checkIsFetchButtonVisible(n - 1)"
                    type="button"
                    class="btn btn-sm btn-outline-secondary me-2"
                    title="Click to get the current position."
                    :disabled="isFetchingByIndex[n - 1]"
                    @click.stop="handleFetchClick(n - 1)"
                  >
                    <div
                      v-if="isFetchingByIndex[n - 1]"
                      class="spinner-border spinner-border-sm"
                    ></div>
                    <i v-else class="fas" :class="fetchIcon" />
                  </button>

                  <button
                    v-if="sectionToDelete != n && canDeleteSection(n - 1)"
                    class="btn btn-sm btn-outline-danger"
                    :disabled="isFetchingByIndex[n - 1]"
                    @click.stop="() => (sectionToDelete = n)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>

                  <div
                    v-if="sectionToDelete == n"
                    class="btn-group btn-group-sm"
                  >
                    <button
                      type="button"
                      class="btn btn-light"
                      @click.stop="() => (sectionToDelete = null)"
                      :disabled="isFetchingByIndex[n - 1]"
                    >
                      <i class="fas fa-times fa-fw" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click.stop="removeSection(n - 1)"
                      :disabled="isFetchingByIndex[n - 1]"
                    >
                      <i class="fas fa-check fa-fw" />
                    </button>
                  </div>

                  <h6
                    class="fas clickable mb-0 ms-2"
                    :class="{
                      'fa-chevron-down': sectionRepeatIsCollapsed(n),
                      'fa-chevron-up': !sectionRepeatIsCollapsed(n),
                    }"
                    @click.stop="toggleSectionRepeatCollapsed(n)"
                  />
                </div>
              </div>

              <div v-if="sectionCount == 1 || !sectionRepeatIsCollapsed(n)">
                <template v-for="(field, fieldIndex) of shownSectionFields">
                  <FormInput
                    :key="field.id"
                    v-show="
                      !currentValue ||
                      (currentValue.template_field_id === field.id &&
                        currentValue.template_section_index === n - 1 &&
                        section.id === currentValue.template_section_id)
                    "
                    :editingByValue="currentValue"
                    :search="search"
                    :field="field"
                    :sample="sample"
                    :inputValue="getInputValue(field, n - 1)"
                    :lastValue="n > 1 ? getInputValue(field, n - 2) : null"
                    :inputValues="inputValues"
                    :fields="shownSectionFields"
                    :allFields="allFields"
                    :allSections="allSections"
                    :sectionCount="sectionCount"
                    :sectionIndex="n - 1"
                    :templateTabId="section.template_tab_id"
                    :repeatable="!!section.is_repeatable"
                    :sampleIdentifier="sampleIdentifier"
                    :class="{
                      'mb-2': fieldIndex != shownSectionFields.length - 1,
                    }"
                    :id="getFieldId(field.id, n)"
                    :css-field-scroll-margin-top="cssFieldScrollMarginTop"
                    @input="$emit('input')"
                    @clickCamera="clickCamera"
                    @clickVideo="clickVideo"
                    @clickSetPreview="clickSetPreview"
                    @clickStartDrawing="clickStartDrawing"
                    @isLoading="isLoading"
                  />
                </template>
              </div>
            </div>
          </template>
        </div>

        <template
          v-if="
            showAddSection &&
            shownSectionFields.length > 0 &&
            section.is_repeatable
          "
        >
          <hr class="mt-2" />

          <div class="d-flex flex-wrap">
            <div
              class="flex-grow-1 text-center mb-3 px-2"
              :style="{ 'min-width': '50%' }"
            >
              <a
                class="btn btn-outline-primary w-100"
                @click="() => addSection()"
              >
                <i class="fas fa-plus"></i>
                Repeat {{ section.label }}
              </a>
            </div>

            <div
              class="flex-grow-1 text-center mb-3 px-2"
              :style="{ 'min-width': '50%' }"
            >
              <a
                class="btn btn-outline-primary w-100"
                @click="() => addSection(true)"
              >
                <i class="fas fa-copy"></i>
                Duplicate the previous one
              </a>
            </div>
          </div>
        </template>
      </template>
      <div v-else>
        <AlertBox type="info" class="mt-2">
          There are {{ pointsOfInterest.length }} data points,
          {{ pointsOfInterestWithData.length }} of which
          {{ pointsOfInterestWithData.length === 1 ? 'has' : 'have' }} data
          collected.
        </AlertBox>
      </div>
    </div>
  </div>
</template>

<script>
import AlertBox from '@component-library/components/AlertBox.vue';
import { getPointsOfInterest } from '../business-logic/input-value';
import {
  getNumberTitle,
  getPrimaryFieldTitle,
} from '../business-logic/section';
import { FieldTypeIds, checkIsConditionMet } from '../fields';
import FormInput from './FormInput.vue';
import SecondaryFieldHeader from './SecondaryFieldHeader.vue';

export default {
  props: {
    search: {
      required: false,
      type: String,
      default: null,
    },
    templateTab: Object,
    section: Object,
    allFields: Array,
    allSections: Array,
    sample: Object,
    inputValues: Array,
    showDelete: Boolean,
    showAddSection: Boolean,
    collapse: Boolean,
    publicForm: {
      required: false,
      default: false,
      type: Boolean,
    },
    previewForm: {
      required: false,
      default: false,
      type: Boolean,
    },
    shouldEmit: {
      required: false,
      default: true,
      type: Boolean,
    },
    isNavigationVisible: {
      type: Boolean,
      default: false,
    },
    editingField: Object,
    keepAllOpen: Boolean,
    fields: Array,
    currentValue: {
      type: Number | null,
      required: false,
    },
    sampleIdentifier: {
      type: String | null,
      required: false,
    },
    cssFieldScrollMarginTop: {
      type: String,
      default: '0px',
    },
  },
  inject: ['formContext'],
  data: () => ({
    sectionCount: 1,
    sectionToDelete: null,
    openedRepeatedSections: [],
    // For some sections, e.g. GPS Point Metadata, data needs to be fetched and filled into the form.
    isFetchingByIndex: {},
    primaryFieldSectionTitles: [],
    reactivityNumber: 0,
  }),
  components: { FormInput, SecondaryFieldHeader, AlertBox },
  watch: {
    search(updated) {
      if (!!updated) {
        this.toggleSectionCollapsed(false);
        this.openedRepeatedSections = Array.from(
          { length: this.sectionCount },
          (_, i) => i + 1
        );
      }
    },
    section() {
      if (!this.section.is_repeatable) {
        this.sectionCount = 1;
        return;
      }
    },
    inputValues: {
      handler: function () {
        this.updateForm();
        this.checkValidationStatus();
      },
      deep: true,
    },
    sectionCount(newValue) {
      this.formContext.updateSectionCount(
        this.section.template_tab_id,
        this.section.id,
        newValue
      );
    },
    editingField() {
      this.highlightEditingField();
    },
  },
  computed: {
    shownSectionFields() {
      // from single input field pane
      if (this.fields) {
        return this.fields;
      }

      if (!this.publicForm || this.section.is_public_form) {
        return this.section.template_fields;
      }

      return this.section.template_fields.filter(
        (f) => f.options.is_public_form
      );
    },
    fetchIcon() {
      if (this.section.is_gps_point_metadata) {
        return 'fa-location';
      }

      return '';
    },
    secondaryField() {
      return this.section.template_fields.find(
        (item) => item.id === this.section.secondary_field_id
      );
    },
    isBespokePointOfInterestSection() {
      return (
        this.templateTab?.allow_collection_on_poi &&
        this.section.system_reference === 'point_of_interest'
      );
    },
    pointsOfInterest() {
      return this.templateTab
        ? getPointsOfInterest(this.templateTab, this.inputValues)
        : [];
    },
    pointsOfInterestWithData() {
      return this.pointsOfInterest.filter((poi) => !!poi.dataForm);
    },
  },
  methods: {
    getFieldId(fieldId, sectionIndex) {
      return `field-${fieldId}-${sectionIndex}`;
    },
    getRepeatedSectionTitle(n) {
      return this.section.is_number_used_as_title
        ? this.getNumberTitle(n)
        : this.getPrimaryFieldTitle(n);
    },
    emitFetchData(index) {
      this.$emit('fetchData', {
        section: this.section,
        index,
        inputValues: this.inputValues,
        updateInputValue: ({ inputValue, field }) => {
          this.$root.$emit('updateInputValue', {
            inputValue,
            field,
            sectionIndex: index,
            templateTabId: this.section.template_tab_id,
          });
        },
        setIsFetching: (index, value) => {
          this.isFetchingByIndex = {
            ...this.isFetchingByIndex,
            [index]: value,
          };
        },
      });
    },
    onAddSection(index) {
      this.emitFetchData(index);
    },
    clickCamera(data) {
      if (!this.shouldEmit) return;
      this.$emit('clickCamera', data);
    },
    clickVideo(data) {
      if (!this.shouldEmit) return;
      this.$emit('clickVideo', data);
    },
    clickSetPreview(data) {
      if (!this.shouldEmit) return;
      this.$emit('clickSetPreview', data);
    },
    clickStartDrawing(data) {
      if (!this.shouldEmit) return;
      this.$emit('clickStartDrawing', data);
    },
    isLoading(value) {
      if (!this.shouldEmit) return;
      this.$emit('isLoading', value);
    },
    getInputValue(field, sectionIndex = 0) {
      if (sectionIndex < 0) {
        throw new Error('Cannot get negative sectionIndex');
      }
      const iv = this.inputValues.find(
        (iv) =>
          iv.template_field_id == field.id &&
          iv.template_section_index == sectionIndex
      );

      if (iv) {
        return iv;
      }

      const placeholder = {
        template_field_id: field.id,
        template_section_index: sectionIndex,
        template_section_id: field.template_section_id,
        value: null,
        value2: null,
        options: null,
        sample_id: this.sample ? this.sample.id : null,
      };

      if (this.shouldEmit) {
        this.$root.$emit('updateInputValue', {
          inputValue: placeholder,
          field,
          sectionIndex,
          templateTabId: this.section.template_tab_id,
          isDefaultInputValue: true,
        });
      }

      return placeholder;
    },
    updateForm() {
      if (!this.section.is_repeatable) {
        this.sectionCount = 1;
        return;
      }

      for (let iv of this.inputValues) {
        if (
          iv.template_section_id == this.section.id &&
          iv.template_section_index > this.sectionCount - 1
        ) {
          this.sectionCount = iv.template_section_index + 1;
        }
      }
    },
    async addSection(shouldDuplicate = false) {
      const myLastInputValues = this.inputValues.filter(
        (iv) =>
          (!this.sample || iv.sample_id === this.sample.id) &&
          iv.template_section_id === this.section.id &&
          iv.template_section_index === this.sectionCount - 1
      );
      const newInputValues = [];
      myLastInputValues.forEach((mliv) => {
        const field = this.allFields.find(
          (f) => f.id === mliv.template_field_id
        );
        const shouldDuplicateField =
          shouldDuplicate &&
          field.field_type_id !== FieldTypeIds.MEDIA &&
          field.field_type_id !== FieldTypeIds.DATE &&
          (field.field_type_id !== FieldTypeIds.NUMBER ||
            !field.options.increment);
        const shouldRangeDepthKeepContinuity =
          field.field_type_id === FieldTypeIds.DEPTH &&
          field.options?.is_range &&
          field.options.should_keep_continuity;

        if (!shouldDuplicateField && !shouldRangeDepthKeepContinuity) {
          return;
        }

        const {
          value,
          value2,
          template_section_index: templateSectionIndex,
        } = mliv;

        newInputValues.push({
          ...mliv,
          id: null,
          template_section_index: templateSectionIndex + 1,
          value: shouldRangeDepthKeepContinuity ? value2 : value,
          value2: shouldRangeDepthKeepContinuity ? null : value2,
          deleted_at: null,
          created_at: null,
          updated_at: null,
        });
      });
      this.inputValues.push(...newInputValues);

      this.sectionCount++;
      if (this.openedRepeatedSections.indexOf(this.sectionCount) === -1) {
        this.openedRepeatedSections = [this.sectionCount];
      }

      await this.$nextTick();
      const [titleBar] = this.$refs[this.getTitleBarRef(this.sectionCount)];
      titleBar.scrollIntoView();
      if (this.isNavigationVisible) {
        document.getElementById('sampleModal').scrollTop -= 51;
      }

      this.onAddSection(this.sectionCount - 1);
    },
    removeSection(index) {
      const toDelete = this.inputValues.filter(
        (iv) =>
          iv.template_section_index == index &&
          iv.template_section_id == this.section.id
      );

      for (let inputValue of toDelete) {
        this.inputValues.splice(this.inputValues.indexOf(inputValue), 1);
      }

      this.inputValues.forEach((iv) => {
        if (
          iv.template_section_id == this.section.id &&
          iv.template_section_index > index &&
          iv.template_section_index > 0
        ) {
          iv.template_section_index--;
        }
      });

      // This is required because this.inputValues could be a
      // subset of the inputValues of a sample. For example,
      // when FormSection is used in the TableManagement.vue component.
      this.$emit('updateInputValues', this.inputValues);

      this.sectionCount--;

      let rIndex = this.openedRepeatedSections.indexOf(index + 1);
      if (rIndex !== -1) {
        this.openedRepeatedSections.splice(rIndex, 1);
      }

      this.sectionToDelete = null;
    },
    checkValidationStatus() {
      if (!this.section.template_fields) {
        return true;
      }
      const requiredFieldIds = this.section.template_fields
        .filter(
          (f) => f.is_required && checkIsConditionMet(f, this.inputValues)
        )
        .map((f) => f.id);

      const isValid =
        this.inputValues.filter(
          (v) =>
            !v.value &&
            !v.value2 &&
            v.template_section_id == this.section.id &&
            v.template_section_index >= 0 &&
            requiredFieldIds.includes(v.template_field_id)
        ).length == 0;

      this.$set(this.section, 'validated', isValid);

      return isValid;
    },
    canDeleteSection(sectionIndex) {
      const sectionValues = this.inputValues.filter(
        (iv) =>
          iv.template_section_id == this.section.id &&
          iv.template_section_index == sectionIndex
      );

      if (sectionValues.filter((iv) => iv.created_at).length == 0) {
        return true;
      }

      const currentDate = new Date().setHours(0, 0, 0, 0);

      return (
        sectionValues.filter(
          (iv) =>
            iv.created_at &&
            new Date(iv.created_at).setHours(0, 0, 0, 0) >= currentDate
        ).length > 0
      );
    },
    toggleSectionCollapsed(option = null) {
      if (option != false) this.$emit('clearSearch', true);
      if (option == null) option = !this.section.collapsed;
      this.$set(this.section, 'collapsed', option);
    },
    sectionRepeatIsCollapsed(index) {
      return this.openedRepeatedSections.indexOf(index) === -1;
    },
    toggleSectionRepeatCollapsed(index) {
      this.$emit('clearSearch', true);
      let rIndex = this.openedRepeatedSections.indexOf(index);
      if (rIndex !== -1) {
        this.openedRepeatedSections.splice(rIndex, 1);
        return;
      }

      this.openedRepeatedSections.push(index);
    },
    collapseAllRepeatedSections() {
      this.$emit('clearSearch', true);
      this.openedRepeatedSections = [];
    },
    getNumberTitle(index) {
      return getNumberTitle(index);
    },
    getPrimaryFieldTitle(index) {
      const { template_fields, primary_field_id } = this.section;
      const primaryField =
        (primary_field_id
          ? template_fields.find((f) => f.id === primary_field_id)
          : null) ?? template_fields[0];
      const inputValue = this.getInputValue(primaryField, index - 1);
      const value = getPrimaryFieldTitle(
        primaryField,
        inputValue,
        index,
        (newValue) => {
          if (newValue !== this.primaryFieldSectionTitles[index]) {
            this.primaryFieldSectionTitles[index] = newValue;
            this.reactivityNumber++;
          }
        }
      );
      while (this.primaryFieldSectionTitles.length <= index) {
        this.primaryFieldSectionTitles.push(null);
      }
      this.primaryFieldSectionTitles[index] = value;
      return value;
    },
    checkIsFetchButtonVisible(index) {
      if (this.section.is_gps_point_metadata) {
        const latitudeField = this.section.template_fields.find(
          (item) => item.system_reference === 'latitude'
        );
        return !!this.inputValues.find(
          (item) =>
            item.template_section_id === this.section.id &&
            item.template_section_index ===
              (this.sectionCount === 1 ? 0 : index) &&
            item.template_field_id === latitudeField.id &&
            !item.value
        );
      }

      return false;
    },
    handleFetchClick(index) {
      this.emitFetchData(index);
    },
    getTitleBarRef(n) {
      return `section${this.section.id}TitleBar${n}`;
    },
    checkIsGapVisible(n) {
      return (
        n > 1 &&
        (!this.secondaryField ||
          this.secondaryField.field_type_id !== FieldTypeIds.LITHOLOGY)
      );
    },
    highlightEditingField() {
      if (!this.editingField) {
        return;
      }

      this.$nextTick(() => {
        const positionManager = document.querySelector('.gather-form');
        if (!positionManager) {
          return;
        }

        const fieldElement = document.getElementById(
          this.getFieldId(
            this.editingField.field_id,
            this.editingField.section_index
          )
        );
        if (!fieldElement) {
          return;
        }

        fieldElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      });
    },
  },
  created() {
    this.updateForm();

    this.$root.$on('updateForm', this.updateForm);

    this.checkValidationStatus();

    if (this.sample && this.sample.id && !this.keepAllOpen) {
      this.collapseAllRepeatedSections();

      if (
        this.collapse ||
        (this.section.validated &&
          this.inputValues.filter(
            (iv) =>
              (iv.value || iv.value2) &&
              iv.template_section_id == this.section.id
          ).length > 0)
      ) {
        this.$set(this.section, 'collapsed', true);
      }
    }

    if (this.keepAllOpen) {
      this.openedRepeatedSections = Array.from(
        { length: this.sectionCount },
        (_, i) => i + 1
      );
    }

    this.highlightEditingField();
  },
  mounted() {
    this.toggleSectionCollapsed(false);

    const isNew =
      !this.previewForm &&
      (this.publicForm ||
        !this.sample.id ||
        (this.sectionCount === 1 &&
          !this.inputValues.find(
            (item) =>
              item.id &&
              item.sample_id === this.sample.id &&
              item.template_section_id === this.section.id &&
              item.template_section_index === 0
          )));
    if (isNew) {
      this.onAddSection(0);
    }
  },
  beforeDestroy() {
    this.$root.$off('updateForm', this.updateForm);
  },
};
</script>

<style lang="scss" scoped>
.form-section {
  .title-bar {
    min-height: 46px;

    .action-bar {
      min-width: 90px;
    }
  }
}
</style>
