export const DPI_96 = 96;
export const DPI_300 = 300;

export const INCHES_PER_MILLIMETER = 1 / 25.4;
export const INCHES_PER_POINT = 1 / 72;

export const DEFAULT_MARGIN_IN_MM = 8;

export const MEASURE_UNIT_PIXEL = 1;
export const MEASURE_UNIT_MILLIMETER = 2;
export const MEASURE_UNIT_INCH = 3;
export const MEASURE_UNIT_POINT = 4;
export const MEASURE_UNITS = [
  { code: MEASURE_UNIT_PIXEL, label: 'px' },
  { code: MEASURE_UNIT_MILLIMETER, label: 'mm' },
  { code: MEASURE_UNIT_INCH, label: 'in' },
  { code: MEASURE_UNIT_POINT, label: 'pt' },
];

export const ORIENTATION_LANDSCAPE = 'landscape';
export const ORIENTATION_PORTRAIT = 'portrait';
export const ORIENTATIONS = [
  { code: ORIENTATION_LANDSCAPE, label: 'Landscape' },
  { code: ORIENTATION_PORTRAIT, label: 'Portrait' },
];

export const PAPER_SIZE_A4 = {
  code: 'A4',
  label: 'A4 Print',
  shortLabel: 'A4',
  width: 210,
  height: 297,
  orientationCode: ORIENTATION_PORTRAIT,
  measureUnitCode: MEASURE_UNIT_MILLIMETER,
};
export const PAPER_SIZE_A3 = {
  code: 'A3',
  label: 'A3 Print',
  shortLabel: 'A3',
  width: 297,
  height: 420,
  orientationCode: ORIENTATION_PORTRAIT,
  measureUnitCode: MEASURE_UNIT_MILLIMETER,
};
// US Letter
export const PAPER_SIZE_ANSI_A = {
  code: 'ANSI_A',
  label: 'US Letter',
  shortLabel: 'US Letter',
  width: 8.5,
  height: 11.0,
  orientationCode: ORIENTATION_PORTRAIT,
  measureUnitCode: MEASURE_UNIT_INCH,
};
// US Tabloid/Ledger
export const PAPER_SIZE_ANSI_B = {
  code: 'ANSI_B',
  label: 'US Tabloid/Ledger',
  shortLabel: 'US Tabloid/Ledger',
  width: 11.0,
  height: 17.0,
  orientationCode: ORIENTATION_PORTRAIT,
  measureUnitCode: MEASURE_UNIT_INCH,
};
export const PAPER_SIZES = [
  PAPER_SIZE_A4,
  PAPER_SIZE_A3,
  PAPER_SIZE_ANSI_A,
  PAPER_SIZE_ANSI_B,
];
export const COUNTRY_PAPER_SIZES = {
  US: [PAPER_SIZE_ANSI_A, PAPER_SIZE_ANSI_B],
  NZ: [PAPER_SIZE_A4, PAPER_SIZE_A3],
  AU: [PAPER_SIZE_A4, PAPER_SIZE_A3],
  CA: [PAPER_SIZE_A4, PAPER_SIZE_A3],
  GB: [PAPER_SIZE_A4, PAPER_SIZE_A3],
};
export const RAW_SCALE_SHORTCUTS = [0.5, 0.75, 1, 1.25, 1.5, 2];

function getPixelsPerMeasureUnit(dpi, measureUnitCode) {
  if (measureUnitCode === MEASURE_UNIT_PIXEL) {
    return 1;
  } else if (measureUnitCode === MEASURE_UNIT_MILLIMETER) {
    return INCHES_PER_MILLIMETER * dpi;
  } else if (measureUnitCode === MEASURE_UNIT_POINT) {
    return INCHES_PER_POINT * dpi;
  } else if (measureUnitCode === MEASURE_UNIT_INCH) {
    return dpi;
  }

  throw `Invalid measure unit: ${measureUnitCode}.`;
}

export function transformMeasure(
  measure,
  sourceDpi,
  targetDpi,
  sourceMeasureUnitCode,
  targetMeasureUnitCode
) {
  if (sourceMeasureUnitCode === targetMeasureUnitCode) {
    return measure;
  }

  const measureInPx =
    measure * getPixelsPerMeasureUnit(sourceDpi, sourceMeasureUnitCode);
  if (targetMeasureUnitCode === MEASURE_UNIT_PIXEL) {
    return measureInPx;
  } else if (targetMeasureUnitCode === MEASURE_UNIT_MILLIMETER) {
    return measureInPx / (INCHES_PER_MILLIMETER * targetDpi);
  } else if (targetMeasureUnitCode === MEASURE_UNIT_POINT) {
    return measureInPx / (INCHES_PER_POINT * targetDpi);
  } else if (targetMeasureUnitCode === MEASURE_UNIT_INCH) {
    return measureInPx / targetDpi;
  }

  throw `Invalid target measure unit: ${targetMeasureUnitCode}.`;
}

function transformPaperSize(
  paperSize,
  sourceDpi,
  targetDpi,
  targetOrientationCode,
  targetMeasureUnitCode
) {
  const {
    width: sourceWidth,
    height: sourceHeight,
    orientationCode: sourceOrientationCode,
    measureUnitCode: sourceMeasureUnitCode,
  } = paperSize;

  let targetWidth =
    sourceOrientationCode === targetOrientationCode
      ? sourceWidth
      : sourceHeight;
  targetWidth = transformMeasure(
    targetWidth,
    sourceDpi,
    targetDpi,
    sourceMeasureUnitCode,
    targetMeasureUnitCode
  );
  let targetHeight =
    sourceOrientationCode === targetOrientationCode
      ? sourceHeight
      : sourceWidth;
  targetHeight = transformMeasure(
    targetHeight,
    sourceDpi,
    targetDpi,
    sourceMeasureUnitCode,
    targetMeasureUnitCode
  );

  return {
    ...paperSize,
    width: targetWidth,
    height: targetHeight,
    orientationCode: targetOrientationCode,
    measureUnitCode: targetMeasureUnitCode,
  };
}

/**
 *
 * @param {Object} paperSize The paper size in the portait orientation.
 * @param {Number} dpi
 * @returns
 */
export function getPrintResolution(paperSize) {
  const { height } = transformPaperSize(
    paperSize,
    DPI_300,
    DPI_300,
    paperSize.orientationCode,
    MEASURE_UNIT_PIXEL
  );
  const margins = transformMeasure(
    DEFAULT_MARGIN_IN_MM * 2,
    DPI_300,
    DPI_300,
    MEASURE_UNIT_MILLIMETER,
    MEASURE_UNIT_PIXEL
  );
  return height - margins;
}

/**
 *
 * @param {Object} paperSize The paper size in the portait orientation.
 * @param {Number} dpi
 * @returns
 */
export function getPrintRatio(paperSize) {
  const { width } = transformPaperSize(
    paperSize,
    DPI_300,
    DPI_300,
    paperSize.orientationCode,
    MEASURE_UNIT_PIXEL
  );
  const margins = transformMeasure(
    DEFAULT_MARGIN_IN_MM * 2,
    DPI_300,
    DPI_300,
    MEASURE_UNIT_MILLIMETER,
    MEASURE_UNIT_PIXEL
  );
  return (width - margins) / getPrintResolution(paperSize);
}

/**
 * The scaled paper size is the size of the paper in pixels which has been adjusted
 * so that the paper fits to its container.
 * @param {Object} containerSize The size of the paper's container in pixels.
 * @param {Object} paperSize The size of paper in pixels.
 * @param {Object} orientation
 * @param {Number} margin The margin which is in paper size's unit.
 */
export function calculateScaledPaperSize(
  containerSize,
  paperSize,
  orientation,
  margin,
  zoomLevel = 1
) {
  const originalPaperSize = transformPaperSize(
    paperSize,
    DPI_300,
    DPI_300,
    orientation.code,
    MEASURE_UNIT_PIXEL
  );
  const { width, height } = originalPaperSize;
  const ratio = width / height;

  const widthBase = Math.min(width, containerSize.width);
  const heightBase = Math.min(height, containerSize.height);
  const proportionalWidth = heightBase * ratio; // Assume that the heightBase fits to the container.
  const proportionalHeight = widthBase / ratio; // Assume that the widthBase fits to the container.
  const margins = transformMeasure(
    margin * 2,
    DPI_300,
    DPI_300,
    paperSize.measureUnitCode,
    MEASURE_UNIT_PIXEL
  );

  let fittedPaperSize;
  if (proportionalHeight <= heightBase) {
    const rawScale = widthBase / width;
    const contentWidth = widthBase - margins * rawScale;
    const contentHeight = proportionalHeight - margins * rawScale;
    fittedPaperSize = {
      ...originalPaperSize,
      width: widthBase,
      height: proportionalHeight,
      contentWidth,
      contentHeight,
      rawScale,
      scale: contentWidth / (width - margins),
    };
  } else {
    const rawScale = heightBase / height;
    const contentWidth = proportionalWidth - margins * rawScale;
    const contentHeight = heightBase - margins * rawScale;
    fittedPaperSize = {
      ...originalPaperSize,
      width: proportionalWidth,
      height: heightBase,
      contentWidth,
      contentHeight,
      rawScale,
      scale: contentHeight / (height - margins),
    };
  }

  return {
    ...fittedPaperSize,
    width: fittedPaperSize.width * zoomLevel,
    height: fittedPaperSize.height * zoomLevel,
    contentWidth: fittedPaperSize.contentWidth * zoomLevel,
    contentHeight: fittedPaperSize.contentHeight * zoomLevel,
    rawScale: fittedPaperSize.rawScale * zoomLevel,
    scale: fittedPaperSize.scale * zoomLevel,
  };
}

export function calculateScaledPaperContentSize(
  paperSize,
  orientation,
  margin,
  scale
) {
  const { width, height } = transformPaperSize(
    paperSize,
    DPI_300,
    DPI_300,
    orientation.code,
    MEASURE_UNIT_PIXEL
  );
  const margins = transformMeasure(
    margin * 2,
    DPI_300,
    DPI_300,
    paperSize.measureUnitCode,
    MEASURE_UNIT_PIXEL
  );
  const contentWidth = (width - margins) * scale;
  const contentHeight = (height - margins) * scale;

  return {
    contentWidth,
    contentHeight,
  };
}

export function findPaperSize(paperSizeCode) {
  return PAPER_SIZES.find((item) => item.code === paperSizeCode);
}

export function findOrientation(orientationCode) {
  return ORIENTATIONS.find((item) => item.code === orientationCode);
}

export function findMeasureUnit(measureUnitCode) {
  return MEASURE_UNITS.find((item) => item.code === measureUnitCode);
}

export function calculateFontSize(measure, rawScale, sourceDpi = DPI_300) {
  return (
    transformMeasure(
      measure,
      sourceDpi,
      DPI_96,
      MEASURE_UNIT_POINT,
      MEASURE_UNIT_PIXEL
    ) * rawScale
  );
}
