<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import _debounce from 'lodash/debounce';

type Positon = {
  clientX: number | undefined;
  clientY: number | undefined;
};

const props = withDefaults(
  defineProps<{
    storageKey?: string;
    width?: string;
  }>(),
  {
    storageKey: 'movable-box-location',
    width: '300px',
  }
);

const position = reactive<Positon>({
  clientX: undefined,
  clientY: undefined,
});

const isDisabled = ref(false);

const draggableContainer = ref<HTMLDivElement | null>(null);
const elementHeight = ref(0);

const storeLocationInLocalStorage = _debounce((top: number, left: number) => {
  localStorage.setItem(
    props.storageKey,
    JSON.stringify({
      top,
      left,
      position,
    })
  );
}, 300);

const dragMouseDown = (e: MouseEvent) => {
  if (isDisabled.value) {
    return;
  }

  e.preventDefault();

  position.clientX = e.clientX;
  position.clientY = e.clientY;

  if (elementHeight.value === 0) {
    elementHeight.value = (
      draggableContainer.value!.childNodes[0] as HTMLDivElement
    ).getBoundingClientRect().height;
  }

  document.onmousemove = elementDrag;
  document.onmouseup = closeDragElement;
};

const elementDrag = (e) => {
  if (isDisabled.value) {
    return;
  }

  e.preventDefault();

  const container = draggableContainer.value!;

  const proposedMovementX = position.clientX! - e.clientX;
  const proposedMovementY = position.clientY! - e.clientY;

  const newTop = container.offsetTop - proposedMovementY;
  const newLeft = container.offsetLeft - proposedMovementX;

  const parent = container.offsetParent!;
  const parentRect = parent.getBoundingClientRect();
  const elemRect = container.getBoundingClientRect();

  const withinVerticalBounds =
    newTop >= 0 && newTop + elementHeight.value <= parentRect.height;
  const withinHorizontalBounds =
    newLeft >= 0 && newLeft + elemRect.width <= parentRect.width;

  if (withinVerticalBounds) {
    container.style.top = newTop + 'px';
    position.clientY = e.clientY;

    storeLocationInLocalStorage(newTop, newLeft);
  }

  if (withinHorizontalBounds) {
    container.style.left = newLeft + 'px';
    position.clientX = e.clientX;

    storeLocationInLocalStorage(newTop, newLeft);
  }
};

const closeDragElement = () => {
  if (isDisabled.value) {
    return;
  }

  document.onmouseup = null;
  document.onmousemove = null;
};

const isOutsideScreenBounds = (top: number, left: number) => {
  return left > window.innerWidth || top > window.innerHeight;
};

const setIsDisabled = (isDisabledSetter: boolean) => {
  isDisabled.value = isDisabledSetter;
};

onMounted(() => {
  const storedPosition = localStorage.getItem(props.storageKey);
  if (storedPosition) {
    const { top, left, position: currentPosition } = JSON.parse(storedPosition);
    if (isOutsideScreenBounds(top, left)) {
      return;
    }

    const container = draggableContainer.value!;
    container.style.top = top + 'px';
    container.style.left = left + 'px';

    position.clientX = currentPosition.clientX;
    position.clientY = currentPosition.clientY;
  }
});
</script>
<template>
  <div
    ref="draggableContainer"
    @mousedown="dragMouseDown"
    class="draggable-container"
    :class="{
      'is-disabled': isDisabled,
    }"
    :style="{
      width: width,
    }"
  >
    <div class="draggable-wrapper">
      <slot name="default" :setIsDisabled="setIsDisabled"></slot>
    </div>
  </div>
</template>
<style scoped>
.draggable-container {
  position: absolute;
  width: 300px;
  top: 0px;
  right: 0px;
  z-index: 1000;
}
.draggable-wrapper {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #f4f4f4;
}

.draggable-container.is-disabled {
  z-index: 9999 !important;
}
</style>
