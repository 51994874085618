<template>
  <div v-if="valuesForEvaluation.length > 0" class="form-group">
    <label class="form-label" for="text">
      {{ field.label }}
      <sup v-if="field.is_required" class="text-danger">*</sup>
    </label>

    <div class="input-group">
      <input
        type="text"
        class="form-control"
        disabled
        :value="valueDisplayText"
      />
      <span v-if="field.options.unit" class="input-group-text">
        {{ field.options.unit }}
      </span>
    </div>

    <small>
      <code>{{ valuesForHint.join('') }}</code>
    </small>
  </div>
</template>

<script>
import { captureException } from '@sentry/browser';
import * as bl from '../../business-logic';
import { stringifyResult } from '../../business-logic/expression';
import * as bm from '../../business-model';
import {
  ACTION_AVG,
  ACTION_COUNT,
  ACTION_CURRENT,
  ACTION_FIRST,
  ACTION_LAST,
  ACTION_MAX,
  ACTION_MIN,
  ACTION_NEXT,
  ACTION_PREVIOUS,
  ACTION_SUM,
  ParamType,
} from '../../business-model/expression';

export default {
  props: {
    field: Object,
    inputValue: Object,
    inputValues: Array,
    allFields: Array,
    allSections: Array,
    sectionIndex: Number,
  },
  inject: ['formContext'],
  data: () => ({
    valuesForEvaluation: [],
    valuesForHint: [],
  }),
  computed: {
    value() {
      return this.inputValue?.value ?? null;
    },
    valueDisplayText() {
      return bl.expression.getResultDisplayText(this.field, this.value);
    },
    expression() {
      const { expression } = this.field.options;

      if (!expression) {
        return null;
      }

      return typeof expression === 'string'
        ? bl.expression.parseLegacyExpression(expression, this.allFields)
        : expression;
    },
    tags() {
      return this.expression?.tags ?? [];
    },
    resultType() {
      return this.expression?.resultType ?? bm.expression.RESULT_TYPE_NUMBER;
    },
    currentInputValue() {
      return this.formContext.currentInputValue;
    },
  },
  methods: {
    getFieldSectionCount(field) {
      const section = this.allSections.find(
        (s) => s.id === field.template_section_id
      );
      return this.formContext.getSectionCount(
        section.template_tab_id,
        section.id
      );
    },
    getFieldSectionIndex(field) {
      let sectionIndex = this.sectionIndex;
      if (field.template_section_id !== this.field.template_section_id) {
        const section = this.allSections.find(
          (s) => s.id === field.template_section_id
        );
        if (!section.is_repeatable) {
          sectionIndex = 0;
        }
      }
      return sectionIndex;
    },
    getValues(isHybrid = false) {
      return bl.expression.getValuesFromTagsWithFunction(
        this.tags,
        (fieldId, action) => {
          const field = this.allFields.find((f) => f.id === fieldId);
          const fdt = bl.expression.getFieldDisplayText(
            fieldId,
            action,
            this.allFields
          );

          if (!field) {
            return !isHybrid ? null : fdt;
          }

          const sectionIndex = this.getFieldSectionIndex(field);
          const sectionCount = this.getFieldSectionCount(field);
          let result = bl.expression.resolveFieldFromInputValues(
            field,
            action,
            this.inputValues,
            sectionCount,
            sectionIndex
          );
          if (result === null && isHybrid) {
            result = fdt;
          }
          return result;
        }
      );
    },
    evaluate(values) {
      if (!values.length || !bl.expression.checkIsExpressionReady(values)) {
        return null;
      }

      try {
        return bl.expression.evaluateValues(values);
      } catch (e) {
        captureException(e);
        this.$toastStore.error(
          `Failed to calculate the expression '${this.field.label}'.`
        );
      }

      return null;
    },
    updateValues() {
      this.valuesForEvaluation = this.getValues();
      this.valuesForHint = this.getValues(true);
    },
    makeAsyncFn(fn) {
      return async (...args) => {
        await new Promise((resolve) => {
          setTimeout(() => {
            fn(...args);
            resolve();
          }, 0);
        });
      };
    },
  },
  created() {
    this.updateValues();
  },
  watch: {
    valuesForEvaluation: {
      async handler(newValue) {
        try {
          this.formContext.setIsBusy(true);

          // The function needs to be called in the next schedule otherwise
          // the isBusy mechanism of the form context doesn't work.
          await this.makeAsyncFn(() => {
            const value = this.evaluate(newValue);
            if (
              stringifyResult(this.field, value) !==
              stringifyResult(this.field, this.value)
            ) {
              this.$root.$emit('updateInputValue', {
                inputValue: { ...this.inputValue, value },
                field: this.inputValue.template_field_id,
                sectionIndex: this.inputValue.template_section_index,
                templateTabId: this.inputValue.template_tab_id,
                // Being true means the value is always calculated automatically.
                isDefaultInputValue: true,
              });
            }
          })();
        } finally {
          this.formContext.setIsBusy(false);
        }
      },
    },
    currentInputValue: {
      async handler(newValue) {
        try {
          this.formContext.setIsBusy(true);

          await this.makeAsyncFn(() => {
            if (newValue.template_field_id === this.field.id) {
              return;
            }
            const dependencies = this.tags.reduce((accu, t) => {
              if (
                t.type === 'variable' &&
                t.field_id === newValue.template_field_id
              ) {
                const { field_id: fieldId, action } = t;
                accu.push({ fieldId, action });
              } else if (t.type === 'custom_function') {
                for (const argGroup of t.value.argGroups) {
                  for (const arg of argGroup) {
                    const { type, fieldId, action } = arg;
                    if (
                      type === ParamType.FIELD &&
                      fieldId === newValue.template_field_id
                    ) {
                      accu.push({ fieldId, action });
                    }
                  }
                }
              }
              return accu;
            }, []);
            if (!dependencies.length) {
              return;
            }
            const hasAggregation = dependencies.some((d) =>
              [
                ACTION_MIN,
                ACTION_MAX,
                ACTION_AVG,
                ACTION_SUM,
                ACTION_COUNT,
              ].includes(d.action)
            );
            if (!hasAggregation) {
              // -1 in this array means the dependency uses the default value pair.
              let dependencySectionIndices = [];
              const field = this.allFields.find(
                (f) => f.id === newValue.template_field_id
              );
              const fsc = this.getFieldSectionCount(field);
              if (fsc === 0 || this.sectionIndex > fsc - 1) {
                dependencySectionIndices.push(fsc === 1 ? 0 : -1);
              } else {
                dependencySectionIndices = dependencies.reduce((accu, d) => {
                  const { action = ACTION_CURRENT } = d;
                  if (action === ACTION_CURRENT) {
                    accu.push(this.sectionIndex);
                  } else if (action === ACTION_FIRST) {
                    accu.push(0);
                  } else if (action === ACTION_LAST) {
                    accu.push(fsc - 1);
                  } else if (action === ACTION_PREVIOUS) {
                    const dsi = this.sectionIndex - 1;
                    accu.push(dsi < 0 ? -1 : dsi);
                  } else if (action === ACTION_NEXT) {
                    const dsi = this.sectionIndex + 1;
                    accu.push(dsi > fsc - 1 ? -1 : dsi);
                  }
                  return accu;
                }, dependencySectionIndices);
              }
              if (
                !dependencySectionIndices.includes(
                  newValue.template_section_index
                )
              ) {
                return;
              }
            }

            this.updateValues();
          })();
        } finally {
          this.formContext.setIsBusy(false);
        }
      },
      deep: true,
    },
  },
};
</script>
