import _isUndefined from 'lodash/isUndefined';
import _pick from 'lodash/pick';

export async function loadTemplateTabs(mapper) {
  const {
    data: { tabs },
  } = await axios.get('/gather/tabs');

  if (_isUndefined(mapper)) {
    mapper = (tab) => _pick(tab, ['id', 'title']);
  }

  return tabs.map(mapper);
}

export async function loadTemplateTab(id) {
  const {
    data: { template },
  } = await axios.get(`/gather/template/${id}`, {
    params: {
      filters: {
        fieldFilterType: 'all-fields',
      },
    },
  });

  return template;
}
// A referenced app is a {template_tab_id: number, sample_id: number} object.
export async function loadHeatmapLinkedApps(referencedApps) {
  const { data } = await axios.get(`/gather/heatmap-linked-apps`, {
    params: {
      referenced_apps: JSON.stringify(referencedApps),
    },
  });

  return data;
}

export async function getInputValueRange({
  matrix,
  sampleChemicalId,
  templateTabId,
  templateSectionId,
  templateFieldId,
  sampleIds,
}) {
  const { data: range } = await axios.get(
    '/figure8/analysis/heatmap/input-value-range',
    {
      params: {
        matrix,
        sample_chemical_id: sampleChemicalId,
        template_tab_id: templateTabId,
        template_section_id: templateSectionId,
        template_field_id: templateFieldId,
        sample_ids: sampleIds,
      },
    }
  );

  return range;
}
