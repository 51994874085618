export async function loadChemicals(matrix) {
  const {
    data: { chemicals },
  } = await axios.get('/figure8/data/chemicals', {
    params: { matrix },
  });
  chemicals.sort((item1, item2) =>
    item1.display_title.localeCompare(item2.display_title)
  );
  return chemicals;
}

export async function loadChemicalResults(chemicalIds, matrix) {
  const {
    data: { chemicals: chemicalResults },
  } = await axios.get('/figure8/data/chemical-results', {
    params: {
      chemical_ids: chemicalIds,
      matrix,
    },
  });
  return chemicalResults;
}
