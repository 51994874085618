import * as bl from '../../../business-logic';

export const checkIsEnviroInput = (state) => (datasource) =>
  bl.analysis.heatmap.getInputType(datasource) ===
  bl.analysis.heatmap.INPUT_TYPES.ENVIRO;

export const checkIsGatherInput = (state) => (datasource) =>
  bl.analysis.heatmap.getInputType(datasource) ===
  bl.analysis.heatmap.INPUT_TYPES.GATHER;

export const getEnviroSamples =
  (state) => (samples, chemicalResult, depthUnit) => {
    return bl.sample.getEnviroSamples(samples, chemicalResult, depthUnit);
  };

export const getSelectedInput = (state) => () => {
  const { inputs, selectedIndex } = state;
  if (selectedIndex === -1) {
    return null;
  }

  return inputs[selectedIndex];
};

export const getClipBoundaries = (state, getters, rootState, rootGetters) => {
  const allLayers = rootGetters['maps/allLayers'];
  return bl.analysis.heatmap.getClipBoundaries(allLayers);
};
