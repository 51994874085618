import createDefaultState from './state';

export function setMapBoundaryLayerId(state, mapBoundaryLayerId) {
  state.mapBoundaryLayerId = mapBoundaryLayerId;
}

export function setDatasources(state, datasources) {
  state.datasources = datasources;
}

export function setInputs(state, inputs) {
  state.inputs = inputs;
}

export function setSelectedIndex(state, selectedIndex) {
  state.selectedIndex = selectedIndex;
}

export function resetMyState(state) {
  // Merge rather than replace so we don't lose observers
  // https://github.com/vuejs/vuex/issues/1118
  const ds = createDefaultState();
  Object.keys(ds).forEach((item) => {
    state[item] = ds[item];
  });
}

export function setInterpolationAlgorithms(state, value) {
  state.interpolationAlgorithms = value;
}
