export const BUFFER_TYPES = {
  FILLED: 1,
  UNFILLED: 2,
  INVERSE: 3,
};

export const BUFFER_TYPE_LABELS = {
  [BUFFER_TYPES.FILLED]: 'Filled',
  [BUFFER_TYPES.UNFILLED]: 'Unfilled',
  [BUFFER_TYPES.INVERSE]: 'Inverse',
};

export const ACTION_TYPES = {
  GENERIC: 1,
};
