import _get from 'lodash/get';
import { createNamespacedHelpers } from 'vuex';

function empowerNamespace(namespace) {
  const subNamespaceKeys = Object.keys(namespace).filter(
    (item) => item !== 'name'
  );

  namespace = {
    ...namespace,
    ...createNamespacedHelpers(namespace.name),
    getState(store, stateName) {
      return _get(
        store.state,
        `${this.name.replaceAll('/', '.')}.${stateName}`
      );
    },
    getGetter(store, getterName) {
      return store.getters[`${this.name}/${getterName}`];
    },
    async dispatch(store, actionName, payload) {
      return await store.dispatch(`${this.name}/${actionName}`, payload);
    },
  };

  if (subNamespaceKeys.length) {
    subNamespaceKeys.forEach((item) => {
      namespace = {
        ...namespace,
        [item]: empowerNamespace(namespace[item]),
      };
    });
  }

  return namespace;
}

const namespace = {
  name: 'maps',
  ANALYSIS: {
    get name() {
      return `${namespace.name}/analysis`;
    },
    HEATMAP: {
      get name() {
        return `${namespace.ANALYSIS.name}/heatmap`;
      },
    },
    BUFFER: {
      get name() {
        return `${namespace.ANALYSIS.name}/buffer`;
      },
    },
  },
};
export const NAMESPACE = empowerNamespace(namespace);
