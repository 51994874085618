export function copyToClipboard(text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('Copy');
    textArea.remove();
  }
}

export function elementInScreenBounds(ele) {
  const { top, bottom } = ele.getBoundingClientRect();
  return top < window.innerHeight && bottom >= 0;
}
