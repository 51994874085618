<template>
  <div class="slide__preview d-flex align-items-center justify-content-center">
    <i class="fal mb-0" :class="getSlideIcon"></i>
  </div>
</template>
<script>
export default {
  props: {
    figure: Object,
  },
  computed: {
    getSlideIcon() {
      if (!this.figure) {
        return 'fa-infinity';
      }

      const type = this.figure.type;

      switch (type) {
        case 'image':
          return 'fa-image';
        case 'audio':
          return 'fa-volume-up';
        case 'video':
          return 'fa-video';
        case 'map':
          return 'fa-map';
        case 'attachment':
          return 'fa-file';
        case 'table':
          return 'fa-table';
        case 'insight':
          return 'fa-analytics';
        case 'coverpage':
          return 'fa-heading';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slide__preview {
  pointer-events: none;
  width: 100px;
  height: 80px;
}

.slide__preview i {
  font-size: 1.75rem;
}
</style>
