import { degreesToRadians, radiansToDegrees } from '@turf/helpers';
import * as olCoordinate from 'ol/coordinate';
import GeometryType from 'ol/geom/GeometryType';
import {
  equivalent,
  fromLonLat,
  get as getProjection,
  toLonLat,
} from 'ol/proj';
import { HIT_TOLERANCE } from './constants';

export function latLngToCoordinate({ lat, lng }, projection) {
  return fromLonLat([lng, lat], projection);
}

export function coordinateToLatLng(coordinate, projection, precision = 7) {
  const factor = Math.pow(10, precision);
  let [lng, lat] = toLonLat(coordinate, projection);
  lng = Math.round(lng * factor) / factor;
  lat = Math.round(lat * factor) / factor;
  return { lat, lng };
}

export function transformCoordinate(
  coordinate,
  sourceProjection,
  targetProjection
) {
  sourceProjection = getProjection(sourceProjection);
  targetProjection = getProjection(targetProjection);
  if (equivalent(sourceProjection, targetProjection)) {
    return coordinate;
  }

  const latLng = coordinateToLatLng(coordinate, sourceProjection);
  return latLngToCoordinate(latLng, targetProjection);
}

/**
 * If both the pixel deltas in X and Y directions are less than
 * the tolerance then the two coordinates are considered to be equal.
 * @param {ol/Map} map
 * @param {ol/coordinate-Coordinate} coordinate1
 * @param {ol/coordinate-Coordinate} coordinate2
 * @param {Number} tolerance The pixel delta tolerance.
 * @returns
 */
export function checkEqualityOfTwoCoordinatesLeniently(
  map,
  coordinate1,
  coordinate2,
  tolerance = 2
) {
  const pixel1 = map.getPixelFromCoordinate(coordinate1);
  const pixel2 = map.getPixelFromCoordinate(coordinate2);
  const deltaX = Math.abs(pixel1[0] - pixel2[0]);
  const deltaY = Math.abs(pixel1[1] - pixel2[1]);
  const scaledTolerance = tolerance * map.getViewer().figureLayout.zoom;
  return deltaX <= scaledTolerance && deltaY <= scaledTolerance;
}

export function formatLatLng(latLng) {
  // Keep 6 decimal places.
  const scale = Math.pow(10, 7);
  const lat = Math.round(latLng.lat * scale) / scale;
  const lng = Math.round(latLng.lng * scale) / scale;
  return `LatLng(${lat}, ${lng})`;
}

/**
 * Check whether the coordinate is one of the vertext coordinates of a geometry.
 * @param {ol.Map} map
 * @param {ol.geom.Geometry} geom
 * @param {ol.coordinate} coord
 * @param {Number} tolerance
 * @returns {Boolean}
 */
export function checkIsVertexCoordinate(
  map,
  geom,
  coord,
  tolerance = HIT_TOLERANCE
) {
  const gt = geom.getType();
  let coords = [];
  if (gt === GeometryType.POLYGON) {
    coords = geom.getLinearRing(0).getCoordinates();
  } else if (gt === GeometryType.LINE_STRING) {
    coords = geom.getCoordinates();
  }

  return coords.some((item) => {
    return checkEqualityOfTwoCoordinatesLeniently(map, item, coord, tolerance);
  });
}

export function findCoordinateIndex(coordinates, coordinate) {
  return coordinates.findIndex((item) => olCoordinate.equals(item, coordinate));
}

export function checkIsFirstCoordinate(coordinates, coordinate) {
  return olCoordinate.equals(coordinate, coordinates[0]);
}

export function checkIsLastCoordinate(coordinates, coordinate) {
  return olCoordinate.equals(coordinate, coordinates[coordinates.length - 1]);
}

export function checkIsEndCoord(coordinates, coordinate) {
  return (
    checkIsFirstCoordinate(coordinates, coordinate) ||
    checkIsLastCoordinate(coordinates, coordinate)
  );
}

// Attributions to:
// Algorithm: https://gis.stackexchange.com/questions/234473/get-a-lonlat-point-by-distance-or-between-2-lonlat-points
// Bearing: https://support.esri.com/en/other-resources/gis-dictionary/term/6fe11f3c-cce4-4eb2-9610-eca8affe84dc#:~:text=and%20Esri%20technology.-,bearing,direction%20clockwise%20through%20360%20degrees.
export function addDistance([longitude, latitude], distance, bearing, radius) {
  radius = radius === undefined ? 6371008.8 : Number(radius);

  var delta = Number(distance) / radius;
  var Theta = degreesToRadians(Number(bearing));

  var Phi1 = degreesToRadians(latitude);
  var Lambda1 = degreesToRadians(longitude);

  var sinPhi1 = Math.sin(Phi1),
    cosPhi1 = Math.cos(Phi1);
  var sindelta = Math.sin(delta),
    cosdelta = Math.cos(delta);
  var sinTheta = Math.sin(Theta),
    cosTheta = Math.cos(Theta);

  var sinPhi2 = sinPhi1 * cosdelta + cosPhi1 * sindelta * cosTheta;
  var Phi2 = Math.asin(sinPhi2);
  var y = sinTheta * sindelta * cosPhi1;
  var x = cosdelta - sinPhi1 * sinPhi2;
  var Lambda2 = Lambda1 + Math.atan2(y, x);

  return [
    Number((((radiansToDegrees(Lambda2) + 540) % 360) - 180).toFixed(7)),
    Number(radiansToDegrees(Phi2).toFixed(7)),
  ];
  // normalise to −180..+180°
}

export function getCoordinateFromGeoJSONPointFeature(gFeature, projection) {
  const [lng, lat] = gFeature.geometry.coordinates;
  return latLngToCoordinate({ lat, lng }, projection);
}

export function getViewportCorners(map) {
  const projection = map.getView().getProjection();
  const size = map.getSize();
  const topLeftCoord = map.getCoordinateFromPixel([0, 0]);
  const bottomRightCoord = map.getCoordinateFromPixel(size);
  const topLeftLatLng = coordinateToLatLng(topLeftCoord, projection);
  const bottomRightLatLng = coordinateToLatLng(bottomRightCoord, projection);
  return {
    topLeft: { coord: topLeftCoord, latLng: topLeftLatLng },
    bottomRight: { coord: bottomRightCoord, latLng: bottomRightLatLng },
  };
}
