<template>
  <div class="numbers-chart d-flex flex-column">
    <div
      class="text-center"
      :style="{
        color: titleColor,
        'font-size': `${titleSize}px`,
        'font-weight': 'bold',
      }"
    >
      {{ title }}
    </div>
    <div class="table-container flex-grow-1 position-relative">
      <table class="w-100 table table-striped table-hover">
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="`header-${index}`"
              scope="col"
              class="position-sticky"
            >
              {{ header }}
            </th>
            <th v-if="eventSourceEnabled">Selected</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in numbers"
            :key="`bar-number-${index}`"
            :class="{
              clickable: eventSourceEnabled,
              selected: isSelected(index),
            }"
            @click="handleClick(index)"
          >
            <td :style="{ color: item.color }">
              {{ item.label }}
            </td>
            <td>
              <span
                class="badge rounded-pill"
                :style="{ 'background-color': item.color }"
                >{{ item.value }}</span
              >
            </td>
            <td v-if="eventSourceEnabled">
              <i v-if="isSelected(index)" class="fas fa-check-circle"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersChart',
  props: {
    title: String,
    titleColor: String,
    titleSize: Number,
    headers: Array,
    numbers: Array,
    eventSourceEnabled: Boolean,
    selection: Array,
    handleClick: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    isSelected(index) {
      return this.selection.indexOf(index) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.numbers-chart {
  padding: 10px;
  .table-container {
    overflow: auto;

    th {
      top: 0px;
      background-color: #ffffff;
    }

    tr.selected {
      font-weight: bold;
    }

    tr.clickable {
      cursor: pointer;
    }
  }
}
</style>
