import { normalizeLongitude, normalizeLatitude } from './coord';

export function normalizeExtent(extent) {
  let [minx, miny, maxx, maxy] = extent;
  minx = normalizeLongitude(minx);
  miny = normalizeLatitude(miny);
  maxx = normalizeLongitude(maxx);
  maxy = normalizeLatitude(maxy);
  if (minx > maxx) {
    [minx, maxx] = [maxx, minx];
  }
  if (miny > maxy) {
    [miny, maxy] = [maxy, miny];
  }
  return [minx, miny, maxx, maxy];
}
