import * as SWATCHES from '../constants/swatches';

function getSwatchCandidateIndex(count) {
  return Math.floor(Math.random() * count);
}
export default (usedSwatches = [], unusedSwatchesCount = 1) => {
  const allSwatches = Object.values(SWATCHES);
  const allUnusedSwatches = allSwatches.filter(
    (swatch) => !usedSwatches.includes(swatch)
  );
  const unusedSwatches = [];
  for (let i = 0; i < unusedSwatchesCount; i++) {
    const swatchCandidateIndex = getSwatchCandidateIndex(
      allUnusedSwatches.length
    );
    unusedSwatches.push(allUnusedSwatches[swatchCandidateIndex]);
  }
  return unusedSwatches;
};
