import EventBus from '@component-library/EventBus';
import {
  VectorImage as VectorImageLayer,
  Vector as VectorLayer,
} from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { createDrawingLayerStyle } from '../styles';
import { extentToBounds, toGeoJSON } from '../utils';
import { LAYER_TYPES, assignIdToLayer } from './LayerManager';

export default function createDrawingLayer(
  map,
  source,
  isRenderedAsImage = false
) {
  const layer = !isRenderedAsImage
    ? new VectorLayer({ source })
    : new VectorImageLayer({ source });
  assignIdToLayer(layer);

  layer.getFirstFeature = function () {
    const [firstFeature] = this.getSource().getFeatures();
    return firstFeature;
  };

  layer.hasFeature = function (feature) {
    return this.getSource().hasFeature(feature);
  };

  layer.toGeoJSON = function () {
    const { type: layerType } = this.options;
    if (layerType === LAYER_TYPES.FEATURE_COLLECTION) {
      const features = this.getSource().getFeatures();
      features.forEach((feature) => feature.setProperties({ ...this.options }));
      return toGeoJSON(map, features);
    } else {
      const feature = this.getFirstFeature().clone();
      feature.setProperties({
        ...this.options,
      });
      return toGeoJSON(map, feature);
    }
  };

  layer.adaptOptions = function (options) {
    return options;
  };

  layer.applyOptions = function (options) {
    this.options = options;
    const style = createDrawingLayerStyle(map, this.adaptOptions(this.options));
    this.setStyle(style);
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.getSource().getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  layer.duplicate = function () {
    const feature = this.getFirstFeature().clone();
    const source = new VectorSource({ features: [feature] });
    const duplicate = createDrawingLayer(map, source);
    duplicate.applyOptions({ ...this.options });
    return duplicate;
  };

  layer.refresh = function (optionsUpdate = {}) {
    this.applyOptions({ ...this.options, ...optionsUpdate });
  };

  layer.on('change', () => {
    EventBus.$emit('handleDrawingLayerChange', layer);
  });

  return layer;
}
