import {
  UnitConversion,
  safeMultiply,
} from '@component-library/utils/unit-conversion';

export default function useUnitConversion(unitConversions: UnitConversion[]) {
  const getFactorByFromAndTo = (
    fromUnit: string,
    toUnit: string
  ): number | null => {
    for (const conversion of unitConversions) {
      if (
        conversion.input_unit === fromUnit &&
        conversion.output_unit === toUnit
      ) {
        return conversion.conversion_factor;
      }

      if (
        conversion.output_unit === fromUnit &&
        conversion.input_unit === toUnit
      ) {
        return 1 / conversion.conversion_factor;
      }
    }

    return null;
  };

  const canConvertUnits = (fromUnit: string, toUnit: string) => {
    return getFactorByFromAndTo(fromUnit, toUnit) !== null;
  };

  const convertResultUnits = (
    result: number,
    fromUnit: string,
    toUnit: string
  ) => {
    if (!canConvertUnits(fromUnit, toUnit)) {
      return result;
    }

    const actualConversion = getFactorByFromAndTo(fromUnit, toUnit)!;
    return safeMultiply(result, actualConversion);
  };

  return {
    canConvertUnits,
    convertResultUnits,
  };
}
