<template>
  <div class="d-flex">
    <div
      v-if="items"
      class="wrapper w-100 flex-col"
      @click.prevent="handleClick"
    >
      <draggable v-model="items" draggable=".input-tag" :disabled="readOnly">
        <span
          v-for="(aItem, index) of items"
          :key="index"
          class="input-tag border-4 newline"
          :class="{ 'bg-dark': selectedItem === aItem }"
        >
          <span @click.prevent.stop.self="$emit('clickOption', aItem)">{{
            aItem
          }}</span>
          <a
            v-if="hasInlineDeletion && !readOnly"
            class="remove"
            @click.prevent.stop.self="removeItem(index)"
          >
            x</a
          >
        </span>
      </draggable>
      <input
        v-if="!readOnly"
        v-model.trim="newItem"
        type="text"
        ref="inputtag"
        class="new-tag"
        @keyup.enter="createItem"
      />
    </div>

    <slot name="extensionContainer"></slot>

    <div v-if="!readOnly" class="d-flex flex-column button-container">
      <button
        v-if="hasCopy"
        type="button"
        class="btn btn-outline-primary"
        :disabled="!items.length"
        title="Copy"
        @click.stop="handleCopyClick"
      >
        <i class="fas fa-copy"></i>
      </button>

      <button
        v-if="hasCut"
        type="button"
        class="btn btn-outline-primary"
        :disabled="!items.length"
        title="Cut"
        @click.stop="handleCutClick"
      >
        <i class="fas fa-cut"></i>
      </button>

      <button
        v-if="hasPaste"
        type="button"
        class="btn btn-outline-primary"
        title="Paste"
        @click.stop="handlePasteClick($event)"
      >
        <i class="fas fa-paste"></i>
      </button>

      <button
        v-if="hasImport"
        type="button"
        class="btn btn-outline-primary"
        title="Import"
        @click.stop="handleImportClick"
      >
        <i class="fas fa-file-import"></i>
      </button>

      <button
        type="button"
        class="btn btn-outline-primary"
        title="Sort in alphabetical order "
        @click.stop="sort"
      >
        <i class="fas fa-sort-alpha-up"></i>
      </button>

      <button
        type="button"
        class="btn btn-outline-primary"
        title="Sort in reverse alphabetical order "
        @click.stop="sortDesc"
      >
        <i class="fas fa-sort-alpha-down"></i>
      </button>
    </div>
  </div>
</template>

<script>
import * as bl from '../business-logic';
import Draggable from 'vuedraggable';

export default {
  components: {
    Draggable,
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    checkIsTagRemovable: {
      type: Function,
      default: (index) => true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isDisabled: Boolean,
    hasInlineDeletion: {
      type: Boolean,
      default: true,
    },
    hasCopy: {
      type: Boolean,
      default: true,
    },
    hasCut: {
      type: Boolean,
      default: true,
    },
    hasPaste: {
      type: Boolean,
      default: true,
    },
    hasImport: {
      type: Boolean,
      default: false,
    },
    selectedItem: String,
  },
  data: () => ({
    newItem: '',
  }),
  computed: {
    items: {
      get() {
        return this.value;
      },
      set(updated, outdated) {
        if (
          updated == null ||
          !Array.isArray(updated) ||
          JSON.stringify(updated) == JSON.stringify(outdated)
        )
          return;
        this.$emit('input', updated);
      },
    },
  },
  methods: {
    handleClick() {
      if (!this.readOnly) {
        this.$refs.inputtag.focus();
      }

      if (this.isDisabled) {
        return;
      }

      this.$emit('click');
    },
    async removeItem(index) {
      if (await this.checkIsTagRemovable(index)) {
        this.items = this.items.filter((v, i) => i !== index);
      }
    },
    createItem() {
      if (
        this.newItem == '' ||
        bl.dropdown.checkIsOptionDuplicate(this.items, this.newItem)
      ) {
        this.newItem = '';
        return;
      }
      this.items = [...this.items, this.newItem];
      this.newItem = '';
      this.$nextTick(() => {
        this.handleClick();
      });
    },
    sort() {
      let isNumber = true;
      this.items = this.items.sort((a, b) => {
        isNumber = Number.isInteger(Number(a)) && Number.isInteger(Number(b));
        if (isNumber) {
          return a - b;
        }
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a > b ? 1 : b > a ? -1 : 0;
      });
    },
    sortDesc() {
      let isNumber = true;
      this.items = this.items.sort((a, b) => {
        isNumber = Number.isInteger(Number(a)) && Number.isInteger(Number(b));
        if (isNumber) {
          return b - a;
        }
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a < b ? 1 : b < a ? -1 : 0;
      });
    },
    async handleCopyClick() {
      if (!this.items.length) {
        return;
      }

      const text = this.items.join('\n');
      try {
        await navigator.clipboard.writeText(text);
        this.$toastStore.success('Copied!');
      } catch (e) {
        this.$toastStore.error('Failed to copy!.');
      }
    },
    async handleCutClick() {
      if (!this.items.length) {
        return;
      }

      const text = this.items.join('\n');
      try {
        await navigator.clipboard.writeText(text);
        this.items = [];
        this.$toastStore.success('Cut!');
      } catch (e) {
        this.$toastStore.error('Failed to cut!.');
      }
    },
    async handlePasteClick(evt) {
      try {
        const text = await navigator.clipboard.readText();
        const items = text
          .split('\n')
          .map((item) => item.trim())
          .filter(
            (item) =>
              !!item && !bl.dropdown.checkIsOptionDuplicate(this.items, item)
          );
        this.items = [...this.items, ...items];
        this.$toastStore.success('Pasted!');
      } catch (e) {
        this.$toastStore.error('Failed to paste!.');
      }
    },
    handleImportClick() {
      this.$emit('importClick');
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #fff;
  border: 1px solid #ccc;
  border-right: 0px;
  overflow: hidden;
  padding-left: 4px;
  padding-top: 4px;
  cursor: text;
  text-align: left;
  -webkit-appearance: textfield;
}

.button-container button {
  border-radius: 0px;
  border-bottom-width: 0px;
}

.button-container button:last-child {
  border-bottom-width: 1px !important;
}

.wrapper > div {
  min-height: 32px;
}

.input-tag {
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-right: 4px;
  padding: 3px;
  cursor: pointer;
}

.input-tab-clickable {
  cursor: pointer;
}

.remove {
  font-weight: 700;
  text-decoration: none;
  color: #fff !important;
}

.new-tag {
  background: transparent;
  border: 0;
  color: #777;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 4px;
  padding-left: 0;
  flex-grow: 1;
}

.sort-btn {
  position: relative;
  margin-top: 2em;
  float: right;
}

.sort-desc-btn {
  position: absolute;
  margin-top: 4em;
  float: right;
}
</style>
