import _isEmpty from 'lodash/isEmpty';
import { Collection, Feature, Map, View } from 'ol';
import { default as GeoImage } from 'ol-ext/layer/GeoImage';
import { default as GeoImageSource } from 'ol-ext/source/GeoImage';
import { defaults as olControlDefaults } from 'ol/control';
import { Point } from 'ol/geom';
import {
  Draw,
  Modify,
  defaults as olInteractionDefaults,
} from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { Projection } from 'ol/proj';
import Units from 'ol/proj/Units';
import { Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import { createEditingStyle } from 'ol/style/Style';
import * as bl from '../../../../business-logic';
import * as openlayers_layers from '../../layers';
import { getMapFont } from '../../styles';
import * as utils from '../../utils';
import HelmertTransformation from './HelmertTransformation';
import * as constants from './constants';

const PIXEL_PROJECTION = new Projection({
  code: 'pixel',
  units: Units.PIXELS,
  extent: [-100000, -100000, 100000, 100000],
});

const EDITING_STYLE = createEditingStyle();

function createPointerTipStyle(text) {
  return new Style({
    text: new Text({
      text,
      font: getMapFont(),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 1)',
      }),
      backgroundFill: new Fill({
        color: 'rgba(0, 0, 0, 0.4)',
      }),
      padding: [2, 2, 2, 2],
      textAlign: 'left',
      offsetX: 15,
    }),
  });
}

// This feature is based on https://github.com/Viglino/Map-georeferencer
// This class functions the same as wapp.img in the Map-georeferencer
class Georeferencer {
  constructor(imageMap, map) {
    this.imageMap = imageMap;
    this.map = map;

    // Image transformation
    this.transformation = new HelmertTransformation();

    this.controlPoints = [];
    this.lastPoint = {};

    this.sourceLayer = this.addSource();

    // Projection layer
    this.destLayer = this.addDest();

    this.onImageMapPointerMove = this.onImageMapPointerMove.bind(this);
    this.imageMap.on('pointermove', this.onImageMapPointerMove);

    this.onMapPointerMove = this.onMapPointerMove.bind(this);
    this.onMapDoubleClick = this.onMapDoubleClick.bind(this);
    this.map.on('pointermove', this.onMapPointerMove);
    this.map.on('dblclick', this.onMapDoubleClick);
  }
  getMapImage_() {
    const { currentOtherLayer: mapImage } = this.map.getViewer();
    return mapImage;
  }
  getSourceDrawStyle() {
    return [
      new Style({
        image: new Circle({
          radius: 8,
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      }),
      new Style({
        image: new Circle({
          radius: 8,
          stroke: new Stroke({ color: '#000', width: 1 }),
        }),
      }),
      createPointerTipStyle('Place a control point on the image.'),
    ];
  }
  getSourceModifyStyle(feature) {
    const geometryType = feature.getGeometry().getType();
    let style = [...EDITING_STYLE[geometryType]];
    style = [
      ...style,
      createPointerTipStyle('Drag to move the control point.'),
    ];
    return style;
  }
  getDestDrawStyle() {
    return [
      new Style({
        image: new Circle({
          radius: 8,
          stroke: new Stroke({ color: '#fff', width: 3 }),
        }),
      }),
      new Style({
        image: new Circle({
          radius: 8,
          stroke: new Stroke({ color: '#000', width: 1 }),
        }),
      }),
      createPointerTipStyle('Place a control point on the map.'),
    ];
  }
  getDestModifyStyle(feature) {
    const geometryType = feature.getGeometry().getType();
    let style = [...EDITING_STYLE[geometryType]];

    let text = 'Drag to move the control point.';
    const id = feature.get('features')[0].get('id');
    const { point } = this.getControlPoint(id);
    if (point && _isEmpty(this.lastPoint)) {
      text =
        text.substring(0, text.length - 1) + ',\nor double click to delete it.';
    }

    style = [...style, createPointerTipStyle(text)];
    return style;
  }
  addSource = function () {
    var self = this;
    var layers = {};

    layers.image = new GeoImage({
      source: new GeoImageSource({
        image: this.getMapImage_().getSource().getGeoImage(),
        imageCenter: [0, 0],
        imageScale: [1, 1],
        projection: PIXEL_PROJECTION,
      }),
    });
    // Add Layer
    this.imageMap.getLayers().insertAt(0, layers.image);

    // Calculate the original box.
    layers.originalBox = utils.rectangle.createRectangleFromExtent(
      layers.image.getExtent()
    );

    // Controls points
    var vector = (layers.vector = new VectorLayer({
      source: new VectorSource({ features: new Collection() }),
      style: this.getStyle.bind(this),
    }));
    this.imageMap.addLayer(vector);

    // Add a new control point
    vector.getSource().on('addfeature', function (e) {
      if (e.feature.getGeometry().getType() === 'Point')
        self.addControlPoint(e.feature, true);
    });

    // Add interaction
    layers.iclick = new Draw({
      type: 'Point',
      source: vector.getSource(),
      style: this.getSourceDrawStyle(),
    });
    this.imageMap.addInteraction(layers.iclick);

    // Modification => calc new transform
    var modify = (layers.imodify = new Modify({
      features: vector.getSource().getFeaturesCollection(),
      style: this.getSourceModifyStyle.bind(this),
    }));
    this.imageMap.addInteraction(modify);
    modify.on('modifyend', function () {
      self.calc();
    });

    return layers;
  };
  getStyle(feature) {
    const cpOnMap = [
      new Style({
        image: new Circle({
          radius: 6,
          fill: new Fill({ color: '#00ff00' }),
        }),
        zIndex: 2,
      }),
    ];
    const cpOnImage = [
      new Style({
        image: new Circle({
          radius: 8,
          stroke: new Stroke({ color: 'red', width: 2 }),
        }),
        zIndex: 1,
      }),
    ];

    // For unknown reason, this method is called with a undefined feature when
    // a Georeferencer object is created in a US project. So checking the
    // nullability of the feature is required.
    if (
      feature &&
      (this.sourceLayer.vector.getSource().hasFeature(feature) ||
        feature.get('isimg'))
    ) {
      return cpOnImage;
    }

    return cpOnMap;
  }
  addDest() {
    var self = this;
    var layers = {};

    // Controls points
    var vector = (layers.vector = new VectorLayer({
      source: new VectorSource({ features: new Collection() }),
      style: this.getStyle.bind(this),
      zIndex: openlayers_layers.getMaxZIndex(),
    }));
    this.map.addLayer(vector);

    // Draw links
    vector.on('prerender', function (e) {
      if (!self.transformation.hasControlPoints) return;
      var ctx = e.context;
      ctx.beginPath();
      ctx.strokeStyle = 'blue';
      ctx.strokeWidth = 3;
      var ratio = e.frameState.pixelRatio;

      for (var i = 0; i < self.controlPoints.length; i++) {
        var pt = self.map.getPixelFromCoordinate(
          self.controlPoints[i].map.getGeometry().getCoordinates()
        );
        var pt2 = self.map.getPixelFromCoordinate(
          self.controlPoints[i].img2.getGeometry().getCoordinates()
        );
        ctx.moveTo(pt[0] * ratio, pt[1] * ratio);
        ctx.lineTo(pt2[0] * ratio, pt2[1] * ratio);
        ctx.stroke();
      }
      ctx.closePath();
    });

    // Add a new control point
    vector.getSource().on('addfeature', function (e) {
      if (!e.feature.get('isimg')) {
        self.addControlPoint(e.feature, false);
      }
    });

    // Add interaction
    layers.iclick = new Draw({
      type: 'Point',
      source: vector.getSource(),
      style: this.getDestDrawStyle(),
    });
    this.map.addInteraction(layers.iclick);

    // Modification => calc new transform
    const mFeatures = new Collection();
    vector.getSource().on('addfeature', (e) => {
      const { feature } = e;
      if (!feature.get('isimg')) {
        mFeatures.push(feature);
      }
    });
    vector.getSource().on('removefeature', (e) => {
      const { feature } = e;
      if (!feature.get('isimg')) {
        mFeatures.remove(feature);
      }
    });
    layers.imodify = new Modify({
      features: mFeatures,
      style: this.getDestModifyStyle.bind(this),
    });
    this.map.addInteraction(layers.imodify);

    layers.imodify.on('modifyend', function (e) {
      self.calc();
    });

    return layers;
  }
  getControlPoint(id) {
    var i,
      point = false;
    for (i = 0; (point = this.controlPoints[i]); i++) {
      if (point.id === id) break;
    }
    return { i, point };
  }
  delControlPoint(id) {
    const { i, point } = this.getControlPoint(id);
    if (point && _isEmpty(this.lastPoint)) {
      this.destLayer.vector.getSource().removeFeature(point.map);
      this.destLayer.vector.getSource().removeFeature(point.img2);
      this.sourceLayer.vector.getSource().removeFeature(point.img);
      //this.lastID_--;
      this.controlPoints.splice(i, 1);
      this.lastPoint = {};
      this.calc();
      this.transformation.hasControlPoints = this.controlPoints.length > 1;
    }
  }
  addControlPoint(feature, img) {
    if (feature.get('id')) return;
    var id = this.lastID_ || 1;

    if (img) {
      this.sourceLayer.iclick.setActive(false);
      this.lastPoint.img = feature;
      feature.set('id', id);
      var pt = this.transform(feature.getGeometry().getCoordinates());
      if (pt) {
        this.map.getView().setCenter(pt);
        this.lastPoint.map = new Feature({
          id: id,
          geometry: new Point(pt),
        });
        this.destLayer.vector.getSource().addFeature(this.lastPoint.map);
      }
    } else {
      this.destLayer.iclick.setActive(false);
      this.lastPoint.map = feature;
      feature.set('id', id);

      var pt = this.revers(feature.getGeometry().getCoordinates());
      if (pt) {
        this.imageMap.getView().setCenter(pt);
        this.lastPoint.img = new Feature({
          id: id,
          geometry: new Point(pt),
        });
        this.sourceLayer.vector.getSource().addFeature(this.lastPoint.img);
      }
    }

    // Add a new Point
    if (this.lastPoint.map && this.lastPoint.img) {
      this.lastPoint.img2 = new Feature({
        isimg: true,
        id: id,
        geometry: new Point([0, 0]),
      });
      this.destLayer.vector.getSource().addFeature(this.lastPoint.img2);

      this.lastPoint.id = id;
      this.controlPoints.push(this.lastPoint);
      this.lastID_ = ++id;

      this.lastPoint = {};
      this.calc();
      this.sourceLayer.iclick.setActive(true);
      this.destLayer.iclick.setActive(true);
    }
  }
  calc() {
    if (!this.controlPoints) return;

    if (this.controlPoints.length > 1) {
      var xy = [],
        XY = [];
      for (var i = 0; i < this.controlPoints.length; i++) {
        //var p = this.controlPoints[i].img.getGeometry().getCoordinates();
        xy.push(this.controlPoints[i].img.getGeometry().getCoordinates());
        //p = this.controlPoints[i].map.getGeometry().getCoordinates();
        XY.push(this.controlPoints[i].map.getGeometry().getCoordinates());
      }

      this.transformation.setControlPoints(xy, XY);

      var sc = this.transformation.getScale();
      var a = this.transformation.getRotation();
      var t = this.transformation.getTranslation();

      if (!this.destLayer.image) {
        this.destLayer.image = this.getMapImage_();
      }
      this.destLayer.image.getSource().setRotation(a);
      this.destLayer.image.getSource().setScale(sc);
      this.destLayer.image.getSource().setCenter(t);
      this.imageMap.getView().setRotation(a);
      if (this.transformation.hasControlPoints) {
        for (var i = 0; i < this.controlPoints.length; i++) {
          var pt = this.controlPoints[i].img.getGeometry().getCoordinates();
          this.destLayer.vector
            .getSource()
            .removeFeature(this.controlPoints[i].img2);
          this.controlPoints[i].img2
            .getGeometry()
            .setCoordinates(this.transform(pt));
          this.destLayer.vector
            .getSource()
            .addFeature(this.controlPoints[i].img2);
        }
      }

      const destCurrentBox = this.sourceLayer.originalBox.clone();
      const coordinates = destCurrentBox
        .getCoordinates()[0]
        .map((item) => this.transform(item));
      destCurrentBox.setCoordinates([coordinates]);
      this.destLayer.image.setCurrentBox(destCurrentBox);

      this.destLayer.image.setVisible(true);
    } else {
      if (this.destLayer.image) {
        this.destLayer.image.setVisible(false);
      }
    }
  }
  transform(xy) {
    return this.transformation.hasControlPoints
      ? this.transformation.transform(xy)
      : false;
  }
  revers(xy) {
    return this.transformation.hasControlPoints
      ? this.transformation.revers(xy)
      : false;
  }
  setSimilarity(b) {
    this.transformation.similarity = b !== false;
    this.calc();
  }
  getSimilarity() {
    return this.transformation.similarity;
  }
  destroy() {
    this.imageMap.removeInteraction(this.sourceLayer.imodify);
    this.imageMap.removeInteraction(this.iclick);
    this.imageMap.removeLayer(this.sourceLayer.vector);
    this.map.removeInteraction(this.destLayer.imodify);
    this.map.removeInteraction(this.destLayer.iclick);
    this.map.removeLayer(this.destLayer.vector);
    this.map.un('pointermove', this.onMapPointerMove);
    this.map.un('dblclick', this.onMapDoubleClick);
  }
  onImageMapPointerMove(e) {
    const targetElement = this.imageMap.getTargetElement();
    if (this.sourceLayer.iclick.getActive()) {
      targetElement.style.cursor = 'crosshair';
    } else {
      targetElement.style.cursor = 'grab';
    }

    const features = this.imageMap.getFeaturesAtPixel(e.pixel, {
      layerFilter: (candidate) => candidate === this.sourceLayer.vector,
      hitTolerance: 8,
    });
    if (features.length) {
      this.sourceLayer.iclick.setActive(false);
      targetElement.style.cursor = 'pointer';
    } else {
      if (!this.lastPoint.img) {
        this.sourceLayer.iclick.setActive(true);
      }
    }
  }
  onMapPointerMove(e) {
    const targetElement = this.map.getTargetElement();
    if (this.destLayer.iclick.getActive()) {
      targetElement.style.cursor = 'crosshair';
    } else {
      targetElement.style.cursor = 'grab';
    }

    const features = this.map.getFeaturesAtPixel(e.pixel, {
      layerFilter: (candidate) => candidate === this.destLayer.vector,
      hitTolerance: 6,
    });
    if (features.length) {
      this.destLayer.iclick.setActive(false);
      targetElement.style.cursor = 'pointer';
    } else {
      if (!this.lastPoint.map) {
        this.destLayer.iclick.setActive(true);
      }
    }
  }
  onMapDoubleClick(e) {
    const features = this.map.getFeaturesAtPixel(e.pixel, {
      layerFilter: (candidate) => candidate === this.destLayer.vector,
    });
    features.forEach((item) => {
      const id = item.get('id');
      this.delControlPoint(id);
    });
  }
}

export default function createGeoreferenceEdit(map) {
  let feature_;
  let imageMapTarget_;
  let imageMap_;

  return {
    editMethodCode: constants.EDIT_METHOD_CODES.GEOREFERENCE,
    selectFeature(feature) {
      feature_ = feature;
    },
    getFeature() {
      return feature_;
    },
    activate(map) {
      const viewer = map.getViewer();
      const { containerSize } = viewer.figureLayout;
      imageMapTarget_ = document.createElement('div');
      imageMapTarget_.style.fontSize = '13px';
      imageMapTarget_.style.position = 'absolute';
      imageMapTarget_.style.bottom = '0px';
      imageMapTarget_.style.right = '0px';
      imageMapTarget_.style.width = `${containerSize[0] / 2}px`;
      imageMapTarget_.style.height = `${containerSize[1] / 2}px`;
      imageMapTarget_.style.borderTop = '1px solid black';
      imageMapTarget_.style.borderLeft = '1px solid black';
      imageMapTarget_.style.backgroundColor = bl.color.WHITE;
      imageMapTarget_.style.cursor = 'crosshair';

      imageMap_ = new Map({
        target: imageMapTarget_,
        view: new View({
          projection: PIXEL_PROJECTION,
          zoom: 8,
          center: [0, 0],
        }),
        controls: olControlDefaults({
          rotate: false,
          attribution: false,
          zoom: false,
        }),
        interactions: olInteractionDefaults({
          altShiftDragRotate: false,
          pinchRotate: false,
        }),
      });
      document.body.appendChild(imageMapTarget_);
      imageMap_.updateSize();

      this.georeferencer = new Georeferencer(imageMap_, map);
    },
    destroy(map) {
      this.georeferencer.destroy();
      document.body.removeChild(imageMapTarget_);
    },
    addControlPoints(topLeftCoord, bottomRightCoord) {
      const { sourceLayer, destLayer } = this.georeferencer;
      const [minx, miny, maxx, maxy] = sourceLayer.image.getExtent();
      const controlPoints = [
        {
          sourceCoord: [minx, maxy],
          destCoord: topLeftCoord,
        },
        {
          sourceCoord: [maxx, miny],
          destCoord: bottomRightCoord,
        },
      ];
      controlPoints.forEach((controlPoint) => {
        const { sourceCoord, destCoord } = controlPoint;
        sourceLayer.vector
          .getSource()
          .addFeature(new Feature(new Point(sourceCoord)));
        destLayer.vector
          .getSource()
          .addFeature(new Feature(new Point(destCoord)));
      });
    },
  };
}
