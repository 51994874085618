<template>
  <div
    class="slideshow__slide-manager bg-dark text-white d-flex align-items-center"
  >
    <div class="pe-5 flex-auto">
      <b>
        <small>
          {{ slides.findIndex((s) => s.id == currentSlide) + 1 }} /
          {{ slides.length }}
        </small>
      </b>
      <br />
      <b>Section Manager</b>
      <br />
      <small>Drag sections to reorder</small>

      <slideshow-setting-dropdown :block="block" class="mt-2" />
    </div>

    <div class="slide-container p-1">
      <div class="d-flex align-items-center w-100">
        <div
          class="slide__wrapper"
          v-for="(slide, slideIndex) in slides"
          :key="'slide-' + slideIndex"
          draggable
          @dragstart="startDrag($event, slide)"
          @dragover="onDragOver($event, slide)"
          @dragleave="onDragLeave($event)"
          @dragend="onDragEnd($event)"
          @click="$emit('goToSlide', slide.id)"
          :class="draggingPosition"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column manager__slide"
            :class="{
              active: slide.id == currentSlide,
              'me-3': slideIndex != slides.length - 1,
            }"
          >
            <slide-preview :figure="slide.figure" />

            <div
              class="slide__content position-relative text-center mt-2 w-100"
            >
              <div class="slide__actions">
                <slide-setting-dropdown
                  @duplicate="$emit('duplicate', slide.id)"
                  @delete="$emit('delete', slide.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="slide__add-btn">
      <button
        type="button"
        class="btn btn-light"
        @click="$emit('create')"
        title="Add section to chapter"
      >
        <i class="fal fa-plus" />
      </button>
    </div>
  </div>
</template>
<script>
import SlideshowSettingDropdown from './SlideshowSettingDropdown.vue';
import SlideSettingDropdown from './SlideSettingDropdown.vue';
import SlidePreview from './SlidePreview.vue';

export default {
  props: {
    block: Object,
    slides: Array,
    currentSlide: Number,
  },
  data: () => ({
    slideBeenDragged: null,
    slideBeenDraggedOver: null,
    draggingPosition: null,
  }),
  components: { SlideshowSettingDropdown, SlideSettingDropdown, SlidePreview },
  methods: {
    startDrag(e, slide) {
      const element = e.target;

      setTimeout(() => {
        element.style.visibility = 'hidden';
      }, 1);

      this.slideBeenDragged = slide;
    },
    onDragOver(e, slide) {
      const element = e.target;

      const draggingAroundRight = e.offsetX > element.offsetWidth / 2;

      if (draggingAroundRight) {
        element.classList.remove('dragging__over');
        element.classList.add('dragging__over');

        this.draggingPosition = 'right';
      } else {
        element.classList.remove('dragging__over');
        element.classList.add('dragging__over');

        this.draggingPosition = 'left';
      }

      this.slideBeenDraggedOver = slide;
    },
    onDragLeave(e) {
      const element = e.target;

      element.classList.remove('dragging__over');
      element.classList.remove('dragging__over');
    },
    onDragEnd(e) {
      const element = e.srcElement;

      setTimeout(() => {
        element.style.visibility = 'visible';
      }, 1);

      this.$emit('updateOrdering', {
        slideBeenDragged: this.slideBeenDragged,
        slideBeenDraggedOver: this.slideBeenDraggedOver,
        position: this.draggingPosition,
      });

      this.draggingPosition = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.slideshow__slide-manager {
  display: flex;
  align-items: center;
  padding: 0px 20px;
  z-index: 6;
}

.slide-container {
  flex: 0;
  overflow: auto hidden;
  flex-grow: 1;
  height: 100%;
}

.manager__slide {
  position: relative;
  padding: 10px;
  width: 150px;
  height: 125px;
  cursor: pointer;
  border: 1px solid transparent;
}

.manager__slide.active {
  background: #47515a47;
  border: 1px solid #56606b;
}

.manager__slide:hover {
  background: #47515a47;
}

.slide__actions {
  position: absolute;
  right: 0px;
  bottom: 0px;
  top: 0px;
  color: #ffffffb3;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.slide__actions i:hover {
  color: #ffffff;
}

.slide__add-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 100%;

  background-image: linear-gradient(
    to right,
    rgba(0, 38, 37, 0),
    rgb(0, 38, 37) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  pointer-events: none;
}

.slide__add-btn button {
  pointer-events: auto;
}

.dragging__over.left {
  border-left: 1px solid #ffffff57;
}

.dragging__over.right {
  border-right: 1px solid #ffffff57;
}
</style>
