export const getDefaultFilters = (
  project,
  has_enviro_data = false,
  scenario_group
) => {
  const isQAQCGroup = scenario_group && scenario_group.type === 1;

  const defaultFilters = {
    // sample filters
    sample_filter_tab: 'general',
    hide_duplicate_samples: false,
    hide_triplicate_samples: false,
    hide_empty_samples: project.project_type == 0 && has_enviro_data,
    hide_empty_chemicals: false,
    display_db_casno: false,
    hide_hidden_samples: false,
    depths: [],
    elevations: [],
    apecs: [],
    analysis: [],
    date_range: {
      start_date: null,
      end_date: null,
    },
    sample_type: null,
    show_all_lab_sample_types: isQAQCGroup,
    lab_sample_type: !isQAQCGroup
      ? [
          ...(project.location && project.location?.country == 'US'
            ? ['trg', 'normal']
            : ['normal']),
        ]
      : [],
    sample_soil_descriptions: [],
    map_groups: [],
    lab_report_numbers: [],
    specific_sample_ids: [],
    allow_all_sample_filters_in_conjunction: false,
    figure_layers: [],
    gather_filters: {},

    // result filters
    order_by_significance: true,
    only_show_exceedances: false,
    show_exceedance_option: 'all',
    has_guidelines: false,
    only_non_detects: false,
    hide_non_detects: false,

    composite_guideline_rounding: 2,
    format_as_scientific: false,
    show_lab_flags: false,

    //chemical filters
    groups: [],
    show_other_group: false,
    hide_hidden_chemicals: false,
    show_surrogate_chemicals: false,
    show_specific_filtered_methods: [],
    show_specific_chemical_units: [],
    show_specific_chemical_analytical_methods: [],
    order_chemicals_by: 'alpha_followed_by_units',
    order_samples_by: 'alpha_by_title_and_depths',
  };

  return defaultFilters;
};

export const getFilters = (
  filters = {},
  project,
  has_enviro_data = false,
  scenario_group
) => {
  const defaultFilters = getDefaultFilters(
    project,
    has_enviro_data,
    scenario_group
  );

  if (Object.keys(filters).length == 0) {
    return defaultFilters;
  }

  return { ...defaultFilters, ...filters };
};
