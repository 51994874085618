export const EPSG_2225 = 'EPSG:2225';
export const EPSG_2226 = 'EPSG:2226';
export const EPSG_2227 = 'EPSG:2227';
export const EPSG_2228 = 'EPSG:2228';
export const EPSG_2229 = 'EPSG:2229';
export const EPSG_2230 = 'EPSG:2230';
export const EPSG_3310 = 'EPSG:3310';

export const projectionConfigs = [
  {
    code: EPSG_2225,
    name: 'NAD83 / California zone 1 (ftUS)',
    bbox: [42.01, -124.45, 39.59, -119.99],
    proj4Def:
      '+proj=lcc +lat_1=41.66666666666666 +lat_2=40 +lat_0=39.33333333333334 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_2226,
    name: 'NAD83 / California zone 2 (ftUS)',
    bbox: [40.16, -124.06, 38.02, -119.54],
    proj4Def:
      '+proj=lcc +lat_1=39.83333333333334 +lat_2=38.33333333333334 +lat_0=37.66666666666666 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_2227,
    name: 'NAD83 / California zone 3 (ftUS)',
    bbox: [38.71, -123.02, 36.73, -117.83],
    proj4Def:
      '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.06666666666667 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_2228,
    name: 'NAD83 / California zone 4 (ftUS)',
    bbox: [37.58, -122.01, 35.78, -115.62],
    proj4Def:
      '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.33333333333334 +lon_0=-119 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_2229,
    name: 'NAD83 / California zone 5 (ftUS)',
    bbox: [35.81, -121.42, 32.76, -114.12],
    proj4Def:
      '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_2230,
    name: 'NAD83 / California zone 6 (ftUS)',
    bbox: [34.08, -118.15, 32.53, -114.42],
    proj4Def:
      '+proj=lcc +lat_1=33.88333333333333 +lat_2=32.78333333333333 +lat_0=32.16666666666666 +lon_0=-116.25 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs',
  },
  {
    code: EPSG_3310,
    name: 'NAD83 / California Albers',
    bbox: [32.51, -124.42, 42.0, -114.13],
    proj4Def:
      '+proj=aea +lat_0=0 +lon_0=-120 +lat_1=34 +lat_2=40.5 +x_0=0 +y_0=-4000000 +datum=NAD83 +units=m +no_defs +type=crs',
  },
];
