import { Feature } from 'ol';
import { MultiLineString } from 'ol/geom';
import * as olCoordinate from 'ol/coordinate';
import _cloneDeep from 'lodash/cloneDeep';
import { fromGeoJSON, toGeoJSON } from './geojson';

export function isFirstSegment(lineString, { start }) {
  const coords = lineString.getCoordinates();
  return olCoordinate.equals(coords[0], start);
}

export function isLastSegment(lineString, { end }) {
  const coords = lineString.getCoordinates();
  return olCoordinate.equals(coords[coords.length - 1], end);
}

/**
 *
 * @param {Object} line The GeoJSON of a ol.geom.LineString
 */
export function lineToMultiLine(map, gLine) {
  const [line] = fromGeoJSON(map, gLine);
  const coordinates = [];
  line.getGeometry().forEachSegment((startCoord, endCoord) => {
    coordinates.push([[...startCoord], [...endCoord]]);
  });
  const multiLine = new Feature(new MultiLineString(coordinates));
  multiLine.setProperties(_cloneDeep(gLine.properties));
  return toGeoJSON(map, multiLine);
}

/**
 * Get the length of the line in pixels.
 * @param {ol.Map} map
 * @param {ol.geom.LineString} lineGeometry
 */
export function getDisplayLength(map, lineGeometry) {
  let result = 0;

  lineGeometry.forEachSegment((start, end) => {
    const startPixel = map.getPixelFromCoordinate(start);
    const endPixel = map.getPixelFromCoordinate(end);
    result += olCoordinate.distance(startPixel, endPixel);
  });

  return result;
}
