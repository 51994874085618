import actions from './actions.js';
import getters from './getters.js';
import mutations from './mutations.js';

export const getDefaultFilters = () => {
  return {
    updated: false,

    sampleFilterType: 'all-samples',
    specificSampleIds: [],
    selectedSubFolders: [],
    orderSamplesBy: 'new-to-old',

    fieldFilterType: 'all-fields',
    specificFieldIds: [],
    fieldValueFilters: [],
  };
};

const state = {
  filters: getDefaultFilters(),
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
