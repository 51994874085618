import Feature from 'ol/Feature';
import {
  getTopLeft,
  getTopRight,
  getBottomRight,
  getBottomLeft,
} from 'ol/extent';
import { Polygon } from 'ol/geom';
import { Style, Fill } from 'ol/style';
import createVectorLayer from './createVectorLayer';
import { LAYER_TYPES, getMaxZIndex } from './LayerManager';
import { createRectangleFromExtent } from '../utils';
import { COLORS, addOpacity } from '../styles';
import { createSquareEdit } from '../interactions';

// Size offset in pixels.
const CORNER_SIZE = 20;
// Thickness offset in pixels.
const CORNER_THICKNESS = 5;

/**
 * A layer used to edit the visible extent of service layers.
 * @param {ol.Map} map
 * @param {Array} initialExtent
 * @param {Boolean} readonly
 * @returns
 */
export default function createExtentEditorLayer(map, initialExtent, readonly) {
  const rectangle = createRectangleFromExtent(initialExtent);
  const extentFeature = new Feature(rectangle);
  extentFeature.setStyle(
    new Style({
      fill: null,
    })
  );
  extentFeature.on('change', () => {
    layer.refresh();
  });
  const layer = createVectorLayer(map, [extentFeature]);
  layer.options = {
    type: LAYER_TYPES.EXTENT_EDITOR,
  };
  layer.getManagedExtent = function () {
    return extentFeature.getGeometry().getExtent();
  };
  layer.remove = function () {
    edit.destroy(map);
    map.layerManager.removeLayer(this);
  };
  layer.refresh = function () {
    const source = layer.getSource();
    source
      .getFeatures()
      .filter((f) => f !== extentFeature)
      .forEach((cornerFeature) => {
        this.removeFeature(cornerFeature);
      });

    const extent = this.getManagedExtent();
    const cornerFeatures = createCornerFeatures(map, extent);
    cornerFeatures.forEach((cornerFeature) => {
      this.addFeature(cornerFeature);
    });
  };
  layer.setReadonly = function (readonly) {
    if (!readonly) {
      edit.activate(map);
      edit.selectFeature(extentFeature);
    } else {
      edit.destroy(map);
    }
  };

  const edit = createSquareEdit(map, {
    translate: true,
    translateFeature: false,
    draggingOnSidesEnabled: true,
    rotate: false,
  });

  layer.setZIndex(getMaxZIndex());
  layer.setReadonly(readonly);
  layer.refresh();

  return layer;
}

function getCornerCoordinate(map, baseCoordinate, xOffset, yOffset) {
  const basePixel = map.getPixelFromCoordinate(baseCoordinate);
  return map.getCoordinateFromPixel([
    basePixel[0] + xOffset,
    basePixel[1] + yOffset,
  ]);
}

function createCornerFeature(coordinates, rotation) {
  const polygon = new Polygon([coordinates]);
  polygon.rotate(rotation, coordinates[0]);
  const feature = new Feature(polygon);
  feature.setStyle(
    new Style({
      fill: new Fill({
        color: addOpacity(COLORS.EXTENT_EDITOR_CORNER_COLOR, 1),
      }),
    })
  );
  return feature;
}

function createCornerFeatures(map, extent) {
  const topLeft = getTopLeft(extent);
  const topRight = getTopRight(extent);
  const bottomRight = getBottomRight(extent);
  const bottomLeft = getBottomLeft(extent);
  const rotation = -(map.getRotation() / 180) * Math.PI;
  const features = [];

  // Top left corner.
  const topLeftCornerFeature = createCornerFeature(
    [
      topLeft,
      getCornerCoordinate(map, topLeft, CORNER_SIZE, 0),
      getCornerCoordinate(map, topLeft, CORNER_SIZE, CORNER_THICKNESS),
      getCornerCoordinate(map, topLeft, CORNER_THICKNESS, CORNER_THICKNESS),
      getCornerCoordinate(map, topLeft, CORNER_THICKNESS, CORNER_SIZE),
      getCornerCoordinate(map, topLeft, 0, CORNER_SIZE),
      topLeft,
    ],
    rotation
  );
  features.push(topLeftCornerFeature);

  // Top right corner.
  const topRightCornerFeature = createCornerFeature(
    [
      topRight,
      getCornerCoordinate(map, topRight, 0, CORNER_SIZE),
      getCornerCoordinate(map, topRight, -CORNER_THICKNESS, CORNER_SIZE),
      getCornerCoordinate(map, topRight, -CORNER_THICKNESS, CORNER_THICKNESS),
      getCornerCoordinate(map, topRight, -CORNER_SIZE, CORNER_THICKNESS),
      getCornerCoordinate(map, topRight, -CORNER_SIZE, 0),
      topRight,
    ],
    rotation
  );
  features.push(topRightCornerFeature);

  // Bottom right corner.
  const bottomRightCornerFeature = createCornerFeature(
    [
      bottomRight,
      getCornerCoordinate(map, bottomRight, -CORNER_SIZE, 0),
      getCornerCoordinate(map, bottomRight, -CORNER_SIZE, -CORNER_THICKNESS),
      getCornerCoordinate(
        map,
        bottomRight,
        -CORNER_THICKNESS,
        -CORNER_THICKNESS
      ),
      getCornerCoordinate(map, bottomRight, -CORNER_THICKNESS, -CORNER_SIZE),
      getCornerCoordinate(map, bottomRight, 0, -CORNER_SIZE),
      bottomRight,
    ],
    rotation
  );
  features.push(bottomRightCornerFeature);

  // Bottom left corner.
  const bottomLeftCornerFeature = createCornerFeature(
    [
      bottomLeft,
      getCornerCoordinate(map, bottomLeft, 0, -CORNER_SIZE),
      getCornerCoordinate(map, bottomLeft, CORNER_THICKNESS, -CORNER_SIZE),
      getCornerCoordinate(map, bottomLeft, CORNER_THICKNESS, -CORNER_THICKNESS),
      getCornerCoordinate(map, bottomLeft, CORNER_SIZE, -CORNER_THICKNESS),
      getCornerCoordinate(map, bottomLeft, CORNER_SIZE, 0),
      bottomLeft,
    ],
    rotation
  );
  features.push(bottomLeftCornerFeature);

  return features;
}
