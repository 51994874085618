<template>
  <div class="samples-chart d-flex flex-column">
    <div
      class="text-center pb-3"
      :style="{
        color: titleColor,
        'font-size': `${titleSize}px`,
        'font-weight': 'bold',
      }"
    >
      {{ title }}
    </div>
    <div class="table-container flex-grow-1 d-flex flex-column">
      <table class="table data-table sticky-table default-styling">
        <thead>
          <tr class="heading-row">
            <th class="highlight-cell">#</th>
            <th class="highlight-cell">Sample Title</th>
            <th
              v-for="item in sectionFields"
              :key="`th-${item.id}`"
              class="highlight-cell"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in sectionRows" :key="`sr-${item.id}`">
            <td>{{ `${parseInt(index, 10) + 1}` }}</td>
            <td>{{ item.sample_title }}</td>
            <td v-for="item2 in sectionFields" :key="`td-${item2.id}-${index}`">
              {{ item[item2.id] }}
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="sectionRows.length === 0"
        class="flex-grow-1 d-flex align-items-center justify-content-center"
      >
        <p class="text-muted">No data found.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SamplesChart',
  props: {
    title: String,
    titleColor: String,
    titleSize: Number,
    sectionRows: Array,
    sectionFields: Array,
  },
};
</script>

<style lang="scss" scoped>
.samples-chart {
  .table-container {
    overflow: auto;
    .table {
      min-height: 0px;
      max-height: 0px;
      th {
        min-width: 0px !important;
        &:nth-child(1) {
          width: 10%;
        }
      }
    }
  }
}
</style>
