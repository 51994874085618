<script setup lang="ts">
import { FORM_CONTEXT_TYPE_DATA_MANAGER } from '@component-library/business-model/common';
import ButtonSpinner from '@component-library/components/ButtonSpinner.vue';
import useLegacyRootBus from '@component-library/composables/useLegacyRootBus';
import useUpdateGatherInputValue from '@component-library/composables/useUpdateGatherInputValue';
import FormSection from '@component-library/form/FormSection.vue';
import { InputValue } from '@component-library/gather';
import { Project } from '@component-library/project';
import { useToastStore } from '@component-library/store/toasts';
import { createFormContext } from '@component-library/utils';
import {
  PropType,
  computed,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from 'vue';
import { PopoverData } from '../gather-table-horizontal/types/popover-data';
import { getAssociatedFields } from './api';
import {
  AssociatedData,
  InputValueWithOriginal,
} from './types/associated-data';
import useLegacyStore from '@component-library/composables/useLegacyStore';

const toastStore = useToastStore();
const legacyStore = useLegacyStore();

const props = defineProps({
  popoverData: {
    type: Object as PropType<PopoverData>,
    required: true,
  },
});

watch(
  () => props.popoverData,
  () => {
    loadAssociatedFields();
  }
);

const emit = defineEmits(['close', 'updateInputValues']);

const formContext = createFormContext(
  FORM_CONTEXT_TYPE_DATA_MANAGER,
  () => new Promise(() => {}),
  () => legacyStore.state.project
);

// refactor out, prevents an error
provide('formContext', formContext);

const { uploadAssets, updateInputValue } = useUpdateGatherInputValue();
const isLoading = ref(true);
const isUpdating = ref(false);
const associatedData = ref<AssociatedData | null>(null);

const sections = computed(() => {
  return associatedData.value!.sections;
});

const fields = computed(() => {
  return associatedData.value!.fields;
});

const inputValues = computed(() => {
  return associatedData.value!.values;
});

const handleUpdateInputValue = ({
  inputValue,
  field,
  sectionIndex,
  templateTabId,
}) => {
  if (!associatedData.value) {
    return;
  }

  let inputValues = associatedData.value.values;

  let existingIV = inputValues.find(
    (iv) =>
      iv.template_field_id == (field?.id || field) &&
      iv.template_section_index == sectionIndex &&
      iv.template_section_id == inputValue.template_section_id
  );

  if (!existingIV) {
    const newIV = {
      ...inputValue,
      template_tab_id: templateTabId,
    };
    inputValues.push(newIV);
    formContext.currentInputValue = newIV;
    return;
  }

  Object.keys(inputValue).forEach((key) => {
    existingIV![key] = inputValue[key];
  });
  formContext.currentInputValue = existingIV;
};

const submitUpdateInputValue = async () => {
  document.getElementById('field-pane-save-button')?.focus();
  const updatedValues = associatedData.value!.values.filter(
    (iv) =>
      iv.original_value &&
      (iv.original_value.value != iv.value ||
        iv.original_value.value2 != iv.value2)
  );

  if (updatedValues.length === 0) {
    emit('close');
    return;
  }

  isUpdating.value = true;

  try {
    for (let inputValue of updatedValues) {
      if (!inputValue.project_id) {
        inputValue.project_id = (
          legacyStore.state.project! as Project
        ).project_id;
      }
      const convertedAssets = await uploadAssets([inputValue]);

      for (let convertedAsset of convertedAssets) {
        if (!convertedAsset.index && convertedAsset.index != 0) {
          inputValue.value = convertedAsset.src;
          continue;
        }
        inputValue.value![convertedAsset.index].src = convertedAsset.src;
      }

      const updatedValue = await updateInputValue(inputValue);
      emit('updateInputValues', setImageSrcsForInputValues([updatedValue]));
    }

    emit('close');
  } catch (e) {
    isUpdating.value = false;
    toastStore.error('Failed to update values, try again.');
    throw e;
  } finally {
    isUpdating.value = false;
  }
};

const loadAssociatedFields = async () => {
  isLoading.value = true;

  const { data } = await getAssociatedFields(props.popoverData.field.id, {
    sample_id: props.popoverData.sample.id,
  });

  associatedData.value = data as AssociatedData;

  const castInputValue = (inputValue: InputValue): InputValueWithOriginal => {
    return {
      ...inputValue,
      original_value: {
        value: inputValue.value,
        value2: inputValue.value2,
      },
    };
  };

  if (!props.popoverData.inputValue.id) {
    associatedData.value.values.push(
      castInputValue(props.popoverData.inputValue)
    );
  }

  associatedData.value!.values = setImageSrcsForInputValues(
    associatedData.value!.values
  ).map((iv) => {
    return castInputValue(iv);
  });

  isLoading.value = false;
};

const setImageSrcsForInputValues = (inputValues: InputValue[]) => {
  return inputValues.map((inputValue) => {
    if (inputValue.value && typeof inputValue.value === 'string') {
      try {
        let jsonValue = JSON.parse(inputValue.value);
        if (jsonValue && Array.isArray(jsonValue)) {
          inputValue.value = jsonValue.map((aValue) => {
            if (aValue.src) {
              aValue.src = `/api/images/value/${
                (legacyStore.state.project! as Project).project_id
              }/${aValue.src}`;
            }
            return aValue;
          });
        }
      } catch (e) {}
    }
    return inputValue;
  });
};

const legacyRootBus = useLegacyRootBus();
onMounted(() => {
  loadAssociatedFields();

  // to work with vue2 event bus
  if (legacyRootBus) {
    legacyRootBus.$on('updateInputValue', handleUpdateInputValue);
  }
});

onUnmounted(() => {
  if (legacyRootBus) {
    legacyRootBus.$off('updateInputValue', handleUpdateInputValue);
  }
});
</script>

<template>
  <div>
    <div v-if="isLoading" class="text-center py-4">
      <i class="spinner-border spinner-border-lg"></i>
    </div>
    <template v-else>
      <div
        class="bg-dark text-white d-flex justify-content-between align-items-center p-3"
        style="border-top-left-radius: 4px; border-top-right-radius: 4px"
      >
        <h6 class="mb-0">Editing Value</h6>
      </div>

      <div class="pt-1 px-1" @mousedown.stop>
        <template v-for="section of sections">
          <FormSection
            :key="section.id"
            v-show="
              section.template_tab_id === popoverData.inputValue.template_tab_id
            "
            :section="section"
            :sample="popoverData.sample"
            :inputValues="inputValues"
            :fields="fields"
            :allFields="fields"
            :allSections="sections"
            :sampleIdentifier="popoverData.sample.custom_title"
            :collapse="true"
            :showAddSection="false"
            :keepAllOpen="true"
            :shouldEmit="false"
            :currentValue="popoverData.inputValue"
          />
        </template>
      </div>

      <div
        class="d-flex bg-light py-2 px-3"
        style="border-bottom-left-radius: 4px; border-bottom-right-radius: 4px"
      >
        <button class="btn btn-flat me-2" @click="emit('close')">Cancel</button>
        <ButtonSpinner
          type="submit"
          id="field-pane-save-button"
          class="btn btn-primary flex-fill"
          :is-loading="isUpdating"
          @click.native.prevent="submitUpdateInputValue"
        >
          Save
        </ButtonSpinner>
      </div>
    </template>
  </div>
</template>
