import GeometryType from 'ol/geom/GeometryType';
import { fromCircle } from 'ol/geom/Polygon';
import {
  getArea as ol_sphere_getArea,
  getLength as ol_sphere_getLength,
} from 'ol/sphere';

export function getArea(map, geometry) {
  const geometryType = geometry.getType();
  if (geometryType === GeometryType.CIRCLE) {
    geometry = fromCircle(geometry, 128);
  }

  return ol_sphere_getArea(geometry, {
    projection: map.getView().getProjection(),
  });
}

/**
 * Format area output.
 */
export function formatArea(map, geometry, projectUnit) {
  const area = getArea(map, geometry);
  return units.metre.format(area, units[projectUnit], units[projectUnit], true);
}

export function getLength(map, polylineGeometry, percentage = 1) {
  return (
    ol_sphere_getLength(polylineGeometry, {
      projection: map.getView().getProjection(),
    }) * percentage
  );
}

/**
 * Format length output.
 */
export function formatLength(map, line, projectUnit) {
  const length = getLength(map, line);
  return units.metre.format(length, units[projectUnit], units[projectUnit]);
}

class Unit {
  static SQUARE_SYMBOL = '\u00B2';

  constructor(options) {
    const { shortName, longName, meters, nextUnit } = options;
    this.shortName = shortName;
    this.longName = longName;
    this.meters = meters;
    this.nextUnit = nextUnit;
  }
  getTippingPoint(squared = false) {
    if (!this.nextUnit) {
      return undefined;
    }

    const value = units[this.nextUnit].meters / this.meters;
    return squared ? Math.pow(value, 2) : value;
  }
  // The value is in meters.
  format(value, projectUnit, targetUnit, squared = false) {
    const valueInTargetUnit = squared
      ? Math.pow(Math.sqrt(value) / targetUnit.meters, 2)
      : value / targetUnit.meters;
    const tippingPoint = targetUnit.getTippingPoint(squared);
    const suffix = squared ? Unit.SQUARE_SYMBOL : '';

    if (!tippingPoint || valueInTargetUnit < tippingPoint) {
      let decimalPlaces;
      if (targetUnit.shortName === 'm') {
        decimalPlaces = 1;
      } else {
        decimalPlaces = Math.round(
          Math.log10(targetUnit.meters / projectUnit.meters)
        );
        if (decimalPlaces < 0) {
          decimalPlaces = 0;
        }
      }
      return `${valueInTargetUnit.toFixed(decimalPlaces)} ${
        targetUnit.shortName
      }${suffix}`;
    }

    return this.format(value, projectUnit, units[targetUnit.nextUnit], squared);
  }
}

const units = {
  // Units in metric system
  mm: new Unit({
    shortName: 'mm',
    longName: 'Millimetre',
    meters: 0.001,
    nextUnit: 'cm',
  }),
  cm: new Unit({
    shortName: 'cm',
    longName: 'Centimetre',
    meters: 0.01,
    nextUnit: 'metre',
  }),
  metre: new Unit({
    shortName: 'm',
    longName: 'Metre',
    meters: 1,
    nextUnit: 'km',
  }),
  km: new Unit({
    shortName: 'km',
    longName: 'Kilometre',
    meters: 1000,
    nextUnit: undefined,
  }),
  // Units in imperial system
  inch: new Unit({
    shortName: '″',
    longName: 'Inch',
    meters: 0.0254,
    nextUnit: 'feet',
  }),
  feet: new Unit({
    shortName: 'ft',
    longName: 'Feet',
    meters: 0.3048,
    nextUnit: 'mile',
  }),
  mile: new Unit({
    shortName: 'mi',
    longName: 'Mile',
    meters: 1609.34,
    nextUnit: undefined,
  }),
};
