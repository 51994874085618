import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import EventBus from '../EventBus';
import { COMPANY_ROLES, hasCompanyRole } from '../company';
import { CompanyRoleProfile } from '../company-role-profile';
import useApi from '../composables/useApi';
import useIsGather from '../composables/useIsGather';
import { setProjectId as setFeatureManagerProjectId } from '../feature-manager';
import { KeyValue } from '../key-value';
import type { Project, ProjectIDOrUUID } from '../project';
import { User } from '../user';
import { useToastStore } from './toasts';

export const OPEN_TYPE_DROPDOWN = 'dropdown';
export const OPEN_TYPE_MODAL = 'modal';
export const OPEN_TYPE_REDIRECT = 'redirect';
export type OpenType =
  | typeof OPEN_TYPE_DROPDOWN
  | typeof OPEN_TYPE_MODAL
  | typeof OPEN_TYPE_REDIRECT;

export const useProjectStore = defineStore('project', () => {
  const isGather = useIsGather();
  const legacyStore = ref<any | undefined>();
  const toastStore = useToastStore();
  // TODO remove old project store state
  // Caution: Legacy store reset and update project may not update this project state.
  // Always set project from this store, the legacy store will be updated for backwards compatibility.
  const currentProject = ref<null | Project>(legacyStore.value?.state.project);

  const showSwitcherModal = ref(false);
  const showSwitcherDropdown = ref(false);
  const isFullPagePopup = ref(false);
  const requestedProjectAfterLogin = ref<null | ProjectIDOrUUID>(null);
  const showProjectConfidentialAcknowledgement = ref(false);
  const openTypeChosen = ref<OpenType>(OPEN_TYPE_REDIRECT);
  const selectedProject = ref<null | Project>(null);
  const loadingProjectIdOrUuid = ref<ProjectIDOrUUID>();
  const temporaryRedirect = ref<null | string>(null);
  const projects = ref<Project[]>([]);

  const loadingProject = computed(() => {
    return loadingProjectIdOrUuid.value !== undefined;
  });

  function openProject(project: Project, openType: OpenType) {
    openTypeChosen.value = openType;
    selectedProject.value = project;
    if (project.is_confidential) {
      showProjectConfidentialAcknowledgement.value = true;
      return;
    }

    _enterProject(project);
  }

  function setTemporaryRedirect(redirect: string) {
    temporaryRedirect.value = redirect;
  }

  function acknowledgeConfidentiality() {
    showSwitcherModal.value = false;
    showSwitcherDropdown.value = false;
    showProjectConfidentialAcknowledgement.value = false;

    if (!selectedProject.value) {
      toastStore.unexpected();
      throw new Error('No project selected');
    }

    _enterProject(selectedProject.value);
  }

  function resetProject() {
    currentProject.value = null;
    setFeatureManagerProjectId(undefined);
    if (!legacyStore.value) {
      throw new Error('Legacy store not set');
    }
    legacyStore.value.dispatch('resetProject');
  }

  function updateProject(project: any) {
    const updatedProject =
      project !== null
        ? ({
          ...(currentProject.value || {}),
          ...project,
        } as Project)
        : null;

    currentProject.value = updatedProject;
    setFeatureManagerProjectId(currentProject.value?.project_id);
    if (!legacyStore.value) {
      throw new Error('Legacy store not set');
    }
    legacyStore.value.dispatch('updateProject', updatedProject);
  }

  function hasAccessToProject(user: User, projectDetails: Project) {
    if (projectDetails.is_confidential === false) {
      return true;
    }
    if (
      hasCompanyRole(user, COMPANY_ROLES.ROLE_ACCESS_CONFIDENTIAL_PROJECTS) ||
      hasCompanyRole(user, COMPANY_ROLES.ROLE_ACCOUNT_MANAGER)
    ) {
      return true;
    }
    return (
      projectDetails.user_id === user.user_id ||
      projectDetails.users?.some((u) => u.user_id === user.user_id)
    );
  }

  function _enterProject(project: Project) {
    const additionalOptions = {
      autoRedirect: false,
      temporaryRedirect: null as null | string,
    };
    if (openTypeChosen.value === OPEN_TYPE_REDIRECT) {
      additionalOptions.autoRedirect = true;
      additionalOptions.temporaryRedirect = temporaryRedirect.value;
      temporaryRedirect.value = null;
    }

    // Tie in to old project state for now
    if (!legacyStore.value) {
      throw new Error('Legacy store not set');
    }
    legacyStore.value.dispatch(
      'updateProject',
      project,
      { root: true }
    );
    currentProject.value = project;
    setFeatureManagerProjectId(project.project_id);

    if (openTypeChosen.value === OPEN_TYPE_REDIRECT) {
      if (additionalOptions.temporaryRedirect) {
        window.location.href = additionalOptions.temporaryRedirect;
      } else if (additionalOptions.autoRedirect) {
        legacyStore.value.dispatch('gotoProjectBaseRoute');
      }
      return;
    }

    if (openTypeChosen.value === OPEN_TYPE_MODAL) {
      showSwitcherModal.value = true;
      return;
    }

    if (openTypeChosen.value === OPEN_TYPE_DROPDOWN) {
      showSwitcherDropdown.value = true;
      return;
    }
  }

  async function loadProjectDetails(
    projectId: ProjectIDOrUUID,
    shouldHandleException = true
  ) {
    loadingProjectIdOrUuid.value = projectId;

    try {
      const response = await (isGather
        ? axios.get('/api/project/details', {
          params: {
            project_id: projectId,
          },
        })
        : axios.get(`/project/details/${projectId}`));
      return response.data.project as Project;
    } catch (err: any) {
      if (shouldHandleException) {
        if (err.response?.status === 404) {
          toastStore.error('Project not found');
        } else if (err.response?.status === 401) {
          toastStore.error('You do not have access to this project.');
        } else {
          toastStore.unexpected();
        }

        if (err.response?.data.redirect) {
          window.location.href = err.response.data.redirect;
        }
      }
      throw err;
    } finally {
      loadingProjectIdOrUuid.value = undefined;
    }
  }

  async function favouriteProject(project: Project) {
    try {
      axios.post('/project/favourite/' + project.project_id);
      project.favourite_count = project.favourite_count ? 0 : 1;
    } catch (e) {
      toastStore.error('Failed to favourite project, try again.');
      throw e;
    }
  }

  function showAppSwitcher() {
    showSwitcherDropdown.value = true;
  }

  EventBus.$on('showAppSwitcher', (show = true) => {
    isFullPagePopup.value = show;
    showSwitcherDropdown.value = true;
  });

  async function updateProjectByField({ key, value }: KeyValue) {
    if (!currentProject.value) {
      return;
    }

    const api = useApi();

    try {
      await api.post('/project/update-by-field', {
        field: key,
        value: value,
        project_id: currentProject.value.project_id,
      });

      updateProject({
        [key]: value,
      });
    } catch (e) {
      throw e;
    }
  }

  async function toggleProjectMapColor(color: string) {
    if (!currentProject.value) {
      return;
    }

    try {
      const api = useApi();

      const { data } = await api.post('/project/toggle-map-color', {
        color,
        project_id: currentProject.value.project_id,
      });

      updateProject({
        map_color_palette: data.colors,
      });
    } catch (e) {
      throw e;
    }
  }

  async function getJoinRoles(project: {
    project_id: Project['project_id'];
  }): Promise<CompanyRoleProfile[]> {
    const api = useApi();
    try {
      const { data } = await api.get(
        `/project/${project.project_id}/join-roles`
      );
      return data.company_role_profiles as CompanyRoleProfile[];
    } catch (e) {
      throw e;
    }
  }

  return {
    project: computed(() => currentProject.value),
    currentProject: computed(() => currentProject.value),

    showProjectConfidentialAcknowledgement,
    loadingProject,
    loadingProjectId: loadingProjectIdOrUuid,
    showSwitcherModal,
    showSwitcherDropdown,
    isFullPagePopup,
    selectedProject,
    requestedProjectAfterLogin,
    resetProject,
    updateProject,

    setLegacyStore(store: any) {
      legacyStore.value = store;
    },

    openProject,
    setTemporaryRedirect,
    showAppSwitcher,
    hasAccessToProject,
    loadProjectDetails,
    acknowledgeConfidentiality,
    favouriteProject,
    projects,

    updateProjectByField,
    toggleProjectMapColor,
    getJoinRoles,
  };
});
