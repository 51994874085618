import posthog from 'posthog-js';
import Vue from 'vue';

export const EVENTS = {
  maps: 'maps',
  autoDocs: 'auto-docs',
  enviro: 'enviro',
  gather: 'gather',
  aiExplorer: 'ai-explorer',
  project: 'project',
  aiReview: 'ai-review',
  csm: 'csm',
  leaderboard: 'leaderboard',
  leagues: 'leagues',
  tabLogs: 'tablogs',
};

export interface AnalyticsEvent {
  event: string;
  properties: Record<string, any>;
}

export interface AnalyticsIdentify {
  user_name: string;
  email: string;
  enad_ref: string;
  customer_ref: string;
  company_name: string;
}

const POSTHOG_TOKEN = import.meta.env.VITE_POSTHOG_KEY;

const getPosthogBaseUrl = () => {
  if (import.meta.env.VITE_POSTHOG_HOST) {
    return import.meta.env.VITE_POSTHOG_HOST;
  }
  return 'https://e.datanest.earth';
};

const shouldCollectAnalytics =
  POSTHOG_TOKEN !== undefined &&
  POSTHOG_TOKEN !== '' &&
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost');

export const initializeAnalytics = () => {
  if (shouldCollectAnalytics) {
    posthog.init(POSTHOG_TOKEN, {
      api_host: getPosthogBaseUrl(),
      disable_session_recording: true,
      autocapture: false,
    });
    // @ts-expect-error auth is globally available, but not typed
    const impersonating = Vue.auth.impersonating();
    const sessionIdObject = getLongSessionId();
    const registerObject = {
      ...sessionIdObject,
      impersonating: impersonating ?? false,
    };

    posthog.register(registerObject);
  }
};

const getLongSessionId = () => {
  const rawUser = localStorage.getItem('user');
  if (!rawUser) {
    return {};
  }
  const user = JSON.parse(rawUser);
  const enadRef = user.enad_ref;
  if (!enadRef) {
    return {};
  }
  const hubUserKey = 'hub-user-' + enadRef;
  const hubLongSessionId = localStorage.getItem(hubUserKey);
  if (hubLongSessionId) {
    return {
      hub_long_session_id: hubLongSessionId,
    };
  }
  const gatherUserKey = 'gather-user-' + enadRef;
  const gatherLongSessionId = localStorage.getItem(gatherUserKey);
  if (gatherLongSessionId) {
    return {
      gather_long_session_id: gatherLongSessionId,
    };
  }
  return {};
};

export const postAnalyticsEvent = (event: AnalyticsEvent) => {
  shouldCollectAnalytics && posthog.capture(event.event, event.properties);
};

export const postAnalyticsUserIdentificationEvent = (
  identifier: AnalyticsIdentify
) => {
  shouldCollectAnalytics && posthog.identify(identifier.email, identifier);
  shouldCollectAnalytics &&
    posthog.group('company', identifier.customer_ref, {
      name: identifier.company_name,
    });
};
