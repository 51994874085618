<template>
  <div
    class="percentage-chart w-100 h-100 d-flex flex-column align-items-stretch"
  >
    <div class="title" :style="{ color: options.titleColor }">
      {{ data.title }}
    </div>
    <div
      class="content d-flex align-items-center justify-content-center"
      :style="{
        color: options.percentageColor,
        'font-size': `${options.percentageSize}px`,
      }"
    >
      <span>
        {{ percentage }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PercentageChart',
  props: {
    data: Object,
    options: Object,
  },
  computed: {
    percentage() {
      const { numberOfCreatedSamples, numberOfPlannedSamples } = this.data;
      return numberOfPlannedSamples > 0
        ? `${Math.round(
            (numberOfCreatedSamples / numberOfPlannedSamples) * 100
          )}%`
        : '0%';
    },
  },
};
</script>
<style lang="scss" scoped>
.percentage-chart {
  .title {
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-weight: bold;
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .content {
    flex: 1;
    font-weight: bold;
  }
}
</style>
