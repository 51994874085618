import { DATANEST_URL } from '@component-library/env';
import { Feature, Map } from 'ol';
import GeoImageLayer from 'ol-ext/layer/GeoImage';
import GeoImageSource from 'ol-ext/source/GeoImage';
import { getCenter } from 'ol/extent';
import { getUid } from 'ol/util';
import { getImageSrc } from '../../adapter/project';
import { extentToBounds } from '../../common/extent';
import { toGeoJSON as _toGeoJSON } from '../../common/geojson';
import { postrender } from '../../common/schedule';
import { getStoreApi } from '../../common/store-api';
import { createWaitForImage } from '../../common/utils';
import {
  calculateRectangleRotation,
  calculateRectangleScale,
  createRectangleFromExtent,
} from '../shape/rectangle';
import { createLayerProperties } from '../utils';
import type { ImageLayer, ImageLayerModel } from './types';
import {
  createRectangleFromImageCorners,
  getImageCenter,
  getImageCorners,
} from './utils';

export default async function createImageLayer(
  map: Map,
  model: ImageLayerModel
): Promise<ImageLayer> {
  const storeApi = getStoreApi(map);
  const project = storeApi.getProject();
  const image = new Image();
  const waitForImage = createWaitForImage(image);
  const { image_data: imageData } = model.geojson.properties;
  image.src = `${DATANEST_URL}${getImageSrc(project, imageData.url)}`;
  await waitForImage();
  await postrender(map);
  const imageCenter = getImageCenter(map, imageData.corners);
  const options = {
    source: new GeoImageSource({
      image,
      imageCenter,
      imageScale: [1, 1],
      imageCrop: [0, 0, image.width, image.height],
      imageRotate: 0,
    }),
    properties: createLayerProperties(
      model.id,
      model.geojson.properties.type,
      model.geojson.properties.usage
    ),
  };
  const layer: ImageLayer = new GeoImageLayer(options);

  layer.getFirstFeature = function () {
    return this.imageMate;
  };

  layer.checkHasFeature = function (feature) {
    return this.imageMate === feature;
  };

  layer.toGeoJSON = function () {
    const feature = this.getFirstFeature().clone();
    feature.setProperties({
      ...this.getProperties(),
    });
    return _toGeoJSON(map, feature);
  };

  layer.getBounds = function (padding = 0) {
    const extent = this.imageMate.getGeometry().getExtent();
    return extentToBounds(extent, map.getView().getProjection(), padding);
  };

  const imageExtent = layer.getSource().calculateExtent();
  layer.originalBox = createRectangleFromExtent(
    imageExtent,
    map.getView().getRotation()
  );

  layer.getCurrentBox = function () {
    return this.imageMate.getGeometry();
  };

  layer.setCurrentBox = function (currentBox) {
    this.imageMate.setGeometry(currentBox);
  };

  layer.getCorners = function () {
    const currentBox = this.getCurrentBox();
    return getImageCorners(map, currentBox);
  };

  // Use image mate to update image's corners.
  layer.syncCorners = function () {
    const currentBox = this.getCurrentBox();

    const scale = calculateRectangleScale(map, this.originalBox, currentBox);
    this.getSource().setScale(scale);

    const angle =
      calculateRectangleRotation(map, currentBox) - map.getView().getRotation();
    this.getSource().setRotation(angle);

    const center = getCenter(currentBox.getExtent());
    this.getSource().setCenter(center);
  };

  layer.refresh = function () {
    const layerModelId = this.get('modelId');
    const layerModel = storeApi.findLayerModelById(
      layerModelId
    )! as ImageLayerModel;
    const { image_data: imageData } = layerModel.geojson.properties;
    const corners = imageData.corners ?? getImageCorners(map, this.originalBox);
    const currentBox = createRectangleFromImageCorners(map, corners);
    this.imageMate = new Feature(currentBox);
    this.imageMate.set('ownerId', getUid(this));
    this.syncCorners();

    const transparency = imageData.transparency ?? 1;
    this.setOpacity(transparency);
  };

  layer.intersectsCoordinate = function (coordinate) {
    const currentBox = this.getCurrentBox();
    return currentBox.intersectsCoordinate(coordinate);
  };

  layer.refresh();

  return layer;
}
