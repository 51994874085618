
export const createInitOptions = () => ({
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount quickbars textcolor',
    ],
    toolbar:
        'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help',
    // skin must not be overriden.
    skin: false,
    // content_css must not be overriden.
    content_css: false,
});
