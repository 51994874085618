import getFillPatternProperties from '@component-library/business-logic/mapping/helpers/getFillPatternProperties';
import * as bl from '../../../business-logic';
import { StylingPriority } from '../../../lib/olbm/style/types';
import { getDefaultStylingPriority } from '../../../lib/olbm/style/utils';
import { getGetter, getState } from '../../../view-utils';

// The layerModel argument is null if the layer is being created.
export default function getDrawingFeatureOptions(map, layerModel) {
  let shapeStyle: any = {};

  if (layerModel) {
    shapeStyle = { ...shapeStyle, ...layerModel.data.properties };
  }

  const viewer = map.getViewer();
  if (
    viewer.currentDrawingLayer &&
    (!layerModel ||
      viewer.currentDrawingLayer.databaseLayerId === layerModel.id)
  ) {
    const shapeProperties = getState('shapeProperties');
    shapeStyle = { ...shapeStyle, ...shapeProperties };
  }

  const fsrsOnLayer = layerModel
    ? (getGetter('getFigureStylingRulesOnLayer')(
        layerModel.id
      ) as bl.styling.figure_styling_rule.FigureStylingRuleOnGatherApp[])
    : [];
  const hasFsrsOnLayer = fsrsOnLayer.length > 0;

  let { stylingPriority } = shapeStyle;
  if (!stylingPriority) {
    stylingPriority = getDefaultStylingPriority(hasFsrsOnLayer);
    shapeStyle = { ...shapeStyle, stylingPriority };
  }

  if (stylingPriority === StylingPriority.Appbased) {
    const { polyGatherSample } = layerModel.data;
    const appId = polyGatherSample?.template_tab_id;
    const app = appId ? getGetter('findGatherAppById')(appId) : undefined;
    if (app) {
      shapeStyle = {
        ...shapeStyle,
        color: app.drawing_colour,
        fillStyle: app.drawing_properties?.fillStyle,
        outlineStyle: app.drawing_properties?.outlineStyle,
        weight: undefined,
        ...getFillPatternProperties(app.drawing_properties?.fillStyle),
      };
    }
  } else if (stylingPriority === StylingPriority.Rulebased) {
    const { polyGatherSample } = layerModel.data;
    const inputValuesForStyling =
      polyGatherSample?.input_values_for_styling ?? [];

    // The latter styling rule takes precedence.
    for (let i = fsrsOnLayer.length - 1; i >= 0; i--) {
      const fsrOnLayer = fsrsOnLayer[i];
      const gatherField = getGetter('findGatherFieldById')(
        fsrOnLayer.condition.fieldId
      );
      if (!gatherField) {
        continue;
      }

      if (
        bl.styling.figure_styling_rule.checkIsFigureStylingRuleOnGatherAppFollowed(
          fsrOnLayer,
          gatherField,
          inputValuesForStyling
        )
      ) {
        shapeStyle = {
          ...shapeStyle,
          ...fsrOnLayer.style,
          subtitle: fsrOnLayer.name,
        };
        break;
      }
    }
  }

  return viewer.getScaledLayerProperties(shapeStyle);
}
