import type { Id, Pid } from '../common/types';

export enum CriteriaType {
  Landuse = 'landuse',
  Criteria = 'criteria',
}

type GuidelineScenario = {
  id: Id;
  landuse: string;
};

export type GuidelineDocument = {
  document_id: number;
  document: string;
  document_shorthand: string | null;
  acronym: string | null;
  short_url: string;
  long_url: any;
  type: any;
  media_2: any;
  soil_depth: any;
};

export type CriteriaSet = {
  id: Id;
  title: string;
  matrix: number;
  created_at: string;
};

type GuidelineScenarioId = Id;

// Project scenario
export type Scenario = {
  id: Id;
  criteria_type: CriteriaType;
  scenario_id: GuidelineScenarioId;
  scenario: GuidelineScenario;
  factor: number;
  document_id: Id;
  document?: GuidelineDocument;
  criteria_set_id: Id;
  criteria: CriteriaSet;
  options: {
    test_hydrocarbons: number;
    groundwater_depth: string;
  };
};

type ScenarioDocumentStyle = {
  short_url: string;
};

enum CriteriaTypeNumber {
  Landuse = 0,
  Criteria = 1,
  Background = 2,
}

export type ScenarioStyle = {
  scenario_id: GuidelineScenarioId;
  factor: number;
  documents: ScenarioDocumentStyle[];
  criteria_set_id: Id;
  criteria_type: CriteriaTypeNumber;
  title?: string;
};

export type Chemical = any;

export type Exceedance = {
  criteria_type: CriteriaType;
  exceedances: CriteriaTypeExceedance[];
};

// Landuse exceedances have document_id and scenario_id
// Criteria exceedances have criteria_set_id
// Background exceedances have no ids
export type CriteriaTypeExceedance = {
  document_id: Id | undefined;
  scenario_id: Id | undefined;
  criteria_set_id: Id | undefined;
  exceedances: ResultExceedance[];
};

export type ResultExceedance = {
  item_id: Pid;
  sample_id: Pid;
  value: number;
};

export type ChemicalResult = { chemical_id: Pid; sample_items: SampleItem[] };

export type SampleItem = {
  id: Pid;
  sample_id: Pid;
};

export enum SampleExceedanceColor {
  Landuse = '#dd4545',
  Criteria = '#dd4545',
  NoExceedances = '#5aa847',
}

export enum BuiltinSampleGroupSubtitle {
  Default = '',
  ExceedsAdoptedCriteria = 'Exceeds Adopted Criteria',
  NoExceedances = 'No Exceedances',
}
