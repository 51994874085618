import _isEqual from 'lodash/isEqual';
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: Object,
  },
  watch: {
    options(newOptions, oldOptions) {
      if (!_isEqual(newOptions, oldOptions)) {
        this.renderChart(this.chartData, newOptions);
      }
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
