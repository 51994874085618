import axios from 'axios';

const getAllSamples = ({ tabId, query, page }) => {
  return axios.get('/gather/all-samples', {
    params: {
      tabId,
      sampleQuery: query,
      page,
    },
  });
};

const getAllAppFields = (tab_id) => {
  return axios.get(`/app/${tab_id}/fields`);
};

const updateTemplateFilters = (template_id, filters) => {
  return axios.put(`/gather/app/${template_id}/filters`, {
    filters,
  });
};

export default {
  getAllSamples,
  getAllAppFields,
  updateTemplateFilters,
};
