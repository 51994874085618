import { useStore } from '@/js/store';
import { Style, Text } from 'ol/style';
import { MapType } from '../../../lib/olbm/common/types';
import { formatArea } from '../utils';
import { getMeasurementStyleConfig } from './text';

export default function createAreaStyle(map, feature, isTemporary) {
  const geometry = feature.getGeometry();
  const store = useStore();
  return new Style({
    text: new Text({
      text: formatArea(map, geometry, store.state.project.measurement_type),
      overflow: map.getType() === MapType.MAIN,
      ...getMeasurementStyleConfig(map, isTemporary),
    }),
    zIndex: 1,
  });
}
