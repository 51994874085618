<script lang="ts" setup>
import { ref, watch, onMounted } from 'vue';
import { Popover } from 'bootstrap';

const DEFAULT_BACKGROUND_COLOR = '#3366a2';
const ERROR_BACKGROUND_COLOR = 'rgb(220, 53, 69)';
const props = withDefaults(
  defineProps<{
    color?: string;
    backgroundColor?: string;
    title?: string;
    info: string;
    danger?: boolean;
    container?: string;
    trigger?: string;
  }>(),
  {
    title: '',
    color: '#ffffff',
    container: 'body',
    trigger: 'focus',
  }
);

const buttonRef = ref(null);
const actualBackgroundColor = ref(
  props.backgroundColor || DEFAULT_BACKGROUND_COLOR
);

watch(
  () => props.info,
  (value) => {
    Popover.getInstance(buttonRef.value).setContent({
      '.popover-body': value,
    });
  }
);

onMounted(() => {
  if (
    props.danger &&
    actualBackgroundColor.value === DEFAULT_BACKGROUND_COLOR
  ) {
    actualBackgroundColor.value = ERROR_BACKGROUND_COLOR;
  }

  const { title, info, container, trigger } = props;
  new Popover(buttonRef.value, {
    title,
    content: info,
    container,
    trigger,
  });
});
</script>

<template>
  <a
    class="text-center info-button"
    :style="{ 'background-color': actualBackgroundColor }"
    style="
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid v-bind('actualBackgroundColor');
      border-radius: 50%;
      font-size: 0.8em;
      aspect-ratio: 1;
      color: v-bind('props.color');
      cursor: pointer;
    "
    ref="buttonRef"
    role="button"
    tabindex="0"
    data-bs-html="true"
  >
    <i class="fas fa-info text-white me-0"></i>
  </a>
</template>
