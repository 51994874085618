import Collection from 'ol/Collection';
import { Modify } from 'ol/interaction';
import { primaryAction, doubleClick } from 'ol/events/condition';
import DragOverlay from 'ol-ext/interaction/DragOverlay';
import { createDrawingStyle } from '../../styles';
import { LAYER_TYPES, getMaxZIndex } from '../LayerManager';
import * as utils from '../../utils';

export default function createEdit(map) {
  const edit = [];
  const features = new Collection();

  const getLayer = () => {
    const connector = features.item(0);
    return map.layerManager.findLayerByFeature(connector);
  };

  const drag = new DragOverlay({ overlays: [], centerOnClick: false });
  let popupPreviousPosition;
  let popupStartPositioning;
  drag.on('dragstart', ({ overlay: popup }) => {
    popupPreviousPosition = popup.getPosition();
    popupStartPositioning = popup.getPositioning();
  });
  drag.on('dragging', ({ overlay: popup }) => {
    const layer = getLayer();
    const currentPosition = popup.getPosition();

    const { connectedTarget } = layer.options;
    if (connectedTarget) {
      layer.updateConnectorFirstCoordinate([...currentPosition]);
    } else {
      const deltaX = currentPosition[0] - popupPreviousPosition[0];
      const deltaY = currentPosition[1] - popupPreviousPosition[1];
      layer.move(deltaX, deltaY);
    }

    popupPreviousPosition = currentPosition;
  });
  drag.on('dragend', () => {
    const layer = getLayer();
    layer.finishConnectorChange(popupStartPositioning);
    popupPreviousPosition = null;
    popupStartPositioning = null;
  });
  edit.push(drag);

  const modify = new Modify({
    features,
    condition: (mapBrowserEvent) => {
      const layer = getLayer();
      // The start position of the connector is not editable.
      const [startPosition] = layer.getConnectorEnds();
      const distance = utils.position.getPixelDistance(
        map,
        startPosition,
        mapBrowserEvent.coordinate
      );
      return primaryAction(mapBrowserEvent) && distance > 10;
    },
    deleteCondition: doubleClick,
    style: (point /*Modification point*/) => {
      const layer = getLayer();
      const ends = layer.getConnectorEnds();
      const pointPosition = point.getGeometry().getCoordinates();
      const isFirstCoordinate = utils.checkIsFirstCoordinate(
        ends,
        pointPosition
      );
      const isLastCoordinate = utils.checkIsLastCoordinate(ends, pointPosition);

      return isFirstCoordinate
        ? null
        : createDrawingStyle(map, {
            getPointerTip: (snappedToVertex) => {
              if (!snappedToVertex) {
                return 'Drag to modify';
              }

              if (!isLastCoordinate) {
                return 'Drag to modify, or double click to \ndelete this vertex';
              }

              return 'Drag to modify';
            },
          })(point);
    },
  });
  modify.on('modifystart', () => {
    const popup = getLayer().getPopup();
    popupStartPositioning = popup.getPositioning();
  });
  modify.on('modifyend', () => {
    const layer = getLayer();
    layer.finishConnectorChange(popupStartPositioning);
    popupStartPositioning = null;
  });
  edit.push(modify);

  edit.selectFeature = function (connector) {
    features.push(connector);

    const popup = getLayer().getPopup();
    popup.element.style.zIndex = getMaxZIndex();
    drag.addOverlay(popup);
  };

  edit.getFeature = function () {
    return features.item(0);
  };

  edit.activate = function (map) {
    this.forEach((item) => {
      map.addInteraction(item);
    });
  };

  edit.destroy = function (map) {
    this.forEach((item) => {
      map.removeInteraction(item);
    });
  };

  edit.checkIsWorking = function () {
    return !!modify.getOverlay().getSource().getFeatures().length;
  };

  return edit;
}
