import { Control } from 'ol/control';
import EventBus from '@component-library/EventBus';

const NorthArrow = (function (Control) {
  function NorthArrow(opt_options) {
    const options = opt_options || {};
    const {
      northArrowType = 0,
      width = 32,
      rotation = 0,
      clickable = true,
      nonClickableTitle = '',
      addClass = true,
    } = options;

    const element = document.createElement('div');
    if (addClass) {
      element.className = 'north-arrow';
    }
    options.element = element;

    const contentEl = document.createElement('div');
    contentEl.innerHTML = this.getContent_(northArrowType, width);
    contentEl.className = clickable ? ' clickable' : '';
    contentEl.style.transformOrigin = 'center';
    contentEl.style.transform = `rotate(${rotation}deg)`;
    if (clickable) {
      contentEl.setAttribute('title', 'Click to reset the map rotation.');
      contentEl.addEventListener('click', () => {
        EventBus.$emit('setMapRotation', 0);
      });
    } else {
      contentEl.setAttribute('title', nonClickableTitle);
    }
    element.appendChild(contentEl);

    Control.call(this, options);
  }

  if (Control) {
    NorthArrow.__proto__ = Control;
  }

  NorthArrow.prototype = Object.create(Control && Control.prototype);
  NorthArrow.prototype.constructor = NorthArrow;

  NorthArrow.prototype.getContent_ = function getContent(
    northArrowType,
    width
  ) {
    return `<img src="/images/map_icons/north_arrows/${northArrowType}.svg" width="${width}px">`;
  };

  return NorthArrow;
})(Control);

export default NorthArrow;
