<template>
  <div v-tooltip="getDate">
    {{ getDate }}
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    field: Object,
    value: Object,
    values: Array,
  },
  computed: {
    getDate() {
      let value = '';

      if (this.value.value) {
        value = this.formatValueDate(this.value.value);
      }

      if (this.value.value2) {
        value += ' ' + this.value.value2;
      }

      return value.trim();
    },
  },
  methods: {
    formatValueDate(date) {
      let format = this.field?.options?.format;
      if (format === undefined || format === null || format === 'day') {
        format = 'DD-MM-YYYY';
      } else if (format === 'month') {
        format = 'MM-YYYY';
      } else if (format === 'year') {
        format = 'YYYY';
      }
      return moment.utc(date).local().format(format);
    },
  },
};
</script>
