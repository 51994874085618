/**
 * Return the width and height in pixels of a dashboard cell.
 * A dashboard cell is the minimum available space in the dashboard.
 * @param {Object} size The dashboard size.
 *  @param {Number} size.width
 *  @param {Number} size.height
 * @param {Number} colNum The number of columns.
 * @param {Number} rowNum The number of rows.
 * @param {Number} margin The space in pixels between cells.
 */
export default (size, colNum, rowNum, margin) => ({
  colWidth: (size.width - (colNum + 1) * margin) / colNum,
  rowHeight: (size.height - (rowNum + 1) * margin) / rowNum,
});
