<template>
  <div
    class="chemistry-table-skeleton sticky-table all-data-table"
    :class="styling"
  >
    <table
      v-if="orientation == 'vertical'"
      class="table loading-table table-fixed"
    >
      <thead>
        <tr class="heading-row">
          <th class="sample-id-column" style="width: 220px">
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th
            v-for="sample in numberOfColumns"
            :key="sample"
            style="width: 100px"
          >
            <span class="placeholder placeholder-wave w-100" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="heading-sub-row"
          v-for="subRow in numberOfSubRows"
          :key="'sub-row-' + subRow"
        >
          <th>
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <td
            v-for="result in numberOfColumns"
            :key="subRow + '-subrow-' + result"
          >
            <span class="placeholder placeholder-wave w-100" />
          </td>
        </tr>
        <template v-for="chemicalRow in 2">
          <tr
            class="chemical-group-header"
            :key="'chemical-group-' + chemicalRow"
          >
            <td colspan="100%">
              <span class="placeholder placeholder-wave w-100" />
            </td>
          </tr>
          <tr
            v-for="chemicalIndex in 8"
            class="chemical-row"
            :key="'chemical-row-' + chemicalRow + chemicalIndex"
          >
            <td class="heading-title-column">
              <span class="placeholder placeholder-wave w-100" />
            </td>
            <td class="heading-body-column">
              <span class="placeholder placeholder-wave w-100" />
            </td>
            <td
              v-for="result in numberOfColumns - 1"
              :key="chemicalIndex + '-result-' + result"
              class="text-center"
            >
              <span class="placeholder placeholder-wave w-50" />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <table v-else class="table loading-table table-fixed">
      <thead>
        <tr class="heading-row">
          <th style="width: 220px">
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th
            v-for="sample in numberOfColumns"
            :key="sample"
            style="width: 100px"
          >
            <span class="placeholder placeholder-wave w-100" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="heading-sub-row">
          <th>
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th colspan="4">
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th colspan="6">
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th colspan="6">
            <span class="placeholder placeholder-wave w-100" />
          </th>
        </tr>
        <tr
          class="heading-sub-row"
          v-for="subRow in numberOfSubRows"
          :key="'sub-row-' + subRow"
        >
          <th>
            <span class="placeholder placeholder-wave w-100" />
          </th>
          <th
            v-for="result in numberOfColumns"
            :key="subRow + '-result-' + result"
          >
            <span class="placeholder placeholder-wave w-100" />
          </th>
        </tr>
        <tr
          v-for="chemicalIndex in 18"
          class="chemical-row"
          :key="'chemical-row-' + chemicalIndex"
        >
          <td class="heading-title-column">
            <span class="placeholder placeholder-wave w-100" />
          </td>
          <td class="heading-body-column">
            <span class="placeholder placeholder-wave w-100" />
          </td>
          <td v-for="p in numberOfColumns - 1" :key="p" class="text-center">
            <span class="placeholder placeholder-wave w-50" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    orientation: {
      type: String,
      default: 'vertical',
    },
    styling: {
      type: String,
      default: 'default-styling',
    },
    numberOfColumns: {
      type: Number,
      default: 16,
    },
    numberOfSubRows: {
      type: Number,
      default: 3,
    },
  },
};
</script>
