import { boundingExtent, getCenter } from 'ol/extent';
import { WMTSCapabilities } from 'ol/format';
import { Tile as TileLayer } from 'ol/layer';
import { get as getProjection, transformExtent } from 'ol/proj';
import { WMTS } from 'ol/source';
import WMTSRequestEncoding from 'ol/source/WMTSRequestEncoding';
import { default as WMTSTileGrid } from 'ol/tilegrid/WMTS';
import * as bl from '../../../business-logic';
import { assignIdToLayer } from '../layers';
import * as utils from '../utils';
import { mergeExtents } from '../utils';
import * as network from '../utils/network';
import enableLoadingEvents from './enableLoadingEvents';

function handleCoord(coord, axisOrientation) {
  return axisOrientation === bl.projection.AXIS_ORIENTATION_NEU.value
    ? [coord[1], coord[0]]
    : coord;
}

function getWgs84BoundingBox(layer) {
  const { WGS84BoundingBox } = layer;
  return WGS84BoundingBox ?? [-180, -90, 180, -90];
}

export async function getServiceData(
  serviceUrl,
  shouldUseCorsProxy,
  axisOrientation
) {
  const url = !shouldUseCorsProxy
    ? serviceUrl
    : utils.network.proxify(serviceUrl);
  const { data } = await axios.get(url, network.createRequestOptions());
  const parser = new WMTSCapabilities();
  const wmtsCapabilities = parser.read(data);

  const {
    version,
    ServiceIdentification: { Title: title },
    Contents: { Layer: wmtsLayers, TileMatrixSet: wmtsTileMatrixSets },
  } = wmtsCapabilities;

  const layers = [];
  wmtsLayers.forEach((item) => {
    let format;
    let url;

    const { ResourceURL } = item;
    if (ResourceURL) {
      const resourceUrl = item.ResourceURL.find(
        (item2) => item2.resourceType === 'tile'
      );
      format = resourceUrl.format;
      url = resourceUrl.template;
    } else if (
      wmtsCapabilities.OperationsMetadata?.GetTile?.DCP?.HTTP?.Get[0]?.href
    ) {
      format = item.Format[0];
      url = wmtsCapabilities.OperationsMetadata.GetTile.DCP.HTTP.Get[0].href;
    } else {
      format = item.Format[0];
      url = serviceUrl.split('?')[0].split('#')[0];
    }

    const requestEncoding = /\{(\w+?)\}/g.test(url)
      ? WMTSRequestEncoding.REST
      : WMTSRequestEncoding.KVP;

    const { TileMatrixSet: matrixSet } = item.TileMatrixSetLink[0];
    const wmtsTileMatrixSet = wmtsTileMatrixSets.find(
      (item2) => item2.Identifier === matrixSet
    );

    let projection;
    const { SupportedCRS } = wmtsTileMatrixSet;
    try {
      projection = getProjection(wmtsTileMatrixSet.SupportedCRS);
      if (!projection) {
        throw `The layer ${item.Title} is dropped because it uses the unsupported projection ${SupportedCRS}.`;
      }
    } catch (e) {
      // The projection is not supported.
      console.warn(e);
      return;
    }

    const tileMatrixSetData = wmtsTileMatrixSet.TileMatrix.map((item2) => {
      return {
        id: item2.Identifier,
        // How to get the resolution from the ScaleDenominator:
        // https://github.com/tschaub/tilet/blob/main/readme.md
        resolution:
          (item2.ScaleDenominator * 0.00028) / projection.getMetersPerUnit(),
        origin: handleCoord(item2.TopLeftCorner, axisOrientation),
        matrixWidth: item2.MatrixWidth,
        matrixHeight: item2.MatrixHeight,
        tileWidth: item2.TileWidth,
        tileHeight: item2.TileHeight,
      };
    }).sort((item1, item2) => parseInt(item1.id, 10) - parseInt(item2.id, 10));

    const { Identifier: style } =
      item.Style.find((item) => item.isDefault) ?? item.Style[0];

    const matrixIds = [];
    const resolutions = [];
    const origins = [];
    const sizes = [];
    const tileSizes = [];
    tileMatrixSetData.forEach((item2) => {
      const {
        id,
        resolution,
        origin,
        matrixWidth,
        matrixHeight,
        tileWidth,
        tileHeight,
      } = item2;
      matrixIds.push(id);
      resolutions.push(resolution);
      origins.push(origin);
      sizes.push([matrixWidth, matrixHeight]);
      tileSizes.push([tileWidth, tileHeight]);
    });
    layers.push({
      id: item.Identifier,
      name: item.Title,
      extent: getWgs84BoundingBox(item),
      format,
      url,
      matrixSet,
      matrixIds,
      resolutions,
      origins,
      sizes,
      tileSizes,
      parentLayerId: -1,
      subLayerIds: null,
      style,
      requestEncoding,
      projection: projection.getCode(),
    });
  });

  const extents = layers.map((item) => item.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    attributions: '',
    version,
    title,
    description: title,
    layers,
    fullExtent,
  };
}

export function createLayer(map, options) {
  const {
    attributions,
    version,
    id,
    format,
    matrixSet,
    matrixIds,
    resolutions,
    origins,
    sizes,
    tileSizes,
    style,
    requestEncoding = WMTSRequestEncoding.REST,
    projection = 'EPSG:3857',
    shouldUseCorsProxy,
  } = options;
  const viewProjection = map.getView().getProjection();

  let { url } = options;
  if (shouldUseCorsProxy) {
    url = utils.network.proxify(url);
  }

  let { visibleExtent } = options;
  if (!visibleExtent) {
    visibleExtent = transformExtent(
      viewProjection.getExtent(),
      viewProjection,
      'EPSG:4326'
    );
  }

  const tileGrid = new WMTSTileGrid({
    extent: transformExtent(visibleExtent, 'EPSG:4326', projection),
    origins,
    resolutions,
    matrixIds,
    sizes,
    tileSizes,
  });
  map.patchTileGrid(tileGrid, projection, bl.measure.Dpi.WMTS);
  const layerSource = new WMTS({
    attributions,
    crossOrigin: 'anonymous',
    version,
    layer: id,
    url,
    requestEncoding,
    style,
    matrixSet,
    format,
    tileGrid,
    wrapX: true,
    projection,
  });
  const layer = new TileLayer({
    source: layerSource,
    extent: transformExtent(visibleExtent, 'EPSG:4326', viewProjection),
  });
  assignIdToLayer(layer);
  layer.applyOptions = function (options) {
    this.options = options;
  };
  layer.hasFeature = function (feature) {
    return false;
  };

  enableLoadingEvents(layer);

  return layer;
}
