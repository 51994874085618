import EventBus from '@component-library/EventBus';
import { Overlay } from 'ol';
import DragOverlay from 'ol-ext/interaction/DragOverlay';
import { appendAsterisk } from '../../../lib/olbm/common/utils';
import {
  ICON_SIZE_ADJUST_FACTOR,
  LABEL_SIZE_ADJUST_FACTOR,
} from '../../../lib/olbm/layer/sample/getDefaultSampleStyle';
import { getSampleIcon } from '../../../lib/olbm/layer/sample/utils';
import { createTextShadow } from '../../../view-utils';
import { getSampleFeatureOptions } from '../layers';
import { applyStyle } from '../styles';
import { checkEqualityOfTwoCoordinatesLeniently } from '../utils';

/**
 * A component used to edit a sample on the map.
 *
 * A sample on the map consists of an icon overlay and a label overlay.
 * The icon overlay always exists, however, when the sample is a composite,
 * there is no icon in the icon overlay. The label overlay could be null
 * because the label of a sample could be hidden.
 *
 *
 * Moving the icon overlay always causes the label overlay to move too.
 *
 * When the icon overlay and the label overlay stay together(glued), they
 * share the sample position as their position.
 * -------------------------------------------------------
 * |                        .Sample Position             | Icon Overlay
 * |-----------------------------------------------------|
 * |                                                     | Label Overlay
 * -------------------------------------------------------
 *
 * When the icon overlay and the label overlay stay apart, the position of the
 * icon overlay is the sample position. The position of the label overlay changes
 * to be one right under the sample position. When moving the label overlay,
 * the icon overlay doesn't move.
 * -------------------------------------------------------
 * |                        .Sample Position             | Icon Overlay
 * |------------------------.Label Overlay Position------|
 * |                                                     | Label Overlay
 * -------------------------------------------------------
 *
 */
export default class EditableSample {
  constructor(map, sample, isNew = true) {
    this.map = map;
    this.sample = sample;
    this.isNew = isNew;
    this.active = false;

    this.drag = new DragOverlay({ overlays: [], centerOnClick: false });
    this.drag.on('dragstart', ({ overlay, coordinate }) => {
      if (overlay === this.labelOverlay) {
        if (!this.isGlued()) {
          return;
        }

        this.labelPosition = getDefaultLabelPosition(
          this.map,
          this.iconOverlay
        );
        this.refresh();
      }
    });

    this.drag.on('dragging', ({ overlay, coordinate }) => {
      if (overlay === this.iconOverlay) {
        this.setPosition(coordinate);
        EventBus.$emit('adaptToSamplePositionChange', this.sample);
      } else if (overlay === this.labelOverlay) {
        this.labelPosition = coordinate;
        // Check whether the overlays should be glued.
        const startLabelPosition = getDefaultLabelPosition(
          this.map,
          this.iconOverlay
        );
        const [startX, startY] =
          this.map.getPixelFromCoordinate(startLabelPosition);
        const endLabelPosition = this.labelOverlay.getPosition();
        const [endX, endY] = this.map.getPixelFromCoordinate(endLabelPosition);
        const deltaX = Math.abs(endX - startX);
        const deltaY = Math.abs(endY - startY);
        const threshold = 10 * this.map.getViewer().figureLayout.zoom;
        if (deltaX <= threshold && deltaY <= threshold) {
          this.labelPosition = this.position;
        }
        this.refresh();
      }
    });

    this.drag.on('dragend', ({ overlay }) => {
      if (overlay === this.labelOverlay) {
        this.sampleLayer.setLabelPosition(this.sample, this.labelPosition);
      }
    });
  }
  /**
   * When glued, there is no space between the icon overlay and the label overlay.
   */
  isGlued() {
    return checkEqualityOfTwoCoordinatesLeniently(
      this.map,
      this.position,
      this.labelPosition
    );
  }
  activate(edit) {
    if (this.active) {
      return;
    }

    this.sampleLayer = this.map.layerManager.findSampleLayerBySampleGroup(
      this.sample.sample_group
    );

    this.position = this.sampleLayer.getPosition(this.sample);
    // The newly created sample should use the sample position as its label position.
    this.labelPosition =
      (this.isNew ? null : this.sampleLayer.getLabelPosition(this.sample)) ||
      this.position;

    this.map.addInteraction(this.drag);

    const options = getSampleFeatureOptions(this.map, this.sample);

    this.iconOverlay = createIconOverlay(this.map, this.position, options);
    this.iconOverlay.getElement().addEventListener('mouseover', () => {
      const { isRenderableNonSpatialSampleGroup } = this.map.getViewer();
      if (this.drag.getActive() && isRenderableNonSpatialSampleGroup) {
        // The location of items in a renderable non-spatial sample group is not changeable.
        this.drag.setActive(false);
      }
    });
    this.map.addOverlay(this.iconOverlay);
    this.drag.addOverlay(this.iconOverlay);

    if (!options.isLabelHidden) {
      this.labelOverlay = createLabelOverlay(this.map, this.position, options);
      this.labelOverlay.getElement().addEventListener('mouseover', () => {
        if (!this.drag.getActive()) {
          this.drag.setActive(true);
        }
      });
      this.map.addOverlay(this.labelOverlay);
      this.drag.addOverlay(this.labelOverlay);
    }

    edit.layer = this.sampleLayer;

    this.active = true;
  }
  deactivate() {
    if (!this.active) {
      return;
    }

    this.map.removeInteraction(this.drag);

    this.map.removeOverlay(this.iconOverlay);
    this.drag.removeOverlay(this.iconOverlay);
    this.iconOverlay = null;

    if (this.labelOverlay) {
      this.map.removeOverlay(this.labelOverlay);
      this.drag.removeOverlay(this.labelOverlay);
      this.labelOverlay = null;
    }

    this.sampleLayer = null;
    this.position = null;
    this.labelPosition = null;
    this.glued = true;
    this.active = false;
  }
  /**
   *
   * @param {ol.Coordinate} position The position of the sample.
   */
  setPosition(position) {
    if (!this.isGlued()) {
      const oldPixel = this.map.getPixelFromCoordinate(this.position);
      const pixel = this.map.getPixelFromCoordinate(position);
      const deltaX = pixel[0] - oldPixel[0];
      const deltaY = pixel[1] - oldPixel[1];
      const oldLabelPixel = this.map.getPixelFromCoordinate(this.labelPosition);
      const labelPixel = [oldLabelPixel[0] + deltaX, oldLabelPixel[1] + deltaY];
      this.labelPosition = this.map.getCoordinateFromPixel(labelPixel);
    } else {
      this.labelPosition = position;
    }
    this.position = position;

    this.sampleLayer.setPosition(this.sample, this.position);
    this.sampleLayer.setLabelPosition(this.sample, this.labelPosition);

    this.refresh();
  }
  refresh() {
    if (!this.active) {
      return;
    }

    // Set the position of the icon overlay.
    this.iconOverlay.setPosition(this.position);
    const { width: ioWidth, height: ioHeight } = this.iconOverlay
      .getElement()
      .getBoundingClientRect();
    this.iconOverlay.setOffset([-(ioWidth / 2), -(ioHeight / 2)]);

    // Set the position of the label overlay.
    if (this.labelOverlay) {
      this.labelOverlay.setPosition(this.labelPosition);
      const { width: loWidth } = this.labelOverlay
        .getElement()
        .getBoundingClientRect();
      if (this.isGlued()) {
        this.labelOverlay.setOffset([-(loWidth / 2), ioHeight / 2]);
      } else {
        this.labelOverlay.setOffset([-(loWidth / 2), 0]);
      }
    }
  }
  invalidate(edit) {
    if (!this.active) {
      return;
    }

    this.deactivate();
    this.isNew = false;
    this.activate(edit);
    this.refresh();
  }
}

/**
 * When the overlays are not glued, the label overlay acts like a
 * satellite of the icon overlay.
 * ----------------
 * |     icon     |
 * -------.--------
 *       The returned position
 * @param {*} map
 * @param {*} iconOverlay
 * @returns
 */
function getDefaultLabelPosition(map, iconOverlay) {
  const position = iconOverlay.getPosition();
  const pixel = map.getPixelFromCoordinate(position);
  const { height: ioHeight } = iconOverlay.getElement().getBoundingClientRect();
  const labelPixel = [pixel[0], pixel[1] + ioHeight / 2];
  return map.getCoordinateFromPixel(labelPixel);
}

function createIconOverlay(map, position, options) {
  const {
    id,
    isDefault,
    width,
    isIconVisible,
    iconSize,
    iconOpacity,
    iconRotation,
    icon,
    color,
  } = options;
  const element = document.createElement('div');
  const { isRenderableNonSpatialSampleGroup } = map.getViewer();
  element.setAttribute(
    'class',
    'editable-sample-icon-container d-flex flex-column align-items-center' +
      (isRenderableNonSpatialSampleGroup ? ' dragging-disabled' : '')
  );
  element.setAttribute('data-sample-id', id);
  element.setAttribute('data-is-default', isDefault);
  element.style.cursor = isRenderableNonSpatialSampleGroup ? 'auto' : 'move';

  let elementStyle = {
    width: `${width}px`,
    wordBreak: 'break-word',
  };
  if (!isIconVisible) {
    // A composite item doesn't have an icon, so height is required
    elementStyle = {
      ...elementStyle,
      height: `${iconSize * ICON_SIZE_ADJUST_FACTOR}px`,
    };
  }
  applyStyle(element, elementStyle);

  // Only none-composite item has an icon
  if (isIconVisible) {
    const iconEl = document.createElement('div');
    iconEl.setAttribute('class', 'marker-image');
    const iconStyle = {
      width: `${iconSize * ICON_SIZE_ADJUST_FACTOR}px`,
      height: `${iconSize * ICON_SIZE_ADJUST_FACTOR}px`,
      opacity: iconOpacity,
      rotate: `${iconRotation}deg`,
    };
    applyStyle(iconEl, iconStyle);

    const svgEl = document.createElement('img');
    const { src } = getSampleIcon(icon, color);
    svgEl.src = src;
    svgEl.draggable = false;
    iconEl.appendChild(svgEl);
    element.appendChild(iconEl);
  }

  const overlay = patchOverlay(
    map,
    new Overlay({
      element,
      position,
      className: 'figure-marker',
      stopEvent: false,
    })
  );

  return overlay;
}

function createLabelOverlay(map, position, options) {
  const {
    id,
    isDefault,
    width,
    isIconVisible,
    exceedanceColor,
    labelColor,
    labelShadowColor,
    isLabelUnderlined,
    isLabelAsteriskAppended,
    labelSize,
    title,
    duplicate,
    isDuplicateLabelHidden,
  } = options;
  const element = document.createElement('div');
  element.setAttribute(
    'class',
    'editable-sample-label-container d-flex flex-column align-items-center'
  );
  element.setAttribute('data-sample-id', id);
  element.setAttribute('data-is-default', isDefault);
  element.style.cursor = 'move';

  const elementStyle = {
    width: `${width}px`,
    wordBreak: 'break-word',
  };
  applyStyle(element, elementStyle);

  // Handle the text element
  const titleEl = document.createElement('span');
  titleEl.setAttribute('id', 'sample-title');
  titleEl.setAttribute('class', `custom-marker__title mt-1`);

  let titleStyle = {
    color: !isIconVisible && exceedanceColor ? exceedanceColor : labelColor,
    textShadow: createTextShadow(labelShadowColor),
    fontSize: `${labelSize * LABEL_SIZE_ADJUST_FACTOR}px`,
    width: `${width}px`,
    textDecoration: isLabelUnderlined ? 'underline' : 'none',
    textUnderlinePosition: 'under',
  };
  applyStyle(titleEl, titleStyle);

  const titleContentEl = document.createTextNode(
    isLabelAsteriskAppended ? appendAsterisk(title) : title
  );
  titleEl.appendChild(titleContentEl);

  if (duplicate && !isDuplicateLabelHidden) {
    const { custom_title: duplicateCustomTitle, lab_title: duplicateLabTitle } =
      duplicate;
    const duplicateTitleEl = document.createElement('span');
    duplicateTitleEl.setAttribute('class', 'duplicate');
    duplicateTitleEl.innerHTML = duplicateCustomTitle || duplicateLabTitle;
    titleEl.appendChild(duplicateTitleEl);
  }

  element.appendChild(titleEl);

  const overlay = patchOverlay(
    map,
    new Overlay({
      element,
      position,
      className: 'figure-marker',
      stopEvent: false,
    })
  );

  return overlay;
}

function patchOverlay(map, overlay) {
  overlay.updateRenderedPosition = function (pixel, mapSize) {
    const { zoom } = map.getViewer().figureLayout;

    const style = this.element.style;
    const offset = this.getOffset();

    this.setVisible(true);

    const x = Math.round(pixel[0] + offset[0]) + 'px';
    const y = Math.round(pixel[1] + offset[1]) + 'px';

    style.transformOrigin = 'left top';

    const transform = `translate(${x}, ${y}) scale(${zoom})`;
    if (this.rendered.transform_ != transform) {
      this.rendered.transform_ = transform;
      style.transform = transform;
      // @ts-expect IE9
      style.msTransform = transform;
    }
  };
  return overlay;
}
