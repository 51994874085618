import { processInputResponse } from '@component-library/business-logic/input-value';

export const uploadProjectAsset = (data) => {
  return axios.post('/gather/project/asset', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteSample = (id) => {
  return axios.delete('/gather/sample/' + id);
};

export const updateSampleData = (data) => {
  return axios.post('/gather/sample/update', data);
};

export const updateSample = (id, data) => {
  return axios.put(`/data/sample/${id}`, data);
};

export const getSampleInputValuesById = (id, data) => {
  return axios
    .get('/gather/samples/' + id + '/values', {
      params: data,
    })
    .then((response) => {
      return processInputResponse(response);
    });
};

export const getAssociatedFields = (id, params) =>
  axios.get(`/gather/associated-fields/${id}`, { params });
