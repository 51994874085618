import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';

function createGeoJSONDriver(map) {
  const featureProjection = map.getView().getProjection();
  return new GeoJSON({ featureProjection });
}

/**
 * Convert object to GeoJSON data.
 * @param {ol/Map} map
 * @param {Feature | Array<Feature>} object
 */
export function toGeoJSON(map, object) {
  const driver = createGeoJSONDriver(map);

  return object instanceof Feature
    ? driver.writeFeatureObject(object)
    : driver.writeFeaturesObject(object);
}

/**
 * Convert data in GeoJSON format to an array of features.
 * @param {ol/Map} map
 * @param {ArrayBuffer | Document | Element | Object | string} object
 * @returns
 */
export function fromGeoJSON(map, object) {
  const driver = createGeoJSONDriver(map);
  return driver.readFeatures(object);
}
