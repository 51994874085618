import {
  boundingExtent,
  getBottomLeft,
  getBottomRight,
  getTopRight,
  getTopLeft,
  getCenter,
  createEmpty as createEmptyExtent,
  extend as enlargeExtent,
  equals as checkEqualityOfTwoExtents,
  intersects as checkInteractivityOfTwoExtents,
  containsExtent,
} from 'ol/extent';
import { transformExtent, getPointResolution } from 'ol/proj';
import { fromExtent as createPolygonFromExtent } from 'ol/geom/Polygon';
import * as olCoordinate from 'ol/coordinate';
import { coordinateToLatLng, latLngToCoordinate } from './coordinate';

export {
  checkEqualityOfTwoExtents,
  checkInteractivityOfTwoExtents,
  getCenter as getExtentCenter,
  transformExtent,
  containsExtent,
};

export function padExtent(extent, padding) {
  const polygon = createPolygonFromExtent(extent);
  polygon.scale(1 + padding);
  return polygon.getExtent();
}

/**
 * Pad the extent by an amount in meters.
 * @param {ol.Map} map
 * @param {ol.Extent} extent
 * @param {Number} meters
 * @returns {ol.Extent}
 */
export function padExtentByMeters(map, extent, meters) {
  const projection = map.getView().getProjection();
  const mpu = projection.getMetersPerUnit();
  if (!mpu) {
    throw 'Unsupported operation.';
  }
  const resolution = map.getView().getResolution();

  const bottomLeft = getBottomLeft(extent);
  const bottomLeftR =
    getPointResolution(projection, resolution, bottomLeft) * mpu;
  const bottomLeftPixel = map.getPixelFromCoordinate(bottomLeft);
  const offset1 = meters / bottomLeftR;
  const newBottomLeft = map.getCoordinateFromPixel([
    bottomLeftPixel[0] - offset1,
    bottomLeftPixel[1] + offset1,
  ]);

  const topRight = getTopRight(extent);
  const topRightR = getPointResolution(projection, resolution, topRight) * mpu;
  const topRightPixel = map.getPixelFromCoordinate(topRight);
  const offset2 = meters / topRightR;
  const newTopRight = map.getCoordinateFromPixel([
    topRightPixel[0] + offset2,
    topRightPixel[1] - offset2,
  ]);

  return boundingExtent([newBottomLeft, newTopRight]);
}

/**
 * A bounds is expressed by a pair of diagonal LatLngs.
 * @param {ol/extent} extent
 * @param {Number} padding Padding in pertentage.
 * @returns
 */
export function extentToBounds(extent, projection, padding = 0) {
  if (padding > 0) {
    extent = padExtent(extent, padding);
  }

  const bottomLeft = getBottomLeft(extent);
  const topRight = getTopRight(extent);

  return {
    _southWest: coordinateToLatLng(bottomLeft, projection),
    _northEast: coordinateToLatLng(topRight, projection),
  };
}

export function boundsToExtent(bounds, projection, padding = 0) {
  const { _southWest, _southEast, _northEast, _northWest } = bounds;
  const coordinates = [];

  if (_southWest) {
    const bottomLeft = latLngToCoordinate(_southWest, projection);
    coordinates.push(bottomLeft);
  }

  if (_southEast) {
    const bottomRight = latLngToCoordinate(_southEast, projection);
    coordinates.push(bottomRight);
  }

  if (_northEast) {
    const topRight = latLngToCoordinate(_northEast, projection);
    coordinates.push(topRight);
  }

  if (_northWest) {
    const topLeft = latLngToCoordinate(_northWest, projection);
    coordinates.push(topLeft);
  }

  let extent = boundingExtent(coordinates);

  if (padding > 0) {
    extent = padExtent(extent, padding);
  }

  return extent;
}

export function transformBounds(bounds, projection) {
  const extent = boundsToExtent(bounds, projection);
  const topLeft = getTopLeft(extent);
  const bottomRight = getBottomRight(extent);

  return {
    _northWest: coordinateToLatLng(topLeft, projection),
    _southEast: coordinateToLatLng(bottomRight, projection),
  };
}

export function mergeExtents(...extents) {
  let newExtent = createEmptyExtent();

  extents.forEach((extent) => {
    newExtent = enlargeExtent(newExtent, extent);
  });

  return newExtent;
}

export function transformEsriServiceLayerExtent(esriServiceLayerExtent) {
  const {
    xmin,
    ymin,
    xmax,
    ymax,
    spatialReference: { wkid: specWkid, latestWkid: specLatestWkid },
  } = esriServiceLayerExtent;
  let wkid = specLatestWkid;
  if (typeof wkid !== 'number') {
    wkid = specWkid;
  }
  if (wkid === 102100) {
    wkid = 3857;
  }

  try {
    return transformExtent(
      [xmin, ymin, xmax, ymax],
      `EPSG:${wkid}`,
      'EPSG:4326'
    );
  } catch (e) {
    console.error(e);
    throw `The coordinate styem 'EPSG:${wkid}' is not supported.`;
  }
}

export function intersectsWithoutEdgeOverlap(extent1, extent2) {
  return (
    extent1[0] < extent2[2] &&
    extent1[2] > extent2[0] &&
    extent1[1] < extent2[3] &&
    extent1[3] > extent2[1]
  );
}

export function checkEqualityOfTwoExtentsLeniently(
  extent1,
  extent2,
  precision = 8
) {
  const handleValue = (value) =>
    Math.round(value * Math.pow(10, precision)) / precision;
  extent1 = [...extent1].map(handleValue);
  extent2 = [...extent2].map(handleValue);
  return (
    extent1[0] == extent2[0] &&
    extent1[2] == extent2[2] &&
    extent1[1] == extent2[1] &&
    extent1[3] == extent2[3]
  );
}

export function getDiagonalLineDisplayLength(map, extent) {
  const topLeft = getTopLeft(extent);
  const bottomRight = getBottomRight(extent);
  const topLeftPixel = map.getPixelFromCoordinate(topLeft);
  const bottomRightPixel = map.getPixelFromCoordinate(bottomRight);
  return olCoordinate.distance(topLeftPixel, bottomRightPixel);
}
