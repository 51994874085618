import BaseEvent from 'ol/events/Event';
import {
  Vector as VectorSource,
  TileArcGISRest,
  VectorTile as VectorTileSource,
  ImageArcGISRest,
  ImageWMS,
  WMTS,
  XYZ,
} from 'ol/source';
import TileState from 'ol/TileState';

export default function enableLoadingEvents(layer) {
  let loadingCounter = 0;
  let startEventName;
  let endEventName;
  let errorEventName;

  const layerSource = layer.getSource();

  function customTileLoadFunction(tile, src) {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.addEventListener('loadend', function (evt) {
      var data = this.response;
      if (this.status === 200 && data !== undefined) {
        tile.getImage().src = URL.createObjectURL(data);
      } else {
        tile.setState(TileState.ERROR);
        if (this.responseType === 'blob' && this.response.size > 0) {
          var reader = new FileReader();
          reader.onload = function () {
            const event = new BaseEvent('loaderror');
            try {
              event.error = JSON.parse(reader.result);
              layer.dispatchEvent(event);
            } catch (e) {
              console.error(reader.result);
            }
          };
          reader.readAsText(this.response);
        }
      }
    });
    xhr.addEventListener('error', function () {
      tile.setState(TileState.ERROR);
    });
    xhr.open('GET', src);
    xhr.send();
  }
  if (layerSource instanceof XYZ) {
    layerSource.setTileLoadFunction(customTileLoadFunction);
  }
  if (layerSource instanceof VectorSource) {
    startEventName = 'featuresloadstart';
    endEventName = 'featuresloadend';
    errorEventName = 'featuresloaderror';
  } else if (
    layerSource instanceof TileArcGISRest ||
    layerSource instanceof VectorTileSource ||
    layerSource instanceof WMTS ||
    layerSource instanceof XYZ
  ) {
    startEventName = 'tileloadstart';
    endEventName = 'tileloadend';
    errorEventName = 'tileloaderror';
  } else if (
    layerSource instanceof ImageArcGISRest ||
    layerSource instanceof ImageWMS
  ) {
    startEventName = 'imageloadstart';
    endEventName = 'imageloadend';
    errorEventName = 'imageloaderror';
  } else {
    return;
  }

  layerSource.on(startEventName, () => {
    if (loadingCounter === 0) {
      layer.dispatchEvent('loadstart');
    }

    loadingCounter++;
  });

  layerSource.on([endEventName, errorEventName], (event) => {
    const { type } = event;
    loadingCounter--;

    if (type === errorEventName) {
      layer.dispatchEvent('loaderror');
    }

    if (loadingCounter === 0) {
      layer.dispatchEvent('loadend');
    }
  });
}
