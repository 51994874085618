import { getOrderOfScenario } from '@/js/helpers/scenario.js';
import { canHydrocarbonOnlyExport } from '@/js/helpers/scenario.mjs';

const get_landuse_scenarios = (state, getters) => {
  return getters.get_processed_scenarios.filter(
    (s) => s.criteria_type == 'landuse'
  );
};

const get_scenario_by_id = (state) => (id) => {
  return state.scenarios.find((s) => s.id == id);
};

const get_document_by_id = (state) => (id) => {
  return state.scenarios.find((d) => d.id == id).document;
};

const get_prerequisite_field = (state) => (field) => {
  return state.processed_information && field in state.processed_information
    ? state.processed_information[field]
    : false;
};

const can_hydrocarbon_only_export = (state) => {
  return canHydrocarbonOnlyExport(state.processed_settings, state.scenarios);
};

const get_processed_scenarios = (state) => {
  const settings = state.processed_settings;
  if (!settings) {
    return [];
  }

  const group = settings.scenario_groups.find((g) => g.id == settings.group_id);
  return state.scenarios
    .filter((s) => s.is_processed && _isScenarioToggled(group, s.id))
    .sort(
      (a, b) =>
        getOrderOfScenario(a, settings.scenario_groups, settings.group_id) -
        getOrderOfScenario(b, settings.scenario_groups, settings.group_id)
    );
};

const get_toggled_scenarios = (state) => {
  const settings = state.processed_settings;
  if (!settings) {
    return [];
  }

  const group = settings.scenario_groups.find((g) => g.id == settings.group_id);
  return state.scenarios.filter((s) => _isScenarioToggled(group, s.id));
};

const has_scenario_by_type = (state, getters) => (critera_type) => {
  return (
    getters.get_processed_scenarios.findIndex(
      (s) => s.criteria_type == critera_type
    ) != -1
  );
};

const is_scenario_toggled = (state) => (scenario_id) => {
  const settings = state.processed_settings;
  return _isScenarioToggled(
    settings.scenario_groups.find((g) => g.id == settings.group_id),
    scenario_id
  );
};

const get_scenario_group = (state) => {
  const settings = state.processed_settings;
  return settings
    ? settings.scenario_groups.find((g) => g.id == settings.group_id)
    : null;
};

const _isScenarioToggled = (group, scenario_id) => {
  return group && group.group_items
    ? group.group_items.findIndex((item) => item.scenario_id == scenario_id) !=
        -1
    : false;
};

export default {
  get_landuse_scenarios,
  get_scenario_by_id,
  get_document_by_id,
  get_prerequisite_field,
  can_hydrocarbon_only_export,
  get_processed_scenarios,
  get_toggled_scenarios,
  has_scenario_by_type,
  is_scenario_toggled,
  get_scenario_group,
};
