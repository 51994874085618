import * as TOOL_TYPES from '../constants/toolTypes';

async function loadBarChartData(params) {
  const { data } = await axios.post('/data-insights/bar-chart-data', {
    data: params,
  });
  const {
    labels,
    datasets: [dataset],
  } = data;

  return labels.length > 0 && dataset.data.length > 0 ? data : null;
}

async function loadLineChartData(params, groupIndex = 0, pageIndex = 0) {
  const { data } = await axios.post('/data-insights/line-chart-data', {
    data: params,
  });
  let chart = data;
  if (Array.isArray(chart) && chart.length > 0) {
    chart = { ...data[groupIndex].pages[pageIndex] };
    chart.groups = data;
  }

  return chart?.labels?.length > 0 && chart.datasets?.length > 0 ? data : null;
}

async function loadPieChartData(params) {
  const { data } = await axios.post('/data-insights/pie-chart-data', {
    data: params,
  });
  const {
    labels,
    datasets: [dataset],
  } = data;

  return labels.length > 0 && dataset.data.length > 0 ? data : null;
}

async function loadScatterChartData(params) {
  const { data } = await axios.post('/data-insights/scatter-chart-data', {
    data: params,
  });
  const { datasets } = data;
  return datasets.length > 0 ? data : null;
}

async function loadPercentageChartData(params) {
  const {
    data: { number_of_created_samples },
  } = await axios.get('/data-insights/percentage-chart-data', {
    params,
  });
  return { numberOfCreatedSamples: number_of_created_samples };
}

async function loadWordCloudChartData(params) {
  const { data } = await axios.post('/data-insights/word-cloud-chart-data', {
    data: params,
  });
  const words = Object.keys(data.words).map((key) => [key, data.words[key]]);

  return words.length > 0
    ? {
        words,
        section_rows: data.section_rows,
        section_fields: data.section_fields,
      }
    : null;
}

async function loadDisplacementGraphData(params) {
  const { data } = await axios.post(
    '/data-insights/displacement-graph-data',
    params
  );

  return data;
}

export default async (toolType, params) => {
  switch (toolType) {
    case TOOL_TYPES.BAR_CHART:
      return await loadBarChartData(params);
    case TOOL_TYPES.LINE_CHART:
      return await loadLineChartData(params);
    case TOOL_TYPES.PIE_CHART:
      return await loadPieChartData(params);
    case TOOL_TYPES.SCATTER_CHART:
      return await loadScatterChartData(params);
    case TOOL_TYPES.PERCENTAGE_CHART:
      return await loadPercentageChartData(params);
    case TOOL_TYPES.WORD_CLOUD_CHART:
      return await loadWordCloudChartData(params);
    case TOOL_TYPES.DISPLACEMENT_GRAPH:
      return await loadDisplacementGraphData(params);
    default:
      return {};
  }
};
