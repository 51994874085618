<template>
  <div class="w-100 h-100">
    <template v-if="isYoutubeVideo">
      <iframe
        width="100%"
        :src="getFileUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </template>

    <video
      width="100%"
      :controls="hasControls"
      :autoplay="shouldAutoplay"
      v-else
    >
      <source :src="getFileUrl" />
      Your browser does not support the video tag.
    </video>

    <div class="caption mt-3" v-if="!isSlide">
      <caption-text
        :block="block"
        placeholder="Add a caption for this video (optional)"
        @update="(data) => $emit('update', data)"
      />
    </div>
  </div>
</template>
<script>
import CaptionText from './Text.vue';

export default {
  props: {
    block: Object,
    isSlide: Boolean,
  },
  components: { CaptionText },
  computed: {
    isYoutubeVideo() {
      const url = this.block.file_external_url;
      return url && (url.includes('youtube') || url.includes('youtu.be'));
    },
    getPlaybackSetting() {
      const { playback } = this.block.settings;

      return playback || 'click-to-play';
    },
    shouldAutoplay() {
      const playback = this.getPlaybackSetting;

      return (
        playback == 'autoplay-with-controls' ||
        playback == 'autoplay-without-controls'
      );
    },
    hasControls() {
      const playback = this.getPlaybackSetting;

      return (
        playback == 'autoplay-with-controls' || playback == 'click-to-play'
      );
    },
    getFileUrl() {
      let url = this.block.file_url;

      if (this.isYoutubeVideo) {
        url =
          url +
          `?autoplay=${this.shouldAutoplay ? 1 : 0}&controls=${
            this.hasControls ? 1 : 0
          }`;
      }

      return url;
    },
  },
};
</script>
<style lang="scss" scoped>
iframe,
video {
  height: 465px;
  min-height: 465px;
}

.slideshow__figure {
  iframe,
  video {
    height: 100%;
  }
}
</style>
