import {
  getFormattedTitle,
  getFormattedType,
  getFormattedPathway,
  getFormattedHQType,
  getFormattedBasis,
  getFormattedMediaType,
  getFormattedSoilDepth,
  getFullScenarioText,
} from '../helpers/scenario.js';

export default {
  methods: {
    getFormattedTitle(
      scenario,
      document,
      showBasis,
      criteriaType,
      factor = 1,
      country = null,
      showFactor = false,
      options = null,
      hydrocarbonSettings = null
    ) {
      return getFormattedTitle(
        scenario,
        document,
        showBasis,
        criteriaType,
        factor,
        country,
        showFactor,
        options,
        hydrocarbonSettings
      );
    },
    getFormattedType(type) {
      return getFormattedType(type);
    },
    getFormattedPathway(pathway) {
      return getFormattedPathway(pathway);
    },
    getFormattedHQType(hqType) {
      return getFormattedHQType(hqType);
    },
    getFormattedBasis(basis) {
      return getFormattedBasis(basis);
    },
    getFormattedMediaType(media) {
      return getFormattedMediaType(media);
    },
    getFormattedSoilDepth(soilDepth) {
      return getFormattedSoilDepth(soilDepth);
    },
    getFullScenarioText(scenario, document, country, withBasis = false) {
      return getFullScenarioText(scenario, document, country, withBasis);
    },
  },
};
