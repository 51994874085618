import { getPixelDistance } from './pixel';

/**
 * A line can be represented as a formula in the coordinate system: y = k * x + b or
 * x = b (if the line is othorgonal to the X axis).This function calculate the k and b
 * constants of the formula.
 * @param {ol/coordinate-Coordinate} start The start coordinate of the line
 * @param {ol/coordinate-Coordinate} end The end coordinate of the line
 */
export function getLineFormula(map, start, end) {
  const startPixel = map.getPixelFromCoordinate(start);
  const endPixel = map.getPixelFromCoordinate(end);
  const x1 = startPixel[0];
  const y1 = startPixel[1];
  const x2 = endPixel[0];
  const y2 = endPixel[1];
  let formula = { startPixel, endPixel };

  if (Math.abs(x2 - x1) >= 0.01) {
    const k = (y2 - y1) / (x2 - x1);
    const b = (y1 * x2 - y2 * x1) / (x2 - x1);
    formula = {
      ...formula,
      k,
      b,
    };
  } else {
    // if the difference is less than 0.01 then the line is orthogonal to X axis
    formula = {
      ...formula,
      b: x2,
    };
  }

  return formula;
}

/**
 * Get the formula of the orthogonal line.
 *
 *               Orthogonal Line
 *                   Start
 *                     |
 *                     |
 *                     |
 * Line Start _________|__________ Line End
 *               Orthogonal Line
 *                    End
 * @param {ol/Map} map
 * @param {ol/coordinate-Coordinate} lineStart The start coordinate of the line
 * @param {ol/coordinate-Coordinate} lineEnd The end coordinate of the line
 * @param {ol/coordinate-Coordinate} point The coordinate used as the start coordinate of the orthogonal line
 * @returns
 */
export function getOrthogonalLineFormula(map, lineStart, lineEnd, point) {
  const lineFormula = getLineFormula(map, lineStart, lineEnd);
  const orthogonalLineStartPixel = map.getPixelFromCoordinate(point);

  // Simple geometry is used to calculate the end pixel of the orthogonal line.
  let x;
  let y;

  if (lineFormula.hasOwnProperty('k')) {
    const { startPixel, endPixel } = lineFormula;
    const [x1, y1] = startPixel;
    const [x2, y2] = endPixel;
    const v1 = Math.pow(
      getPixelDistance(startPixel, orthogonalLineStartPixel),
      2
    );
    const v2 = Math.pow(
      getPixelDistance(endPixel, orthogonalLineStartPixel),
      2
    );
    const v3 = Math.pow(y1, 2);
    const v4 = Math.pow(y2, 2);
    const v5 = Math.pow(x1, 2);
    const v6 = Math.pow(x2, 2);
    const v7 = v1 - v2 - v3 + v4 - v5 + v6;
    const v8 = 2 * (y2 - y1);
    const v9 = 2 * (x2 - x1);
    x = (v7 - v8 * lineFormula['b']) / (v8 * lineFormula['k'] + v9);
    y = lineFormula['k'] * x + lineFormula['b'];
  } else {
    x = lineFormula['b'];
    y = orthogonalLineStartPixel[1];
  }
  const orthogonalLineStart = point;
  const orthogonalLineEnd = map.getCoordinateFromPixel([x, y]);

  return getLineFormula(map, orthogonalLineStart, orthogonalLineEnd);
}
