export async function createHeatmapFiles(type, data) {
  await axios.post('/figure8/analysis/heatmap/files', { type, data });
}

export async function loadInterpolationAlgorithms() {
  const { data } = await axios.get(
    '/figure8/analysis/heatmap/interpolation-algorithms'
  );
  return data;
}
