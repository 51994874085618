export { default as enableLoadingEvents } from './enableLoadingEvents';
export { default as esri, checkAndUpdateEsriServiceLayers } from './esri';
export const OGC_OPENGIS_SERVICE_TYPE = 'ogc_opengis';
export const OGC_OPENGIS_SERVICE_SUBTYPES = {
  WFS: 'ogc_opengis_wfs',
  WMS: 'ogc_opengis_wms',
  WMTS: 'ogc_opengis_wmts',
};
export * as OgcOpenGisWfs from './OgcOpenGisWfs';
export * as OgcOpenGisWms from './OgcOpenGisWms';
export * as OgcOpenGisWmts from './OgcOpenGisWmts';
export * as VISIBLE_EXTENT_OPTIONS from './VisibleExtentOptions';
export const XYZ_TILES_SERVICE_TYPE = 'xyz_tiles';
export { default as createXyzTilesServiceLayer } from './createXyzTilesServiceLayer';
