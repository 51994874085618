import * as cl_utils from '@component-library/utils';
import _isEmpty from 'lodash/isEmpty';
import { WMSCapabilities } from 'ol/format';
import { Image as ImageLayer } from 'ol/layer';
import { transformExtent } from 'ol/proj';
import { ImageWMS } from 'ol/source';
import * as bl from '../../../business-logic';
import { EPSG_3857 } from '../../../lib/olbm/projection/common';
import { assignIdToLayer } from '../layers';
import * as utils from '../utils';
import { mergeExtents } from '../utils';
import * as network from '../utils/network';
import enableLoadingEvents from './enableLoadingEvents';

function correctBBOX(requestUrl) {
  const url = new URL(requestUrl);
  const [minx, miny, maxx, maxy] = url.searchParams.get('BBOX').split(',');
  const bbox = [miny, minx, maxy, maxx];
  const bboxParam = encodeURIComponent(bbox.join(','));
  return cl_utils.replaceQueryParam(requestUrl, 'BBOX', bboxParam);
}

function correctSize(requestUrl, scale) {
  const url = new URL(requestUrl);
  const width = parseInt(url.searchParams.get('WIDTH'));
  const height = parseInt(url.searchParams.get('HEIGHT'));
  requestUrl = cl_utils.replaceQueryParam(
    requestUrl,
    'WIDTH',
    String(Math.round(width * scale))
  );
  requestUrl = cl_utils.replaceQueryParam(
    requestUrl,
    'HEIGHT',
    String(Math.round(height * scale))
  );
  return requestUrl;
}

export async function getServiceData(serviceUrl, shouldUseCorsProxy) {
  const url = !shouldUseCorsProxy
    ? serviceUrl
    : utils.network.proxify(serviceUrl);
  const { data } = await axios.get(url, network.createRequestOptions());
  const parser = new WMSCapabilities();
  const wmsCapabilities = parser.read(data);

  const {
    version,
    Service: { Title: title, Abstract: description },
    Capability: {
      Request: {
        GetMap: {
          DCPType: [
            {
              HTTP: {
                Get: { OnlineResource: onlineResource },
              },
            },
          ],
        },
      },
      Layer: wmsLayerGroup,
    },
  } = wmsCapabilities;
  const { Layer: wmsLayers } = wmsLayerGroup;
  const layers = [];
  const subLayerIds = wmsLayers.map((wmsLayer) => wmsLayer.Name);
  layers.push({
    id: 1,
    name: wmsLayerGroup.Title,
    description: wmsLayerGroup.Abstract,
    extent: wmsLayerGroup.EX_GeographicBoundingBox,
    parentLayerId: -1,
    subLayerIds,
  });

  wmsLayers.forEach((wmsLayer) => {
    const { Name, Title, EX_GeographicBoundingBox, Style } = wmsLayer;

    const legendGraphics = [];
    if (Array.isArray(Style)) {
      Style.forEach((item) => {
        const { Title: title } = item;
        const [{ OnlineResource: url, size }] = item.LegendURL; // LegendURL is an array with only one item.
        legendGraphics.push({
          title,
          url: utils.network.forceHttps(url),
          size,
        });
      });
    }

    layers.push({
      url: utils.network.forceHttps(onlineResource),
      id: Name,
      name: Title,
      extent: EX_GeographicBoundingBox,
      parentLayerId: 1,
      subLayerIds: null,
      projection: wmsLayer.CRS?.[0] ?? EPSG_3857,
      legendGraphics,
    });
  });

  const extents = layers.map((item) => item.extent);
  const fullExtent = mergeExtents(...extents);

  return {
    // TODO Research whether we can get attributions from the capabilities data.
    attributions: '',
    version,
    title,
    description,
    layers,
    fullExtent,
  };
}

export function createLayer(map, options) {
  const {
    attributions,
    version,
    id,
    projection = EPSG_3857,
    shouldUseCorsProxy,
    axisOrientation,
  } = options;

  const visibleExtent = transformExtent(
    options.visibleExtent,
    'EPSG:4326',
    map.getView().getProjection()
  );

  const url = new URL(options.url);
  const layerSource = new ImageWMS({
    attributions,
    crossOrigin: 'anonymous',
    url: url.toString(),
    params: { VERSION: version, LAYERS: id },
    ratio: 1,
    projection,
  });

  const getRequestUrl_ = layerSource.getRequestUrl_;
  layerSource.getRequestUrl_ = function (...args) {
    let requestUrl = getRequestUrl_.call(this, ...args);
    if (shouldUseCorsProxy) {
      requestUrl = utils.network.proxify(requestUrl);
    }
    if (axisOrientation === bl.projection.AXIS_ORIENTATION_NEU.value) {
      requestUrl = correctBBOX(requestUrl);
    }
    requestUrl = correctSize(requestUrl, map.getViewer().scaleFactor);
    return requestUrl;
  };

  const layer = new ImageLayer({
    source: layerSource,
    extent: visibleExtent,
  });
  assignIdToLayer(layer);
  layer.applyOptions = function (options) {
    this.options = options;
  };
  layer.hasFeature = function (feature) {
    return false;
  };

  enableLoadingEvents(layer);

  return layer;
}
