<script setup lang="ts">
import { Sample } from '@component-library/enviro';
import { parseIdAsArray } from '@component-library/business-logic/app';
import {
  GatherField,
  InputValue,
  SectionWithLoading,
} from '@component-library/gather';
import { FieldTypeIds } from '@component-library/fields';
import fields from '@component-library/gather-table/fields';
import _isNil from 'lodash/isNil';
import { PropType, Ref, inject } from 'vue';
import { PopoverData } from '../../gather-table-horizontal/types/popover-data';
import FieldFilterColumn from './FieldFilterColumn.vue';

const props = defineProps({
  samples: {
    type: Array as PropType<Sample[]>,
    required: true,
  },
  templateTab: {
    type: Object,
    required: true,
  },
  section: {
    type: Object as PropType<SectionWithLoading>,
    required: true,
  },
  inputValues: {
    type: Array as PropType<InputValue[]>,
    required: true,
  },
  field: {
    type: Object as PropType<GatherField>,
    required: true,
  },
  sectionIndex: Number,
  editable: Boolean,
  loadingSamples: Boolean,
});

const isReference = (field: GatherField) => {
  return field.field_type_id === FieldTypeIds.REFERENCE;
};

const popoverData = inject<Ref<PopoverData>>('popoverData')!;
const openFieldPopover = inject<Function>('openFieldPopover')!;

const components = fields.components;

const getTagName = (sample: Sample): string => {
  const { field } = getSectionAndField(sample);
  const { field_type_id } = field;
  return fields.getUpperCaseComponentNameFromId(field_type_id);
};

const getSectionAndField = (sample: Sample) => {
  const tabIds = parseIdAsArray(props.templateTab.id);
  if (tabIds.length === 1) {
    return {
      section: props.section,
      field: props.field,
    };
  } else {
    const sectionIds = parseIdAsArray(props.section.id);
    const fieldIds = parseIdAsArray(props.field.id);
    const index = tabIds.indexOf(sample.template_tab_id!);
    const sectionId = sectionIds[index];
    const fieldId = fieldIds[index];
    const section = props.templateTab.sources[index].sections.find(
      (section) => section.id === sectionId
    );
    const field = section.template_fields.find((field) => field.id === fieldId);
    return { section, field };
  }
};

const getInputValue = (sample: Sample) => {
  const { section, field } = getSectionAndField(sample);

  const inputValue = props.inputValues.find(
    (v) =>
      v.template_field_id == field.id &&
      v.template_section_id == section.id &&
      v.sample_id == sample.id &&
      v.template_section_index == props.sectionIndex
  );

  return inputValue &&
    (!_isNil(inputValue.value) || !_isNil(inputValue.value2)) &&
    !(Array.isArray(inputValue.value) && inputValue.value.length == 0)
    ? inputValue
    : null;
};

const isSelected = (inputValue: InputValue | null) => {
  return (
    inputValue &&
    popoverData.value &&
    popoverData.value.inputValue.id === inputValue.id
  );
};

const getTdKey = (sample: Sample) => {
  const { section, field } = getSectionAndField(sample);
  return `td-${sample.id}-${section.id}-${props.sectionIndex}-${field.id}`;
};
</script>

<template>
  <tr class="field-row">
    <td v-if="editable" class="p-0 heading-title-column overflow-visible">
      <FieldFilterColumn
        :section="section"
        :sectionIndex="sectionIndex"
        :field="field"
        :loadingSamples="loadingSamples || section.loading"
        @loadSamples="(data) => $emit('loadSamples', data)"
      >
        <i class="fas fa-filter dropdown-toggle" />
      </FieldFilterColumn>
    </td>
    <td class="heading-title-column identifier-column-sticky">
      <span :class="{ 'me-2': isReference(field) }">{{ field.label }}</span>
      <span
        v-if="isReference(field)"
        class="badge rounded-pill bg-primary"
        :title="`Link to ${field.options?.template_tab_title}`"
      >
        <i class="fas fa-link"></i>
      </span>
    </td>
    <td
      v-for="sample in samples"
      :key="getTdKey(sample)"
      class="clickable"
      :class="{
        'bg-dark text-white': isSelected(getInputValue(sample)),
      }"
      @click="
        $emit('selectField', sample);
        openFieldPopover({
          sample,
          field,
          inputValue: getInputValue(sample),
          sectionIndex,
        });
      "
    >
      <template v-if="getInputValue(sample)">
        <component
          :is="components[getTagName(sample)]"
          :field="getSectionAndField(sample).field"
          :value="getInputValue(sample)"
          :values="inputValues"
          @viewImage="(data) => $emit('viewImage', data)"
          @viewMedia="(data) => $emit('viewMedia', data)"
        />
      </template>
      <template v-else> - </template>
    </td>
  </tr>
</template>
