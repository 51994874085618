import { Feature, Map, Overlay, getUid } from 'ol';
import type { Coordinate } from 'ol/coordinate';
import { Point } from 'ol/geom';
import {
  checkEqualityOfTwoCoordinatesLeniently,
  checkIsValidLatLng,
  fromLonLat,
} from '../../common/coordinate';
import { Code, throwError } from '../../common/error';
import { getStoreApi } from '../../common/store-api';
import type { Id, LegacyLatLng } from '../../common/types';
import { applyLayoutZoomToRead, getLayoutZoom } from '../../measurement/layout';
import type { LegacyPixel, Size } from '../../measurement/types';
import { Dpi } from '../../measurement/types';
import type { LayerModel } from '../types';
import { LayerType } from '../types';
import { ICON_SIZE_ADJUST_FACTOR } from './getDefaultSampleStyle';
import getSampleStyle from './getSampleStyle';
import type {
  LabelPosition,
  LegacyPixelCoordinate,
  Sample,
  SampleGroup,
  SampleGroupProperties,
  SampleIcon,
  SampleLayer,
  SupportedLabelPosition,
} from './types';

export const ALL_ITEMS_OPTION_VALUE = 0;

export const DEFAULT_LABEL_POSITION: LabelPosition = Object.freeze({
  offsetX: 0,
  offsetY: 0,
});

export function normalizeSampleLocation(sample: any): Sample {
  let { longitude, latitude } = sample;
  if (typeof longitude === 'string') {
    longitude = parseFloat(longitude);
  }
  if (typeof latitude === 'string') {
    latitude = parseFloat(latitude);
  }
  return {
    ...sample,
    longitude,
    latitude,
  };
}

export function getSampleTitle(sample: Sample): string {
  const { custom_title: customTitle, lab_title: labTitle } = sample;
  return (customTitle || labTitle) ?? '';
}

export function getSampleLocation(
  map: Map,
  sample: Sample
): { longitude: number | null; latitude: number | null } {
  const storeApi = getStoreApi(map);
  const { longitude, latitude, is_non_spatial, linked_spatial_sample_id } =
    sample;
  if (!is_non_spatial) {
    return { longitude, latitude };
  } else if (linked_spatial_sample_id) {
    const linkedSpatialSample = storeApi.findSampleById(
      linked_spatial_sample_id
    );
    if (linkedSpatialSample) {
      return getSampleLocation(map, linkedSpatialSample);
    }
  }

  return { longitude: null, latitude: null };
}

export function checkIsRenderableNonSpatialSample(sample: Sample): boolean {
  const { is_non_spatial, linked_spatial_sample_id } = sample;
  return is_non_spatial && !!linked_spatial_sample_id;
}

export function checkIsFilteredOutBySampleGroupInternal(
  sample: Sample,
  sampleGroupProperties: SampleGroupProperties
): boolean {
  const {
    renderableAppLinkConfig,
    unrenderableAppLinkConfigs = [],
    filteredUnrenderableItemIds = [],
  } = sampleGroupProperties;
  if (
    renderableAppLinkConfig &&
    unrenderableAppLinkConfigs.length > 0 &&
    ![undefined, ALL_ITEMS_OPTION_VALUE].includes(
      filteredUnrenderableItemIds[0]
    )
  ) {
    const { linkFieldId } = unrenderableAppLinkConfigs[0];
    const { input_values_for_linking } = sample;
    return !input_values_for_linking.some((iv) => {
      return (
        iv.template_field_id === linkFieldId &&
        parseInt(iv.value as string, 10) === filteredUnrenderableItemIds[0]
      );
    });
  }

  return false;
}

export function checkIsFilteredOutBySampleGroup(
  map: Map,
  sample: Sample
): boolean {
  const storeApi = getStoreApi(map);
  const layerModel = storeApi.getSampleLayerModel(sample);

  if (!layerModel || !checkIsSampleGroup(layerModel)) {
    throwError(Code.InvalidArgument, sample.id);
  }

  return checkIsFilteredOutBySampleGroupInternal(
    sample,
    layerModel.geojson.properties
  );
}

export function checkIsSampleGroup(model: LayerModel): model is SampleGroup {
  const { type } = model.geojson.properties;
  return type === LayerType.SAMPLE_GROUP;
}

export function getSampleIcon(
  icon: number,
  color: string,
  width: number = 22,
  height: number = 22,
  adjustFactor: number = ICON_SIZE_ADJUST_FACTOR
): SampleIcon {
  const iconColorParam = encodeURIComponent(color);
  let src = `/markers/${
    icon + 1
  }?fill=${iconColorParam}&stroke=${iconColorParam}`;
  width = Math.round(width * adjustFactor);
  height = Math.round(height * adjustFactor);
  src += `&width=${width}&height=${height}`;
  const size = [width, height] as Size;
  return {
    src,
    size,
  };
}

export function addSampleFeature(
  map: Map,
  layer: SampleLayer,
  sample: Sample,
  isDuplicate: boolean
): Feature<Point> {
  const position = getPosition(map, sample)!;
  const feature = new Feature(new Point(position));
  feature.setId(sample.id);
  feature.set('layerUid', getUid(layer));
  feature.set('isDuplicate', isDuplicate);
  layer.getSource().addFeature(feature);
  return feature;
}

export function updateSampleFeature(
  map: Map,
  layer: SampleLayer,
  sample: Sample
): void {
  const position = getPosition(map, sample)!;
  const feature = layer.getSource().getFeatureById(sample.id);
  feature.getGeometry()!.setCoordinates(position);
}

export function removeSampleFeature(layer: SampleLayer, sampleId: Id): void {
  const source = layer.getSource();
  const feature = source.getFeatureById(sampleId);
  if (feature) {
    source.removeFeature(feature);
  }
}

export function parseMarkerIdentifier(value: string): {
  icon: number;
  color: string;
} {
  const matchResult = value.match(/(\d+)_(#[a-zA-Z0-9]{6})/);
  if (matchResult) {
    return {
      icon: parseInt(matchResult[1], 10),
      color: matchResult[2],
    };
  }

  return { icon: 0, color: '#000000' };
}

export function getOverlaySize(o: Overlay): Size {
  const { width, height } = o.getElement()!.getBoundingClientRect();
  return [width, height];
}

// Get the position of the sample.
export function getPosition(map: Map, sample: Sample): Coordinate | null {
  const { longitude, latitude } = getSampleLocation(map, sample);
  const latLng = { lat: latitude, lng: longitude };
  return checkIsValidLatLng(latLng)
    ? fromLonLat(
        { longitude: latLng.lng, latitude: latLng.lat },
        map.getView().getProjection()
      )
    : null;
}

// Get the label position of a sample. If the icon and the label are glued then
// the label position equals to the position or else the label position is
// calculated based on offsets.
export function getLabelPosition(map: Map, sample: Sample): Coordinate | null {
  const position = getPosition(map, sample);
  if (!position) {
    return null;
  }

  const labelPosition = sample.label_position ?? DEFAULT_LABEL_POSITION;

  if (checkIsLegacyPixelCoordinate(labelPosition)) {
    const { x, y } = labelPosition;
    return [x, y];
  }

  if (checkIsLegacyLatLng(labelPosition)) {
    return fromLonLat(
      { longitude: labelPosition.lng, latitude: labelPosition.lat },
      map.getView().getProjection()
    );
  }

  if (checkIsNull(labelPosition)) {
    return position;
  }

  let offsetX;
  let offsetY;
  if (checkIsLabelPosition(labelPosition)) {
    offsetX = labelPosition.offsetX;
    offsetY = labelPosition.offsetY;
  } else if (checkIsLegacyPixel(labelPosition)) {
    offsetX = labelPosition.x;
    offsetY = labelPosition.y;
  }
  const isGlued = checkIsGlued(map, [offsetX, offsetY]);
  if (isGlued) {
    return position;
  }

  const offsets = applyLayoutZoomToRead<Size>(
    map,
    () => {
      return [offsetX, offsetY];
    },
    (offsets) => {
      return offsets.map((o) => Math.round(o)) as Size;
    }
  )();
  const pixel = map.getPixelFromCoordinate(position);
  const labelPixel = [pixel[0] + offsets[0], pixel[1] + offsets[1]];
  return map.getCoordinateFromPixel(labelPixel);
}

// Used to locate a sample label when the sample is being rendered.
export const getLabelOffsets = (map: Map, sample: Sample): Size => {
  const position = getPosition(map, sample);
  if (!position) {
    return [0, 0];
  }

  const labelPosition = getLabelPosition(map, sample)!;
  if (checkIsGlued(map, { position, labelPosition })) {
    return [0, getGluedOffsetY(map, sample)];
  }

  const pixel = map.getPixelFromCoordinate(position);
  const labelPixel = map.getPixelFromCoordinate(labelPosition);
  return [labelPixel[0] - pixel[0], labelPixel[1] - pixel[1]];
};

function checkIsLegacyPixelCoordinate(
  value: SupportedLabelPosition
): value is LegacyPixelCoordinate {
  return checkHasXy(value) && 'isCoordinate' in value;
}

function checkIsLegacyLatLng(
  value: SupportedLabelPosition
): value is LegacyLatLng {
  return (
    'lat' in value &&
    typeof value.lat === 'number' &&
    'lng' in value &&
    typeof value.lng === 'number'
  );
}

function checkIsNull(
  value: SupportedLabelPosition
): value is { lat: null; lng: null } {
  return (
    'lat' in value && value.lat === null && 'lng' in value && value.lng === null
  );
}

function checkIsLabelPosition(
  value: SupportedLabelPosition
): value is LabelPosition {
  return (
    'offsetX' in value &&
    typeof value.offsetX === 'number' &&
    'offsetY' in value &&
    typeof value.offsetY === 'number'
  );
}

function checkIsLegacyPixel(
  value: SupportedLabelPosition
): value is LegacyPixel {
  return checkHasXy(value) && !('isCoordinate' in value);
}

function checkHasXy(value: SupportedLabelPosition) {
  return 'x' in value && 'y' in value;
}

type Positions = { position: Coordinate; labelPosition: Coordinate };
// When glued, there is no space between the icon and the label.
export function checkIsGlued(map: Map, offsets: Size): boolean;
export function checkIsGlued(map: Map, positions: Positions): boolean;
export function checkIsGlued(map: Map, param: Size | Positions): boolean {
  return Array.isArray(param)
    ? param[0] === 0 && param[1] === 0
    : checkEqualityOfTwoCoordinatesLeniently(
        map,
        param.position,
        param.labelPosition
      );
}

function getGluedOffsetY(map: Map, sample: Sample): number {
  const storeApi = getStoreApi(map);
  const figure = storeApi.getSelectedFigure()!;
  const zoom = getLayoutZoom(map);
  const { isIconVisible, icon, color, iconSize } = getSampleStyle(
    map,
    figure,
    sample
  );
  const { size } = getSampleIcon(icon, color, iconSize * zoom, iconSize * zoom);
  return isIconVisible ? size[1] / 2 : 0;
}

export const preloadedSampleIcons: Record<string, HTMLImageElement> = {};
// Sample icons used for exporting figures on a print paper should be
// preloaded or they are invisible when the figure is exported for the
// first time.
export async function preloadSampleIcons(map: Map, samples: Sample[]) {
  const storeApi = getStoreApi(map);
  const figure = storeApi.getSelectedFigure()!;
  const zoom = Dpi.PrinterPaper / Dpi.Screen;
  for (const s of samples) {
    const { icon, color, iconSize } = getSampleStyle(map, figure, s);
    const {
      src,
      size: [w, h],
    } = getSampleIcon(icon, color, iconSize * zoom, iconSize * zoom);
    if (!preloadedSampleIcons[src]) {
      await new Promise<void>((resolve) => {
        const img = document.createElement('img');
        img.src = src;
        img.width = w;
        img.height = h;
        img.onload = () => {
          preloadedSampleIcons[src] = img;
          resolve();
        };
      });
    }
  }
}
