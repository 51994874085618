import { Fill, Stroke } from 'ol/style';
import { BLACK, WHITE } from '../../../business-logic/color';
import addOpacity from './addOpacity';
import getMapFont from './getMapFont';

/**
 * Get text style config.
 * @param {ol/Map} map
 * @param {boolean} isTemporary The measurements which show on drawing and editing are temporary.
 * @returns
 */
export function getMeasurementStyleConfig(map, isTemporary = false) {
  const { zoom } = map.getViewer().figureLayout;

  return {
    font: getMapFont(),
    fill: new Fill({
      color: isTemporary ? WHITE : BLACK,
    }),
    stroke: new Stroke({
      color: isTemporary ? addOpacity(BLACK, 0.4) : WHITE,
      width: 4,
    }),
    scale: zoom,
  };
}

export function getLabelStyleConfig(map, label, color) {
  const { zoom } = map.getViewer().figureLayout;

  return {
    text: label,
    font: getMapFont(),
    fill: new Fill({
      color,
    }),
    stroke: new Stroke({
      color: WHITE,
      width: 4,
    }),
    backgroundFill: new Fill({
      color: addOpacity(WHITE, 0.9),
    }),
    padding: [2, 0, 0, 2],
    justify: 'center',
    scale: zoom,
    overflow: true,
  };
}
