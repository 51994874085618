import generateDashboardItemLayoutId from './generateDashboardItemLayoutId';

/**
 * Return a list of vacant dashbaord cell layouts.
 * A dashboard cell is the minimum available space in the dashboard.
 * Its width and height are 1-unit long.
 * @param {*} dashboardLayout
 * @param {*} dashboardColNum
 * @param {*} dashboardRowNum
 * @returns
 */
function getVacantDashboardCellLayouts(
  dashboardLayout,
  dashboardColNum,
  dashboardRowNum
) {
  const result = [];

  for (let i = 0; i < dashboardColNum; i++) {
    for (let j = 0; j < dashboardRowNum; j++) {
      if (
        !dashboardLayout.find((itemLayout) => {
          const topLeft = {
            x: itemLayout.x,
            y: itemLayout.y,
          };
          const bottomRight = {
            x: itemLayout.x + itemLayout.w,
            y: itemLayout.y + itemLayout.h,
          };
          return (
            i >= topLeft.x &&
            j >= topLeft.y &&
            i + 1 <= bottomRight.x &&
            j + 1 <= bottomRight.y
          );
        })
      ) {
        result.push({ x: i, y: j, w: 1, h: 1 });
      }
    }
  }

  return result;
}

function isEqual(itemLayouts1, itemLayouts2) {
  if (itemLayouts1.length !== itemLayouts2.length) {
    return false;
  }

  for (let i = 0; i < itemLayouts1.length; i++) {
    const { x: x1, y: y1, w: w1, h: h1 } = itemLayouts1[i];
    const { x: x2, y: y2, w: w2, h: h2 } = itemLayouts2[i];
    if (x1 !== x2 || y1 !== y2 || w1 !== w2 || h1 !== h2) {
      return false;
    }
  }

  return true;
}

function mergeDashboardItemLayouts(itemLayouts) {
  let compositeItemLayouts = [];

  while (!isEqual(itemLayouts, compositeItemLayouts)) {
    itemLayouts.forEach((itemLayout) => {
      // A target is either a vertical or a horizontal dashboard item layout to
      // which the current dashboard item layout can be merged.
      let targetExists = false;

      const verticalTarget = compositeItemLayouts.find(
        (compositeItemLayout) =>
          compositeItemLayout.y + compositeItemLayout.h === itemLayout.y &&
          compositeItemLayout.x === itemLayout.x &&
          compositeItemLayout.w === itemLayout.w
      );
      if (verticalTarget) {
        targetExists = true;
        verticalTarget.h += itemLayout.h;
      }

      if (!targetExists) {
        const horizontalTarget = compositeItemLayouts.find(
          (compositeItemLayout) =>
            compositeItemLayout.x + compositeItemLayout.w === itemLayout.x &&
            compositeItemLayout.y === itemLayout.y &&
            compositeItemLayout.h === itemLayout.h
        );
        if (horizontalTarget) {
          targetExists = true;
          horizontalTarget.w += itemLayout.w;
        }
      }

      if (!targetExists) {
        compositeItemLayouts.push({ ...itemLayout });
      }
    });

    if (!isEqual(itemLayouts, compositeItemLayouts)) {
      itemLayouts = compositeItemLayouts;
      compositeItemLayouts = [];
    }
  }

  return compositeItemLayouts;
}

export default (
  dashboardLayout,
  dashboardColNum,
  dashboardRowNum,
  widthUnits,
  heightUnits
) => {
  const vacantCellLayouts = getVacantDashboardCellLayouts(
    dashboardLayout,
    dashboardColNum,
    dashboardRowNum
  );
  const vacantItemLayouts = mergeDashboardItemLayouts(vacantCellLayouts);

  if (vacantItemLayouts.length === 0) {
    return null;
  }

  vacantItemLayouts.sort((vacantlayoutitem1, vacantlayoutitem2) => {
    return (
      vacantlayoutitem1.x - vacantlayoutitem2.x ||
      vacantlayoutitem1.y - vacantlayoutitem2.y
    );
  });
  const eligibleItemLayout = vacantItemLayouts.find(
    (vacantitemlayout) =>
      vacantitemlayout.w >= widthUnits && vacantitemlayout.h >= heightUnits
  );

  if (eligibleItemLayout) {
    return {
      x: eligibleItemLayout.x,
      y: eligibleItemLayout.y,
      w: widthUnits,
      h: heightUnits,
      i: generateDashboardItemLayoutId(),
    };
  } else {
    return {
      ...vacantItemLayouts[0],
      i: generateDashboardItemLayoutId(),
    };
  }
};
