import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { isProd } from '../utils/is-prod';

export interface Reward {
    percentage: number;
    text: string;
};

export const useNavigationStore = defineStore('navigation', () => {
    const forceOpenHelpDropdown = ref(false);
    const showFeedbackModal = ref(false);
    const showNotificationDropdown = ref(false);
    const demoModeEnabled = ref(localStorage.getItem('demoModeEnabled') === '1');

    const loadingClosestReward = ref(false);
    const closestReward = ref<Reward | null>(null);

    function toggleFeedbackModal(show: boolean) {
        showFeedbackModal.value = show;
    }

    function toggleHelpDropdown() {
        forceOpenHelpDropdown.value = true;

        setTimeout(() => {
            forceOpenHelpDropdown.value = false;
        }, 1000);
    }

    function enableDemoMode() {
        demoModeEnabled.value = true;
        localStorage.setItem('demoModeEnabled', '1');
    }

    function disableDemoMode() {
        demoModeEnabled.value = false;
        localStorage.removeItem('demoModeEnabled');
    }

    function toggleDemoMode() {
        demoModeEnabled.value = !demoModeEnabled.value;
        localStorage.setItem('demoModeEnabled', demoModeEnabled.value ? '1' : '0');
    }

    const isProduction = computed(() => {
        return isProd;
    });

    return {
        forceOpenHelpDropdown,
        showFeedbackModal,
        showNotificationDropdown,
        loadingClosestReward,
        closestReward,

        isProduction,

        demoModeEnabled: computed(() => demoModeEnabled.value),
        toggleDemoMode,
        enableDemoMode,
        disableDemoMode,

        toggleFeedbackModal,
        toggleHelpDropdown,
        toggleNotificationDropdown(state?: boolean) {
            showNotificationDropdown.value = state || !showNotificationDropdown.value;
        },
        openNotificationDropdown() {
            showNotificationDropdown.value = true;
        },
    };
});