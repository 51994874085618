import * as bl_color from '../color';

export * as figure_styling_rule from './figure-styling-rule';

export function getDefaultColors() {
  return bl_color.SWATCHES;
}

export function getFillPatterns() {
  return [
    { title: 'Overlay', identifier: 0, class: 'overlay' },
    { title: 'No Overlay', identifier: 1, class: 'noverlay' },
    { title: 'Horizontal Stripe', identifier: 2, class: 'hstripe' },
    { title: 'Vertical Stripe', identifier: 3, class: 'vstripe' },
    { title: 'Dots', identifier: 4, class: 'dots' },
    { tilte: 'Circle', identifier: 5, class: 'circle' },
    { title: '45° Cross', identifier: 6, class: '45degcross' },
    { title: '45° Stripe', identifier: 7, class: '45degstripe' },
  ];
}

export function getOutlinePatterns() {
  return [
    { title: 'Solid', identifier: 0, class: 'solid' },
    { title: 'Dashed', identifier: 1, class: 'dashed' },
    { title: 'Dotted', identifier: 2, class: 'dotted' },
  ];
}

export enum SampleExceedanceColor {
  Landuse = '#dd4545',
  Criteria = '#dd4545',
  NoExceedances = '#5aa847',
}

export enum BuiltinSampleGroupSubtitle {
  Default = '',
  ExceedsAdoptedCriteria = 'Exceeds Adopted Criteria',
  NoExceedances = 'No Exceedances',
}
