import { processInputResponse } from '@component-library/business-logic/input-value';

const getSamples = (data) => {
  return axios.get('/gather/samples', {
    params: data,
  });
};

const getInputValuesBySection = (data) => {
  return axios
    .get('/gather/section/input-values', {
      params: data,
    })
    .then((response) => {
      return processInputResponse(response);
    });
};

const getTemplate = ({ tab_id, filters }) => {
  return axios.get('/gather/template/' + tab_id, {
    params: {
      filters,
    },
  });
};

const getFilterableValues = (data) => {
  return axios.get('/gather/filterable-values', {
    params: data,
  });
};

const getCompoundApp = async (appIds) => {
  const tabIdsParam = appIds.map((appId) => `tab_ids[]=${appId}`).join('&');
  const { data: compoundApp } = await axios.get(
    `/gather/compound-tab?${tabIdsParam}`
  );
  return compoundApp;
};

export default {
  getSamples,
  getInputValuesBySection,
  getTemplate,
  getFilterableValues,
  getCompoundApp,
};
