import { NOOP } from '../utils';

/**
 * Features in a feature collection are not editable.
 * @returns
 */
export default function createFeatureCollectionEdit() {
  return {
    activate: NOOP,
    destroy: NOOP,
    selectFeature: NOOP,
    getFeature() {
      return null;
    },
  };
}
