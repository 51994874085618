export function generateTextPlaceholder(existingTexts, prefix = 'Text') {
  const regexp = new RegExp(`^${prefix}\\s(\\d+)$`);

  let maxTextNumber = 0;
  existingTexts.forEach((item) => {
    const matchResult = regexp.exec(item);
    if (matchResult) {
      const textNumber = parseInt(matchResult[1], 10);
      if (textNumber > maxTextNumber) {
        maxTextNumber = textNumber;
      }
    }
  });

  return `${prefix} ${maxTextNumber + 1}`;
}
