<template>
  <div
    class="slideshow__size-toggle d-flex flex-column"
    :style="{
      left: `calc(${
        block.sidebar_is_right ? 100 - block.sidebar_width : block.sidebar_width
      }% - 20px)`,
    }"
  >
    <button class="btn btn-dark mb-2" @click="updateToNextWidth">
      <i class="fas fa-arrows-alt-h" />
    </button>
    <button
      class="btn btn-dark"
      @click="
        $emit('updateSlideshow', {
          sidebar_is_right: !block.sidebar_is_right,
        })
      "
    >
      <i class="fas fa-exchange-alt" />
    </button>
  </div>
</template>
<script>
export default {
  props: {
    block: Object,
  },
  methods: {
    updateToNextWidth() {
      this.$emit('updateSlideshow', {
        sidebar_width: this.block.sidebar_width == 30 ? 50 : 30,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.slideshow__size-toggle {
  position: absolute;
  z-index: 5;
  justify-content: center;
  height: calc(100vh - 175px - 70px);
  padding: 0px !important;
}

.viewer__container--headings .slideshow__size-toggle {
  height: calc(100vh - 175px - 70px - 50px);
}
</style>
