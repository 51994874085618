export { default as createDraw } from './createDraw';
export { default as createCircleEdit } from './createCircleEdit';
export { default as createPolyEdit } from './createPolyEdit';
export { default as createChainageEdit } from './createChainageEdit';
export { default as createSampleEdit } from './createSampleEdit';
export { default as createSquareEdit } from './createSquareEdit';
export { default as createTextEdit } from './createTextEdit';
export { default as createFeatureCollectionEdit } from './createFeatureCollectionEdit';
export { default as createBufferEdit } from './createBufferEdit';
export { default as InteractionManager } from './InteractionManager';
export * as image from './image';

export function enableInteractions(map) {
  map.getInteractions().forEach((interaction) => interaction.setActive(true));
}

export function disableInteractions(map) {
  map.getInteractions().forEach((interaction) => interaction.setActive(false));
}
