import { Overlay, Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { always } from 'ol/events/condition';
import * as olCoordinate from 'ol/coordinate';
import Transform from 'ol-ext/interaction/Transform';
import store from '@/js/store';
import { LAYER_TYPES, assignIdToLayer, getMaxZIndex } from '../layers';
import { createTextStyle, changeTransformStyle } from '../styles';
import EventBus from '@component-library/EventBus';
import { NAMESPACE } from '../../../store';

/**
 * EditableText uses ol/Overlay to input and transform
 * a piece of text on the map.
 * The transformations include rotation and moving.
 */
export default class EditableText {
  /**
   *
   * @param {ol/Map} map
   * @param {Object} managedText
   */
  constructor(map, managedText) {
    this.map = map;
    this.managedText = managedText;
    this.rotationAngle = 0;
    this.textAngle = 0;
    this.translateStartCoord = null;
    this.translateCurrentCoord = null;
    this.input = createInput({
      onInput: (event) => {
        this.managedText.setText(event.target.value);
      },
      onBlur: () => {
        this.switchToTransformer();
      },
    });
    this.transformer = createTransformer({
      getLayerId: () => {
        return this.managedText.layerUid;
      },
      onClick: () => {
        if (
          olCoordinate.equals(
            this.translateStartCoord,
            this.translateCurrentCoord
          )
        ) {
          this.switchToInput();
        }
      },
      onRotating: (rotationAngle) => {
        this.rotationAngle = rotationAngle;
        this.textAngle = this.managedText.getTextAngle();
        this.textAngle += this.rotationAngle;
        this.transformer.setTextAngle(this.textAngle);
      },
      onRotateEnd: () => {
        this.managedText.setTextAngle(this.textAngle);
      },
      onTranslateStart: (refCoord) => {
        this.translateStartCoord = refCoord;
        this.translateCurrentCoord = refCoord;
      },
      onTranslating: (refCoord) => {
        const [x1, y1] = map.getPixelFromCoordinate(this.translateCurrentCoord);
        const [x2, y2] = map.getPixelFromCoordinate(refCoord);
        const deltaX = x2 - x1;
        const deltaY = y2 - y1;
        let position = this.managedText.getPosition();
        const pixel = map.getPixelFromCoordinate(position);
        position = map.getCoordinateFromPixel([
          pixel[0] + deltaX,
          pixel[1] + deltaY,
        ]);
        this.managedText.setPosition(position);
        this.currentControl.setPosition(position);
        this.translateCurrentCoord = refCoord;
      },
    });
    // The current control could be either the input or the transformer
    this.currentControl = null;
    this.updateTextListener = () => {
      this.update();
    };
    EventBus.$on('updateText', this.updateTextListener);
  }
  switchToInput() {
    switchTo(this, this.input);
  }
  switchToTransformer() {
    switchTo(this, this.transformer);
  }
  destroy() {
    if (this.currentControl) {
      this.currentControl.removeFromMap(this.map);
    }
    EventBus.$off('updateText', this.updateTextListener);
  }
  /**
   * Use data from the store to do the update.
   */
  update() {
    const text = this.managedText.getText();
    const position = this.managedText.getPosition();
    if (this.currentControl === this.input) {
      this.currentControl.setText(text);
    } else if (this.currentControl === this.transformer) {
      const style = createTextStyle(
        NAMESPACE.getState(store, 'shapeProperties'),
        this.map.getViewer().figureLayout.zoom
      );
      this.currentControl.setTextAndStyle(text, style);
    }
    this.currentControl.setPosition(position);
  }
}

function switchTo(editableText, control) {
  if (editableText.currentControl) {
    editableText.currentControl.removeFromMap(editableText.map);
  }
  editableText.currentControl = control;
  editableText.currentControl.addToMap(
    editableText.map,
    editableText.managedText.getPosition()
  );
  editableText.update();
}

function createInput({ onInput, onBlur }) {
  const element = document.createElement('div');
  element.setAttribute('class', 'form-group');

  const textarea = document.createElement('textarea');
  textarea.setAttribute('placeholder', 'Type text here...');
  textarea.setAttribute('class', 'form-control');
  textarea.style.resize = 'both';
  textarea.style.height = '66px';
  textarea.addEventListener('input', onInput);
  textarea.addEventListener('blur', onBlur);
  element.appendChild(textarea);
  const overlay = new Overlay({ element });

  function setText(text) {
    textarea.value = text;
  }

  return {
    addToMap(map, position) {
      map.addOverlay(overlay);
      this.setPosition(position);
      setTimeout(() => {
        textarea.focus();
      });
    },
    removeFromMap(map) {
      map.removeOverlay(overlay);
    },
    setPosition(position) {
      position && overlay.setPosition(position);
    },
    setText,
  };
}

/**
 * Transformer is used rotate and move the text
 * @param {*} An object that defines event listeners
 * @returns
 */
function createTransformer({
  getLayerId,
  onClick,
  onRotating,
  onRotateEnd,
  onTranslateStart,
  onTranslating,
}) {
  // Create the overlay
  const element = document.createElement('div');
  element.style.whiteSpace = 'pre-wrap';
  element.addEventListener('click', onClick);

  const placeholder = document.createElement('div');
  placeholder.innerHTML = 'text here';
  element.appendChild(placeholder);

  const overlay = new Overlay({ element, stopEvent: false });

  // Create the transform
  // The transform interaction is from ol-ext. It is used to do square resizing, rotation and moving.
  // For usage of transform, see https://viglino.github.io/ol-ext/examples/interaction/map.interaction.transform.html
  const transform = new Transform({
    hitTolerance: 2,
    translateFeature: true,
    scale: false,
    rotate: true,
    keepAspectRatio: always,
    translate: false,
    stretch: false,
    translateBBox: false,
    enableRotatedTransform: true,
    // Don't use the Select interaction so that the shape can keep being selected
    selection: false,
  });
  transform.on('rotating', ({ angle: radian }) => {
    const rotationAngle = (radian / Math.PI) * 180;
    onRotating(rotationAngle);
  });
  transform.on('rotateend', onRotateEnd);
  transform.on('translatestart', ({ feature }) => {
    const refCoord = getRefCoord(feature);
    onTranslateStart(refCoord);
  });
  transform.on('translating', ({ feature }) => {
    const refCoord = getRefCoord(feature);
    onTranslating(refCoord);
  });

  // Create the layer
  const source = new VectorSource({
    features: [],
  });
  source.on('addfeature', ({ feature }) => {
    transform.select(feature, true);
  });
  source.on('removefeature', ({ feature }) => {
    transform.select(feature, false);
  });
  const layer = new VectorLayer({
    source,
  });
  assignIdToLayer(layer);
  layer.options = {
    type: LAYER_TYPES.TEXT_MATE,
  };
  layer.setZIndex(getMaxZIndex());

  function setText(text, refresh = true) {
    const { childNodes } = element;
    if (childNodes.length === 1) {
      // only the placehoder
      const textNode = document.createTextNode(text);
      element.appendChild(textNode);
    } else {
      // Find and update the text node
      childNodes.forEach((node) => {
        if (node.nodeName === '#text') {
          node.nodeValue = text;
        }
      });
    }
    placeholder.style.display = text ? 'none' : 'block';
    refresh && refreshTextBCRFeature();
  }

  function setStyle(style, refresh = true) {
    for (const property in style) {
      element.style[property] = style[property];
    }
    refresh && refreshTextBCRFeature();
  }

  function setTextAndStyle(text, style) {
    setText(text, false);
    setStyle(style, false);
    refreshTextBCRFeature();
  }

  function setTextAngle(textAngle) {
    let transformStyle = element.style.transform;
    transformStyle = transformStyle.replace(
      /(rotate\()(.+)(deg\))/,
      `$1${-textAngle}$3`
    );
    element.style.transform = transformStyle;
  }

  function getRefCoord(feature) {
    return feature.getGeometry().getLinearRing(0).getCoordinates()[0];
  }

  function addTextBCRFeature() {
    const textBCRFeature = createTextBCRFeature(overlay);
    textBCRFeature.layerUid = getLayerId();
    source.addFeature(textBCRFeature);
  }

  function removeTextBCRFeature() {
    const [feature] = source.getFeatures();
    if (feature) {
      source.removeFeature(feature);
    }
  }

  function refreshTextBCRFeature() {
    removeTextBCRFeature();
    addTextBCRFeature();
  }

  return {
    addToMap(map, position) {
      map.addOverlay(overlay);
      map.addInteraction(transform);
      changeTransformStyle(transform);
      map.layerManager.addLayer(layer);
      map.getView().on('change:resolution', refreshTextBCRFeature);
      // Setting position causes the overlay to be rendered, so it
      // must be called before textBCRFeature creation
      this.setPosition(position);
      addTextBCRFeature();
    },
    removeFromMap(map) {
      map.removeOverlay(overlay);
      map.removeInteraction(transform);
      map.layerManager.removeLayer(layer);
      map.getView().un('change:resolution', refreshTextBCRFeature);
      removeTextBCRFeature();
    },
    setPosition(position) {
      if (position) {
        overlay.setPosition(position);
        transform.setCenter(position);
      }
    },
    setText,
    setStyle,
    setTextAndStyle,
    setTextAngle,
  };
}

function createTextBCRFeature(overlay) {
  const element = overlay.getElement();
  const textBCR = element.getBoundingClientRect();
  const map = overlay.getMap();
  const mapTargetBCR = map.getTargetElement().getBoundingClientRect();
  const startPixel = [
    textBCR.left - mapTargetBCR.left,
    textBCR.top - mapTargetBCR.top,
  ];
  const p0 = map.getCoordinateFromPixel(startPixel);
  const p1 = map.getCoordinateFromPixel([
    startPixel[0] + textBCR.width,
    startPixel[1],
  ]);
  const p2 = map.getCoordinateFromPixel([
    startPixel[0] + textBCR.width,
    startPixel[1] + textBCR.height,
  ]);
  const p3 = map.getCoordinateFromPixel([
    startPixel[0],
    startPixel[1] + textBCR.height,
  ]);
  const geometry = new Polygon([[p0, p1, p2, p3, p0]]);
  return new Feature(geometry);
}
