import { UnitShortName, Unit, AbsoluteUnit } from './types';

export const KILOMETER = new AbsoluteUnit(
  UnitShortName.Kilometer,
  'Kilometre',
  'Kilometres',
  1000,
  undefined
);

export const METER = new AbsoluteUnit(
  UnitShortName.Meter,
  'Metre',
  'Metres',
  1,
  KILOMETER
);

export const CENTIMETER = new AbsoluteUnit(
  UnitShortName.Centimeter,
  'Centimetre',
  'Centimetres',
  0.01,
  METER
);

export const MILLIMETER = new AbsoluteUnit(
  UnitShortName.Millimeter,
  'Millimetre',
  'Millimetres',
  0.001,
  CENTIMETER
);

export const MILE = new AbsoluteUnit(
  UnitShortName.Mile,
  'Mile',
  'Miles',
  1609.34,
  undefined
);

export const YARD = new AbsoluteUnit(
  UnitShortName.Yard,
  'Yard',
  'Yards',
  0.9144,
  MILE
);

export const FEET = new AbsoluteUnit(
  UnitShortName.Foot,
  'Foot',
  'Feet',
  0.3048,
  YARD
);

export const INCH = new AbsoluteUnit(
  UnitShortName.Inch,
  'Inch',
  'Inches',
  0.0254,
  FEET
);

export const PIXEL = new Unit(UnitShortName.Pixel, 'Pixel', 'Pixels');

export const POINT = new Unit(UnitShortName.Point, 'Point', 'Points');

export function checkIsMetric(unit: AbsoluteUnit): boolean {
  return (
    [
      UnitShortName.Millimeter,
      UnitShortName.Centimeter,
      UnitShortName.Meter,
      UnitShortName.Kilometer,
    ].indexOf(unit.shortName) !== -1
  );
}

export function checkIsImperial(unit: AbsoluteUnit): boolean {
  return (
    [
      UnitShortName.Inch,
      UnitShortName.Foot,
      UnitShortName.Yard,
      UnitShortName.Mile,
    ].indexOf(unit.shortName) !== -1
  );
}
