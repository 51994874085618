<script setup>
import makeId from '../local-id.mjs';
import { onBeforeMount, ref, computed, onMounted } from 'vue';

const props = defineProps({
  label: String,
  placeholder: String,
  name: String,
  modelValue: String | null,
  value: String | null,
  errorBag: {
    type: Object,
    default: () => ({}),
  },
  type: {
    type: String,
    default: 'type',
  },
  required: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false,
  },
  autoResize: {
    required: false,
    default: false,
  },
  isDisabled: Boolean,
});

const emit = defineEmits(['update:modelValue']);

const id = ref(props.name + '-' + makeId());

onBeforeMount(updateId);
onMounted(autoResize);

const inputError = computed(() => {
  return props.name in props.errorBag ? props.errorBag[props.name] : null;
});

const inputValue = computed({
  get: () => props.modelValue || props.value,
  set: (value) => {
    emit('update:modelValue', value);
    emit('input', value);
  },
});

function updateId() {
  id.value = props.name + '-' + makeId();
}

function autoResize() {
  if (!props.autoResize) return;

  const textarea = document.getElementById(id.value);
  if (!textarea) {
    throw Error('Failed to resize textarea');
  }

  textarea.style.height = Math.min(textarea.scrollHeight, 1500) + 'px';
}
</script>

<template>
  <div>
    <label class="form-label" :for="id" v-if="label">
      {{ label }} <sup class="text-danger" v-if="required">*</sup>
    </label>
    <textarea
      :type="type"
      class="form-control"
      :class="{ 'is-invalid': invalid || inputError }"
      :id="id"
      :required="required"
      :disabled="isDisabled"
      :placeholder="placeholder"
      @keypress.ctrl.enter="() => $emit('enter')"
      v-model="inputValue"
    ></textarea>
    <div class="invalid-feedback" v-if="inputError">
      {{ inputError }}
    </div>
  </div>
</template>
